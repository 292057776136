import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import React, { FC } from "react";

interface IPrivateProps {
  children: React.ReactNode;
  redirectTo?: string;
}

const PrivateRoute: FC<IPrivateProps> = ({ children, redirectTo = "/" }) => {
  const [cookies] = useCookies(["access_token"]);

  const isLogged = cookies.access_token !== undefined && cookies.access_token.length;
  return <>{isLogged ? children : <Navigate replace to={redirectTo} />}</>;
};

export default PrivateRoute;
