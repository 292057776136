import { useSelectedProjectSelector } from "../../../store/projects/selectors";
import { dispatch } from "../../../store";
import { changeSelectedTypeAction } from "../../../store/projects/actions";
import { EProjectTypes } from "../../../types";

export const ProjectTabs = () => {
  const selectedType = useSelectedProjectSelector();
  const tabs = [
    {
      code: EProjectTypes.REGISTERED,
      name: "Registered projects",
    },
    {
      code: EProjectTypes.IN_PROGRESS,
      name: "In-Progress projects",
    },
    {
      code: EProjectTypes.COMPLETED,
      name: "Completed projects",
    },
  ];

  const renderTabs = tabs.map((tab) => (
    <li className="me-8" key={tab.code}>
      <a
        href="#"
        className={
          "inline-block rounded-t-lg border-b-2 py-1 " +
          (selectedType === tab.code
            ? "active border-primaryPurple-100 text-black"
            : "border-transparent hover:border-gray-300 hover:text-gray-600")
        }
        aria-current="page"
        onClick={() => dispatch(changeSelectedTypeAction(tab.code))}
      >
        {tab.name}
      </a>
    </li>
  ));

  return (
    <div className="border-b border-gray-200 text-center text-sm font-medium text-gray-500">
      <ul className="-mb-px flex flex-wrap">{renderTabs}</ul>
    </div>
  );
};
