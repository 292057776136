import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

import App from "./components/App";

import { StoreProvider } from "./store/provider";

const CURRENT_REVISION = process.env.REACT_APP_REVISION || "0.0.1";

function clearStorageIfUpdated() {
  const storedRevision = localStorage.getItem("appRevision");
  if (storedRevision !== CURRENT_REVISION) {
    localStorage.clear();
    localStorage.setItem("appRevision", CURRENT_REVISION);
  }
}

clearStorageIfUpdated();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StoreProvider>
    <BrowserRouter>
      <StrictMode>
        <App />
      </StrictMode>
    </BrowserRouter>
  </StoreProvider>,
);
