import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { Field } from "formik";
import * as Yup from "yup";

// import { useAppDispatch } from "../store/hooks";
import { useNavigate } from "react-router-dom";
// import { loginUser } from "../store/actions/auth";

import { TLoginFormValues } from "../../types";
import MicrosoftLoginButton from "../../components/MicrosoftLoginButton";
import { loginUser } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import "./Auth.css";

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const AuthPageV2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: TLoginFormValues,
    formikHelpers: FormikHelpers<TLoginFormValues>,
  ) => {
    await dispatch(loginUser(values) as any);
    formikHelpers.resetForm();
    //   alert(JSON.stringify(values, null, 2));
    navigate("/");
  };

  const redirectToMicrosoft = () =>
    window.location.replace(`${process.env.REACT_APP_API_URL}/oauth/microsoft/login`);

  return (
    <section className="bgContainer flex min-h-screen w-full items-center justify-center px-8 py-8">
      {/* <div className="relative mx-auto px-6 py-8 md:h-full lg:py-0"> */}
      <div className="rounded-lg border bg-white px-6 py-8 shadow sm:max-w-md md:mt-0 md:max-w-lg xl:p-0">
        <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
          {/* logo goes here */}
          <div className="text-center">
            {/* <img src={proclimeLogo} alt="prcolime logo" className="mx-auto w-[138px]" /> */}
          </div>

          <h1 className="text-center text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Hi, Welcome Back!
          </h1>

          {/* login with microsoft button */}
          <div>
            <MicrosoftLoginButton onClick={redirectToMicrosoft} />
          </div>

          {/* or */}
          <div className="text-center font-normal">
            <span>or</span>
          </div>

          <Formik
            initialValues={{ userName: "", password: "" }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form className="space-y-4 md:space-y-6">
              <div className="relative">
                {/* <label htmlFor="userName" className="md-label">
                  Your userName
                </label> */}
                <Field
                  type="text"
                  name="userName"
                  id="userName"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-md border border-gray-300 bg-gray-50 p-3.5 text-gray-900 placeholder-gray-500 outline-none sm:text-sm"
                  placeholder="Username"
                />
                {/* <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  id="userName"
                  name="userName"
                  label="Email ID"
                  placeholder="Email ID"
                /> */}
                <ErrorMessage name="userName" component="div" className="text-sm text-red-500" />
              </div>
              <div>
                {/* <label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900">
                  Password
                </label> */}
                <Field
                  variant="outlined"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-md border border-gray-300 bg-gray-50 p-3.5 text-gray-900 placeholder-gray-500 outline-none sm:text-sm"
                />
                {/* <TextField
                  variant="outlined"
                  type="password"
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                /> */}
                <ErrorMessage name="password" component="div" className="text-sm text-red-500" />
              </div>

              {/* forgot password */}
              <div className="mt-0 text-end">
                <a
                  href="/"
                  className="focus:ring-primary-300 block text-sm font-medium text-[#714ECE] hover:underline focus:outline-none focus:ring-4"
                >
                  Forgot Password?
                </a>
              </div>

              {/* checkbox */}
              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="text-primary-600 focus:ring-primary-600 rounded border-gray-300 accent-[#714ECE]"
                  />
                  <span className="text-sm font-light text-[#8E949C]">
                    By proceeding Agreed To Our Terms of use & Privacy policy
                  </span>
                </label>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mx-auto w-5/6 rounded-full bg-[#482F8B] px-5 py-3.5 text-center text-sm font-medium text-white hover:bg-[#482F8B] focus:outline-none focus:ring-4"
                >
                  Login
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default AuthPageV2;
