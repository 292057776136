const selectOutlinedLabelColors: object = {
  'blue-gray': {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-blue-gray-500',
      before: 'before:border-blue-gray-500',
      after: 'after:border-blue-gray-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  gray: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-gray-900',
      before: 'before:border-gray-900',
      after: 'after:border-gray-900',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  brown: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-brown-500',
      before: 'before:border-brown-500',
      after: 'after:border-brown-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  'deep-orange': {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-deep-orange-500',
      before: 'before:border-deep-orange-500',
      after: 'after:border-deep-orange-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  orange: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-orange-500',
      before: 'before:border-orange-500',
      after: 'after:border-orange-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  amber: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-amber-500',
      before: 'before:border-amber-500',
      after: 'after:border-amber-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  yellow: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-yellow-500',
      before: 'before:border-yellow-500',
      after: 'after:border-yellow-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  lime: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-lime-500',
      before: 'before:border-lime-500',
      after: 'after:border-lime-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  'light-green': {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-light-green-500',
      before: 'before:border-light-green-500',
      after: 'after:border-light-green-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  green: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-green-500',
      before: 'before:border-green-500',
      after: 'after:border-green-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  teal: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-teal-500',
      before: 'before:border-teal-500',
      after: 'after:border-teal-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  cyan: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-cyan-500',
      before: 'before:border-cyan-500',
      after: 'after:border-cyan-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  'light-blue': {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-light-blue-500',
      before: 'before:border-light-blue-500',
      after: 'after:border-light-blue-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  blue: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-blue-500',
      before: 'before:border-blue-500',
      after: 'after:border-blue-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  indigo: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-indigo-500',
      before: 'before:border-indigo-500',
      after: 'after:border-indigo-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  'deep-purple': {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-deep-purple-500',
      before: 'before:border-deep-purple-500',
      after: 'after:border-deep-purple-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  purple: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-purple-500',
      before: 'before:border-purple-500',
      after: 'after:border-purple-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  pink: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-pink-500',
      before: 'before:border-pink-500',
      after: 'after:border-pink-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
  red: {
    close: {
      color: 'text-blue-gray-400',
      before: 'before:border-transparent',
      after: 'after:border-transparent',
    },
    open: {
      color: 'text-red-500',
      before: 'before:border-red-500',
      after: 'after:border-red-500',
    },
    withValue: {
      color: 'text-blue-gray-400',
      before: 'before:border-blue-gray-200',
      after: 'after:border-blue-gray-200',
    },
  },
};

export default selectOutlinedLabelColors;
