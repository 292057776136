import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { EProjectStatus } from "../../../types";
import { useRef, useState } from "react";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { api } from "../../../api/index";
import { setSelectedReProject } from "../../../store/projects/actions";
import { dispatch } from "../../../store";
import { NotificationModal } from "../../../components/NotificationModal";

interface trackProjectUpdateStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TrackProjectUpdateStatusModal = ({ isOpen, onClose }: trackProjectUpdateStatusModalProps) => {
  const selectedProject = useSelectedReProjectSelector();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(selectedProject?.status);
  const [isOpenNoti, setIsOpenNoti] = useState<boolean>(false);
  const notificationRef = useRef<{
    title: string;
    content: string;
    isSuccess: boolean;
  }>({ title: "", content: "", isSuccess: true });

  const handleOpenNoti = () => {
    setIsOpenNoti((prev) => !prev);
  };

  const projectStatus = [
    { key: EProjectStatus?.ADD_PROJECT, label: "Added Project" },
    { key: EProjectStatus?.DOCS_RECEIVED, label: "Docs Received" },
    { key: EProjectStatus?.SENT_TO_REGISTRY, label: "Sent to Registry" },
    { key: EProjectStatus?.DUE_DILIGENCE_CHECK, label: "Due Diligence Check" },
    { key: EProjectStatus?.DOCS_APPROVED, label: "Docs Approved" },
    { key: EProjectStatus?.CERTIFICATE_ISSUED, label: "Certificate Issued" },
    { key: EProjectStatus?.SALE_MADE, label: "Sale Made" },
  ];

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value);
  };

  const handleStatusChangeFunc = async () => {
    try {
      const statusChangeResponse = await api.put(`/admin/re-projects/`, {
        id: selectedProject?.id,
        status: selectedStatus,
      });

      dispatch(setSelectedReProject(statusChangeResponse?.data));
      onClose();
      notificationRef.current = {
        isSuccess: true,
        title: "Project has been updated successfully",
        content: `Project ID #${statusChangeResponse.data.id} has been changed to ${
          projectStatus.find((status) => status.key === statusChangeResponse.data.status)?.label ??
          statusChangeResponse.data.status
        } status`,
      };
      setIsOpenNoti(true);
    } catch (err) {
      console.log(err);
      notificationRef.current = {
        isSuccess: false,
        title: "Project has not been updated",
        content: `There is an error when update status of Project ID #${selectedProject?.id}`,
      };
      setIsOpenNoti(true);
    }
  };

  return (
    <div>
      <NotificationModal
        isOpen={isOpenNoti}
        handleOpen={handleOpenNoti}
        isSuccess={notificationRef.current.isSuccess}
        title={notificationRef.current.title}
        content={notificationRef.current.content}
      />

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <div className="border-b border-b-gray-300">
            <ModalHeader>
              <span className="font-primary text-xl font-medium text-black">
                Project Status Update
              </span>
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <div className="my-2 font-primary">
              <h1>Project Status</h1>
              <select
                name="projectStatus"
                id=""
                className="mt-2 w-1/2 rounded-md border border-gray-600 px-4 py-2 outline-none"
                onChange={handleChangeStatus}
                value={selectedStatus}
              >
                {projectStatus?.map((status) => (
                  <option value={status?.key} key={status?.key}>
                    {status?.label}
                  </option>
                ))}
              </select>
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              onClick={onClose}
              className="mx-3 rounded-md border border-black px-4 py-2 font-primary font-normal text-black outline-none"
            >
              Cancel
            </button>
            <button
              className="rounded-md border border-black bg-black px-4 py-2 font-primary font-normal text-white outline-none"
              onClick={handleStatusChangeFunc}
            >
              Update
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TrackProjectUpdateStatusModal;
