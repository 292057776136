import React, { useEffect, useState } from "react";
import { api } from "../../api";
// import NoOfCertificatesChart from "./components/NoOfCertificatesChart";
import Select from "react-select";
import { BiUpArrowAlt } from "react-icons/bi";
import { RSSFeedItems } from "./RSSFeed";

function Dashboard() {
  const [dashBoardData, setDashBoardData] = useState<any>({});
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedClientInfo, setSelectedClientInfo] = useState<any>(null);
  const [documentTypes, setDocumentTypes] = useState<any>([]);
  const [documentUploadStatus, setDocumentUploadStatus] = useState<any>({
    submitted: 0,
    pending: 0,
  });

  // get clients list
  useEffect(() => {
    api
      .post("/user/search", {
        role: "CLIENT_SELLER",
        page: 1,
        take: 300,
      })
      .then((response: any) => {
        // Filter out clients where client_name is empty or null, then map the rest
        const mappedClients = response?.data?.items
          ?.filter((client: any) => client?.client_name) // This filters out clients with empty or null names
          .map((client: any) => ({
            name: "client",
            value: client?.client_name,
            label: client?.client_name,
            id: client.id,
          }));

        setClientList(mappedClients);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // call /admin/dashboard/ endpoint
  useEffect(() => {
    getAdminDashboardData()
      .then((response: any) => {
        if (response) {
          setDashBoardData(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  // get all available document types
  const fetchDocumentTypes = async () => {
    try {
      const fetchedDocumentTypes = await api.get("/document/types");
      setDocumentTypes(fetchedDocumentTypes.data);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  // function to get dashboard response
  const getAdminDashboardData = async () => {
    const response = await api.get("/admin/dashboard/");
    return response.data;
  };

  // handle change client
  const handleChangeClient = (e: any) => {
    const selectedClient = dashBoardData.client_data[e.value];
    setSelectedClientInfo(selectedClient);
    setSelectedClient(e.value);

    // get selected client's project information
    getClientProjectInformation(e.id);
  };

  // fetch selected client's project information
  const getClientProjectInformation = async (clientID: number) => {
    try {
      let submittedDocumentsCount = 0;
      let pendingDocumentsCount = 0;
      const response = await api.post(`/admin/re-projects/search`, {
        user_id: clientID,
      });
      const projects = response?.data?.items;

      // iterate through projects and get count of uploaded documents
      if (projects?.length > 0) {
        for (const project of projects) {
          const uploadedDocumentsResponse = await api.get(`/document/uploaded-docs/${project?.id}`);

          // if project has uploaded documents
          if (uploadedDocumentsResponse?.data) {
            const objectList = Object.keys(uploadedDocumentsResponse.data);

            // Calculate submitted document count
            if (objectList.length > 0) {
              objectList.forEach((object: any) => {
                const length = uploadedDocumentsResponse.data[object].length;
                submittedDocumentsCount += length;
              });
            }

            // Calculate pending document count as (10 - objectList.length)
            pendingDocumentsCount += documentTypes.length - objectList.length;
          }
        }
      }

      // Set the document counts in the state
      setDocumentUploadStatus({
        submitted: submittedDocumentsCount,
        pending: pendingDocumentsCount,
      });
    } catch (err) {
      console.error("An error occurred while fetching projects", err);
    }
  };

  return (
    <div className="px-4">
      {/* date component */}
      <div>
        <h1>date component goes here</h1>
      </div>

      {/*  dashboard part goes here*/}
      <div className="my-4 grid grid-cols-4 grid-rows-3 gap-4">
        {/*  no of certificates sold box*/}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">No of Certificates Sold</h1>
          {/*<NoOfCertificatesChart />*/}
        </div>

        {/*  client select dropdown */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Client</h1>
          <Select
            options={clientList}
            name="client"
            id="client"
            onChange={handleChangeClient}
            className="mt-4"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "1px solid #A6A6A6",
                height: "42px",
              }),
            }}
          />
        </div>

        {/*  To-do box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">To Do</h1>
          <div className="mt-4 grid grid-cols-1 grid-rows-2 gap-4">
            <div>
              <h1 className="text-5xl font-medium text-black">{documentUploadStatus.submitted}</h1>
              <p className="text-sm text-gray-600">Submitted Documents</p>
            </div>
            <div>
              <h1 className="text-5xl font-medium text-black">{documentUploadStatus.pending}</h1>
              <p className="text-sm text-gray-600">Pending Documents</p>
            </div>
            {/*<div>*/}
            {/*  <h1 className="text-xl font-medium text-black">40</h1>*/}
            {/*  <p className="text-sm text-gray-600">Submit Document</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <h1 className="text-xl font-medium text-black">50</h1>*/}
            {/*  <p className="text-sm text-gray-600">Sample topic</p>*/}
            {/*</div>*/}
          </div>
        </div>

        {/*  registry updates box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">Registry Updates</h1>
          {/*  registry updates list displays here*/}
          <div className="mt-4 h-48 overflow-auto">
            {RSSFeedItems?.map((registry: any, index: number) => (
              <div key={index} className="border-b border-b-gray-300 p-2">
                <div className="flex items-start gap-2">
                  <div className="rounded-sm bg-gray-300 p-2">image goes here</div>
                  <div>
                    <a className="font-medium" href={registry.URL}>
                      {registry.News}
                    </a>
                    <h1 className="text-gray-600">{registry.DateOfPublishing}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/*  No of projects box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">No of Projects</h1>
          <div className="mt-4 flex items-center justify-between">
            <h1 className="text-2xl font-semibold">
              {selectedClient != null
                ? selectedClientInfo != null
                  ? selectedClientInfo.num_projects
                  : 0
                : dashBoardData?.total_projects}
            </h1>
            <div className="flex items-center gap-2">
              <h1>+ {dashBoardData?.percent_change_24h}%</h1>
              <BiUpArrowAlt size={25} />
            </div>
          </div>
        </div>

        {/*  Total revenue generated box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Total Revenue Generated</h1>
          <div className="mt-4 flex items-center justify-between gap-2">
            <h1 className="text-2xl font-semibold">2,00,000</h1>
            <div className="flex items-center gap-2">
              <h1>+ {dashBoardData?.percent_change_24h}%</h1>
              <BiUpArrowAlt size={25} />
            </div>
          </div>
        </div>

        {/*  Total capacity box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Total Capacity</h1>
          <h1 className="mt-4 text-2xl font-semibold">
            {selectedClientInfo != null ? selectedClientInfo.total_capacity : 0}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
