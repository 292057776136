import { useDisclosure } from "@chakra-ui/react";
import TrackProjectUpdateStatusModal from "./TrackProjectUpdateStatusModal";
// import { Stepper, Step } from "@material-tailwind/react";
// import { FaCheckCircle } from "react-icons/fa";
// import { useState } from "react";
// import IndicatorTopStepper from "./StepperComponent";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { useEffect, useState } from "react";
import { EProjectStatus } from "../../../types";
import "./stepper.css";
import { FaCheck } from "react-icons/fa";

const TrackProjectTab = () => {
  const projectStatus = [
    { key: EProjectStatus.ADD_PROJECT, label: "Added Project" },
    { key: EProjectStatus.DOCS_RECEIVED, label: "Docs Received" },
    { key: EProjectStatus.SENT_TO_REGISTRY, label: "Sent to Registry" },
    { key: EProjectStatus.DUE_DILIGENCE_CHECK, label: "Due Diligence Check" },
    { key: EProjectStatus.DOCS_APPROVED, label: "Docs Approved" },
    { key: EProjectStatus.CERTIFICATE_ISSUED, label: "Certificate Issued" },
    { key: EProjectStatus.SALE_MADE, label: "Sale Made" },
  ];

  const { onOpen, isOpen, onClose } = useDisclosure();
  const selectedProject = useSelectedReProjectSelector();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (selectedProject && selectedProject.status) {
      const index = projectStatus?.findIndex((status) => status?.key === selectedProject?.status);
      if (index !== -1) {
        setActiveStep(index);
      }
    }
  }, [selectedProject]);

  return (
    <div className="relative overflow-auto rounded-md bg-white p-4 shadow-md">
      <div className="flex items-center justify-between">
        <h1 className="font-medium text-black">Project Tracking</h1>
        <button
          className="rounded-md bg-primaryPurple-100 px-4 py-2 text-white outline-none duration-150 ease-in-out hover:bg-primaryPurple-200"
          onClick={onOpen}
        >
          Update Project Status
        </button>
      </div>

      {/* stepper */}
      <div className="my-6 w-full px-4 py-4">
        <div className="flex h-32 items-center justify-center gap-16">
          {projectStatus?.map((status, index) => (
            <div
              key={index}
              className={`stepper relative flex h-full w-20 flex-col items-center justify-between gap-2 ${
                index <= activeStep ? "text-green-700" : "text-gray-700"
              }`}
            >
              <p className="text-center">{status?.label}</p>

              <div
                className={`z-50 flex h-8 w-8 items-center justify-center rounded-full ${
                  index <= activeStep ? "bg-green-700" : "bg-gray-700"
                }`}
              >
                <FaCheck className="z-50 text-white" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <TrackProjectUpdateStatusModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
};

export default TrackProjectTab;
