import React from "react";
import { twMerge } from "tailwind-merge";
import { SearchableMultiSelectOptionProps } from "../../types/components/seachablemultiselect";

export const SearchableMultiSelectOption = ({
  key,
  value,
  className,
  selected = false,
  onClick,
  children,
}: SearchableMultiSelectOptionProps) => {
  const classes = twMerge(
    "block cursor-pointer rounded-md px-3 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100",
    selected ? "bg-blue-gray-50 bg-opacity-80 text-blue-gray-900" : "",
    className ?? "",
  );
  return (
    <li
      key={key}
      className={classes}
      onClick={() => {
        if (onClick) {
          onClick(value);
        }
      }}
    >
      {children}
    </li>
  );
};
