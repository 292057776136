import { useSelector } from '../index';

export const useProjectsSelector = () => useSelector(({ projects }) => projects.projects);
export const useIndicatorsSelector = () => useSelector(({ projects }) => projects.indicators);
export const useSelectedProjectSelector = () => useSelector(({ projects }) => projects.selected);
export const useSearchSelector = () => useSelector(({ projects }) => projects.searchByNaming);
export const useFilterProjectSelector = () => useSelector(({ projects }) => projects.filters);
export const useFilterValuesSelector = () => useSelector(({ projects }) => projects.filterValues);
export const useViewModeTabsSelector = () => useSelector(({ projects }) => projects.viewMode);
export const usePaginationSelector = () => useSelector(({ projects }) => projects.pagination);
export const useUploadedFilesSelector = () => useSelector(({ projects }) => projects.uploadedFiles);
export const useSelectedReProjectSelector = () =>
  useSelector(({ projects }) => projects.selectedProject);
