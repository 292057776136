const inputStaticColors: object = {
  black: {
    color: 'text-black',
    borderColor: 'border-black',
    borderColorFocused: 'focus:border-black',
  },
  white: {
    color: '!text-white',
    borderColor: 'border-white',
    borderColorFocused: 'focus:border-white',
  },
  'blue-gray': {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-blue-gray-500',
  },
  gray: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-gray-900',
  },
  brown: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-brown-500',
  },
  'deep-orange': {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-deep-orange-500',
  },
  orange: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-orange-500',
  },
  amber: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-amber-500',
  },
  yellow: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-yellow-500',
  },
  lime: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-lime-500',
  },
  'light-green': {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-light-green-500',
  },
  green: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-green-500',
  },
  teal: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-teal-500',
  },
  cyan: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-cyan-500',
  },
  'light-blue': {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-light-blue-500',
  },
  blue: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-blue-500',
  },
  indigo: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-indigo-500',
  },
  'deep-purple': {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-deep-purple-500',
  },
  purple: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-purple-500',
  },
  pink: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-pink-500',
  },
  red: {
    borderColor: 'border-blue-gray-200',
    borderColorFocused: 'focus:border-red-500',
  },
};

export default inputStaticColors;
