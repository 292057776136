import { useState } from "react";
import { UploadDocumentTabs } from "./UploadDocumentTabs";
import UploadDocumentsTab from "./components/UploadDocumentsTab";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelectedReProjectSelector } from "../../store/projects/selectors";
import TrackProjectTab from "./components/TrackProjectTab";
import PlantDetailsTab from "./components/PlantDetailsTab";

const ProjectDescriptionPage = () => {
  const navigate = useNavigate();
  const selectedProject = useSelectedReProjectSelector();
  const [selectedUploadTab, setSelectedUploadTab] = useState("uploadDocuments");
  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className="p-4">
      <div>
        {/* BreadCrumb goes here */}
        <div className="mb-4 text-sm">
          <p>
            Projects /<span className="text-gray-600">{selectedProject?.name}</span>
          </p>
        </div>

        {/* selected project name and status */}
        <div className="mb-4 flex items-center gap-2">
          <IoMdArrowRoundBack
            className="cursor-pointer text-primaryPurple-100"
            onClick={handleNavigate}
          />
          <h1 className="font-semibold">{selectedProject?.name}</h1>
          <div
            className={`rounded-full px-4 py-1 text-sm font-medium ${
              selectedProject?.active ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            }`}
          >
            {selectedProject?.active ? "Active" : "Not active"}
          </div>
        </div>

        {/* selected project details */}
        <div className="mb-4 rounded-md bg-white p-4 shadow-sm">
          <h1 className="font-medium text-black">Project Information</h1>
          <div className="grid grid-cols-4 gap-4 py-3">
            <div>
              <p className="text-sm text-gray-600">ID</p>
              <p className="font-medium">{selectedProject?.id}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Device Name</p>
              <p className="font-medium">{selectedProject?.device_type}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Device Code</p>
              <p className="font-medium">{selectedProject?.device_code}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Credit Period Term</p>
              <p className="font-medium">
                {selectedProject?.registration_date || selectedProject?.expiry_date
                  ? `${selectedProject?.registration_date ?? "Undefined"} - ${
                      selectedProject?.expiry_date ?? "Undefined"
                    }`
                  : "Undefined Period"}
              </p>
            </div>
            <div className="group relative">
              <p className="text-sm text-gray-600">Address</p>
              <p className="font-medium">
                {(selectedProject?.address?.length ?? 0) > 40
                  ? `${selectedProject?.address.slice(0, 40)}...`
                  : selectedProject?.address}
              </p>
              {selectedProject?.address && selectedProject?.address.length > 80 && (
                <span className="absolute left-0 top-full mt-1 w-max max-w-xs rounded-lg bg-gray-800 p-2 text-xs text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  {selectedProject?.address}
                </span>
              )}
            </div>
            <div>
              <p className="text-sm text-gray-600">Registrant</p>
              <p className="font-medium">{selectedProject?.registrant}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Issuer</p>
              <p className="font-medium">{selectedProject?.issuer}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Country</p>
              <p className="font-medium">{selectedProject?.country}</p>
            </div>
          </div>
        </div>

        <UploadDocumentTabs setSelectedUploadTab={setSelectedUploadTab} />

        {/* tab content goes here */}
        <div className="my-4">
          {selectedUploadTab === "uploadDocuments" && <UploadDocumentsTab />}
          {selectedUploadTab === "trackProject" && <TrackProjectTab />}
          {selectedUploadTab === "plantDetails" && <PlantDetailsTab />}
        </div>
      </div>
    </div>
  );
};

export default ProjectDescriptionPage;
