import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { Cookies } from 'react-cookie';
import { fetchLogout } from './home.api';

let isRefreshing = false;

export const cookies = new Cookies();

const refreshSubscribers: (() => void)[] = [];

export const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get('access_token');
    config.headers['Authorization'] = 'Bearer ' + accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const { config, response } = error;
    const status = response?.status;

    const originalRequest = config;
    const accessToken = cookies.get('access_token');

    if (originalRequest) {
      if (status === 401 && accessToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshAccessToken()
            .then(() => {
              isRefreshing = false;
              console.log('refreshed successfully');
              onRefreshed();
            })
            .catch(() => {
              isRefreshing = false;
              handleLogout();
            });
        }
        const retryOrigReq = new Promise<AxiosResponse>((resolve) => {
          subscribeTokenRefresh(() => {
            resolve(api(originalRequest));
          });
        }).catch((e) => Promise.reject(e));
        return retryOrigReq;
      } else {
        console.error(`${originalRequest.url} request failed with status ${status}`);
      }
    }
  },
);

export const handleLogout = async () => {
  cookies.remove('access_token');
  cookies.remove('refresh_token');
  localStorage.clear();
  await fetchLogout();
};

const subscribeTokenRefresh = (cb: () => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = () => {
  refreshSubscribers.map((cb) => cb());
};

async function refreshAccessToken(): Promise<string> {
  const refreshToken = cookies.get('refresh_token');

  if (!refreshToken) {
    handleLogout();
  }

  return axios.post(
    `${api.defaults.baseURL}/refresh-token`,
    { refresh_token: refreshToken },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
}
