import { LoadScript } from "@react-google-maps/api";

export const LoadScriptOnce = ({ children }: any) => {
  return !window.google?.maps ? (
    <LoadScript googleMapsApiKey={String(process.env.REACT_APP_GOOGLE_MAP_API_KEY)} version="3.55">
      {children}
    </LoadScript>
  ) : (
    children
  );
};
