export const RSSFeedItems = [
  {
    SrNo: 1,
    DateOfPublishing: '2023-08-01',
    News: 'India to emerge as global renewable energy powerhouse with fastest growing capacity',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-poised-to-become-global-leader-in-renewable-energy-with-growing-solar-manufacturing-and-ev-adoption-11690894068652.html',
  },
  {
    SrNo: 2,
    DateOfPublishing: '2023-08-01',
    News: 'Degrowth: Slowing down rich economies to deal with climate change is a flawed idea',
    LinkName: 'Down to earth',
    URL: 'https://www.downtoearth.org.in/blog/climate-change/degrowth-slowing-down-rich-economies-to-deal-with-climate-change-is-a-flawed-idea-90934',
  },
  {
    SrNo: 3,
    DateOfPublishing: '2023-08-02',
    News: 'Rajya Sabha okays contentious biological diversity bill',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/rajya-sabha-okays-contentious-biological-diversity-bill-101690915990746.html',
  },
  {
    SrNo: 4,
    DateOfPublishing: '2023-08-01',
    News: 'Carbon Offset Market Opens to Small Southern Timberland Owners',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/carbon-offset-market-opens-to-small-southern-timberland-owners-11690888349255.html',
  },
  {
    SrNo: 5,
    DateOfPublishing: '2023-08-03',
    News: 'OPINION: Why the future of climate action rests on strong renewable energy supply chains',
    LinkName: 'ET ENergyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/opinion-why-the-future-of-climate-action-rests-on-strong-renewable-energy-supply-chains/102394012',
  },
  {
    SrNo: 6,
    DateOfPublishing: '2023-08-03',
    News: 'Banks ignored climate action support to Sunderbans: Report',
    LinkName: 'My Kolkata',
    URL: 'https://www.telegraphindia.com/my-kolkata/news/banks-ignored-climate-action-support-to-sunderbans-report/cid/1956478',
  },
  {
    SrNo: 7,
    DateOfPublishing: '2023-08-03',
    News: 'India, UK may work jointly on Sri Lanka',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/world/india-uk-may-work-jointly-on-sri-lanka-11691082704985.html',
  },
  {
    SrNo: 8,
    DateOfPublishing: '2023-08-03',
    News: 'Electrifying the future: How electric vehicles (EVs) can propel climate change mitigation',
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/blogs/voices/electrifying-the-future-how-electric-vehicles-evs-can-propel-climate-change-mitigation/',
  },
  {
    SrNo: 9,
    DateOfPublishing: '2023-08-04',
    News: 'Businesses profit off the environment so they have to let go of money to save it: Aakash Ranison',
    LinkName: 'Forbes India',
    URL: 'https://www.forbesindia.com/article/news/businesses-profit-off-the-environment-so-they-have-to-let-go-of-money-to-save-it-aakash-ranison/87287/1',
  },
  {
    SrNo: 10,
    DateOfPublishing: '2023-08-04',
    News: 'Govt to allow carbon credit trade in global market for some sectors: RK Singh',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/govt-to-allow-carbon-credit-trade-in-global-market-for-some-sectors-rk-singh-11100351.html',
  },
  {
    SrNo: 11,
    DateOfPublishing: '2023-08-05',
    News: 'India needs to develop vibrant low carbon manufacturing industry',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/india-urged-to-develop-vibrant-low-carbon-manufacturing-industry-to-achieve-energy-transition-goals-11691211766690.html',
  },
  {
    SrNo: 12,
    DateOfPublishing: '2023-08-05',
    News: 'RGU signs MoU with WCD to research women specific impact due to climate change',
    LinkName: 'The Arunachal Times',
    URL: 'https://arunachaltimes.in/index.php/2023/08/05/rgu-signs-mou-with-wcd-to-research-women-specific-impact-due-to-climate-change/',
  },
  {
    SrNo: 13,
    DateOfPublishing: '2023-08-06',
    News: 'By 2030, Indian railway network will run on net zero emissions: PM Modi',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/india/by-2030-indian-railway-network-will-run-on-net-zero-emissions-pm-modi/videoshow/102478079.cms?from=mdr',
  },
  {
    SrNo: 14,
    DateOfPublishing: '2023-08-06',
    News: 'UAE: Ministry of Climate Change and the Environment, TRENDS Research Centre sign MoU',
    LinkName: 'ANI',
    URL: 'https://www.aninews.in/news/world/middle-east/uae-ministry-of-climate-change-and-the-environment-trends-research-centre-sign-mou20230806224243/',
  },
  {
    SrNo: 15,
    DateOfPublishing: '2023-08-06',
    News: 'What climate FDI can do to make a real difference to a low carbon economy',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/what-climate-fdi-can-do-to-make-a-real-difference-to-a-low-carbon-economy/1702814/',
  },
  {
    SrNo: 16,
    DateOfPublishing: '2023-08-06',
    News: 'Sri Lanka to launch climate justice forum for vulnerable countries',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/politics/sri-lanka-to-launch-climate-justice-forum-for-vulnerable-countries-11107091.html',
  },
  {
    SrNo: 17,
    DateOfPublishing: '2023-08-07',
    News: "Climate change could reduce India's credit rating, study finds",
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/economy/indicators/climate-change-could-reduce-indias-credit-rating-study-finds/articleshow/102500023.cms?from=mdr',
  },
  {
    SrNo: 18,
    DateOfPublishing: '2023-08-07',
    News: 'Singareni aims to become net-zero by October-end next year',
    LinkName: 'The Hindu',
    URL: 'https://www.livemint.com/industry/energy/india-urged-to-develop-vibrant-low-carbon-manufacturing-industry-to-achieve-energy-transition-goals-11691211766690.html',
  },
  {
    SrNo: 19,
    DateOfPublishing: '2023-08-07',
    News: 'Transition finance: Paving way to a net zero future',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/first-person/transition-finance-paving-way-to-a-net-zero-future-11691406274557.html',
  },
  {
    SrNo: 20,
    DateOfPublishing: '2023-08-08',
    News: 'RIL to invest \u20b91.5 tn in green energy business',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/companies/news/ril-ready-to-double-its-green-energy-investment-to-1-5-trillion-11691432801242.html',
  },
  {
    SrNo: 21,
    DateOfPublishing: '2023-08-09',
    News: 'Kerala State Climate Change Adaptation Mission to serve as monitoring and coordinating mechanism',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/news/national/kerala/kerala-state-climate-change-adaptation-mission-to-serve-as-monitoring-and-coordinating-mechanism/article67171369.ece',
  },
  {
    SrNo: 22,
    DateOfPublishing: '2023-08-09',
    News: 'Amazon nations seek common voice on matter of climate change, urge action',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/amazon-nations-seek-common-voice-on-climate-change-urge-action-123080900612_1.html',
  },
  {
    SrNo: 23,
    DateOfPublishing: '2023-08-09',
    News: "India's ONGC is looking for partners for low carbon initiatives",
    LinkName: 'Contruction World',
    URL: 'https://www.constructionworld.in/energy-infrastructure/oil-and-gas/india-s-ongc-is-looking-for-partners-for-low-carbon-initiatives/43101',
  },
  {
    SrNo: 24,
    DateOfPublishing: '2023-08-10',
    News: 'India succeeds in reducing emissions rate by 33% over 14 years, sources say',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-succeeds-in-reducing-emissions-rate-by-33-over-14-years-sources-say/102590699',
  },
  {
    SrNo: 25,
    DateOfPublishing: '2023-08-09',
    News: 'India now has 70,000 MW of solar power generation capacity; Rajasthan leading the pack',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-now-has-70000-mw-of-solar-power-generation-capacity-rajasthan-leading-the-pack/102577162',
  },
  {
    SrNo: 27,
    DateOfPublishing: '2023-08-10',
    News: 'Greenfuel Energy partners with Ventrex for green energy control solutions',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/greenfuel-energy-partners-with-ventrex-for-green-energy-control-solutions/102604302',
  },
  {
    SrNo: 28,
    DateOfPublishing: '2023-08-10',
    News: 'Maharashtra sets up climate action cell to handhold polluting depts into reducing emissions',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/maharashtra-sets-up-climate-action-cell-to-handhold-polluting-depts-into-reducing-emissions/1708973/',
  },
  {
    SrNo: 29,
    DateOfPublishing: '2023-08-10',
    News: 'Developed nations overconsuming carbon budget; very little left for countries like India: Govt',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/india/developed-nations-overconsuming-carbon-budget-very-little-left-for-countries-like-india-govt/articleshow/102616343.cms?from=mdr',
  },
  {
    SrNo: 30,
    DateOfPublishing: '2023-08-10',
    News: 'Delhi Metro\u2019s New Initiative Puts Brakes on Carbon Emissions',
    LinkName: 'Times Now',
    URL: 'https://www.timesnownews.com/delhi/delhi-metros-new-initiative-puts-brakes-on-carbon-emissionsdmrcs-carbon-cutting-metro-campaign-unveiled-article-102603141',
  },
  {
    SrNo: 31,
    DateOfPublishing: '2023-08-11',
    News: 'Greenfuel Energy partners with Ventrex for green energy control solutions',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/greenfuel-energy-partners-with-ventrex-for-green-energy-control-solutions/102604302',
  },
  {
    SrNo: 32,
    DateOfPublishing: '2023-08-11',
    News: 'Maharashtra sets up climate action cell to handhold polluting depts into reducing emissions',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/maharashtra-sets-up-climate-action-cell-to-handhold-polluting-depts-into-reducing-emissions/1708973/',
  },
  {
    SrNo: 33,
    DateOfPublishing: '2023-08-11',
    News: 'Developed nations overconsuming carbon budget; very little left for countries like India: Govt',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/india/developed-nations-overconsuming-carbon-budget-very-little-left-for-countries-like-india-govt/articleshow/102616343.cms?from=mdr',
  },
  {
    SrNo: 34,
    DateOfPublishing: '2023-08-11',
    News: 'Delhi Metro\u2019s New Initiative Puts Brakes on Carbon Emissions',
    LinkName: 'Times Now',
    URL: 'https://www.timesnownews.com/delhi/delhi-metros-new-initiative-puts-brakes-on-carbon-emissionsdmrcs-carbon-cutting-metro-campaign-unveiled-article-102603141',
  },
  {
    SrNo: 35,
    DateOfPublishing: '2023-08-14',
    News: 'Climate change adaptation is the need of the hour',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/blogs/developing-contemporary-india/climate-change-adaptation-is-the-need-of-the-hour/',
  },
  {
    SrNo: 36,
    DateOfPublishing: '2023-08-14',
    News: 'State govt to form agency for implementing climate action plan',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/pune-news/state-govt-to-form-agency-for-implementing-climate-action-plan-101691766428672.html',
  },
  {
    SrNo: 37,
    DateOfPublishing: '2023-08-14',
    News: 'Energy Industry Welcomes Green Energy Summit After "Anxiety" Over Net Zero',
    LinkName: 'Politics Home',
    URL: 'https://www.politicshome.com/news/article/energy-stakeholders-welcome-government-net-zero-messaging-shift',
  },
  {
    SrNo: 38,
    DateOfPublishing: '2023-08-14',
    News: 'How carbon emissions can also be used to achieve green goals',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/how-to/how-carbon-emissions-can-also-be-used-to-achieve-green-goals/articleshow/102673617.cms?from=mdr',
  },
  {
    SrNo: 39,
    DateOfPublishing: '2023-08-14',
    News: 'Meghalaya CM launches solar mission worth Rs 100 crore for sustainable green energy',
    LinkName: 'India Today',
    URL: 'https://www.indiatodayne.in/meghalaya/story/meghalaya-cm-launches-solar-mission-worth-rs-100-crore-for-sustainable-green-energy-630129-2023-08-11',
  },
  {
    SrNo: 40,
    DateOfPublishing: '2023-08-16',
    News: 'Reality bites: How is India tackling climate change ahead of G20 summit?',
    LinkName: 'The Business Standard',
    URL: 'https://www.business-standard.com/economy/news/new-delhi-takes-lead-in-climate-policymaking-amid-a-hectic-negotiation-time-123081400857_1.html',
  },
  {
    SrNo: 41,
    DateOfPublishing: '2023-08-16',
    News: 'Climate change and women in agriculture: Navigating challenges and fostering resilience.',
    LinkName: 'The Financial Express',
    URL: 'https://www.financialexpress.com/opinion/climate-change-and-women-in-agriculture-navigating-challenges-and-fostering-resilience/3211237/',
  },
  {
    SrNo: 42,
    DateOfPublishing: '2023-08-16',
    News: 'Bhutan becomes country with \u2018net zero carbon emissions\u2019',
    LinkName: 'The Hindustan Times',
    URL: 'https://www.hindustantimes.com/environment/bhutan-becomes-country-with-net-zero-carbon-emissions-101692151932977.html',
  },
  {
    SrNo: 43,
    DateOfPublishing: '2023-08-16',
    News: 'India showing way to fight climate change: PM Modi',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-showing-way-to-fight-climate-change-pm-modi/102756802',
  },
  {
    SrNo: 44,
    DateOfPublishing: '2023-08-16',
    News: 'Climate risk finds high awareness in urban India: Survey',
    LinkName: 'Live Mint',
    URL: 'https://www.livemint.com/specials/climate-risk-finds-high-awareness-in-urban-india-survey-11692016253463.html',
  },
  {
    SrNo: 45,
    DateOfPublishing: '2023-08-16',
    News: 'OPINION: Why there is an urgency for India to roll out its carbon market',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/opinion-why-there-is-an-urgency-for-india-to-roll-out-its-carbon-market/102749033',
  },
  {
    SrNo: 46,
    DateOfPublishing: '2023-08-16',
    News: 'Climate change merits urgent attention of scientists, policymakers worldwide: President Murmu',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/climate-change-merits-urgent-attention-of-scientists-policymakers-worldwide-president-murmu/102737336',
  },
  {
    SrNo: 47,
    DateOfPublishing: '2023-08-17',
    News: "What's carbon sequestration and how it can help deal climate issues",
    LinkName: 'The Economics Times',
    URL: 'https://economictimes.indiatimes.com/news/how-to/whats-carbon-sequestration-and-how-it-can-help-deal-climate-issues/articleshow/102779362.cms?from=mdr',
  },
  {
    SrNo: 48,
    DateOfPublishing: '2023-08-17',
    News: 'Insurers will place a vital role in India\u2019s green-energy transition',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/first-person/insurers-will-place-a-vital-role-in-india-s-green-energy-transition-11692179447523.html',
  },
  {
    SrNo: 49,
    DateOfPublishing: '2023-08-17',
    News: 'Why India needs regulations to encourage net-zero buildings',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/money/why-india-needs-regulations-to-encourage-net-zero-buildings/3212012/',
  },
  {
    SrNo: 50,
    DateOfPublishing: '2023-08-17',
    News: 'India\u2019s green ambitions - An assessment of recent policy decisions to curtail carbon emissions',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-green-ambitions-an-assessment-of-recent-policy-decisions-to-curtail-carbon-emissions/102790455',
  },
  {
    SrNo: 51,
    DateOfPublishing: '2023-08-17',
    News: 'To reduce carbon cover, Cabinet approves PM-eBus Sewa',
    LinkName: 'Rising Kashmir',
    URL: 'http://risingkashmir.com/to-reduce-carbon-cover-cabinet-approves-pm-ebus-sewa',
  },
  {
    SrNo: 52,
    DateOfPublishing: '2023-08-18',
    News: 'ONGC investing Rs 1 lakh cr to transform into low-carbon energy player',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/ongc-investing-rs-1-lakh-cr-to-transform-into-low-carbon-energy-player/articleshow/102803213.cms?from=mdr',
  },
  {
    SrNo: 53,
    DateOfPublishing: '2023-08-18',
    News: "Climate change is not the lone culprit in Himachal Pradesh's havoc: Here's the CM's take on what else went wrong",
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/environment/himachal-pradesh-rain-havoc-cm-lists-reasons-climate-change-faulty-infrastructure-17551401.htm',
  },
  {
    SrNo: 54,
    DateOfPublishing: '2023-08-18',
    News: 'Special Focus On One Health, AMR & Climate Change In G20 Presidency: Mos Bharti Pawar',
    LinkName: 'BW Business World',
    URL: 'https://www.businessworld.in/article/Special-Focus-On-One-Health-AMR-Climate-Change-In-G20-Presidency-Mos-Bharti-Pawar/17-08-2023-488003/',
  },
  {
    SrNo: 55,
    DateOfPublishing: '2023-08-18',
    News: 'Rashtriya Chemicals and Fertilizers gains 4% on green nod to nano-urea plant',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/stocks/rashtriya-chemicals-and-fertilizers-gains-4-on-green-nod-to-nano-urea-plant-11208711.html',
  },
  {
    SrNo: 56,
    DateOfPublishing: '2023-08-21',
    News: 'Varanasi Y-20 Summit discusses issues climate change, peace-building, health',
    LinkName: 'The Week \u00a0',
    URL: 'https://www.theweek.in/news/india/2023/08/19/varanasi-y-20-summit-discusses-issues-climate-change-peace-building-health.html',
  },
  {
    SrNo: 57,
    DateOfPublishing: '2023-08-21',
    News: 'Net Zero Summit 2023 - Empowering Indian Industries Towards Sustainability',
    LinkName: 'The Hans India',
    URL: 'https://www.thehansindia.com/business/net-zero-summit-2023-empowering-indian-industries-towards-sustainability-816791',
  },
  {
    SrNo: 58,
    DateOfPublishing: '2023-08-21',
    News: "Towards 'Net Zero': Railways Sanctions Work With Carbon-Neutral Growth Strategy For Maintenance Of LHB Rakes",
    LinkName: 'Swarajya',
    URL: 'https://swarajyamag.com/infrastructure/towards-net-zero-railways-sanctions-work-with-carbon-neutral-growth-strategy-for-maintenance-of-lhb-rakes',
  },
  {
    SrNo: 59,
    DateOfPublishing: '2023-08-21',
    News: 'Green transformation: Making India\u2019s commercial buildings net zero',
    LinkName: 'Construction week Online',
    URL: 'https://www.constructionweekonline.in/people/green-transformation-making-indias-commercial-buildings-net-zero',
  },
  {
    SrNo: 60,
    DateOfPublishing: '2023-08-21',
    News: 'India approves Green Hydrogen Mission to promote sustainable energy',
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/environment/india-approves-green-hydrogen-mission-to-promote-sustainable-energy-17577691.htm',
  },
  {
    SrNo: 61,
    DateOfPublishing: '2023-08-21',
    News: 'Environment policy needs federalism to play a big role',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/environment-policy-needs-federalism-to-play-a-big-role-11692524042561.html',
  },
  {
    SrNo: 62,
    DateOfPublishing: '2023-08-21',
    News: 'SECI to call tender for Electrolyser Manufacturing units for Green Hydrogen soon',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/seci-to-call-tender-for-electrolyser-manufacturing-units-for-green-hydrogen-soon/102826855',
  },
  {
    SrNo: 63,
    DateOfPublishing: '2023-08-22',
    News: 'T.N. Chief Minister Stalin launches Green Fellowship programme',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/news/national/tamil-nadu/tn-chief-minister-stalin-launches-green-fellowship-programme/article67220453.ece',
  },
  {
    SrNo: 64,
    DateOfPublishing: '2023-08-22',
    News: 'Climate change, disaster risks to be included in town planning Act in Tamil Nadu',
    LinkName: 'The New Indian Express\u00a0',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/aug/22/climate-change-disaster-risks-to-be-included-in-town-planning-act-in-tamil-nadu-2607622.html',
  },
  {
    SrNo: 65,
    DateOfPublishing: '2023-08-22',
    News: 'UAE oil giant Adnoc sets tougher climate targets before COP28',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/uae-oil-giant-adnoc-sets-tougher-climate-targets-before-cop28/102908706',
  },
  {
    SrNo: 66,
    DateOfPublishing: '2023-08-22',
    News: 'Driving change on the global stage: India\u2019s G20 presidency and socio-economic impact of sustainable development goals',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-driving-change-on-global-stage-indias-g20-presidency-and-socio-economic-impact-of-sustainable-development-goals-3217432/',
  },
  {
    SrNo: 67,
    DateOfPublishing: '2023-08-22',
    News: 'Protectionism a global phenomenon, India not alone: Amitabh Kant',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/economy/protectionism-a-global-phenomenon-india-not-alone-amitabh-kant-11692640827020.html',
  },
  {
    SrNo: 68,
    DateOfPublishing: '2023-08-23',
    News: 'Climate concerns: Ahead of G20 Summit, study says bloc poured $1.4 trillion into fossil fuels in 2022',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/coal/climate-concerns-ahead-of-g20-summit-study-says-bloc-poured-1-4-trillion-into-fossil-fuels-in-2022/102960572',
  },
  {
    SrNo: 69,
    DateOfPublishing: '2023-08-23',
    News: 'UNDP partners govt to fast-track sustainable development in northeast',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/undp-partners-govt-to-fast-track-sustainable-development-in-northeast-11692711810935.html',
  },
  {
    SrNo: 70,
    DateOfPublishing: '2023-08-23',
    News: 'Net Zero Summit held in Hyderabad',
    LinkName: 'Telangana Today',
    URL: 'https://telanganatoday.com/net-zero-summit-held-in-hyderabad',
  },
  {
    SrNo: 71,
    DateOfPublishing: '2023-08-23',
    News: "NTPC's Next Big Leap in the Green Hydrogen Space",
    LinkName: 'Equity Master',
    URL: 'https://www.equitymaster.com/detail.asp?date=08/22/2023&story=4&title=NTPCs-Next-Big-Leap-in-the-Green-Hydrogen-Space',
  },
  {
    SrNo: 72,
    DateOfPublishing: '2023-08-23',
    News: 'Green Hydrogen standards: A call for holistic environmental accountability',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/green-hydrogen-standards-a-call-for-holistic-environmental-accountability/102962355',
  },
  {
    SrNo: 73,
    DateOfPublishing: '2023-08-23',
    News: 'CM Jagan to virtually lay stone for green energy projects today',
    LinkName: 'The Hans India',
    URL: 'https://www.thehansindia.com/andhra-pradesh/cm-jagan-to-virtually-lay-stone-for-green-energy-projects-today-817548',
  },
  {
    SrNo: 74,
    DateOfPublishing: '2023-08-23',
    News: 'Mizoram assembly adopts resolution opposing Forest (Conservation) Amendment Act',
    LinkName: 'PTI',
    URL: 'https://www.ptinews.com/news/national/mizoram-assembly-adopts-resolution-opposing-forest-conservation-amendment-act/635208.html',
  },
  {
    SrNo: 75,
    DateOfPublishing: '2023-08-23',
    News: 'Himachal cabinet decides to amend Saran Jayanti Energy Policy',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/himachal-cabinet-decides-to-amend-saran-jayanti-energy-policy/102960652',
  },
  {
    SrNo: 76,
    DateOfPublishing: '2023-08-24',
    News: 'Andhra Pradesh CM lays foundation for three renewable energy projects worth over Rs 25,000 crore',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/andhra-pradesh-cm-lays-foundation-for-three-renewable-energy-projects-worth-over-rs-25000-crore/articleshow/102991034.cms?from=mdr',
  },
  {
    SrNo: 77,
    DateOfPublishing: '2023-08-24',
    News: 'Germany set to miss net zero by 2045 target as climate efforts falter',
    LinkName: 'Times Of India',
    URL: 'https://timesofindia.indiatimes.com/home/environment/germany-set-to-miss-net-zero-by-2045-target-as-climate-efforts-falter/articleshow/102961237.cms?from=mdr',
  },
  {
    SrNo: 78,
    DateOfPublishing: '2023-08-24',
    News: 'India\u2019s green hydrogen definition is ambitious, but also ambiguous',
    LinkName: 'The Morning Context',
    URL: 'https://themorningcontext.com/business/indias-green-hydrogen-definition-is-ambitious-but-also-ambiguous',
  },
  {
    SrNo: 79,
    DateOfPublishing: '2023-08-24',
    News: "India's green hydrogen pilot gathers steam, economics yet to add up",
    LinkName: 'The Business Standard',
    URL: 'https://www.business-standard.com/companies/news/india-s-green-hydrogen-pilot-gathers-steam-economics-yet-to-add-up-123082300805_1.html',
  },
  {
    SrNo: 80,
    DateOfPublishing: '2023-08-24',
    News: 'G20 Countries Spent a Record $ 1.4 Trillion on Fossil Fuels in 2022: Report',
    LinkName: 'The Wire',
    URL: 'https://thewire.in/environment/g20-countries-trillion-fossil-fuel',
  },
  {
    SrNo: 81,
    DateOfPublishing: '2023-08-24',
    News: 'Ahead of COP28, Africa meets to deliberate on integrating health into climate agenda',
    LinkName: 'Down to Earth',
    URL: 'https://www.downtoearth.org.in/news/world/ahead-of-cop28-africa-meets-to-deliberate-on-integrating-health-into-climate-agenda-91324',
  },
  {
    SrNo: 82,
    DateOfPublishing: '2023-08-24',
    News: 'Conclave on \u2018Energy Transition towards Net Zero\u2019',
    LinkName: 'Pioneer',
    URL: 'https://www.dailypioneer.com/2023/business/conclave-on----energy-transition-towards-net-zero---.html',
  },
  {
    SrNo: 83,
    DateOfPublishing: '2023-08-25',
    News: "India's vision for climate justice: COP28 and beyond",
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/indias-vision-for-climate-justice-cop28-and-beyond-101692863824448.html',
  },
  {
    SrNo: 84,
    DateOfPublishing: '2023-08-25',
    News: 'EU\u2019s green rules clash with trade goals as India talks resume',
    LinkName: 'ET Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/eus-green-rules-clash-with-trade-goals-as-india-talks-resume/103010275',
  },
  {
    SrNo: 85,
    DateOfPublishing: '2023-08-25',
    News: 'India to hold off on green bonds sale.',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-to-hold-off-on-green-bonds-sale-11692899991291.html',
  },
  {
    SrNo: 86,
    DateOfPublishing: '2023-08-25',
    News: 'India needs $13 trillion to hit net zero emissions by 2050',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-india-needs-13-trillion-to-hit-net-zero-emissions-by-2050-3221191/',
  },
  {
    SrNo: 87,
    DateOfPublishing: '2023-08-25',
    News: 'HSBC\u2019s green hydrogen partnerships unveiled by FM',
    LinkName: 'The Times of India',
    URL: "HSBC%20India%20has%20forged%20strategic%20alliances%20with%20the%20Indian%20Institute%20of%20Technology%20(IIT)%20Bombay%20and%20Shakti%20Sustainable%20Energy%20Foundation%20(SSEF)%20to%20propel%20innovation%20in%20Green%20Hydrogen.%20These%20partnerships%20are%20backed%20by%20a%20grant%20of%20Rs%2015%20crore%20to%20enhance%20the%20efficiency,%20cost-effectiveness,%20and%20scalability%20of%20green%20hydrogen.%20The%20partnership%20aims%20to%20prioritize%20green%20hydrogen%20as%20a%20strategic%20alternative%20fuel%20and%20realize%20India's%20vision%20of%20an%20energy-independent%20nation.",
  },
  {
    SrNo: 88,
    DateOfPublishing: '2023-08-25',
    News: 'Subsidy on fossil fuels in India touches $350 billion: IMF Working Paper',
    LinkName: 'The Hindu Businessline',
    URL: 'https://www.thehindubusinessline.com/economy/subsidy-on-fossil-fuels-in-india-touches-350-billion-imf-working-paper/article67230853.ece',
  },
  {
    SrNo: 89,
    DateOfPublishing: '2023-08-25',
    News: 'Mandatory green hydrogen plan may go to cabinet soon',
    LinkName: 'Mint',
    URL: 'Industries%20such%20as%20refineries%20and%20fertilizer%20makers%20may%20soon%20have%20to%20follow%20a%20green%20hydrogen%20consumption%20mandate,%20with%20the%20ministry%20of%20new%20and%20renewable%20energy%20(MNRE)%20set%20to%20approach%20the%20union%20cabinet%20with%20a%20proposal',
  },
  {
    SrNo: 90,
    DateOfPublishing: '2023-08-25',
    News: 'Study reveals how much carbon damage would cost corporations if they paid for their emissions',
    LinkName: 'The Times of India',
    URL: "The%20world's%20corporations%20produce%20so%20much%20climate%20change",
  },
  {
    SrNo: 91,
    DateOfPublishing: '2023-08-25',
    News: 'CME, Khadki becomes India\u2019s first carbon negative garrison',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/pune-news/college-of-military-engineering-cme-achieves-carbon-negative-status-with-5mw-solar-power-plant-commissioning-101692903713293.html',
  },
  {
    SrNo: 92,
    DateOfPublishing: '2023-08-25',
    News: 'Refineries, fertiliser firms will be given green hydrogen consumption mandates: RK Singh',
    LinkName: 'The Hindu Businessline',
    URL: 'https://www.thehindubusinessline.com/companies/refineries-fertiliser-firms-to-soon-have-consumption-mandates-for-green-hydrogen/article67230503.ece',
  },
  {
    SrNo: 93,
    DateOfPublishing: '2023-08-28',
    News: 'As young conservatives try to get climate on the agenda in 2024, denial takes the spotlight instead',
    LinkName: 'CNN',
    URL: 'https://edition.cnn.com/2023/08/26/politics/climate-change-republican-debate-ramaswamy/index.html',
  },
  {
    SrNo: 94,
    DateOfPublishing: '2023-08-28',
    News: "Sanjiv Puri warns extreme climate events 'here to stay', companies in India must adapt",
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/companies/people/sanjiv-puri-warns-extreme-climate-events-here-to-stay-companies-in-india-must-adapt-11693128988284.html',
  },
  {
    SrNo: 95,
    DateOfPublishing: '2023-08-28',
    News: 'G20 economies need to invest $35 tonnes more to achieve net-zero, says study',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/business/india-business/g20-economies-need-to-invest-35-tonnes-more-to-achieve-net-zero-says-study/articleshow/103114697.cms?from=mdr',
  },
  {
    SrNo: 96,
    DateOfPublishing: '2023-08-28',
    News: 'Sajjan Jindal calls for global collaboration to meet net-zero goals',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/sajjan-jindal-calls-for-global-collaboration-to-meet-net-zero-goals/articleshow/103052578.cms?from=mdr',
  },
  {
    SrNo: 97,
    DateOfPublishing: '2023-08-28',
    News: 'India Needs to Invest $12.7 Tn in Its Energy System to Achieve Net-Zero Emissions by 2050: Report',
    LinkName: 'The Wire',
    URL: 'https://thewire.in/environment/india-needs-to-invest-12-7-tn-in-its-energy-system-to-achieve-net-zero-emissions-by-2050-report',
  },
  {
    SrNo: 98,
    DateOfPublishing: '2023-08-28',
    News: "Jharkhand inks pact for India's 'First' Hydrogen fuel project with TCPL green energy solutions",
    LinkName: 'The Telegraph India',
    URL: 'https://www.telegraphindia.com/jharkhand/jharkhand-inks-pact-for-indias-first-hydrogen-fuel-project-with-tcpl-green-energy-solutions/cid/1961597',
  },
  {
    SrNo: 99,
    DateOfPublishing: '2023-08-28',
    News: 'Business 20 Summit: Nandan Nilekani hails India\u2019s digital public infrastructure',
    LinkName: 'The Statesman',
    URL: 'https://www.thestatesman.com/business/business-20-summit-nandan-nilekani-hails-indias-digital-public-infrastructure-1503216096.html',
  },
  {
    SrNo: 100,
    DateOfPublishing: '2023-08-28',
    News: 'Telangana Governor stresses importance of environmental protection',
    LinkName: 'UNI',
    URL: 'http://www.uniindia.com/telangana-governor-stresses-importance-of-environmental-protection/south/news/3037921.html',
  },
  {
    SrNo: 101,
    DateOfPublishing: '2023-08-28',
    News: 'EV, green hydrogen may come under priority sector lending: Govt Official',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/india-considering-evs-for-priority-sector-lending-official-says-11263151.html',
  },
  {
    SrNo: 102,
    DateOfPublishing: '2023-08-28',
    News: 'EXPLAINER: India\u2019s Green Hydrogen Standard',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/explainer-indias-green-hydrogen-standard/103114649',
  },
  {
    SrNo: 103,
    DateOfPublishing: '2023-08-28',
    News: 'IndianOil sets sight on being \u2018360-degree energy\u2019 company; to invest Rs 4 lakh crore',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry/indianoil-sets-sight-on-being-360-degree-energy-company-to-invest-rs-4-lakh-crore/3222545/',
  },
  {
    SrNo: 104,
    DateOfPublishing: '2023-08-28',
    News: 'B20 Summit addresses climate transition financing challenges',
    LinkName: 'The Sunday Guardian',
    URL: 'https://sundayguardianlive.com/business/b20-summit-addresses-climate-transition-financing-challenges',
  },
  {
    SrNo: 105,
    DateOfPublishing: '2023-08-28',
    News: 'Why the biogas sector presents a compelling case for growth of clean energy and start up.',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/why-the-biogas-sector-presents-a-compelling-case-for-growth-of-clean-energy-and-startups/103050670',
  },
  {
    SrNo: 106,
    DateOfPublishing: '2023-08-28',
    News: 'Sustainable India 2047: Green Growth and Environmental Stewardship',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/india-at-100/story/sustainable-india-2047-green-growth-and-environmental-stewardship-395650-2023-08-25',
  },
  {
    SrNo: 107,
    DateOfPublishing: '2023-08-28',
    News: "How India's G20 presidency can give a fillip to green policies for global steel industry",
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/magazine/columns/story/how-indias-g20-presidency-can-give-a-fillip-to-green-policies-for-global-steel-industry-395430-2023-08-26',
  },
  {
    SrNo: 108,
    DateOfPublishing: '2023-08-28',
    News: 'UN-backed Deforestation Carbon Credits Failing, Study',
    LinkName: 'News18',
    URL: 'https://www.news18.com/viral/un-backed-deforestation-carbon-credits-failing-study-8551859.html',
  },
  {
    SrNo: 109,
    DateOfPublishing: '2023-08-29',
    News: 'Net-zero target calls for tech innovation, cheaper capital\u2019',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/netzero-target-calls-for-tech-innovation-cheaper-capital-11693248186092.html',
  },
  {
    SrNo: 110,
    DateOfPublishing: '2023-08-29',
    News: "Renewable energy's soaring ascent: Fueling growth, overcoming hurdles, and forging an inclusive future",
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renewable-energys-soaring-ascent-fuelling-growth-overcoming-hurdles-and-forging-an-inclusive-future/103137390',
  },
  {
    SrNo: 111,
    DateOfPublishing: '2023-08-29',
    News: 'ONGC in talks with Gentari for joint venture in energy storage',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/companies/news/ongc-in-talks-with-gentari-for-joint-venture-in-energy-storage-11693248802027.html',
  },
  {
    SrNo: 112,
    DateOfPublishing: '2023-08-29',
    News: 'Distributed Renewable Energy Can Foster a Gender-inclusive Future',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/distributed-renewable-energy-can-foster-a-gender-inclusive-future/103137726',
  },
  {
    SrNo: 113,
    DateOfPublishing: '2023-08-29',
    News: 'BharatRohan Partners with Kosher Climate to Transform Sustainable Rice Cultivation in Barabanki District, Uttar Pradesh',
    LinkName: 'PTI',
    URL: 'https://www.ptinews.com/press-release/pti/64482.html',
  },
  {
    SrNo: 114,
    DateOfPublishing: '2023-08-29',
    News: 'Sterlite Power bags green energy transmission project in Rajasthan',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/sterlite-power-bags-green-energy-transmission-project-in-rajasthan/103138168',
  },
  {
    SrNo: 115,
    DateOfPublishing: '2023-08-29',
    News: 'PanXchange Carbon Credit Market to Integrate with the Hedera Blockchain',
    LinkName: 'Business Lend',
    URL: 'https://www.businesslend.com/press-release/panxchange-carbon-credit-market-to-integrate-with-the-hedera-blockchain/',
  },
  {
    SrNo: 116,
    DateOfPublishing: '2023-08-29',
    News: 'Carbon dioxide to ethanol: Chennai-based Ramcharan springs another surprise',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/news/carbon-dioxide-to-ethanol-chennai-based-ramcharan-springs-another-surprise-123082800965_1.html',
  },
  {
    SrNo: 117,
    DateOfPublishing: '2023-08-29',
    News: 'Indian Oil Corporation invites global tenders to set up its first green hydrogen plant in Panipat',
    LinkName: 'CNBC Tv18',
    URL: 'https://www.cnbctv18.com/business/companies/indian-oil-corporation-invites-global-tenders-to-set-up-its-first-green-hydrogen-plant-in-panipat-17661501.htm',
  },
  {
    SrNo: 118,
    DateOfPublishing: '2023-08-31',
    News: 'COP28 Presidency calls for urgent global adaptation action on climate change at Korea Global Adaptation Week 2023',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/world/cop28-presidency-calls-for-urgent-global-adaptation-action-on-climate-change-at-korea-global-adaptation-week-2023-11693390958321.html',
  },
  {
    SrNo: 119,
    DateOfPublishing: '2023-08-31',
    News: 'Three questions before India in its quest for Green Hydrogen',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/three-questions-before-india-in-its-quest-for-green-hydrogen/103212026',
  },
  {
    SrNo: 120,
    DateOfPublishing: '2023-08-31',
    News: 'Coal PSUs aim to generate 5,200 mw solar energy to seek net zero carbon status in 3 years',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/coal-psus-aim-to-generate-5200-mw-solar-energy-to-seek-net-zero-carbon-status-in-3-years/103226503',
  },
  {
    SrNo: 121,
    DateOfPublishing: '2023-08-31',
    News: 'Indian Railways Strides Towards Net Zero: Solar Power Capacity Surges 54-Fold Over Nine-Year Period',
    LinkName: 'Swarajya',
    URL: 'https://swarajyamag.com/infrastructure/indian-railways-strides-towards-net-zero-solar-power-capacity-surges-54-fold-over-nine-year-period',
  },
  {
    SrNo: 122,
    DateOfPublishing: '2023-08-31',
    News: 'India Energy Storage Alliance begins recognising women leaders driving energy transition',
    LinkName: 'Economic Times Energy World',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fenergy.economictimes.indiatimes.com%2Fnews%2Frenewable%2Findia-energy-storage-alliance-begins-recognising-women-leaders-driving-energy-transition%2F103226949',
  },
  {
    SrNo: 123,
    DateOfPublishing: '2023-08-31',
    News: 'India, US join hands to launch Renewable Energy Tech Action Platform for clean energy',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/sterlite-power-bags-green-energy-transmission-project-in-rajasthan/103138168',
  },
  {
    SrNo: 124,
    DateOfPublishing: '2023-08-31',
    News: 'Human-caused climate change may lead to 1 billion premature deaths over next century: Study',
    LinkName: 'The Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/aug/29/human-caused-climate-change-may-lead-to-1-billion-premature-deaths-over-next-century-study-2609756.html',
  },
  {
    SrNo: 125,
    DateOfPublishing: '2023-08-31',
    News: 'Indian banks could be catalyst in mitigating climate change',
    LinkName: 'The Hindu',
    URL: 'https://www.hindustantimes.com/ht-insight/economy/indian-banks-could-be-catalyst-in-mitigating-climate-change-101693294225379.html',
  },
  {
    SrNo: 126,
    DateOfPublishing: '2023-08-31',
    News: 'Before climate change sinks what mismanagement started, Mumbai must get its act right',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/opinion/before-climate-change-sinks-what-mismanagement-started-mumbai-must-get-its-act-right-11286651.html',
  },
  {
    SrNo: 127,
    DateOfPublishing: '2023-08-31',
    News: 'Climate change may increase conflict deaths, says IMF',
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/science-environment/2575838-climate-change-may-increase-conflict-deaths-says-imf',
  },
  {
    SrNo: 128,
    DateOfPublishing: '2023-08-31',
    News: '\u2018Time to Work Together Now\u2019: India\u2019s Message to G20 as it Paves Way for Talks on Disaster Risk Reduction',
    LinkName: 'News18',
    URL: 'https://www.news18.com/india/g20-india-message-paves-way-talks-disaster-risk-reduction-8557304.html',
  },
  {
    SrNo: 129,
    DateOfPublishing: '2023-09-01',
    News: 'Rajapalayam will achieve carbon neutral status by 2040, says Supriya Sahu',
    LinkName: 'The Indian Express',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/aug/31/rajapalayam-will-achieve-carbon-neutral-status-by-2040-says-supriya-sahu-2610112.html',
  },
  {
    SrNo: 130,
    DateOfPublishing: '2023-09-01',
    News: 'Climate change exacerbating conflict and poverty: IMF',
    LinkName: 'The Tribune',
    URL: 'https://www.telegraphindia.com/world/climate-change-exacerbating-conflict-and-poverty-international-monetary-fund-dws/cid/1962804',
  },
  {
    SrNo: 131,
    DateOfPublishing: '2023-09-01',
    News: 'Sustainability as a solution for climate change in Chennai and beyond',
    LinkName: 'Chennai Citizen Matters',
    URL: 'https://chennai.citizenmatters.in/chennai-sustainable-gcc-tnpcb-cmwssb-cmda-rwh-waste-management-renewable-energy-plastic-ban-81724',
  },
  {
    SrNo: 132,
    DateOfPublishing: '2023-09-01',
    News: 'India will need to invest 5% of its GDP annually to meet net-zero emissions by 2050; EV largest share of investment',
    LinkName: 'Business Insider India',
    URL: 'https://www.businessinsider.in/sustainability/news/india-will-need-to-invest-5-of-its-gdp-annually-to-meet-net-zero-emissions-by-2050-ev-largest-share-of-investment/articleshow/103244408.cms',
  },
  {
    SrNo: 133,
    DateOfPublishing: '2023-09-01',
    News: 'Renewable energy as an integral part of India\u2019s Energy Security: A critical appraisal',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renewable-energy-as-an-integral-part-of-indias-energy-security-a-critical-appraisal/103235396',
  },
  {
    SrNo: 134,
    DateOfPublishing: '2023-09-01',
    News: 'Coal PSUs aim to generate 5,200 mw solar energy to seek net zero carbon status in 3 years',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/sterlite-power-bags-green-energy-transmission-project-in-rajasthan/103138168',
  },
  {
    SrNo: 135,
    DateOfPublishing: '2023-09-01',
    News: 'Artificial Intelligence Steps In to Lower Carbon Footprint of Buildings',
    LinkName: 'The Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/aug/29/human-caused-climate-change-may-lead-to-1-billion-premature-deaths-over-next-century-study-2609756.html',
  },
  {
    SrNo: 136,
    DateOfPublishing: '2023-09-01',
    News: 'Artificial Intelligence Steps In to Lower Carbon Footprint of Buildings',
    LinkName: 'Mint',
    URL: 'livemint.com/ai/artificial-intelligence-steps-in-to-lower-carbon-footprint-of-buildings-11693495745316.html',
  },
  {
    SrNo: 137,
    DateOfPublishing: '2023-09-01',
    News: 'Bridging the green finance gap in India: A status check',
    LinkName: 'Economic Times Energy world',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/bridging-the-green-finance-gap-in-india-a-status-check/103255890',
  },
  {
    SrNo: 138,
    DateOfPublishing: '2023-09-01',
    News: 'India\u2019s G20 Leadership and Aviation\u2019s Eco-Friendly Flight Towards a Sustainable Future',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/bridging-the-green-finance-gap-in-india-a-status-check/103255890',
  },
  {
    SrNo: 139,
    DateOfPublishing: '2023-09-01',
    News: 'U.P. to have its own green hydrogen policy: CM Yogi',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/lucknow-news/up-to-have-its-own-green-hydrogen-policy-cm-yogi-101693498963879.html',
  },
  {
    SrNo: 140,
    DateOfPublishing: '2023-09-01',
    News: 'ONGC commits Rs 2 Lakh crore for carbon neutrality',
    LinkName: 'Economic Times Energy ',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ongc-commits-rs-2-lakh-crore-for-carbon-neutrality/103253204',
  },
  {
    SrNo: 141,
    DateOfPublishing: '2023-09-04',
    News: 'Steel sector emits the largest amount of carbon. But it can lead decarbonisation efforts too',
    LinkName: 'The Print',
    URL: 'https://theprint.in/feature/steel-sector-emits-the-largest-amount-of-carbon-but-it-can-lead-decarbonisation-efforts-too/1744543/',
  },
  {
    SrNo: 142,
    DateOfPublishing: '2023-09-04',
    News: 'Sustainability synergy: AI and EVs paving the way to greener transportation',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/digital-transformation-sustainability-synergy-ai-and-evs-paving-the-way-to-greener-transportation-3230250/',
  },
  {
    SrNo: 143,
    DateOfPublishing: '2023-09-04',
    News: 'Oil Industry\u2019s Dirty Secret About Carbon Capture Plants',
    LinkName: 'News Click',
    URL: 'https://www.newsclick.in/oil-industrys-dirty-secret-about-carbon-capture-plants',
  },
  {
    SrNo: 144,
    DateOfPublishing: '2023-09-04',
    News: "Tech's carbon footprint: Can AI revolutionise responsibly?",
    LinkName: 'Economic Times Telecom',
    URL: 'https://telecom.economictimes.indiatimes.com/news/internet/techs-carbon-footprint-can-ai-revolutionise-responsibly/103322096',
  },
  {
    SrNo: 145,
    DateOfPublishing: '2023-09-04',
    News: 'After solar alliance, India makes case for biofuels grouping to support energy transition: PM Modi',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/after-solar-alliance-india-makes-case-for-biofuels-grouping-to-support-energy-transition-pm-modi/103336226',
  },
  {
    SrNo: 146,
    DateOfPublishing: '2023-09-04',
    News: 'Diversity best bet, no one-size-fits-all solution to energy transition: PM Modi',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/defence-diversity-best-bet-no-one-size-fits-all-solution-to-energy-transition-pm-modi-3231540/',
  },
  {
    SrNo: 147,
    DateOfPublishing: '2023-09-04',
    News: 'NTPC and OIL Sign MoU for Renewable Energy and Green Initiatives',
    LinkName: 'Interview Times',
    URL: 'https://interviewtimes.net/ntpc-and-oil-sign-mou-for-renewable-energy-and-green-initiatives/',
  },
  {
    SrNo: 148,
    DateOfPublishing: '2023-09-04',
    News: 'Power ministry may recommend GST cut on hydro project components',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/union-ministry-of-power-may-seek-cut-in-gst-on-components-for-hydropower-projects-11693761347288.html',
  },
  {
    SrNo: 149,
    DateOfPublishing: '2023-09-04',
    News: 'West Bengal Initiatives Aim to Revolutionise Climate Resilience and Disaster Preparedness',
    LinkName: 'News Click',
    URL: 'https://www.newsclick.in/west-bengal-initiatives-aim-revolutionise-climate-resilience-and-disaster-preparedness',
  },
  {
    SrNo: 150,
    DateOfPublishing: '2023-09-04',
    News: 'Need to alter approach on climate action from restrictive to constructive: PM Modi',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/need-to-alter-approach-on-climate-action-from-restrictive-to-constructive-pm-modi/103336260',
  },
  {
    SrNo: 151,
    DateOfPublishing: '2023-09-04',
    News: 'COP28 provides fresh opportunity to further unite global efforts to reduce carbon emissions: Egyptian Minister',
    LinkName: 'Zee Bussiness',
    URL: 'https://www.zeebiz.com/world/news-cop28-provides-fresh-opportunity-to-further-unite-global-efforts-to-reduce-carbon-emissions-egyptian-minister-251877',
  },
  {
    SrNo: 152,
    DateOfPublishing: '2023-09-04',
    News: 'Electrifying the future: How electric vehicles (EVs) can propel climate change mitigation',
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/blogs/voices/electrifying-the-future-how-electric-vehicles-evs-can-propel-climate-change-mitigation/',
  },
  {
    SrNo: 153,
    DateOfPublishing: '2023-09-05',
    News: "Time to act': Union Minister Meenakashi Lekhi calls for immediate action on climate change",
    LinkName: 'ANI',
    URL: 'https://www.aninews.in/news/world/asia/time-to-act-union-minister-meenakashi-lekhi-calls-for-immediate-action-on-climate-change20230905011240/',
  },
  {
    SrNo: 154,
    DateOfPublishing: '2023-09-05',
    News: 'ICAR signs agreement with Bayer for resource technology development',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/icar-signs-mou-with-bayer-for-resource-efficient-tech-development-123090401034_1.html',
  },
  {
    SrNo: 155,
    DateOfPublishing: '2023-09-05',
    News: '50% of MSMEs ask for monetary incentives to adopt sustainability',
    LinkName: 'CXO Today',
    URL: 'https://www.cxotoday.com/press-release/50-of-msmes-ask-for-monetary-incentives-to-adopt-sustainability-sidbi-db-spex/',
  },
  {
    SrNo: 156,
    DateOfPublishing: '2023-09-05',
    News: "NTPC to host conference on 'Green Hydrogen Pilots in India'",
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ntpc-to-host-conference-on-green-hydrogen-pilots-in-india/103362049',
  },
  {
    SrNo: 157,
    DateOfPublishing: '2023-09-05',
    News: "Green Hydrogen Pilots in India' conference on pilot projects on Sept 5",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/green-hydrogen-pilots-in-india-conference-on-pilot-projects-on-sept-5-123090400960_1.html',
  },
  {
    SrNo: 158,
    DateOfPublishing: '2023-09-05',
    News: 'Himachal Pradesh plans policy to develop electric charging stations for e-vehicles',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/polls/himachal-pradesh-plans-policy-to-develop-electric-charging-stations-for-e-vehicles/103373975',
  },
  {
    SrNo: 159,
    DateOfPublishing: '2023-09-06',
    News: 'India among five major global economies in race to reach net-zero',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-among-five-major-global-economies-in-race-to-reach-net-zero/103411634',
  },
  {
    SrNo: 160,
    DateOfPublishing: '2023-09-06',
    News: 'Green fuel pilots are the first step in India\u2019s leap towards energy independence, asserts Power Minister',
    LinkName: 'News Click',
    URL: 'https://www.newsclick.in/oil-industrys-dirty-secret-about-carbon-capture-plants',
  },
  {
    SrNo: 161,
    DateOfPublishing: '2023-09-06',
    News: 'Why India must focus on MSMEs for meaningful energy transition',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/why-india-must-focus-on-msmes-for-meaningful-energy-transition/103415768',
  },
  {
    SrNo: 162,
    DateOfPublishing: '2023-09-06',
    News: 'G-20 Summit in Delhi: Global leaders to discuss \u2018green hydrogen\u2019 manufacturing and supply',
    LinkName: 'Mint',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.livemint.com%2Findustry%2Fenergy%2Fg20-summit-in-delhi-global-leaders-to-discuss-green-hydrogen-manufacturing-and-supply-11693964667017.html',
  },
  {
    SrNo: 163,
    DateOfPublishing: '2023-09-06',
    News: "Kenya's leader says climate change is eating away Africa's GDP, calls for talks on carbon Tax",
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/kenyas-leader-says-climate-change-is-eating-away-africas-gdp-calls-for-talks-on-global-carbon-tax/103395775',
  },
  {
    SrNo: 164,
    DateOfPublishing: '2023-09-06',
    News: 'Time to have global conversation about carbon tax on polluters: Kenya prez',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/time-to-have-global-conversation-about-carbon-tax-on-polluters-kenya-prez-123090501400_1.html',
  },
  {
    SrNo: 165,
    DateOfPublishing: '2023-09-06',
    News: "A Look At India's Agenda For The G20 Summit",
    LinkName: 'BW Business World',
    URL: 'https://www.businessworld.in/article/A-Look-At-India-s-Agenda-For-The-G20-Summit/06-09-2023-490160/',
  },
  {
    SrNo: 166,
    DateOfPublishing: '2023-09-08',
    News: 'Indian banks not ready to confront climate change risks, says report',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/business/2023/sep/07/indian-banks-not-ready-to-confront-climate-risks-says-report-2612621.html',
  },
  {
    SrNo: 167,
    DateOfPublishing: '2023-09-08',
    News: 'India aims to lead as green fuel refuelling hub for shipping.',
    LinkName: 'CW Construction World',
    URL: 'https://www.constructionworld.in/energy-infrastructure/power-and-renewable-energy/india-aims-to-lead-as-green-fuel-refuelling-hub-for-shipping/44190',
  },
  {
    SrNo: 168,
    DateOfPublishing: '2023-09-08',
    News: 'Tata Steel gains after subsidiary signs MoU for green hydrogen plant in Odishaz',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/markets/tata-steel-gains-after-subsidiary-signs-mou-for-green-hydrogen-plant-in-odisha-11332181.html',
  },
  {
    SrNo: 169,
    DateOfPublishing: '2023-09-08',
    News: 'Climate change sticking point, Saudi differs on fossil fuel reference.',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/india/climate-change-saudi-fossil-fuel-reference-8929979/',
  },
  {
    SrNo: 170,
    DateOfPublishing: '2023-09-08',
    News: 'India\u2019s Leadership at G20: A spot light on Energy & Climate',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-leadership-at-the-g20-a-spotlight-on-energy-and-climate/103488430',
  },
  {
    SrNo: 171,
    DateOfPublishing: '2023-09-08',
    News: 'ET Sustainability Forum: Tech to play key role in helping companies and countries to cut carbon footprint say top execs',
    LinkName: 'ET Sustainability',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/et-sustainability-forum-tech-to-play-key-role-in-helping-companies-and-countries-cut-carbon-footprint-say-top-execs/103463096',
  },
  {
    SrNo: 172,
    DateOfPublishing: '2023-09-08',
    News: 'Microsoft will use carbon absorbing rocks to meet climate goals',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/microsoft-will-use-carbon-absorbing-rocks-to-meet-climate-goals-11694088276595.html',
  },
  {
    SrNo: 173,
    DateOfPublishing: '2023-09-08',
    News: 'REUTERS IMPACT-Companies call for more action on carbon, subsidies at COP28',
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/headlines/2584373-reuters-impact-companies-call-for-more-action-on-carbon-subsidies-at-cop28',
  },
  {
    SrNo: 174,
    DateOfPublishing: '2023-09-08',
    News: 'How carbon emissions accounting helps in achieving net zero targets',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/how-carbon-emissions-accounting-helps-in-achieving-net-zero-targets-123090700762_1.html',
  },
  {
    SrNo: 175,
    DateOfPublishing: '2023-09-11',
    News: "Several G20 leaders hail PM Modi's initiatives to combat climate change",
    LinkName: 'Eonomic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/several-g20-leaders-hail-pm-modis-initiatives-to-combat-climate-change/103563964',
  },
  {
    SrNo: 176,
    DateOfPublishing: '2023-09-11',
    News: 'UK commits $2 billion to Green Climate Fund; check details here',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/g20-summit/story/uk-commits-2-billion-to-green-climate-fund-check-details-here-397711-2023-09-10',
  },
  {
    SrNo: 177,
    DateOfPublishing: '2023-09-11',
    News: 'G20 commits to achieving net zero emissions by mid-century',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/g20-commits-to-achieving-net-zero-emissions-by-midcentury-101694260905671.html',
  },
  {
    SrNo: 178,
    DateOfPublishing: '2023-09-11',
    News: 'Accelerating Green Economy with Vision of Net Zero in 2070, more than Rs 2000 crores are being invested in biodiesel sector in the country in FY-23-24',
    LinkName: 'PTI',
    URL: 'https://www.ptinews.com/pti/Accelerating-Green-Economy-with-Vision-of-Net-Zero-in-2070--more-than-Rs-2000-crores-are-being-invested-in-biodiesel-sector-in-the-country-in-FY-23-24/64781.html',
  },
  {
    SrNo: 179,
    DateOfPublishing: '2023-09-11',
    News: 'G20 Summit : India launches Global Biofuel Alliance to meet net zero targets',
    LinkName: 'UNI',
    URL: 'http://www.uniindia.com/news/india/g20-summit-india-launches-global-biofuel-alliance-to-meet-net-zero-targets/3047365.html',
  },
  {
    SrNo: 180,
    DateOfPublishing: '2023-09-11',
    News: 'Differentiated responsibility gets G20 nod before COP28',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/differentiated-responsibility-gets-g20-nod-before-cop28-11694370935374.html',
  },
  {
    SrNo: 181,
    DateOfPublishing: '2023-09-11',
    News: 'G20 declaration stresses on transparent global market for low-carbon hydrogen',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/g20-declaration-stresses-on-transparent-global-market-for-low-carbon-hydrogen-11694272080764.html',
  },
  {
    SrNo: 182,
    DateOfPublishing: '2023-09-11',
    News: 'G20 to accelerate coal power reduction; aims to triple RE capacity',
    LinkName: 'Eonomic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/g20-to-accelerate-coal-power-reduction-aims-to-triple-re-capacity/103564367',
  },
  {
    SrNo: 183,
    DateOfPublishing: '2023-09-11',
    News: "Trillions needed to meet Paris climate goals: G20 under India's presidency",
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-g20-declaration-on-climate-redefines-life-with-a-reminder-on-past-commitments-3239063/',
  },
  {
    SrNo: 184,
    DateOfPublishing: '2023-09-11',
    News: 'G20 declaration on climate: Redefines LiFE with a reminder on past commitments',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-g20-declaration-on-climate-redefines-life-with-a-reminder-on-past-commitments-3239063/',
  },
  {
    SrNo: 185,
    DateOfPublishing: '2023-09-11',
    News: 'G20 push for renewable energy and clean tech',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/g20-will-use-a-mix-of-renewable-energy-low-carbon-and-clean-tech-to-achieve-carbon-neutrality-101694372579306.html',
  },
  {
    SrNo: 186,
    DateOfPublishing: '2023-09-12',
    News: '"Climate change will definitely not end the world as we know it": Elon Musk shares views on climate crisis',
    LinkName: 'Business Insider',
    URL: 'https://www.businessinsider.in/sustainability/news/climate-change-will-definitely-not-end-the-world-as-we-know-it-elon-musk-shares-views-on-climate-crisis/articleshow/103593171.cms',
  },
  {
    SrNo: 187,
    DateOfPublishing: '2023-09-12',
    News: 'G20 New Delhi declaration to compliment the efforts to fight climate change and can feed into the deliberations at COP28: Yadav',
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/india/g20-new-delhi-declaration-to-compliment-the-efforts-to-fight-climate-change-and-can-feed-into-the-deliberations-at-cop28-yadav/articleshow/103586174.cms?from=mdr',
  },
  {
    SrNo: 188,
    DateOfPublishing: '2023-09-12',
    News: 'Edelweiss Alternatives launches its first climate fund to combat climate change',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/business/edelweiss-alternatives-launches-its-first-climate-fund-to-combat-climate-change-11349911.html',
  },
  {
    SrNo: 189,
    DateOfPublishing: '2023-09-12',
    News: "G20 climate deal: Experts laud India's balancing act in the face of fossil fuel debate",
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/oil-permits-and-wind-crisis-threaten-uk-net-zero-pledge/103567307',
  },
  {
    SrNo: 190,
    DateOfPublishing: '2023-09-12',
    News: 'Oil permits and wind crisis threaten UK net zero pledge',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/oil-permits-and-wind-crisis-threaten-uk-net-zero-pledge/103567307',
  },
  {
    SrNo: 191,
    DateOfPublishing: '2023-09-12',
    News: "Making EVs affordable and green in India's journey to carbon neutrality",
    LinkName: 'Economic Times Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/making-evs-affordable-and-green-in-indias-journey-to-carbon-neutrality/103578092',
  },
  {
    SrNo: 192,
    DateOfPublishing: '2023-09-12',
    News: 'NTPC Green Energy, Nayara Energy ink pact to explore opportunities in green hydrogen space',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ntpc-green-energy-nayara-energy-ink-pact-to-explore-opportunities-in-green-hydrogen-space/103591906',
  },
  {
    SrNo: 193,
    DateOfPublishing: '2023-09-12',
    News: 'Avaada Energy, Al Jomaih Energy Water partner for green energy projects',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/avaada-energy-al-jomaih-energy-water-partner-for-green-energy-projects-123091101214_1.html',
  },
  {
    SrNo: 194,
    DateOfPublishing: '2023-09-12',
    News: 'India needs much more clean energy to meet climate target',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-g20-declaration-on-climate-redefines-life-with-a-reminder-on-past-commitments-3239063/',
  },
  {
    SrNo: 195,
    DateOfPublishing: '2023-09-12',
    News: 'India, Saudi Arabia close historic agreement on enhanced cooperation in energy sector',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/latest/economy/story/amid-geopolitical-shift-changing-priorities-india-saudi-arabia-close-historic-agreement-on-enhanced-cooperation-in-energy-sector-397973-2023-09-11',
  },
  {
    SrNo: 196,
    DateOfPublishing: '2023-09-13',
    News: 'G20 meet\u2019s climate action promise: Significant, but not enough',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/explained/explained-climate/g20s-climate-action-promise-8937202/',
  },
  {
    SrNo: 197,
    DateOfPublishing: '2023-09-13',
    News: 'Climate Change: Kashmir Faces Harsh Heat Wave',
    LinkName: 'Kahmir Life',
    URL: 'https://kashmirlife.net/climate-change-kashmir-faces-harsh-heat-wave-327750/',
  },
  {
    SrNo: 198,
    DateOfPublishing: '2023-09-13',
    News: 'Accelerating Green Economy With Vision Of Net Zero In 2070, More Than Rs 2000 Crores Are Being Invested In Biodiesel Sector In The Country In FY-23-24',
    LinkName: 'Ahmadabad Mirror',
    URL: 'https://ahmedabadmirror.com/accelerating-green-economy-with-vision-of-net-zero-in-2070-more-than-rs-2000-crores-are-being-invested-in-biodiesel-sector-in-the-country-in-fy-23-24/81863686.html',
  },
  {
    SrNo: 199,
    DateOfPublishing: '2023-09-13',
    News: 'How Gujarat plans to make air polluters pay',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/india-today-insight/story/how-gujarat-plans-to-make-air-polluters-pay-2434745-2023-09-12',
  },
  {
    SrNo: 200,
    DateOfPublishing: '2023-09-13',
    News: 'Our EV subsidy policy is due for a full overhaul',
    LinkName: 'Mint',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.livemint.com%2Fopinion%2Fonline-views%2Four-ev-subsidy-policy-is-due-for-a-full-overhaul-11694531840905.html',
  },
  {
    SrNo: 201,
    DateOfPublishing: '2023-09-13',
    News: 'EU lawmakers pass bill hiking renewable energy targets',
    LinkName: 'Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/eu-lawmakers-pass-bill-hiking-renewable-energy-targets/103606887',
  },
  {
    SrNo: 202,
    DateOfPublishing: '2023-09-13',
    News: 'Sunny side up India: Saudi MoU on linking national power grids is smart prep for coming surge in green energy capacity',
    LinkName: 'Times of India',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Ftimesofindia.indiatimes.com%2Fblogs%2Ftoi-editorials%2Fsunny-side-up-india-saudi-mou-on-linking-national-power-grids-is-smart-prep-for-coming-surge-in-green-energy-capacity%2F',
  },
  {
    SrNo: 203,
    DateOfPublishing: '2023-09-13',
    News: 'Nayara Energy inks MoU with NTPC Green Energy for green hydrogen',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/rajkot/nayara-energy-inks-mou-with-ntpc-green-energy-for-green-hydrogen-8937304/',
  },
  {
    SrNo: 204,
    DateOfPublishing: '2023-09-13',
    News: 'Bhopal airport gets Level-2 tag in carbon accreditation',
    LinkName: 'The Economic Times',
    URL: 'https://infra.economictimes.indiatimes.com/news/aviation/bhopal-airport-gets-l-2-tag-in-carbon-accreditation/103603973',
  },
  {
    SrNo: 205,
    DateOfPublishing: '2023-09-14',
    News: 'Climate Change: Justice BR Gavai says companies should be held liable; have moral responsibility',
    LinkName: 'Bar & Bench',
    URL: 'https://www.barandbench.com/news/climate-change-justice-br-gavai-companies-moralresponsibility',
  },
  {
    SrNo: 206,
    DateOfPublishing: '2023-09-14',
    News: '\u2018Planet wreckers\u2019: 20 countries led by US to emit 90% carbon dioxide through 2050',
    LinkName: 'Down to Earth',
    URL: 'https://www.downtoearth.org.in/news/world/-planet-wreckers-20-countries-led-by-us-to-emit-90-carbon-dioxide-through-2050-91730',
  },
  {
    SrNo: 207,
    DateOfPublishing: '2023-09-14',
    News: '4th G20 Sustainable Finance Working Group Meeting begins in Varanasi',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/lucknow-news/4th-g20-sustainable-finance-working-group-meeting-begins-in-varanasi-101694591840375.html',
  },
  {
    SrNo: 208,
    DateOfPublishing: '2023-09-14',
    News: 'GEAPP helping Indian states to build green energy capacity',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-geapp-helping-indian-states-to-build-green-energy-capacity-3242871/',
  },
  {
    SrNo: 209,
    DateOfPublishing: '2023-09-14',
    News: 'More than 100 paper mills likely to come under PAT scheme: IPMA official',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/more-than-100-paper-mills-likely-to-come-under-pat-scheme-ipma-official-123091300864_1.html',
  },
  {
    SrNo: 210,
    DateOfPublishing: '2023-09-14',
    News: 'Govt Calls All-Party Meeting On Sep 17 Ahead Of Parliament Session',
    LinkName: 'State Times',
    URL: 'https://statetimes.in/govt-calls-all-party-meeting-on-sep-17-ahead-of-parliament-session/',
  },
  {
    SrNo: 211,
    DateOfPublishing: '2023-09-14',
    News: 'Setting green hydrogen standards is a great first step, but challenges remain',
    LinkName: 'Mint',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.livemint.com%2Findustry%2Fenergy%2Fsetting-green-hydrogen-standards-is-a-great-first-step-but-challenges-remain-11694607716847.html',
  },
  {
    SrNo: 212,
    DateOfPublishing: '2023-09-14',
    News: 'Sikkim CM Prem Singh Tamang meets Korean delegation, discusses feasibility and benefits of green hydrogen fuel',
    LinkName: 'India Today',
    URL: 'https://www.indiatodayne.in/sikkim/story/sikkim-cm-prem-singh-tamang-meets-korean-delegation-discusses-feasibility-and-benefits-of-green-hydrogen-fuel-677802-2023-09-14?onetap=true',
  },
  {
    SrNo: 213,
    DateOfPublishing: '2023-09-14',
    News: 'Taking forward G-20 agenda, 3rd International Climate Summit to begin tomorrow',
    LinkName: 'The Statesman',
    URL: 'https://www.thestatesman.com/india/taking-forward-g-20-agenda-3rd-international-climate-summit-to-begin-tomorrow-1503221651.html',
  },
  {
    SrNo: 214,
    DateOfPublishing: '2023-09-14',
    News: 'Dream of farmers generating fossil fuel alternatives is no longer just a dream, says Union Minister Nitin Gadkari',
    LinkName: 'News on Air',
    URL: 'https://newsonair.gov.in/News?title=Dream-of-farmers-generating-fossil-fuel-alternatives-is-no-longer-just-a-dream%2C-says-Union-Minister-Nitin-Gadkari&id=467712',
  },
  {
    SrNo: 215,
    DateOfPublishing: '2023-09-15',
    News: 'Oil India plans to invest Rs 25,000 crore to achieve net-zero targets by 2040: CMD',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/business/oil-india-plans-to-invest-rs-25000-crore-to-achieve-net-zero-targets-by-2040-cmd-11367861.html',
  },
  {
    SrNo: 216,
    DateOfPublishing: '2023-09-15',
    News: 'InMobi Forges Global Partnership with Ad Net Zero',
    LinkName: 'Adgully',
    URL: 'https://www.adgully.com/inmobi-forges-global-partnership-with-ad-net-zero-136584.html',
  },
  {
    SrNo: 217,
    DateOfPublishing: '2023-09-15',
    News: 'The New Delhi Declaration and Paris Agenda show a way forth',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/the-new-delhi-declaration-and-paris-agenda-show-a-way-forth-11694706998704.html',
  },
  {
    SrNo: 218,
    DateOfPublishing: '2023-09-15',
    News: '\u2018Climate change undermining world\u2019s SDG progress, science central to solution\u2019',
    LinkName: 'Millenniumpost',
    URL: 'https://www.millenniumpost.in/nation/climate-change-undermining-worlds-sdg-progress-science-central-to-solution-532967',
  },
  {
    SrNo: 219,
    DateOfPublishing: '2023-09-15',
    News: 'Expanding the Indian economy, the green way',
    LinkName: 'Mint',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.livemint.com%2Findustry%2Fenergy%2Fsetting-green-hydrogen-standards-is-a-great-first-step-but-challenges-remain-11694607716847.html',
  },
  {
    SrNo: 220,
    DateOfPublishing: '2023-09-15',
    News: 'Industry body submits recommendations to govt to fast-track Green Hydrogen mission',
    LinkName: 'Economic Times Energy',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fenergy.economictimes.indiatimes.com%2Fnews%2Frenewable%2Findustry-body-submits-recommendations-to-govt-to-fast-track-green-hydrogen-mission%2F103665424',
  },
  {
    SrNo: 221,
    DateOfPublishing: '2023-09-18',
    News: 'Global climate protests demand world leaders phase out fossil fuels',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/environment/story/global-climate-protests-demand-world-leaders-phase-out-fossil-fuels-2436079-2023-09-15',
  },
  {
    SrNo: 222,
    DateOfPublishing: '2023-09-18',
    News: 'India to launch 100 MW green hydrogen storage pilot for round-the-clock power supply: R K Singh',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-to-launch-100-mw-green-hydrogen-storage-pilot-for-round-the-clock-power-supply-r-k-singh/103694631',
  },
  {
    SrNo: 223,
    DateOfPublishing: '2023-09-18',
    News: 'Tamil Nadu set to become green hydrogen research hub',
    LinkName: 'The News Indian Express',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/sep/16/tamil-naduset-to-become-green-hydrogen-research-hub-2615316.html',
  },
  {
    SrNo: 224,
    DateOfPublishing: '2023-09-18',
    News: 'G20 plans to triple renewable energy capacity by 2030',
    LinkName: 'The Week',
    URL: 'https://www.theweek.in/theweek/more/2023/09/16/g20-plans-to-triple-renewable-energy-capacity-by-2030.html',
  },
  {
    SrNo: 225,
    DateOfPublishing: '2023-09-18',
    News: 'How India can galvanize PE-VC funds for its Net-Zero ambitions',
    LinkName: 'The Economic Times',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fenergy.economictimes.indiatimes.com%2Fnews%2Frenewable%2Fhow-india-can-galvanize-pe-vc-funds-for-its-net-zero-ambitions%2F103687199',
  },
  {
    SrNo: 226,
    DateOfPublishing: '2023-09-18',
    News: 'UAE to circulate COP28 draft declaration on food security',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/world/uae-to-circulate-cop28-draft-declaration-on-food-security-11694799035440.html',
  },
  {
    SrNo: 227,
    DateOfPublishing: '2023-09-18',
    News: "UK firm secures lucrative order to supply Indian solar facility in Gujarat's Mundra.",
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/uk-firm-secures-lucrative-order-to-supply-indian-solar-facility-in-gujarats-mundra/articleshow/103717150.cms?from=mdr',
  },
  {
    SrNo: 228,
    DateOfPublishing: '2023-09-19',
    News: "Climate change, security on top of Indo-French ties', says French envoy",
    LinkName: 'The News Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/sep/19/climate-change-security-on-top-of-indo-french-ties-says-french-envoy-2616211.html',
  },
  {
    SrNo: 229,
    DateOfPublishing: '2023-09-19',
    News: 'How a Pune startup is helping manufacturers, industries reduce their carbon footprint',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/pune/pune-startup-helpi-manufacturers-industries-reduce-carbon-footprint-8946294/',
  },
  {
    SrNo: 230,
    DateOfPublishing: '2023-09-19',
    News: 'Hydrogen Boom: After the centre, states begin to roll out green hydrogen policies',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/business/hydrogen-boom-after-the-centre-states-begin-to-roll-out-green-hydrogen-policies-11390091.html',
  },
  {
    SrNo: 231,
    DateOfPublishing: '2023-09-19',
    News: 'Rajasthan govt approves hydrogen policy',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/rajasthan-govt-approves-hydrogen-policy/103750404',
  },
  {
    SrNo: 232,
    DateOfPublishing: '2023-09-19',
    News: 'G 20 Summit Delhi: 4 Ways in which the G20 Declaration will accelerate ESG',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/opinion/columns/g20-summit-delhi-4-ways-in-which-the-g20-declaration-will-accelerate-esg-123091800622_1.html',
  },
  {
    SrNo: 233,
    DateOfPublishing: '2023-09-20',
    News: 'Companies Stall Climate Action Despite Earlier Promises',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/companies/companies-stall-climate-action-despite-earlier-promises-11695124659546.html',
  },
  {
    SrNo: 234,
    DateOfPublishing: '2023-09-20',
    News: 'Remote and hybrid work can significantly cut carbon footprint, finds study',
    LinkName: 'India Tv',
    URL: 'https://www.indiatvnews.com/health/remote-and-hybrid-work-can-significantly-cut-carbon-footprint-finds-study-2023-09-19-893614',
  },
  {
    SrNo: 235,
    DateOfPublishing: '2023-09-20',
    News: "India's renewable energy boom not fast enough for 1.5-degree Celsius goal: Report",
    LinkName: 'ET Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-renewable-energy-boom-not-fast-enough-for-1-5-degree-celsius-goal-report/103794872',
  },
  {
    SrNo: 236,
    DateOfPublishing: '2023-09-20',
    News: 'Sikkim banks on green hydrogen for future urbanisation',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/opinion/sikkim-banks-on-green-hydrogen-for-future-urbanisation-11394941.html',
  },
  {
    SrNo: 237,
    DateOfPublishing: '2023-09-20',
    News: 'ICRA forecasts rise in green energy usage to 42% in cement firms by FY2025',
    LinkName: 'Construction Week',
    URL: 'https://www.constructionweekonline.in/business/icra-forecasts-rise-in-green-energy-usage-to-42-in-cement-firms-by-fy2025',
  },
  {
    SrNo: 238,
    DateOfPublishing: '2023-09-20',
    News: 'Sidbi Extends 100% Funding for MSME Green Projects',
    LinkName: 'Businessworld',
    URL: 'https://www.businessworld.in/article/Sidbi-Extends-100-Funding-For-MSME-Green-Projects/20-09-2023-491898/',
  },
  {
    SrNo: 239,
    DateOfPublishing: '2023-09-21',
    News: 'Ireda Inks MoU With Bank Of Maharashtra For Financing Renewable Energy Projects',
    LinkName: 'BQ Prime',
    URL: 'https://www.bqprime.com/business/ireda-inks-mou-with-bank-of-maharashtra-for-financing-renewable-energy-projects',
  },
  {
    SrNo: 240,
    DateOfPublishing: '2023-09-21',
    News: 'Raj Cabinet approves Green Hydrogen Policy, Biomass and Waste to Energy Policy',
    LinkName: 'The Hans India',
    URL: 'https://www.thehansindia.com/news/national/raj-cabinet-approves-green-hydrogen-policy-biomass-and-waste-to-energy-policy-824104',
  },
  {
    SrNo: 241,
    DateOfPublishing: '2023-09-21',
    News: 'Sikkim Leads in Green Hydrogen Smart City: Greater Gangtok Chosen..',
    LinkName: 'CW Construction World',
    URL: 'http://constructionworld.in/energy-infrastructure/power-and-renewable-energy/sikkim-leads-in-green-hydrogen-smart-city--greater-gangtok-chosen/44582',
  },
  {
    SrNo: 242,
    DateOfPublishing: '2023-09-21',
    News: "India's green hydrogen ambitions ride the coat-tails of Chinese equipment",
    LinkName: 'Business Standard',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.business-standard.com%2Feconomy%2Fnews%2Findia-s-clean-hydrogen-plans-begin-at-chinese-manufacturing-facilities-123092000950_1.html',
  },
  {
    SrNo: 243,
    DateOfPublishing: '2023-09-21',
    News: 'SJVN shares hit record high on MoU with Power Finance Corp for renewable energy projects',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/markets/company-stock/story/sjvn-shares-rise-record-high-mou-power-finance-corporation-399048-2023-09-20',
  },
  {
    SrNo: 244,
    DateOfPublishing: '2023-09-21',
    News: 'AmpIn Energy to set up 33 MW solar plant in Maharashtra; signs pact with Varroc',
    LinkName: 'Zee Business',
    URL: 'https://www.zeebiz.com/companies/news-ampin-energy-to-set-up-33-mw-solar-plant-in-maharashtra-signs-pact-with-varroc-254999',
  },
  {
    SrNo: 245,
    DateOfPublishing: '2023-09-22',
    News: "Bombay High Court Upholds Constitutional Validity of Goa Green Cess Act For 'Reduction Of Carbon Footprint'",
    LinkName: 'Live Law',
    URL: 'https://www.livelaw.in/high-court/bombay-high-court/bombay-high-court-upholds-constitutional-validity-goa-green-cess-act-carbon-footprint-fee-238346',
  },
  {
    SrNo: 246,
    DateOfPublishing: '2023-09-22',
    News: 'India\u2019s National Statement at the Summit of the Future Preparatory Ministerial Meeting',
    LinkName: 'Ministry of External Affairs',
    URL: 'https://www.mea.gov.in/Speeches-Statements.htm?dtl/37136/Indias_National_Statement_at_the_Summit_of_the_Future_Preparatory_Ministerial_Meeting',
  },
  {
    SrNo: 247,
    DateOfPublishing: '2023-09-22',
    News: 'India hopeful about climate financing at UN conference: Environment secretary',
    LinkName: 'Mint',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.livemint.com%2Fnews%2Findia%2Findia-optimistic-for-greater-clarity-on-financial-pathways-at-un-climate-change-conference-11695314629272.html',
  },
  {
    SrNo: 248,
    DateOfPublishing: '2023-09-22',
    News: 'Climate action plan to help cut Chennai\u2019s carbon emission by 40 per cent in 17 years',
    LinkName: 'The News Indian Express',
    URL: 'https://www.newindianexpress.com/cities/chennai/2023/sep/22/climate-action-plan-to-help-cut-chennais-carbon-emission-by-40-per-centin-17-years-2617144.html',
  },
  {
    SrNo: 249,
    DateOfPublishing: '2023-09-22',
    News: 'CMAI, KUKVC ink pact to promote sustainable farming practices',
    LinkName: 'The Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/economy/agri-business/cmai-kukvc-ink-pact-to-promote-sustainable-farming-practices/article67326204.ece',
  },
  {
    SrNo: 250,
    DateOfPublishing: '2023-09-22',
    News: '9 Indian projects to cut carbon emissions \u2018worthless\u2019, finds climate change report',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/9-indian-projects-to-cut-carbon-emissions-worthless-finds-climate-change-report/1771584/',
  },
  {
    SrNo: 251,
    DateOfPublishing: '2023-09-22',
    News: "Tamil Nadu's Shore Temple is now India's first Green Energy Archeological Site",
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/travel/travel-news/tamil-nadus-shore-temple-is-now-indias-first-green-energy-archeological-site/articleshow/103835877.cms',
  },
  {
    SrNo: 252,
    DateOfPublishing: '2023-09-26',
    News: 'UK\u2019s backtracking on fossil fuel shows developed nations\u2019 hypocrisy',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/editorials/climate-abdication-8954529/',
  },
  {
    SrNo: 253,
    DateOfPublishing: '2023-09-26',
    News: 'Delhi Joins UN Campaign To Boost Climate Resilience, Pledges 25% Green Cover',
    LinkName: 'BW Business World',
    URL: 'https://www.businessworld.in/article/Delhi-Joins-UN-Campaign-To-Boost-Climate-Resilience-Pledges-25-Green-Cover/23-09-2023-492437/',
  },
  {
    SrNo: 254,
    DateOfPublishing: '2023-09-26',
    News: 'After U.N. meeting, countries brace for COP28 fossil fuel fight',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/after-u-n-meeting-countries-brace-for-cop28-fossil-fuel-fight/103946342',
  },
  {
    SrNo: 255,
    DateOfPublishing: '2023-09-26',
    News: 'ESG predicted to constitute 34 per cent % of India\u2019s domestic AUM by 2051: Study',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/esg-predicted-to-constitute-34-per-cent-of-indias-domestic-aum-by-2051-study/103948673',
  },
  {
    SrNo: 256,
    DateOfPublishing: '2023-09-26',
    News: 'India sets sight on 40 GW renewable energy capacity by 2024: Bhupinder Bhalla, Secy, MNRE',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-sets-sight-on-40-gw-renewable-energy-capacity-by-2024-bhupinder-bhalla-secy-mnre/103948606',
  },
  {
    SrNo: 257,
    DateOfPublishing: '2023-09-26',
    News: "India's 1st green hydrogen bus to run starting Monday at Kartavya Path",
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-1st-green-hydrogen-bus-to-run-starting-monday-at-kartavya-path/103919366',
  },
  {
    SrNo: 258,
    DateOfPublishing: '2023-09-26',
    News: 'India\u2019s final energy demand expected to double by 2070, says Deloitte-FICCI report',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-india-will-require-15-trillion-to-achieve-net-zero-emissions-target-by-2070-says-deloitte-ficci-report-3254499/',
  },
  {
    SrNo: 259,
    DateOfPublishing: '2023-09-26',
    News: 'How India can transition its EV battery waste crisis into a green energy opportunity',
    LinkName: 'Financial Express',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fwww.financialexpress.com%2Fbusiness%2Fexpress-mobility-how-india-can-transition-its-ev-battery-waste-crisis-into-a-green-energy-opportunity-3254380%2F',
  },
  {
    SrNo: 260,
    DateOfPublishing: '2023-09-26',
    News: 'VOC Port becomes first Indian port to handle green ammonia import',
    LinkName: 'The Economic Times Energy',
    URL: 'https://infra.economictimes.indiatimes.com/news/ports-shipping/voc-port-becomes-first-indian-port-to-handle-green-ammonia-import/103918209',
  },
  {
    SrNo: 261,
    DateOfPublishing: '2023-09-26',
    News: 'India\u2019s final energy demand expected to double by 2070: Report',
    LinkName: 'The Economic Times Energy',
    URL: 'https://infra.economictimes.indiatimes.com/news/ports-shipping/voc-port-becomes-first-indian-port-to-handle-green-ammonia-import/103918209',
  },
  {
    SrNo: 262,
    DateOfPublishing: '2023-09-26',
    News: 'OPINION: Why it is urgent for India to focus on Battery Energy Storage Systems - by Sumant Sinha',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/why-it-is-urgent-for-india-to-focus-on-battery-energy-storage-systems-by-sumant-sinha/103921668',
  },
  {
    SrNo: 263,
    DateOfPublishing: '2023-09-26',
    News: 'India among top five best performing countries on Climate Change: Hardeep Singh Puri',
    LinkName: 'UNI',
    URL: 'http://www.uniindia.com/india-among-top-five-best-performing-countries-on-climate-change-hardeep-singh-puri/india/news/3057295.html',
  },
  {
    SrNo: 264,
    DateOfPublishing: '2023-09-27',
    News: 'Water wisdom will help us cope with climate change',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/water-wisdom-will-help-us-cope-with-climate-change-11695738746260.html',
  },
  {
    SrNo: 265,
    DateOfPublishing: '2023-09-27',
    News: 'NTPC\u2019s green hydrogen initiative gains momentum with Syama Prasad Mookerjee Port collaboration',
    LinkName: 'Manufacturing Today',
    URL: 'https://www.manufacturingtodayindia.com/sectors/ntpcs-green-hydrogen-initiative-gains-momentum-with-syama-prasad-mookerjee-port-collaboration',
  },
  {
    SrNo: 266,
    DateOfPublishing: '2023-09-27',
    News: 'India to set emission reduction mandates for 4 sectors, to start carbon trading from 2025',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/business/economy/india-to-set-emission-reduction-mandates-for-4-sectors-to-start-carbon-trading-from-2025-11430631.html',
  },
  {
    SrNo: 267,
    DateOfPublishing: '2023-09-27',
    News: "Private sector participation lags in India's climate financing efforts, claims report",
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/business/private-sector-participation-lags-in-indias-climate-financing-efforts-claims-report-101695722276164.html',
  },
  {
    SrNo: 268,
    DateOfPublishing: '2023-09-29',
    News: 'Tata Steel inks pact with IOCL to further reduce carbon footprint',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/news/tata-steel-inks-pact-with-iocl-to-further-reduce-carbon-footprint-123092801501_1.html',
  },
  {
    SrNo: 269,
    DateOfPublishing: '2023-09-29',
    News: 'JSW Cement begins EV trialsto reduce carbon footprint in logistics',
    LinkName: 'Autocar Professional',
    URL: 'https://www.autocarpro.in/news/jsw-cement-begins-ev-trials-to-reduce-carbon-footprint-in-logistics-117000',
  },
  {
    SrNo: 270,
    DateOfPublishing: '2023-09-29',
    News: 'The need for behviourally informed policy tools for climate action',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/blogs/the-vantage-viewpoint/the-need-for-behaviourally-informed-policy-tools-for-climate-action/',
  },
  {
    SrNo: 271,
    DateOfPublishing: '2023-09-29',
    News: 'Carbon trading can help India become net zero by 2040: Nadir Godrej',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/carbon-trading-can-help-india-become-net-zero-by-2040-nadir-godrej-11695908762408.html',
  },
  {
    SrNo: 272,
    DateOfPublishing: '2023-09-29',
    News: 'ONGC And NTPC Green Partner for Domestic And Worldwide Renewable Energy Initiatives',
    LinkName: 'Indiainfoline',
    URL: 'https://www.indiainfoline.com/article/news-top-story/ongc-and-ntpc-green-partner-for-domestic-and-worldwide-renewable-energy-initiatives-1695878311906_1.html',
  },
  {
    SrNo: 273,
    DateOfPublishing: '2023-09-29',
    News: "India slams rich nations' climate inaction; calls for focus on pre-2020 gaps, equity in Global Stocktake",
    LinkName: 'The News Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/sep/29/india-slams-rich-nations-climate-inaction-calls-for-focus-on-pre-2020-gaps-equity-in-global-stock-2619320.html',
  },
  {
    SrNo: 274,
    DateOfPublishing: '2023-09-29',
    News: 'IndianOil Introduces Green Hydrogen Bus: Zero Emissions',
    LinkName: 'Construction World',
    URL: 'https://www.constructionworld.in/energy-infrastructure/power-and-renewable-energy/indianoil-introduces-green-hydrogen-bus-zero-emissions/44903',
  },
  {
    SrNo: 275,
    DateOfPublishing: '2023-09-29',
    News: 'Indian Ocean to face disproportionate effects of climate change: NIO director',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/indian-ocean-to-face-disproportionate-effects-of-climate-change-nio-director-101695902736928.html',
  },
  {
    SrNo: 276,
    DateOfPublishing: '3rd October 2023',
    News: 'CBAM: India hopes to establish carbon credit trading system, carbon verifiers by 2026',
    LinkName: 'The Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/economy/cbam-india-hopes-to-establish-carbon-credit-trading-system-carbon-verifiers-by-2026/article67369054.ece',
  },
  {
    SrNo: 277,
    DateOfPublishing: '3rd October 2023',
    News: 'Kolkata Port, NTPC arm to work together to set up green hydrogen, ammonia hub',
    LinkName: 'Economic Times Energy World',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/kolkata-port-ntpc-arm-to-work-together-to-set-up-green-hydrogen-ammonia-hub/104097704',
  },
  {
    SrNo: 278,
    DateOfPublishing: '3rd October 2023',
    News: 'Rich Nations Exploiting Paris Agreement Clause...: India',
    LinkName: 'Republic World',
    URL: 'https://www.republicworld.com/world-news/rest-of-the-world-news/rich-nations-exploiting-paris-agreement-clause-dot-india-articleshow.html',
  },
  {
    SrNo: 279,
    DateOfPublishing: '3rd October 2023',
    News: 'India calls out \u2018double standards\u2019 of rich nations on fossil fuel use ahead of COP28',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india-calls-out-double-standards-of-rich-nations-on-fossil-fuel-use-ahead-of-cop28/articleshow/104112488.cms?from=mdr',
  },
  {
    SrNo: 280,
    DateOfPublishing: '4th October 2023',
    News: 'CBAM: India hopes to establish carbon credit trading system, carbon verifiers by 2026',
    LinkName: 'The Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/economy/cbam-india-hopes-to-establish-carbon-credit-trading-system-carbon-verifiers-by-2026/article67369054.ece',
  },
  {
    SrNo: 281,
    DateOfPublishing: '4th October 2023',
    News: 'Kolkata Port, NTPC arm to work together to set up green hydrogen, ammonia hub',
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/kolkata-port-ntpc-arm-to-work-together-to-set-up-green-hydrogen-ammonia-hub/104097704',
  },
  {
    SrNo: 282,
    DateOfPublishing: '4th October 2023',
    News: "Rapid policy shifts fuel India's 5mmtpa green hydrogen ambition for 2030: Report",
    LinkName: 'The Economic Times Energy',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/rapid-policy-shifts-fuel-indias-5mmtpa-green-hydrogen-ambition-for-2030-report/104147775',
  },
  {
    SrNo: 283,
    DateOfPublishing: '4th October 2023',
    News: 'MPs raised concerns on climate change: Jairam Ramesh after House panel meet',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/mps-raised-concerns-on-climate-change-jairam-ramesh-after-house-panel-meet-123100301451_1.html',
  },
  {
    SrNo: 284,
    DateOfPublishing: '4th October 2023',
    News: 'Tata Steel Collaborates With IOCL To Reduce Carbon Footprint',
    LinkName: 'Republic World',
    URL: 'https://www.republicworld.com/business-news/india-business/tata-steel-collaborates-with-iocl-to-reduce-carbon-footprint-articleshow.html',
  },
  {
    SrNo: 285,
    DateOfPublishing: '4th October 2023',
    News: 'Carbon Swap: From trenchant flaring to tenacious carbon sink',
    LinkName: 'The Economic Times Energy',
    URL: 'https://12ft.io/proxy?q=https%3A%2F%2Fenergy.economictimes.indiatimes.com%2Fnews%2Frenewable%2Fcarbon-swap-from-trenchant-flaring-to-tenacious-carbon-sink%2F104131970',
  },
  {
    SrNo: 286,
    DateOfPublishing: '4th October 2023',
    News: 'How Efficient Buildings Can Help India Meet Its Climate Goals',
    LinkName: 'India Spends',
    URL: 'https://www.indiaspend.com/climate-change/how-efficient-buildings-can-help-india-meet-its-climate-goals-877297',
  },
  {
    SrNo: 287,
    DateOfPublishing: '4th October 2023',
    News: 'Lessons of the Green Revolution could aid our climate transition',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/lessons-of-the-green-revolution-could-aid-our-climate-transition-11696325907849.html',
  },
  {
    SrNo: 288,
    DateOfPublishing: '4th October 2023',
    News: 'State-run agency suggests mandatory green hydrogen norms for oil refineries',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/bureau-of-energy-efficiency-suggests-green-hydrogen-norms-for-refineries-123100300784_1.html',
  },
  {
    SrNo: 289,
    DateOfPublishing: '4th October 2023',
    News: 'India calls out \u2018double standards\u2019 of rich nations on fossil fuel use ahead of COP28',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india-calls-out-double-standards-of-rich-nations-on-fossil-fuel-use-ahead-of-cop28/articleshow/104112488.cms?from=mdr',
  },
  {
    SrNo: 290,
    DateOfPublishing: '5th October 2023',
    News: "Renewable energy's growth faces power stability challenges: Report",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renewable-energys-growth-faces-power-stability-challenges-report/104154494',
  },
  {
    SrNo: 291,
    DateOfPublishing: '5th October 2023',
    News: 'Credit Fair announces collaboration with Amplus Solar to offer financial solutions to solar rooftop customers',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-credit-fair-announces-collaboration-with-amplus-solar-to-offer-financial-solutions-to-solar-rooftop-customers-3263381/',
  },
  {
    SrNo: 292,
    DateOfPublishing: '5th October 2023',
    News: 'Tripura CM calls for investment in renewable energy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/tripura-cm-calls-for-investment-in-renewable-energy/104160688',
  },
  {
    SrNo: 293,
    DateOfPublishing: '5th October 2023',
    News: "Climate change damage to infrastructure poses 'huge' financial risks",
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/science-environment/2618037-climate-change-damage-to-infrastructure-poses-huge-financial-risks',
  },
  {
    SrNo: 294,
    DateOfPublishing: '5th October 2023',
    News: 'NIIF launches $600 mn India-Japan Fund to fight climate change',
    LinkName: 'Statesman',
    URL: 'https://www.thestatesman.com/business/niif-launches-600-mn-india-japan-fund-to-fight-climate-change-1503228105.html',
  },
  {
    SrNo: 295,
    DateOfPublishing: '5th October 2023',
    News: 'IIT Madras Hosts Water Security And Climate Adaptation Conference',
    LinkName: 'Education Diary',
    URL: 'https://indiaeducationdiary.in/iit-madras-hosts-water-security-and-climate-adaptation-conference/',
  },
  {
    SrNo: 296,
    DateOfPublishing: '5th October 2023',
    News: 'Kolkata Port and NTPC Green Energy to Establish Green Hydrogen Hub',
    LinkName: 'Construction World',
    URL: 'https://www.constructionworld.in/transport-infrastructure/ports-and-shipping/kolkata-port-and-ntpc-green-energy-to-establish-green-hydrogen-hub/45189',
  },
  {
    SrNo: 297,
    DateOfPublishing: '5th October 2023',
    News: 'Singareni to use green hydrogen to run STPP',
    LinkName: 'Telangana Today',
    URL: 'https://telanganatoday.com/singareni-to-use-green-hydrogen-to-run-stpp',
  },
  {
    SrNo: 298,
    DateOfPublishing: '5th October 2023',
    News: 'Tamil Nadu govt to explore large scale green energy storage options',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/tamil-nadu-govt-to-explore-large-scale-green-energy-storage-options-123100400940_1.html',
  },
  {
    SrNo: 299,
    DateOfPublishing: '5th October 2023',
    News: "Gujarat's new policy to generate 50% power via renewable sources by 2030",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/gujarat-s-new-policy-to-generate-50-power-via-renewable-sources-by-2030-123100401505_1.html',
  },
  {
    SrNo: 300,
    DateOfPublishing: '6th October 2023',
    News: 'Due credit: The Indian voluntary carbon market is growing exponentially',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/due-credit-the-indian-voluntary-carbon-market-is-growing-exponentially-92091',
  },
  {
    SrNo: 301,
    DateOfPublishing: '6th October 2023',
    News: 'Gujarat Renewable Energy Policy Aims to Harness 36 GW of Solar Potential',
    LinkName: 'Mercom India',
    URL: 'https://www.mercomindia.com/gujarat-renewable-energy-policy',
  },
  {
    SrNo: 302,
    DateOfPublishing: '6th October 2023',
    News: 'UN Green Climate Fund pledges reach $9.3 billion',
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/science-environment/2619109-un-green-climate-fund-pledges-reach-93-billion',
  },
  {
    SrNo: 303,
    DateOfPublishing: '6th October 2023',
    News: 'India, Japan Fund to Finance Clean Energy Firms',
    LinkName: 'Deccan Chronicle',
    URL: 'https://www.deccanchronicle.com/world/neighbours/051023/india-japan-fund-to-finance-clean-energy-firms.html',
  },
  {
    SrNo: 304,
    DateOfPublishing: '6th October 2023',
    News: 'Climate change is causing more frequent, intense cyclones on India\u2019s western coast',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/technology/science/climate-change-frequent-intense-cyclones-india-west-coast-8969486/',
  },
  {
    SrNo: 305,
    DateOfPublishing: '6th October 2023',
    News: 'Climate change endangering amphibian species around the world, 136 of 426 in India threatened: Study',
    LinkName: 'Press Trust of India',
    URL: 'https://www.ptinews.com/news/health-and-lifestyle/climate-change-endangering-amphibian-species-around-the-world-136-of-426-in-india-threatened-study/15/664411.html',
  },
  {
    SrNo: 306,
    DateOfPublishing: '6th October 2023',
    News: 'Green hydrogen, energy storage to give fillip to India\u2019s renewable energy push',
    LinkName: 'Autocar Professional',
    URL: 'https://www.autocarpro.in/news/green-hydrogen-energy-storage-to-give-fillip-to-indias-renewable-energy-push-117130',
  },
  {
    SrNo: 307,
    DateOfPublishing: '6th October 2023',
    News: 'Hydrogen buses can pave the way for energy security in India\u2019s heavy-duty mobility sector',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/hydrogen-buses-can-pave-the-way-for-energy-security-in-indias-heavy-duty-mobility-sector/104179310',
  },
  {
    SrNo: 308,
    DateOfPublishing: '6th October 2023',
    News: 'India must Adopt Zero-emissions Technology to Mitigate Methane Emanating from Transport Sector',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/india-must-adopt-zero-emissions-technology-to-mitigate-methane-emanating-from-transport-sector/104178460',
  },
  {
    SrNo: 309,
    DateOfPublishing: '9th October 2023',
    News: 'India may lose 35% of GDP to climate change by 2100, warns Unescap report',
    LinkName: 'The Economic Times ',
    URL: 'https://economictimes.indiatimes.com/news/economy/indicators/india-may-lose-35-of-gdp-to-climate-change-by-2100-warns-unescap-report/articleshow/104222968.cms?from=mdr',
  },
  {
    SrNo: 310,
    DateOfPublishing: '9th October 2023',
    News: 'UN launches 10 principles to close Asia-Pacific sustainable finance gap',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/un-launches-10-principles-to-close-asia-pacific-sustainable-finance-gap-92164',
  },
  {
    SrNo: 311,
    DateOfPublishing: '9th October 2023',
    News: 'India, Saudi Arabia tie up for electrical interconnections, green hydrogen',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-saudi-arabia-tie-up-for-electrical-interconnections-green-hydrogen/104270599',
  },
  {
    SrNo: 312,
    DateOfPublishing: '9th October 2023',
    News: 'Back India, Saudi Arabia sign MoU for green hydrogen supply chain, power grid interconnection',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-and-saudi-arabia-sign-mou-for-green-hydrogen-supply-chain-and-power-grid-interconnection-11696773021692.html',
  },
  {
    SrNo: 313,
    DateOfPublishing: '9th October 2023',
    News: 'Shift to green hydrogen from gray to cost India\u2019s refining sector $3/bbl on gross margins',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/shift-to-green-hydrogen-from-gray-to-cost-indias-refining-sector-3/bbl-on-gross-margins/104269847',
  },
  {
    SrNo: 314,
    DateOfPublishing: '9th October 2023',
    News: "Boost for green energy: India's Rs 400 crore plan for green hydrogen unveiled",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/boost-for-green-energy-indias-rs-400-crore-plan-for-green-hydrogen-unveiled/104271463',
  },
  {
    SrNo: 315,
    DateOfPublishing: '9th October 2023',
    News: "Gujarat's green push to help India achieve net-zero emissions target, says CM",
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/gujarats-green-push-to-help-india-achieve-net-zero-emissions-target-says-cm-11696596098489.html',
  },
  {
    SrNo: 316,
    DateOfPublishing: '9th October 2023',
    News: 'ENGIE India advocates for Clean Energy Future REI Expo 2023',
    LinkName: 'Press Trust of India',
    URL: 'https://www.ptinews.com/pti/engie-india-advocates-for-clean-energy-future-rei-expo-2023/65486.html',
  },
  {
    SrNo: 317,
    DateOfPublishing: '10th October 2023',
    News: 'How climate change poses a big risk to global GDP',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/india-today-insight/story/how-climate-change-poses-a-big-risk-to-global-gdp-2446599-2023-10-09',
  },
  {
    SrNo: 318,
    DateOfPublishing: '10th October 2023',
    News: 'Climate change to trigger mortality risks from air pollution, flooding: Researchers warn ahead of COP28',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/world/climate-change-to-trigger-mortality-risks-from-air-pollution-flooding-researchers-warn-ahead-of-cop28-92204',
  },
  {
    SrNo: 319,
    DateOfPublishing: '10th October 2023',
    News: 'India\u2019s approach to COP28: Equal emphasis on adaptation along with mitigation in climate action',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/express-mobility-indias-approach-to-cop28-equal-emphasis-on-adaptation-along-with-mitigation-in-climate-action-3267691/',
  },
  {
    SrNo: 320,
    DateOfPublishing: '10th October 2023',
    News: 'Why India must ramp up wind energy generation for its energy transition',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/why-india-must-ramp-up-wind-energy-generation-for-its-energy-transition/104298221',
  },
  {
    SrNo: 321,
    DateOfPublishing: '10th October 2023',
    News: 'India Launches National Green Hydrogen Mission R&D Roadmap on World Hydrogen and Fuel Cell Day',
    LinkName: 'egov',
    URL: 'https://egov.eletsonline.com/2023/10/india-launches-national-green-hydrogen-mission-rd-roadmap-on-world-hydrogen-and-fuel-cell-day/',
  },
  {
    SrNo: 322,
    DateOfPublishing: '10th October 2023',
    News: 'India, Saudi Arabia sign MoU on electrical interconnections and green hydrogen',
    LinkName: 'DD News',
    URL: 'https://ddnews.gov.in/international/india-saudi-arabia-sign-mou-electrical-interconnections-and-green-hydrogen',
  },
  {
    SrNo: 323,
    DateOfPublishing: '10th October 2023',
    News: 'SBI borrowers to get special credits for green initiatives: Chairman',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/banking-finance-sbi-borrowers-to-get-special-credits-for-green-initiatives-chairman-3267864/',
  },
  {
    SrNo: 324,
    DateOfPublishing: '10th October 2023',
    News: "India\u2019s New Market for 'Green Credits': A Promising Initiative for Environmental Sustainability Insight by LIBF India",
    LinkName: 'Press Trust of India',
    URL: 'https://www.ptinews.com/pti/india-s-new-market-for-green-credits--a-promising-initiative-for-environmental-sustainability-insight-by-libf-india/65518.html',
  },
  {
    SrNo: 325,
    DateOfPublishing: '11th October 2023',
    News: 'Govt may soon announce green hydrogen consumption obligations for various sectors',
    LinkName: 'The Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/economy/govt-may-soon-announce-green-hydrogen-consumption-obligations-for-various-sectors/article67403950.ece',
  },
  {
    SrNo: 326,
    DateOfPublishing: '11th October 2023',
    News: 'We are in a period of climate stuckness',
    LinkName: 'Financial Times',
    URL: 'https://www.ft.com/content/880b76c0-1b6a-4914-8aa7-77eb1212314e',
  },
  {
    SrNo: 327,
    DateOfPublishing: '11th October 2023',
    News: 'Green hydrogen costs to decline sharply, but adoption will need govt\u2019s push: Kotak Institutional Equities',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/markets/green-hydrogen-costs-to-decline-sharply-but-adoption-will-need-govts-push-kotak-institutional-equities-11510271.html',
  },
  {
    SrNo: 328,
    DateOfPublishing: '11th October 2023',
    News: 'Climate change may make India and the Indus Valley too hot for up to 2.2 billion: Study',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/climate-change-india-indus-valley-hot-study-8976019/',
  },
  {
    SrNo: 329,
    DateOfPublishing: '11th October 2023',
    News: 'What the hottest September ever reveals about climate change',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/explained/explained-climate/what-the-hottest-september-ever-reveals-about-climate-change-8977093/',
  },
  {
    SrNo: 330,
    DateOfPublishing: '11th October 2023',
    News: 'Tamil Nadu MLAs discuss ways to combat climate change',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/oct/11/tamil-nadu-mlas-discuss-ways-to-combat-climate-change-2622800.html',
  },
  {
    SrNo: 331,
    DateOfPublishing: '11th October 2023',
    News: 'Grave environmental concerns: India\u2019s carbon emissions from surface passenger transportation to touch around 147 MT in 2023',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/business/india-business/grave-environmental-concerns-indias-carbon-emissions-from-surface-passenger-transportation-to-touch-around-147-mt-in-2023/articleshow/104310697.cms?from=mdr',
  },
  {
    SrNo: 332,
    DateOfPublishing: '12th October 2023',
    News: 'Even temporary global warming above 2\u00b0C will affect life in the oceans for centuries',
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 333,
    DateOfPublishing: '12th October 2023',
    News: 'Quantum technology promises green hydrogen revolution',
    LinkName: 'DD News',
    URL: 'https://ddnews.gov.in/sci-tech/quantum-technology-promises-green-hydrogen-revolution',
  },
  {
    SrNo: 334,
    DateOfPublishing: '12th October 2023',
    News: 'ReNew plans to enter green hydrogen in a big way, says Sumant Sinha',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renew-plans-to-enter-green-hydrogen-in-a-big-way-says-sumant-sinha/104349989',
  },
  {
    SrNo: 335,
    DateOfPublishing: '12th October 2023',
    News: 'Experts discuss green H2 fuels production, utilisation',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/varanasi/experts-discuss-green-h2-fuels-production-utilisation/articleshow/104358063.cms',
  },
  {
    SrNo: 336,
    DateOfPublishing: '12th October 2023',
    News: 'India encourages Saudi investment in renewable energy',
    LinkName: 'Manufacturing Today',
    URL: 'https://www.manufacturingtodayindia.com/sectors/india-encourages-saudi-investment-in-renewable-energy',
  },
  {
    SrNo: 337,
    DateOfPublishing: '12th October 2023',
    News: 'KPI Green Energy down on board nod to acquiring power company',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/stocks/kpi-green-energy-down-on-board-nod-to-acquiring-power-company-11518571.html',
  },
  {
    SrNo: 338,
    DateOfPublishing: '12th October 2023',
    News: 'India well placed to contribute towards strengthening and diversifying supply chains: FM',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-well-placed-to-contribute-towards-strengthening-and-diversifying-supply-chains-fm/104357114',
  },
  {
    SrNo: 339,
    DateOfPublishing: '12th October 2023',
    News: 'Putting CSOs at the frontline of climate action',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/putting-csos-at-the-frontline-of-climate-action-101697087636834.html',
  },
  {
    SrNo: 340,
    DateOfPublishing: '12th October 2023',
    News: 'How India can utilize its coal and lignite mines for Green Hydrogen production',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/how-india-can-utilize-its-coal-and-lignite-mines-for-green-hydrogen-production/104359727',
  },
  {
    SrNo: 341,
    DateOfPublishing: '12th October 2023',
    News: "Ingar Electronics And India's EV 2030 Mission",
    LinkName: 'The Quint',
    URL: 'https://www.thequint.com/brandstudio/partner-ingar-electronics-and-indias-ev-2030-mission',
  },
  {
    SrNo: 342,
    DateOfPublishing: '13th October 2023',
    News: 'Need of an individual \u2018Climate-Identity\u2019 to effectively tackle climate disruptions',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/blogs/voices/need-of-an-individual-climate-identity-to-effectively-tackle-climate-disruptions/',
  },
  {
    SrNo: 343,
    DateOfPublishing: '13th October 2023',
    News: 'As Maharashtra readies district-level climate action plan, citizens stress on solar energy and subsidy from Govt',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/mumbai/maharashtra-district-level-climate-action-plan-citizens-stress-solar-energy-subsidy-govt-8979724/',
  },
  {
    SrNo: 344,
    DateOfPublishing: '13th October 2023',
    News: 'How oil companies put the responsibility for climate change on consumers',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/how-oil-companies-put-the-responsibility-for-climate-change-on-consumers/104368169',
  },
  {
    SrNo: 345,
    DateOfPublishing: '13th October 2023',
    News: 'Putting CSOs at the frontline of climate action',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/putting-csos-at-the-frontline-of-climate-action-101697087636834.html',
  },
  {
    SrNo: 346,
    DateOfPublishing: '13th October 2023',
    News: 'Philanthropy in India shifts focus towards climate action',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india/philanthropy-in-india-shifts-focus-towards-climate-action/articleshow/104379841.cms?from=mdr',
  },
  {
    SrNo: 347,
    DateOfPublishing: '13th October 2023',
    News: 'Mundra to be largest fully integrated renewable energy manufacturing hub: Adani Group',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/companies/exclusive-mundra-to-be-largest-fully-integrated-renewable-energy-manufacturing-hub-adani-group-11523161.html',
  },
  {
    SrNo: 348,
    DateOfPublishing: '13th October 2023',
    News: 'Envision receives 653.4 MW wind energy order from JSW Energy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/envision-receives-653-4-mw-wind-energy-order-from-jsw-energy/104365117',
  },
  {
    SrNo: 349,
    DateOfPublishing: '13th October 2023',
    News: 'STL inks deal with Hygenco to source green hydrogen for manufacturing plants',
    LinkName: 'ET Telecom',
    URL: 'https://telecom.economictimes.indiatimes.com/news/telecom-equipment/stl-collaborates-with-hygenco-to-source-green-hydrogen-for-manufacturing-plants/104374805',
  },
  {
    SrNo: 350,
    DateOfPublishing: '13th October 2023',
    News: 'How voluntary carbon credit derivatives can drive sustainable investment in India',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/how-voluntary-carbon-credit-derivatives-can-drive-sustainable-investment-in-india/104360768',
  },
  {
    SrNo: 351,
    DateOfPublishing: '13th October 2023',
    News: 'UPNEDA signs MoU with TNC India to accelerate utility-scale solar projects in Uttar Pradesh',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/upneda-signs-mou-with-tnc-india-to-accelerate-utility-scale-solar-projects-in-uttar-pradesh/104374674',
  },
  {
    SrNo: 352,
    DateOfPublishing: '16th October 2023',
    News: 'A Forced Shift | Climate change, Govt apathy de-motivate farmers from growing saffron',
    LinkName: 'Great Kashmir',
    URL: 'https://www.greaterkashmir.com/todays-paper/front-page/a-forced-shift-climate-change-govt-apathy-de-motivate-farmers-from-growing-saffron',
  },
  {
    SrNo: 353,
    DateOfPublishing: '16th October 2023',
    News: 'Tropical crops at risk from pollinator loss due to climate change, shift in land use, finds study',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/world/tropical-crops-at-risk-from-pollinator-loss-due-to-climate-change-shift-in-land-use-finds-study-92306',
  },
  {
    SrNo: 354,
    DateOfPublishing: '16th October 2023',
    News: 'Centre notifies Green Credit Programme, revises ecomark scheme',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india/centre-notifies-green-credit-programme-revises-ecomark-scheme/articleshow/104438291.cms?from=mdr',
  },
  {
    SrNo: 355,
    DateOfPublishing: '16th October 2023',
    News: 'Ashok Gulati writes: How India can minimise climate risk in agriculture',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/columns/ashok-gulati-writes-how-india-can-minimise-climate-risk-in-agriculture-8984596/',
  },
  {
    SrNo: 356,
    DateOfPublishing: '16th October 2023',
    News: 'India among 4 big economies set to meet Paris climate goals',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india/india-among-4-big-economies-set-to-meet-paris-climate-goals/articleshow/104449329.cms?from=mdr',
  },
  {
    SrNo: 357,
    DateOfPublishing: '16th October 2023',
    News: 'REC signs MoUs with 3 firms to build green power projects in Odisha',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/power/rec-signs-mous-with-3-firms-to-build-green-power-projects-in-odisha-11538521.html',
  },
  {
    SrNo: 358,
    DateOfPublishing: '16th October 2023',
    News: "For EVs to contribute to India's net zero goals, integrated action called for",
    LinkName: 'ETAuto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/for-evs-to-contribute-to-indias-net-zero-goals-integrated-action-called-for/104453033',
  },
  {
    SrNo: 359,
    DateOfPublishing: '16th October 2023',
    News: '\u2018Increase investments to address infra deficit, achieve sustainable development\u2019',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/delhi-news/increase-investments-to-address-infra-deficit-achieve-sustainable-development-101697372324257.html',
  },
  {
    SrNo: 360,
    DateOfPublishing: '16th October 2023',
    News: 'Sitharaman calls for private sector capital funding for SDGs',
    LinkName: 'The Statesman',
    URL: 'https://www.thestatesman.com/business/sitharaman-calls-for-private-sector-capital-funding-for-sdgs-1503231543.html',
  },
  {
    SrNo: 361,
    DateOfPublishing: '17th October 2023',
    News: 'Green hydrogen framework in India: Learning from global initiatives',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/sustainability-green-hydrogen-framework-in-india-learning-from-global-initiatives-3274301/',
  },
  {
    SrNo: 362,
    DateOfPublishing: '17th October 2023',
    News: "For EVs to contribute to India's net zero goals",
    LinkName: 'ET Infra',
    URL: 'https://infra.economictimes.indiatimes.com/news/urban-transportation/for-evs-to-contribute-to-indias-net-zero-goals/104463623',
  },
  {
    SrNo: 363,
    DateOfPublishing: '17th October 2023',
    News: 'NLCIL incorporates arm for commissioning green projects',
    LinkName: 'ET Energyworld',
    URL: 'https://www.financialexpress.com/business/sustainability-green-supply-chains-enhancing-efficiency-and-sustainability-for-the-festive-season-3273977/',
  },
  {
    SrNo: 364,
    DateOfPublishing: '17th October 2023',
    News: 'Green supply chains: Enhancing efficiency and sustainability for the festive season',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/sustainability-green-supply-chains-enhancing-efficiency-and-sustainability-for-the-festive-season-3273977/',
  },
  {
    SrNo: 365,
    DateOfPublishing: '17th October 2023',
    News: 'EU wrangles over negotiating stance for COP28 climate summit',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/eu-wrangles-over-negotiating-stance-for-cop28-climate-summit/104457657',
  },
  {
    SrNo: 366,
    DateOfPublishing: '17th October 2023',
    News: 'The materiality and morality of Net Zero should go hand in hand',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/the-materiality-and-morality-of-net-zero-11697457015690.html',
  },
  {
    SrNo: 367,
    DateOfPublishing: '17th October 2023',
    News: 'Climate change & wildlife: How focused monitoring of distribution, population trends can help conservation efforts',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/blog/wildlife-biodiversity/climate-change-wildlife-how-focused-monitoring-of-distribution-population-trends-can-help-conservation-efforts-92324',
  },
  {
    SrNo: 368,
    DateOfPublishing: '17th October 2023',
    News: 'Tata Power Renewable Energy to set up 12.5 MW captive solar plant in Maharashtra',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/tata-power-renewable-energy-to-set-up-12-5-mw-captive-solar-plant-in-maharashtra/104483099',
  },
  {
    SrNo: 369,
    DateOfPublishing: '17th October 2023',
    News: 'Agroecology can fix food crisis in Andhra Pradesh, says study',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/andhra-pradesh/2023/oct/17/agroecology-can-fix-food-crisis-in-andhra-pradesh-says-study-2624656.html',
  },
  {
    SrNo: 370,
    DateOfPublishing: '17th October 2023',
    News: "Revitalizing India's Agriculture: IDH and Better Cotton Promote Regenerative Farming for a Sustainable Futurez",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/content/press-releases-ani/revitalizing-india-s-agriculture-idh-and-better-cotton-promote-regenerative-farming-for-a-sustainable-future-123101600767_1.html',
  },
  {
    SrNo: 371,
    DateOfPublishing: '18th October 2023',
    News: 'Odisha to follow new strategy to reduce carbon footprint',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/odisha/2023/oct/18/odisha-to-follow-new-strategy-to-reduce-carbon-footprint-2625024.html',
  },
  {
    SrNo: 372,
    DateOfPublishing: '18th October 2023',
    News: 'National carbon trading system to cover 15% of emissions by 2030: BEE',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/national-carbon-trading-system-to-cover-15-of-emissions-by-2030-bee-123101701390_1.html',
  },
  {
    SrNo: 373,
    DateOfPublishing: '18th October 2023',
    News: 'India\u2019s carbon market seen covering 15% of emissions by 2030',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/indias-carbon-market-seen-covering-15-of-emissions-by-2030-11549371.html',
  },
  {
    SrNo: 374,
    DateOfPublishing: '18th October 2023',
    News: 'India sets up inter-ministerial group for climate negotiations strategy',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/india-sets-up-inter-ministerial-group-for-climate-negotiations-strategy-123101700689_1.html',
  },
  {
    SrNo: 375,
    DateOfPublishing: '18th October 2023',
    News: 'Decoding how a climate career in India looks like',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/trends/weather/decoding-how-a-climate-career-in-india-looks-like-11548921.html',
  },
  {
    SrNo: 376,
    DateOfPublishing: '18th October 2023',
    News: "India's interests at stake as world trade talks take a green turn",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/analysis/india-s-interests-at-stake-as-world-trade-talks-take-a-green-turn-123101700494_1.html',
  },
  {
    SrNo: 377,
    DateOfPublishing: '18th October 2023',
    News: 'Air pollution missing from two-thirds of national climate plans',
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/science-environment/2653715-air-pollution-missing-from-two-thirds-of-national-climate-plans',
  },
  {
    SrNo: 378,
    DateOfPublishing: '19th October 2023',
    News: 'JCB: Hydrogen an alternative for fuel in India',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/pune/jcb-hydrogen-an-alternative-for-fuel-in-india/articleshow/104541787.cms',
  },
  {
    SrNo: 379,
    DateOfPublishing: '19th October 2023',
    News: 'COP28 Prez seeks plans to cut 22 gigatons of greenhouse emissions in 7 years',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/environment/cop28-prez-seeks-plans-to-cut-22-gigatons-of-greenhouse-emissions-in-7-years-101697689638053.html',
  },
  {
    SrNo: 380,
    DateOfPublishing: '19th October 2023',
    News: 'Public-private partnership crucial to achieve climate finance objectives',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/opinion/public-private-partnership-crucial-to-achieve-climate-finance-objectives-11551811.html',
  },
  {
    SrNo: 381,
    DateOfPublishing: '19th October 2023',
    News: 'REC, BOI signs MoU to cofinance projects worth Rs 30,000 cr',
    LinkName: 'Projects Today',
    URL: 'https://www.projectstoday.com/News/REC-BOI-signs-MoU-to-cofinance-projects-worth-Rs-30000-cr',
  },
  {
    SrNo: 382,
    DateOfPublishing: '19th October 2023',
    News: 'Cabinet approves 13 GW green energy corridor in Ladakh',
    LinkName: 'Fortune India',
    URL: 'https://www.fortuneindia.com/macro/cabinet-approves-13-gw-green-energy-corridor-in-ladakh/114507',
  },
  {
    SrNo: 383,
    DateOfPublishing: '19th October 2023',
    News: 'Regulatory structures needed for solar energy push: Power Minister RK Singh',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-regulatory-structures-needed-for-solar-energy-push-power-minister-rk-singh-3276920/',
  },
  {
    SrNo: 384,
    DateOfPublishing: '19th October 2023',
    News: 'GMIS 2023 Day 2: Rs 2.37 lakh crore boost in maritime; 70 MoUs spanning ports, green hydrogen',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/gmis-2023-day-2-rs-2-37-lakh-crore-boost-in-maritime-70-mous-spanning-ports-green-hydrogen/104540527',
  },
  {
    SrNo: 385,
    DateOfPublishing: '19th October 2023',
    News: 'OSRTC adopts cleaner fuel technologies to reduce carbon footprint',
    LinkName: 'The Economic Times',
    URL: 'https://auto.economictimes.indiatimes.com/news/commercial-vehicle/mhcv/osrtc-adopts-cleaner-fuel-technologies-to-reduce-carbon-footprint/104527478',
  },
  {
    SrNo: 386,
    DateOfPublishing: '20th October 2023',
    News: 'Fault lines over Loss and Damage fund deepen at key climate meet',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/fault-lines-over-loss-and-damage-fund-deepen-at-key-climate-meet-101697741989655.html',
  },
  {
    SrNo: 387,
    DateOfPublishing: '20th October 2023',
    News: 'Air pollution missing from India\u2019s climate plans: Study',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/nagpur/air-pollution-missing-from-indias-climate-plans-study/articleshow/104562626.cms',
  },
  {
    SrNo: 388,
    DateOfPublishing: '20th October 2023',
    News: 'In a first, Karnataka to explore \u2018blue energy\u2019',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/karnataka/2023/oct/20/in-a-first-karnataka-to-explore-blue-energy-2625616.html',
  },
  {
    SrNo: 389,
    DateOfPublishing: '20th October 2023',
    News: "Rajasthan, J&K to buy power from SJVN's solar power facility",
    LinkName: 'ET Energyworld',
    URL: 'http://rajasthan/',
  },
  {
    SrNo: 390,
    DateOfPublishing: '20th October 2023',
    News: 'Green fees overtake fossil fuels for second straight year',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/green-fees-overtake-fossil-fuels-for-second-straight-year/104547989',
  },
  {
    SrNo: 391,
    DateOfPublishing: '20th October 2023',
    News: 'Dark underbelly of carbon trade',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/blog/climate-change/dark-underbelly-of-carbon-trade-92321',
  },
  {
    SrNo: 392,
    DateOfPublishing: '20th October 2023',
    News: 'Himachal Pradesh Government to Set Up 11 Ecotourism Sites',
    LinkName: 'Outlook Traveller',
    URL: 'https://www.outlooktraveller.com/whats-new/himachal-pradesh-government-to-set-up-11-ecotourism-sites',
  },
  {
    SrNo: 393,
    DateOfPublishing: '20th October 2023',
    News: 'Climate change taking a toll on marine life: Bay of Bengal Programme',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/cities/chennai/2023/oct/20/climate-change-takinga-toll-on-marine-lifebay-of-bengal-programme-2625557.html',
  },
  {
    SrNo: 394,
    DateOfPublishing: '23rd October 2023',
    News: 'NLC India launches NIGEL, aims for 6 GW renewable energy capacity by 2030',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/nlc-india-launches-nigel-aims-for-6-gw-renewable-energy-capacity-by-2030/104637799',
  },
  {
    SrNo: 395,
    DateOfPublishing: '23rd October 2023',
    News: 'IIT Indore Signs Pact with L&T for Renewable Energy Management Research',
    LinkName: 'Business Outreach',
    URL: 'https://www.businessoutreach.in/iit-indore-signs-pact/',
  },
  {
    SrNo: 396,
    DateOfPublishing: '23rd October 2023',
    News: 'Guv seeks KGMU\u2019s response on \u2018breach\u2019',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/lucknow/guv-seeks-kgmus-response-on-breach/articleshow/104638751.cms',
  },
  {
    SrNo: 397,
    DateOfPublishing: '23rd October 2023',
    News: '9 vision groups established for industrial growth',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/bengaluru/9-vision-groups-established-for-industrial-growth/articleshow/104596918.cms',
  },
  {
    SrNo: 398,
    DateOfPublishing: '23rd October 2023',
    News: 'Tata Power Renewable Energy signs power delivery pact with Supreme Petrochem',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/tata-power-renewable-energy-signs-power-delivery-pact-with-supreme-petrochem/104587523',
  },
  {
    SrNo: 399,
    DateOfPublishing: '23rd October 2023',
    News: 'KAU develops app to measure carbon footprint',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/kochi/kau-develops-app-to-measure-carbon-footprint/articleshow/104618987.cms',
  },
  {
    SrNo: 400,
    DateOfPublishing: '23rd October 2023',
    News: 'How companies play the Carbon Credit games through investing in green energy',
    LinkName: 'Trade Brains',
    URL: 'https://tradebrains.in/features/how-companies-play-the-carbon-credit-games-through-investing-in-green-energy/',
  },
  {
    SrNo: 401,
    DateOfPublishing: '23rd October 2023',
    News: 'The green power grid isn\u2019t coming',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/the-green-power-grid-isn-t-coming-11697784508052.html',
  },
  {
    SrNo: 402,
    DateOfPublishing: '23rd October 2023',
    News: '\u2018India, UAE can broaden economic ties\u2019',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-and-uae-aim-to-diversify-trade-and-boost-local-currency-transactions-in-new-era-of-economic-cooperation-11697985622264.html',
  },
  {
    SrNo: 403,
    DateOfPublishing: '25th October 2023',
    News: 'Punjab Inc: Meet the man who transforms crop residue into green energy',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/chandigarh/punjab-inc-meet-man-transforms-crop-residue-green-energy-8998273/',
  },
  {
    SrNo: 404,
    DateOfPublishing: '25th October 2023',
    News: 'Multiple green technologies in cars to help meet carbon neutrality targets in India: Toyota',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/multiple-green-technologies-in-cars-to-help-meet-carbon-neutrality-targets-in-india-toyota/104686133',
  },
  {
    SrNo: 405,
    DateOfPublishing: '25th October 2023',
    News: 'Nestle, Volvo among 130 companies urging COP28 agreement to ditch fossil fuels',
    LinkName: 'ET Auto',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/food-security-effects-of-the-climate-crisis-on-agriculture-101698045527529.html',
  },
  {
    SrNo: 406,
    DateOfPublishing: '25th October 2023',
    News: 'Food security: Effects of the climate crisis on agriculture',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/food-security-effects-of-the-climate-crisis-on-agriculture-101698045527529.html',
  },
  {
    SrNo: 407,
    DateOfPublishing: '25th October 2023',
    News: 'Global report shows climate change \u2018imperilled life on Earth\u2019',
    LinkName: 'Great Kashmir',
    URL: 'https://www.greaterkashmir.com/todays-paper/world-todays-paper/global-report-shows-climate-change-imperilled-life-on-earth',
  },
  {
    SrNo: 408,
    DateOfPublishing: '25th October 2023',
    News: 'Explained: What is the Paris Agreement to combat climate change?',
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/education/learning-with-toi/explained-what-is-the-paris-agreement-to-combat-climate-change/articleshow/103932188.cms',
  },
  {
    SrNo: 409,
    DateOfPublishing: '25th October 2023',
    News: 'Record-breaking weather in 2023 shows impact of climate change',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/world/record-breaking-weather-in-2023-shows-impact-of-climate-change-11592681.html',
  },
  {
    SrNo: 410,
    DateOfPublishing: '25th October 2023',
    News: 'Climate change has made it harder to breathe in Mumbai',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/columns/breathless-in-mumbai-8998013/',
  },
  {
    SrNo: 411,
    DateOfPublishing: '25th October 2023',
    News: 'Rs 550 crore biogas, biodiesel projects get Uttar Pradesh govt nod',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/rs-550-crore-biogas-biodiesel-projects-get-uttar-pradesh-govt-nod-123102301087_1.html',
  },
  {
    SrNo: 412,
    DateOfPublishing: '25th October 2023',
    News: 'Fossil-Fuel Demand Likely to Peak Before 2030 as Renewable Uptake Rises, IEA Says',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/fossilfuel-demand-likely-to-peak-before-2030-as-renewable-uptake-rises-iea-says-11698124799641.html',
  },
  {
    SrNo: 413,
    DateOfPublishing: '27th October 2023',
    News: 'Life on Earth \u2018under siege\u2019 as climate change pushes planet into \u2018uncharted territory\u2019',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/agriculture/sustainable-agri-boost-vital-amid-climate-food-price-concerns-niti-aayogs-ramesh-chand-11698323569647.html',
  },
  {
    SrNo: 414,
    DateOfPublishing: '27th October 2023',
    News: 'With over 800 climate-resilient varieties available, can agricultural sector evade climate change bouncer?',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/carbon-credit-and-trading-scheme-for-indian-carbon-market-to-be-amended-soon-92492',
  },
  {
    SrNo: 415,
    DateOfPublishing: '27th October 2023',
    News: "Sustainable agri-boost vital amid climate, food price concerns: Niti Aayog's Ramesh Chand",
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/agriculture/sustainable-agri-boost-vital-amid-climate-food-price-concerns-niti-aayogs-ramesh-chand-11698323569647.html',
  },
  {
    SrNo: 416,
    DateOfPublishing: '27th October 2023',
    News: 'Carbon Credit and Trading Scheme for Indian Carbon Market to be amended soon',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/carbon-credit-and-trading-scheme-for-indian-carbon-market-to-be-amended-soon-92492',
  },
  {
    SrNo: 417,
    DateOfPublishing: '27th October 2023',
    News: "Without rigorous rules, India's green hydrogen push could spike emissions: Research",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/without-rigorous-rules-indias-green-hydrogen-push-could-spike-emissions-research/104721382',
  },
  {
    SrNo: 418,
    DateOfPublishing: '27th October 2023',
    News: 'A green transition, but not without the coal-rich states',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/columns/a-green-transition-but-not-without-the-coal-rich-states-8999599/',
  },
  {
    SrNo: 419,
    DateOfPublishing: '27th October 2023',
    News: "Global study shows over half of Earth's vital signs at record extremes",
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/oct/26/global-study-shows-over-half-of-earths-vital-signs-at-record-extremes-2627187.html',
  },
  {
    SrNo: 420,
    DateOfPublishing: '27th October 2023',
    News: 'India\u2019s leadership in climate resilience is rooted in its agrarian landscapes',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/blog/agriculture/india-s-leadership-in-climate-resilience-is-rooted-in-its-agrarian-landscapes-92484',
  },
  {
    SrNo: 421,
    DateOfPublishing: '30th October 2023',
    News: 'Towards a waste-free world; startups develop solutions to achieve carbon neutrality',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry/towards-a-waste-free-world-startups-develop-solutions-to-achieve-carbon-neutrality/3290586/',
  },
  {
    SrNo: 422,
    DateOfPublishing: '30th October 2023',
    News: 'Climate change may soon be considered a public health emergency worldwide, if doctors get their way',
    LinkName: 'Business Insider',
    URL: 'https://www.businessinsider.in/sustainability/news/climate-change-may-soon-be-considered-a-public-health-emergency-worldwide-if-doctors-get-their-way/articleshow/104756838.cms',
  },
  {
    SrNo: 423,
    DateOfPublishing: '30th October 2023',
    News: 'Climate change ignites olive oil prices',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/climate-change-ignites-olive-oil-prices-11698604458083.html',
  },
  {
    SrNo: 424,
    DateOfPublishing: '30th October 2023',
    News: 'As developed nations trail, India a beacon of hope on climate change',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/as-developed-nations-trail-india-a-beacon-of-hope-on-climate-change-101698480458834.html',
  },
  {
    SrNo: 425,
    DateOfPublishing: '30th October 2023',
    News: 'The disproportionate impact of climate change on women: A closer look ahead of COP28',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/blog/climate-change/the-disproportionate-impact-of-climate-change-on-women-a-closer-look-ahead-of-cop28-92523',
  },
  {
    SrNo: 426,
    DateOfPublishing: '30th October 2023',
    News: 'Symposium on climate change being held in Chennai\u2019s DakshinChitra',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/chennai/symposium-on-climate-change-being-held-in-chennais-dakshinchitra/articleshow/104759149.cms',
  },
  {
    SrNo: 427,
    DateOfPublishing: '30th October 2023',
    News: 'Green Energy push: Coal Ministry and Ministry of New & Renewable Energy join hands',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/green-energy-push-coal-ministry-and-ministry-of-new-renewable-energy-join-hands/104768576',
  },
  {
    SrNo: 428,
    DateOfPublishing: '30th October 2023',
    News: 'SJVN Green collaborates with Ocean Sun for new tech in green, clean energy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/sjvn-green-collaborates-with-ocean-sun-for-new-tech-in-green-clean-energy/104768631',
  },
  {
    SrNo: 429,
    DateOfPublishing: '30th October 2023',
    News: 'Renewable energy in Gujarat | The green makeover',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/magazine/up-front/story/20231106-renewable-energy-in-gujarat-the-green-makeover-2454604-2023-10-27',
  },
  {
    SrNo: 430,
    DateOfPublishing: '31st October 2023',
    News: 'Climate Change: At Current Emission Levels, Remaining Carbon Budget for 1.5\u00b0C May Run Out By 2029, Says New Study',
    LinkName: 'News18',
    URL: 'https://www.news18.com/india/climate-change-at-current-emission-levels-remaining-carbon-budget-for-1-5c-may-run-out-by-2029-says-new-study-8640798.html',
  },
  {
    SrNo: 431,
    DateOfPublishing: '31st October 2023',
    News: "Minimising Impact Of Climate Change In India's Agriculture Sector",
    LinkName: 'BW Businessworld',
    URL: 'https://www.businessworld.in/article/Minimising-Impact-Of-Climate-Change-In-India-s-Agriculture-Sector-/30-10-2023-496328/',
  },
  {
    SrNo: 432,
    DateOfPublishing: '31st October 2023',
    News: 'Climate Risk Is Becoming Uninsurable. Better Forecasting Can Help.',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/special-report/climate-risk-is-becoming-uninsurable-better-forecasting-can-help-11698685365556.html',
  },
  {
    SrNo: 433,
    DateOfPublishing: '31st October 2023',
    News: "We have to come through': COP28 chief urges unity to deliver climate action",
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/environment/story/we-have-to-come-through-cop28-chief-urges-unity-to-deliver-climate-action-2455541-2023-10-30',
  },
  {
    SrNo: 434,
    DateOfPublishing: '31st October 2023',
    News: 'Change in weather pattern cause of worry in Himalayas',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/oct/31/change-in-weather-pattern-cause-of-worry-in-himalayas-2628634.html',
  },
  {
    SrNo: 435,
    DateOfPublishing: '31st October 2023',
    News: "Solar cushion helps India's green energy firms to offset wind shortfall: Moody's",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/solar-cushion-helps-indias-green-energy-firms-to-offset-wind-shortfall-moodys/104822594',
  },
  {
    SrNo: 436,
    DateOfPublishing: '31st October 2023',
    News: 'Petronas, GIC to invest in green ammonia project in India',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/petronas-gic-to-invest-in-green-ammonia-project-in-india/104839459',
  },
  {
    SrNo: 437,
    DateOfPublishing: '31st October 2023',
    News: 'COP28 faces debate over controversy-mired carbon credits',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/cop28-faces-debate-over-controversy-mired-carbon-credits/104822911',
  },
  {
    SrNo: 438,
    DateOfPublishing: '31st October 2023',
    News: 'Enabling climate finance as a tool for inclusive economic growth',
    LinkName: 'ORF',
    URL: 'https://www.orfonline.org/expert-speak/enabling-climate-finance-as-a-tool-for-inclusive-economic-growth/',
  },
  {
    SrNo: 439,
    DateOfPublishing: '31st October 2023',
    News: 'A cap-and-trade system will help slash emissions more efficiently',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/a-cap-and-trade-system-will-help-slash-emissions-more-efficiently-11698669104571.html',
  },
  {
    SrNo: 440,
    DateOfPublishing: '1st November 2023',
    News: "ISA to adopt India's solar practices; eyes investment in Africa: R K Singh",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/isa-to-adopt-indias-solar-practices-eyes-investment-in-africa-r-k-singh/104871404',
  },
  {
    SrNo: 441,
    DateOfPublishing: '1st November 2023',
    News: 'Delhi govt plans to increase share of ecotourism in GDP',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/delhi/delhi-govt-plans-to-increase-share-of-ecotourism-in-gdp/articleshow/104874740.cms',
  },
  {
    SrNo: 442,
    DateOfPublishing: '1st November 2023',
    News: 'As head of International Solar Alliance, India calls for derisking climate finance to boost energy transition',
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/environment/as-head-of-international-solar-alliance-india-calls-for-derisking-climate-finance-to-boost-energy-transition-18203501.htm',
  },
  {
    SrNo: 443,
    DateOfPublishing: '1st November 2023',
    News: 'Net zero emission positive solution to combat air pollution and boost green tech: Jayant Sinha',
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/environment/net-zero-emission-positive-solution-to-combat-air-pollution-and-boost-green-tech-jayant-sinha-18204791.htm',
  },
  {
    SrNo: 444,
    DateOfPublishing: '1st November 2023',
    News: 'Ladakh being developed as a carbon neutral region: President',
    LinkName: 'The Statesman',
    URL: 'https://www.thestatesman.com/india/ladakh-being-developed-as-a-carbon-neutral-region-president-1503236540.html',
  },
  {
    SrNo: 445,
    DateOfPublishing: '2nd November 2023',
    News: 'MoC and MNRE to work together for advancing clean energy sources - Power Line Magazine',
    LinkName: 'Powerline',
    URL: 'https://powerline.net.in/2023/11/01/moc-and-mnre-to-work-together-for-advancing-clean-energy-sources/',
  },
  {
    SrNo: 446,
    DateOfPublishing: '2nd November 2023',
    News: "India's Green Hydrogen Initiatives Risk Increased Pollution Without Strict Carbon Accounting Rules",
    LinkName: 'The Wire',
    URL: 'https://thewire.in/energy/indias-green-hydrogen-initiatives-risk-increased-pollution-without-strict-carbon-accounting-rules',
  },
  {
    SrNo: 447,
    DateOfPublishing: '2nd November 2023',
    News: 'India set to add 5.8 million tonne of green ammonia manufacturing capacity: R K Singh',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-set-to-add-5-8-million-tonne-of-green-ammonia-manufacturing-capacity-r-k-singh/104901184',
  },
  {
    SrNo: 448,
    DateOfPublishing: '2nd November 2023',
    News: 'Eyeing net zero carbon emission: Experts, officials discuss policies',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/ahmedabad/eyeing-net-zero-carbon-emission-experts-officials-discuss-policies-9009811/',
  },
  {
    SrNo: 449,
    DateOfPublishing: '2nd November 2023',
    News: 'India Pushes For Investment In Africa For Energy Projects At International Solar Alliance Meet',
    LinkName: 'Business World',
    URL: 'https://www.businessworld.in/article/India-Pushes-For-Investment-In-Africa-For-Energy-Projects-At-International-Solar-Alliance-Meet/01-11-2023-497253/',
  },
  {
    SrNo: 450,
    DateOfPublishing: '2nd November 2023',
    News: 'Net zero goal relies on diversified solar supply chains: R K Singh',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/net-zero-goal-relies-on-diversified-solar-supply-chains-r-k-singh/104901173',
  },
  {
    SrNo: 451,
    DateOfPublishing: '2nd November 2023',
    News: 'Bihar CM Nitish Kumar launches power sector projects worth Rs 13,934 crore',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/patna/bihar-cm-nitish-kumar-launches-power-sector-projects-worth-rs-13934-crore/articleshow/104896135.cms',
  },
  {
    SrNo: 452,
    DateOfPublishing: '3rd November 2023',
    News: 'Indian Energy Exchange Q2 result: Net profit up over 21% to Rs 86 crore',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/results/indian-energy-exchange-q2-result-net-profit-up-over-21-to-rs-86-crore-123110200900_1.html',
  },
  {
    SrNo: 453,
    DateOfPublishing: '3rd November 2023',
    News: 'Developing Countries Are Underfinanced, Underprepared to Adapt to Climate Change: UNEP Report',
    LinkName: 'The Wire',
    URL: 'https://thewire.in/environment/developing-countries-are-underfinanced-underprepared-to-adapt-to-climate-change-unep-report',
  },
  {
    SrNo: 454,
    DateOfPublishing: '3rd November 2023',
    News: 'Climate adaptation funding gap 50% higher than estimated, UN says',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/science/story/climate-adaptation-funding-gap-50-higher-than-estimated-un-says-2457225-2023-11-02',
  },
  {
    SrNo: 455,
    DateOfPublishing: '3rd November 2023',
    News: 'Climate finance tops $1 trillion in 2021, still far from 2030 target: Study',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/climate-finance-tops-1-trillion-in-2021-still-far-from-2030-target-study/104928648',
  },
  {
    SrNo: 456,
    DateOfPublishing: '3rd November 2023',
    News: 'No enthusiasm among developed nations to meet $100 bn climate funding pledge: Piyush Goyal',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/world/no-enthusiasm-among-developed-nations-to-meet-100-bn-climate-funding-pledge-piyush-goyal-11698936981824.html',
  },
  {
    SrNo: 457,
    DateOfPublishing: '3rd November 2023',
    News: 'Government urges state-owned finance firm REC to boost coal sector investment and mine financing',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/consider-investing-in-coal-provide-financing-to-mines-centre-asks-rec-123110200855_1.html',
  },
  {
    SrNo: 458,
    DateOfPublishing: '3rd November 2023',
    News: 'Bihar CM Nitish Kumar launches power sector projects worth Rs 13,934 crore',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/patna/bihar-cm-nitish-kumar-launches-power-sector-projects-worth-rs-13934-crore/articleshow/104896135.cms',
  },
  {
    SrNo: 459,
    DateOfPublishing: '6th November 2023',
    News: 'World Bank to host new climate fund that\u2019ll support affected countries',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/india/world-bank-to-host-new-climate-fund-thatll-support-affected-countries/articleshow/104995820.cms?from=mdr',
  },
  {
    SrNo: 460,
    DateOfPublishing: '6th November 2023',
    News: 'Renewable share in power cos\u2019 generation mix rising sharply',
    LinkName: 'The Financial Express',
    URL: 'https://www.financialexpress.com/business/industry/renewable-share-in-power-cos-generation-mix-rising-sharply/3297866/',
  },
  {
    SrNo: 461,
    DateOfPublishing: '6th November 2023',
    News: 'Tamil Nadu exploring alternatives for ACs to cut down power consumption',
    LinkName: 'The Indian Express',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/nov/06/tamil-nadu-exploring-alternatives-for-acs-to-cut-down-power-consumption-2630457.html',
  },
  {
    SrNo: 462,
    DateOfPublishing: '6th November 2023',
    News: "NTPC's renewable enrygy capacity goes up 3,364 MW; total energy goes up to 73,874 MW",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ntpcs-renewable-energy-capacity-goes-up-3364-mw-total-energy-capacity-goes-up-to-73874-mw/104997284',
  },
  {
    SrNo: 463,
    DateOfPublishing: '6th November 2023',
    News: 'Power plants\u2019 fuel linkages to reach 1 billion tonne by 2030',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/power-plants-fuel-linkages-to-reach-1-billion-tonne-by-2030-11699171902319.html',
  },
  {
    SrNo: 464,
    DateOfPublishing: '6th November 2023',
    News: 'Green finance market size could be $5.6 trn by 2047',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/opinion/columns/green-finance-market-size-could-be-5-6-trn-by-2047-123110500856_1.html',
  },
  {
    SrNo: 465,
    DateOfPublishing: '7th November 2023',
    News: 'Notification on minimum share of renewable energy consumption sets clear path for usage: MNRE Secy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/notification-on-minimum-share-of-renewable-energy-consumption-sets-clear-path-for-usage-mnre-secy/105024318',
  },
  {
    SrNo: 466,
    DateOfPublishing: '7th November 2023',
    News: 'IEX power trade volume rises 18 pc to 9,483 million units in October',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/iex-power-trade-volume-rises-18-pc-to-9483-million-units-in-october/105009540',
  },
  {
    SrNo: 467,
    DateOfPublishing: '7th November 2023',
    News: 'India, Bhutan agree to extend energy partnership to non-hydro renewables',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/india-bhutan-agree-to-extend-energy-partnership-to-non-hydro-renewables-123110601424_1.html',
  },
  {
    SrNo: 468,
    DateOfPublishing: '7th November 2023',
    News: 'India set to boost power capacity by 31,000 MW in FY24, eyes 900 GW by 2032: Power Secy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/india-set-to-boost-power-capacity-by-31000-mw-in-fy24-eyes-900-gw-by-2032-power-secy/105024343',
  },
  {
    SrNo: 469,
    DateOfPublishing: '7th November 2023',
    News: 'Renewable energy to fuel cost competitveness for industires ',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renewable-energy-to-fuel-cost-competitiveness-for-industries/105024367',
  },
  {
    SrNo: 470,
    DateOfPublishing: '7th November 2023',
    News: "Arunachal deputy CM mulls modernization of state's power system to oust technical and commerical losses",
    LinkName: 'India Today North East',
    URL: 'https://www.indiatodayne.in/arunachal-pradesh/story/arunachal-deputy-cm-mulls-modernization-of-states-power-system-to-oust-technical-and-commercial-losses-706837-2023-11-06',
  },
  {
    SrNo: 471,
    DateOfPublishing: '8th November 2023',
    News: 'Alpine tourism threatened by overcrowding and climate change',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/lifestyle/travel/alpine-tourism-threatened-by-overcrowding-and-climate-change-101699360260144.html',
  },
  {
    SrNo: 472,
    DateOfPublishing: '8th November 2023',
    News: 'Workshop on climate change held',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/coimbatore/workshop-on-climate-change-held/articleshow/105055193.cms',
  },
  {
    SrNo: 473,
    DateOfPublishing: '8th November 2023',
    News: "Yogi government's green energy push: Solar power to fuel Lalitpur's Bulk Drug Park",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/yogi-governments-green-energy-push-solar-power-to-fuel-lalitpurs-bulk-drug-park/105040295',
  },
  {
    SrNo: 474,
    DateOfPublishing: '8th November 2023',
    News: 'Thermal Power To Remain Until Continous Renewable energy Availability: RK Singh ',
    LinkName: 'BW Businessworld',
    URL: 'https://www.businessworld.in/article/Thermal-Power-To-Remain-Until-Continuous-Renewable-Energy-Availability-RK-Singh/07-11-2023-497942/',
  },
  {
    SrNo: 475,
    DateOfPublishing: '8th November 2023',
    News: 'Decarbonising the steel industry with biomass',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/opinion/decarbonising-the-steel-industry-with-biomass/3300178/',
  },
  {
    SrNo: 476,
    DateOfPublishing: '8th November 2023',
    News: 'India Energy Storage Alliance ties up with Messe Meunchen India, others for EV Asia Expo 2024',
    LinkName: 'DailyExcelsior',
    URL: 'https://www.dailyexcelsior.com/india-energy-storage-alliance-ties-up-with-messe-muenchen-india-others-for-ev-asia-expo-2024/',
  },
  {
    SrNo: 477,
    DateOfPublishing: '8th November 2023',
    News: 'CSJMU;s dept bags project  for research in crop improvement, renewable energy ',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/kanpur/csjmus-dept-bags-project-for-research-in-crop-improvement-renewable-energy/articleshow/105054999.cms',
  },
  {
    SrNo: 478,
    DateOfPublishing: '8th November 2023',
    News: 'CEA advocates for Evs as energy storage in national grid support ',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/cea-advocates-for-evs-as-energy-storage-in-national-grid-support/105053731',
  },
  {
    SrNo: 479,
    DateOfPublishing: '8th November 2023',
    News: 'COP28: Green education prepares future generations to lead climate action pathway',
    LinkName: 'ANI',
    URL: 'https://www.aninews.in/news/world/middle-east/cop28-green-education-prepares-future-generations-to-lead-climate-action-pathway20231107144926/',
  },
  {
    SrNo: 480,
    DateOfPublishing: '9th November 2023',
    News: "World Bank pledges USD 200 million to propel Himachal Pradesh's 'Green State' vision",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/world-bank-pledges-usd-200-million-to-propel-himachal-pradeshs-green-state-vision/105060923',
  },
  {
    SrNo: 481,
    DateOfPublishing: '9th November 2023',
    News: 'AP to play key role in energy efficiency, renewable energy',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/visakhapatnam/ap-to-play-key-role-in-energy-efficiency-renewable-energy/articleshow/105082601.cms',
  },
  {
    SrNo: 482,
    DateOfPublishing: '9th November 2023',
    News: 'States told to form energy transition committees to fasttrack decision making, achieve targets',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/states-told-to-form-energy-transition-committees-to-fasttrack-decision-making-achieve-targets/105082565',
  },
  {
    SrNo: 483,
    DateOfPublishing: '9th November 2023',
    News: 'India developing Digital Public Infrastructure for climate finance, a historic clean growth initiative',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-developing-digital-public-infrastructure-for-climate-finance-a-historic-clean-growth-initiative/105083581',
  },
  {
    SrNo: 484,
    DateOfPublishing: '10th November 2023',
    News: 'India reaches 60 GW high-end solar module manufacturing capacity: AISMA',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/india-reaches-60-gw-high-end-solar-module-manufacturing-capacity-aisma-123110900683_1.html',
  },
  {
    SrNo: 485,
    DateOfPublishing: '10th November 2023',
    News: 'Global Biofuels Alliance likely to be launched at G20: Sources',
    LinkName: 'Daily Excelsior',
    URL: 'https://www.dailyexcelsior.com/global-biofuels-alliance-likely-to-be-launched-at-g20-sources-2/',
  },
  {
    SrNo: 486,
    DateOfPublishing: '10th November 2023',
    News: 'Decoupling of emissions and GDP not yet achieved: RBI deputy governor Patra',
    LinkName: 'Business World',
    URL: 'https://www.business-standard.com/india-news/complete-decoupling-of-carbon-emission-global-gdp-not-yet-achieved-patra-123110901134_1.html',
  },
  {
    SrNo: 487,
    DateOfPublishing: '10th November 2023',
    News: 'Karnataka signs MoU with Tehri Hydro Development Corp for Rs 15,000 crore power projects',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/karnataka-signs-mou-with-tehri-hydro-development-corp-for-rs-15000-crore-power-projects/105094111',
  },
  {
    SrNo: 488,
    DateOfPublishing: '15th November 2023',
    News: 'Power ministry sets benchmark prices for biomass pellets to boost sustainable energy in thermal power plants',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/power-ministry-sets-benchmark-prices-for-biomass-pellets-to-boost-sustainable-energy-in-thermal-power-plants/105222817',
  },
  {
    SrNo: 489,
    DateOfPublishing: '15th November 2023',
    News: 'Bureau of Energy Efficiency Issues Terms for Accrediting Carbon Verification Agencies',
    LinkName: 'Mercom India',
    URL: 'https://www.mercomindia.com/accrediting-carbon-verification-agencies',
  },
  {
    SrNo: 490,
    DateOfPublishing: '15th November 2023',
    News: "India's Energy Transition Vision Earns Praise From Upcoming COP28 President",
    LinkName: 'BW Businessworld',
    URL: 'https://www.businessworld.in/article/India-s-Energy-Transition-Vision-Earns-Praise-From-Upcoming-COP28-President/14-11-2023-498638/',
  },
  {
    SrNo: 491,
    DateOfPublishing: '15th November 2023',
    News: "India won't compromise on growing energy demand of economy: R K Singh",
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/politics/india-not-to-compromise-on-growing-energy-demand-of-indian-economy-but-develop-responsibly-r-k-singh-11740481.html',
  },
  {
    SrNo: 492,
    DateOfPublishing: '16th November 2023',
    News: "UNDP's National Carbon Registry revolutionising climate action in India",
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/undps-national-carbon-registry-revolutionising-climate-action-in-india-101700032978096.html',
  },
  {
    SrNo: 493,
    DateOfPublishing: '16th November 2023',
    News: 'Bengaluru to get its own climate action plan',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/karnataka/2023/nov/16/bengaluru-to-get-its-own-climate-action-plan-2633249.html',
  },
  {
    SrNo: 494,
    DateOfPublishing: '16th November 2023',
    News: 'Hydropower jobs surge in Asia; India emerges as key player: Report',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/hydropower-jobs-surge-in-asia-india-emerges-as-key-player-report/105247559',
  },
  {
    SrNo: 495,
    DateOfPublishing: '16th November 2023',
    News: 'BEE meeting with automakers in December',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/express-mobility/industry-express-mobility/bee-meeting-with-automakers-in-december/3308059/',
  },
  {
    SrNo: 496,
    DateOfPublishing: '17th November 2023',
    News: 'CM: Prepare Outline Of Solar Power Scheme',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/shimla/cm-prepare-outline-of-solar-power-scheme/amp_articleshow/105276344.cms',
  },
  {
    SrNo: 497,
    DateOfPublishing: '17th November 2023',
    News: 'Coal power plants thrive despite surge in renewable energy capacity: CRISIL Ratings',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/coal-power-plants-thrive-despite-surge-in-renewable-energy-capacity-crisil-ratings-11755711.html/amp',
  },
  {
    SrNo: 498,
    DateOfPublishing: '17th November 2023',
    News: 'Haryana Regulator Clarifies Issues in Implementing Green Energy Open Access',
    LinkName: 'Mercom ',
    URL: 'https://www.mercomindia.com/haryana-clarifies-green-open-access',
  },
  {
    SrNo: 499,
    DateOfPublishing: '17th November 2023',
    News: 'Clean energy surpasses fossil fuel industry in post-pandemic job creation, says IEA report',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/world/clean-energy-surpasses-fossil-fuel-industry-in-post-pandemic-job-creation-says-iea-report/amp-11700153113332.html',
  },
  {
    SrNo: 500,
    DateOfPublishing: '17th November 2023',
    News: 'India tightens climate goals with new carbon credit trading compliance draft',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-tightens-climate-goals-with-new-carbon-credit-trading-compliance-draft/105275822',
  },
  {
    SrNo: 501,
    DateOfPublishing: '20th November 2023',
    News: 'India among few big economies on track to meet climate pledges: Bhupender Yadav',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-among-few-big-economies-on-track-to-meet-climate-pledges-bhupender-yadav-11700237176598.html',
  },
  {
    SrNo: 502,
    DateOfPublishing: '20th November 2023',
    News: 'Climate crisis potentially lethal threat for people with mental health problems: Report',
    LinkName: 'ET Healthworld',
    URL: 'https://health.economictimes.indiatimes.com/news/industry/climate-crisis-potentially-lethal-threat-for-people-with-mental-health-problems-report/105308731',
  },
  {
    SrNo: 503,
    DateOfPublishing: '20th November 2023',
    News: 'High levels of black carbon emission sets off alarm bells in Guwahati',
    LinkName: 'The Morung Express',
    URL: 'https://morungexpress.com/high-levels-of-black-carbon-emission-sets-off-alarm-bells-in-guwahati',
  },
  {
    SrNo: 504,
    DateOfPublishing: '20th November 2023',
    News: 'COP28 will be more decisive on climate change issues\u2019',
    LinkName: 'The Sunday Guardian',
    URL: 'https://sundayguardianlive.com/news/cop28-will-be-more-decisive-on-climate-change-issues',
  },
  {
    SrNo: 505,
    DateOfPublishing: '20th November 2023',
    News: 'Plants are likely to absorb more CO2 in a changing climate than we thought',
    LinkName: 'Daily Excelsior',
    URL: 'https://www.dailyexcelsior.com/plants-are-likely-to-absorb-more-co2-in-a-changing-climate-than-we-thought-heres-why/',
  },
  {
    SrNo: 506,
    DateOfPublishing: '20th November 2023',
    News: 'Climate change will soon begin to alter the very structure of our brains, study warns',
    LinkName: 'Business Insider',
    URL: 'https://www.businessinsider.in/sustainability/news/climate-change-will-soon-begin-to-alter-the-very-structure-of-our-brains-study-warns/articleshow/105290486.cms',
  },
  {
    SrNo: 507,
    DateOfPublishing: '20th November 2023',
    News: "Powered by govt, corporate sector, India's green energy drive in top gear",
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/powered-by-govt-corporate-sector-indias-green-energy-drive-in-top-gear/105341461',
  },
  {
    SrNo: 508,
    DateOfPublishing: '20th November 2023',
    News: 'State govt to develop methodology to avail carbon credit benefits',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/goa/state-govt-to-develop-methodology-to-avail-carbon-credit-benefits/articleshow/105319921.cms',
  },
  {
    SrNo: 509,
    DateOfPublishing: '20th November 2023',
    News: 'Development of sustainable jet fuels must to decarbonise economy: Murmu',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/development-of-sustainable-jet-fuels-must-to-decarbonise-economy-murmu-123111800934_1.html',
  },
  {
    SrNo: 510,
    DateOfPublishing: '20th November 2023',
    News: 'COP28 boosts green financing; UAE holds 19% of global sustainable bonds',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/cop28-boosts-green-financing-uae-holds-19-of-global-sustainable-bonds-123111900813_1.html',
  },
  {
    SrNo: 511,
    DateOfPublishing: '21st November 2023',
    News: 'Green living key to tackle climate change, says President Droupadi Murmu',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/odisha/2023/nov/21/green-living-key-to-tackle-climate-change-says-presidentdroupadi-murmu-2634880.html',
  },
  {
    SrNo: 512,
    DateOfPublishing: '21st November 2023',
    News: 'One-day temperatures breach 2\u00b0C warming point for first time: Report',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/one-day-temperatures-breach-2-c-warming-point-for-first-time-report-92898',
  },
  {
    SrNo: 513,
    DateOfPublishing: '21st November 2023',
    News: 'Ahead of COP28, climate seems conducive to collaborative efforts',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/opinion/ahead-of-cop28-climate-seems-conducive-to-collaborative-efforts-11774831.html',
  },
  {
    SrNo: 514,
    DateOfPublishing: '21st November 2023',
    News: 'Tata Power Renewable Energy adds 1.4 GW in group captive projects in last 6 months',
    LinkName: 'Zee Business',
    URL: 'https://www.zeebiz.com/companies/news-tata-power-renewable-energy-adds-14-gw-in-group-captive-projects-in-last-6-months-265419',
  },
  {
    SrNo: 515,
    DateOfPublishing: '21st November 2023',
    News: 'Emerging trends in sustainable solar energy technology',
    LinkName: 'Times Tech',
    URL: 'https://timestech.in/emerging-trends-in-sustainable-solar-energy-technology/',
  },
  {
    SrNo: 516,
    DateOfPublishing: '21st November 2023',
    News: 'Innovations in Clean Energy Technologies: A Key Driver for ESG Excellence',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/innovations-in-clean-energy-technologies-a-key-driver-for-esg-excellence/105352284',
  },
  {
    SrNo: 517,
    DateOfPublishing: '22nd November 2023',
    News: 'India\u2019s climate change transition costs add to daunting challenges',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/70-of-electric-2-wheelers-sold-in-country-are-manufactured-in-tamil-nadu-tn-govt-11783041.html',
  },
  {
    SrNo: 518,
    DateOfPublishing: '22nd November 2023',
    News: 'Achieving full decarbonisation could help save $12 trillion by 2050: Amitabh Kant',
    LinkName: 'The Economic Times',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/achieving-full-decarbonisation-could-help-save-12-trillion-by-2050-amitabh-kant/105393932',
  },
  {
    SrNo: 519,
    DateOfPublishing: '22nd November 2023',
    News: 'Tamil Nadu in process of developing green hydrogen hub: Minister Rajaa',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/tamil-nadu-in-process-of-developing-green-hydrogen-hub-minister-rajaa-123112101407_1.html',
  },
  {
    SrNo: 520,
    DateOfPublishing: '22nd November 2023',
    News: 'Green hydrogen to ring in investment',
    LinkName: 'Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/ahmedabad/green-hydrogen-to-ring-in-investment/articleshow/105400532.cms',
  },
  {
    SrNo: 521,
    DateOfPublishing: '22nd November 2023',
    News: 'UN climate negotiations through the years up to COP28',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/sci-tech/energy-and-environment/un-climate-negotiations-through-the-years-up-to-cop28/article67557084.ece',
  },
  {
    SrNo: 522,
    DateOfPublishing: '22nd November 2023',
    News: 'Presidential push for green economy',
    LinkName: 'Daily FT',
    URL: 'https://www.ft.lk/top-story/Presidential-push-for-green-economy/26-755447',
  },
  {
    SrNo: 523,
    DateOfPublishing: '22nd November 2023',
    News: 'Xi will not attend G20 Virtual Summit; Agenda and Developments',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/defence-xi-will-not-attend-g20-virtual-summitnbsp-agenda-and-developments-3313683/',
  },
  {
    SrNo: 524,
    DateOfPublishing: '22nd November 2023',
    News: '\u2018France is key partner in India\u2019s energy transition\u2019',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/bengaluru/france-india-energy-transition-consul-general-france-bengaluru/articleshow/105400096.cms',
  },
  {
    SrNo: 525,
    DateOfPublishing: '22nd November 2023',
    News: 'Urgent Need for Higher Climate Action to Limit Global Warming: UNEP Report',
    LinkName: 'The Wire',
    URL: 'https://thewire.in/environment/urgent-need-for-higher-climate-action-to-limit-global-warming-unep-report',
  },
  {
    SrNo: 526,
    DateOfPublishing: '22nd November 2023',
    News: 'UAE showcases advances in Green Hydrogen Project Ahead of COP28',
    LinkName: 'DD News',
    URL: 'https://ddnews.gov.in/international/uae-showcases-advances-green-hydrogen-project-ahead-cop28',
  },
  {
    SrNo: 527,
    DateOfPublishing: '22nd November 2023',
    News: 'Global Green Growth Institute, EESL to implement project in India',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/news/global-green-growth-institute-eesl-to-implement-project-in-india-123112101020_1.html',
  },
  {
    SrNo: 528,
    DateOfPublishing: '22nd November 2023',
    News: 'Farming carbon credits',
    LinkName: 'The Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/opinion/farming-carbon-credits/article67559222.ece',
  },
  {
    SrNo: 529,
    DateOfPublishing: '23rd November 2023',
    News: 'India leads G20 in green energy transition; pledges $25 mn for digital growth in global South',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-leads-g20-in-green-energy-transition-pledges-25-mn-for-digital-growth-in-global-south/105428281',
  },
  {
    SrNo: 530,
    DateOfPublishing: '23rd November 2023',
    News: 'Push to green energy in U.P: 19 solar, biogas plants worth\u00a0\u20b918,831 cr granted land ahead of groundbreaking ceremony',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/lucknow-news/push-to-green-energy-in-u-p-19-solar-biogas-plants-worth-rs-18-831-cr-granted-land-ahead-of-groundbreaking-ceremony-101700672025707.html',
  },
  {
    SrNo: 531,
    DateOfPublishing: '23rd November 2023',
    News: 'India to add 80 GW coal-fuelled power generation capacity by 2031-32',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/india-to-add-80-gw-coal-fuelled-power-generation-capacity-by-203132-11700659459524.html',
  },
  {
    SrNo: 532,
    DateOfPublishing: '23rd November 2023',
    News: 'Tamil Nadu in process of developing green hydrogen hub: Industries Minister Rajaa',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/tamil-nadu-in-process-of-developing-green-hydrogen-hub-industries-minister-rajaa/105418672',
  },
  {
    SrNo: 533,
    DateOfPublishing: '23rd November 2023',
    News: '80 per cent of India\u2019s districts hit by climate change',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/cities/bengaluru/2023/nov/23/80-per-centof-indias-districts-hit-by-climate-change-2635436.html',
  },
  {
    SrNo: 534,
    DateOfPublishing: '23rd November 2023',
    News: 'ADB grants $6.5 million for clean cooking in rural India, eyes major carbon reduction',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/adb-grants-6-5-million-for-clean-cooking-in-rural-india-eyes-major-carbon-reduction/105429207',
  },
  {
    SrNo: 535,
    DateOfPublishing: '23rd November 2023',
    News: 'Congress focus on renewable energy in Rajasthan faces backlash on ground',
    LinkName: 'Frontline',
    URL: 'https://frontline.thehindu.com/politics/controversy-congress-focus-on-renewable-energy-in-rajasthan-faces-backlash-on-ground-ahead-of-2023-assembly-election-ashok-gehlot-bjp-adani-green-energy/article67561887.ece',
  },
  {
    SrNo: 536,
    DateOfPublishing: '24th November 2023',
    News: 'Finland, India discuss hydrogen electrolyzer production in India',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/finland-india-discuss-hydrogen-electrolyzer-production-in-india-11700748605327.html',
  },
  {
    SrNo: 537,
    DateOfPublishing: '24th November 2023',
    News: '\u2018Tamil Nadu will reach net zero target by 2060\u2019, says Indian Green Building Council chairman',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/cities/chennai/2023/nov/24/tamil-nadu-will-reach-net-zero-target-by-2060-says-indian-green-building-council-chairman-2635706.html',
  },
  {
    SrNo: 538,
    DateOfPublishing: '24th November 2023',
    News: 'Tamil Nadu in process of developing green hydrogen hub, says Industries Minister Rajaa',
    LinkName: 'India TV',
    URL: 'https://www.indiatvnews.com/tamil-nadu/news-industries-minister-rajaa-on-developing-green-hydrogen-hub-electric-vehicle-conclave-mk-stalin-latest-news-2023-11-23-904092',
  },
  {
    SrNo: 539,
    DateOfPublishing: '24th November 2023',
    News: 'UP Govt To Transform Bundelkhand Expressway Into Solar Expressway, Targeting 550 MW Of Solar Power Generation',
    LinkName: 'BusinessWorld',
    URL: 'https://www.businessworld.in/article/UP-Govt-To-Transform-Bundelkhand-Expressway-Into-Solar-Expressway-Targeting-550-MW-of-Solar-Power-Generation-/23-11-2023-499860/',
  },
  {
    SrNo: 540,
    DateOfPublishing: '28th November 2023',
    News: 'India calls for concrete action on climate funding, transfer of technology ahead of COP28',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-calls-for-concrete-action-on-climate-funding-transfer-of-technology-ahead-of-cop28/105547487',
  },
  {
    SrNo: 541,
    DateOfPublishing: '28th November 2023',
    News: 'Net zero goal calls for USD 5 trillion in annual green investments: IMF',
    LinkName: 'ZEE News',
    URL: 'https://www.zeebiz.com/world/news-net-zero-goal-calls-for-usd-5-trillion-in-annual-green-investments-imf-266205',
  },
  {
    SrNo: 542,
    DateOfPublishing: '28th November 2023',
    News: 'Goal set to make Himachal a green energy state by March 2026: CM Sukhu',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/goal-set-to-make-himachal-a-green-energy-state-by-march-2026-cm-sukhu-123112700863_1.html',
  },
  {
    SrNo: 543,
    DateOfPublishing: '28th November 2023',
    News: 'Gujarat chief minister visits Yamanashi Hydrogen Company in Japan, invites it to Vibrant Gujrat Summit',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/gujarat-chief-minister-visits-yamanashi-hydrogen-company-in-japan-invites-it-to-vibrant-gujrat-summit/105534600',
  },
  {
    SrNo: 544,
    DateOfPublishing: '28th November 2023',
    News: 'Odisha approves 12 projects with investment of Rs 84,918 crore',
    LinkName: 'ET Infra',
    URL: 'https://infra.economictimes.indiatimes.com/news/urban-infrastructure/odisha-approves-12-projects-with-investment-of-rs-84918-crore/105528043',
  },
  {
    SrNo: 545,
    DateOfPublishing: '28th November 2023',
    News: "Bengaluru civic body's climate action plan aims to achieve net-zero by 2050",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/bengaluru-civic-body-s-climate-action-plan-aims-to-achieve-net-zero-by-2050-123112700643_1.html',
  },
  {
    SrNo: 546,
    DateOfPublishing: '29th November 2023',
    News: 'India calls for concrete action on climate funding, transfer of technology ahead of COP28',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/india-calls-for-concrete-action-on-climate-funding-transfer-of-technology-ahead-of-cop28/105551353',
  },
  {
    SrNo: 547,
    DateOfPublishing: '29th November 2023',
    News: 'COP28: India has walked the talk on climate change, says Indian envoy',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/cop28-india-has-walked-the-talk-on-climate-change-says-indian-envoy-123112900112_1.html',
  },
  {
    SrNo: 548,
    DateOfPublishing: '29th November 2023',
    News: 'Climate change reducing global GDP: report',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/environment/climate-change-reducing-global-gdp-report-101701147932349.html',
  },
  {
    SrNo: 549,
    DateOfPublishing: '29th November 2023',
    News: 'Raising the stakes on climate funding \u2013 the significance of blended finance',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/raising-the-stakes-on-climate-funding-the-significance-of-blended-finance-11701076592994.html',
  },
  {
    SrNo: 550,
    DateOfPublishing: '29th November 2023',
    News: '\u2018Green Gurukul\u2019 competition to address local ecological issues',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/dehradun/green-gurukul-competition-address-local-ecological-issues/articleshow/105578794.cms',
  },
  {
    SrNo: 551,
    DateOfPublishing: '29th November 2023',
    News: 'Adani Total Gas fires up 18% on launch of green hydrogen blending project',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/business/stocks/adani-total-gas-launches-green-hydrogen-blending-project-11821731.html',
  },
  {
    SrNo: 552,
    DateOfPublishing: '29th November 2023',
    News: 'Record COP28 carbon footprint under scrutiny,',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/record-cop28-carbon-footprint-under-scrutiny/105558546',
  },
  {
    SrNo: 553,
    DateOfPublishing: '30th November 2023',
    News: 'Green hydrogen organisation names ProClime as carbon partner in India at CETFiS',
    LinkName: 'Manufacturing Today',
    URL: 'https://www.manufacturingtodayindia.com/sectors/green-hydrogen-organisation-names-proclime-as-carbon-partner-in-india-at-cetfis',
  },
  {
    SrNo: 554,
    DateOfPublishing: '30th November 2023',
    News: 'India to Unveil National Green Hydrogen Mission',
    LinkName: 'Construction World',
    URL: 'https://www.constructionworld.in/energy-infrastructure/power-and-renewable-energy/india-to-unveil-national-green-hydrogen-mission/47379',
  },
  {
    SrNo: 555,
    DateOfPublishing: '30th November 2023',
    News: "Union Minister Bhupender Yadav advocates 'Climate Justice' ahead of COP28 Summit",
    LinkName: 'ANI',
    URL: 'https://www.aninews.in/news/world/middle-east/union-minister-bhupender-yadav-advocates-climate-justice-ahead-of-cop28-summit20231130051849/',
  },
  {
    SrNo: 556,
    DateOfPublishing: '30th November 2023',
    News: '\u2018RE policy to help state get half its energy from renewable sources\u2019',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/ahmedabad/gujarats-renewable-energy-policy-2023-50-renewable-energy-target/articleshow/105607862.cms',
  },
  {
    SrNo: 557,
    DateOfPublishing: '30th November 2023',
    News: 'Carbon credit market: Centre to soon start stakeholder consultations',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/policy/economy-carbon-credit-market-centre-to-soon-start-stakeholder-consultations-3321195/',
  },
  {
    SrNo: 558,
    DateOfPublishing: '30th November 2023',
    News: 'WEF says USD 13.5 trillion needed to fast-track decarbonisation of key sectors',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/wef-says-usd-13-5-trillion-needed-to-fast-track-decarbonisation-of-key-sectors/105593342',
  },
  {
    SrNo: 559,
    DateOfPublishing: '30th November 2023',
    News: 'India needs $293 billion to triple renewable energy capacity by 2030: Report',
    LinkName: 'Republic World',
    URL: 'https://www.republicworld.com/economy/global-business/india-needs-293-billion-to-triple-renewable-energy-capacity-by-2030-report.news',
  },
  {
    SrNo: 560,
    DateOfPublishing: '1st December 2023',
    News: 'Alternative energy sources will co-exist in India: CES India',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/pune/alternative-energy-sources-will-co-exist-in-india-ces-india/articleshow/105642364.cms',
  },
  {
    SrNo: 561,
    DateOfPublishing: '1st December 2023',
    News: 'Offshore energy will be game changer in Tamil Nadu & boost economy, says TEDA chief',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/tamil-nadu/2023/dec/01/offshore-energy-will-be-game-changer-in-tamil-nadu--boost-economy-says-teda-chief-2637744.html',
  },
  {
    SrNo: 562,
    DateOfPublishing: '1st December 2023',
    News: 'India favoured destination in renewable power, Japan\u2019s firms welcome to invest: R.K. Singh',
    LinkName: 'India Today',
    URL: 'India%20favoured%20destination%20in%20renewable%20power,%20Japan\u2019s%20firms%20welcome%20to%20invest:%20R.K.%20Singh',
  },
  {
    SrNo: 563,
    DateOfPublishing: '1st December 2023',
    News: 'India to be most impacted by climate change: IIT Bombay professor',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/india-to-be-most-impacted-by-climate-change-iit-bombay-professor-123120100109_1.html',
  },
  {
    SrNo: 564,
    DateOfPublishing: '1st December 2023',
    News: 'Climate change: COP28 okays $475 mn loss and damage fund',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/cop28-launches-climate-loss-and-damage-fund-11701351427707.html',
  },
  {
    SrNo: 565,
    DateOfPublishing: '1st December 2023',
    News: 'India walked the talk when it comes to climate action: PM Modi',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-walked-the-talk-when-it-comes-to-climate-action-pm-modi/105641658',
  },
  {
    SrNo: 566,
    DateOfPublishing: '4th December 2023',
    News: "Climate change threatens India's agricultural sector, report finds",
    LinkName: 'Deccan Herald',
    URL: 'https://www.deccanherald.com/science/climate-change-threatens-indias-agricultural-sector-report-finds-2795608',
  },
  {
    SrNo: 567,
    DateOfPublishing: '4th December 2023',
    News: 'Progress on climate change has been too slow. But it\u2019s been real',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/environment/progress-on-climate-change-has-been-too-slow-but-it-s-been-real-101701608636100.html',
  },
  {
    SrNo: 568,
    DateOfPublishing: '4th December 2023',
    News: "10% hosps in India could shut by 2100 due to climate change'",
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/mumbai/10-hosps-in-india-could-shut-by-2100-due-to-climate-change/articleshow/105689871.cms',
  },
  {
    SrNo: 569,
    DateOfPublishing: '4th December 2023',
    News: 'Kerala allocates over Rs 12,500 cr for phase-I green hydrogen valley, green transport corridor',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/kerala-allocates-over-rs-12500-cr-for-phase-i-green-hydrogen-valley-green-transport-corridor/105707895',
  },
  {
    SrNo: 570,
    DateOfPublishing: '4th December 2023',
    News: "Govt's green hydrogen push to cut fossil fuel imports by INR 1 trillion",
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/govts-green-hydrogen-push-to-cut-fossil-fuel-imports-by-inr-1-trillion/105696913',
  },
  {
    SrNo: 571,
    DateOfPublishing: '4th December 2023',
    News: 'How climate change is making the world sick',
    LinkName: 'Hindu',
    URL: 'https://www.thehindu.com/sci-tech/energy-and-environment/how-climate-change-making-world-sick/article67600720.ece',
  },
  {
    SrNo: 572,
    DateOfPublishing: '4th December 2023',
    News: 'COP28: 118 countries agree to triple renewable energy, to push out fossil fuels',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/cop28/story/cop28-118-countries-agree-to-triple-renewable-energy-to-push-out-fossil-fuels-407982-2023-12-02',
  },
  {
    SrNo: 573,
    DateOfPublishing: '4th December 2023',
    News: 'India refrained from signing on global renewable energy pledge at COP28 over coal phase-out terms: Sources',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-refrained-from-signing-on-global-renewable-energy-pledge-at-cop28-over-coal-phase-out-terms-sources/105712052',
  },
  {
    SrNo: 574,
    DateOfPublishing: '5th December 2023',
    News: 'Renewable energy tenders of 15,165 MW capacity issued in India in November',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/renewable-energy-tenders-of-15165-mw-capacity-issued-in-india-in-november/105730532',
  },
  {
    SrNo: 575,
    DateOfPublishing: '5th December 2023',
    News: 'REC aiming for 30% AUM in renewable energy',
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/business/rec-asset-under-management-aum-renewable-energy-portfolio-18483521.htm',
  },
  {
    SrNo: 576,
    DateOfPublishing: '5th December 2023',
    News: 'Andhra Pradesh unveils Rs 66 Billion clean energy and infrastructure expansion plans',
    LinkName: 'Elets EGov',
    URL: 'https://egov.eletsonline.com/2023/12/andhra-pradesh-unveils-rs-66-billion-clean-energy-and-infrastructure-expansion-plans/',
  },
  {
    SrNo: 577,
    DateOfPublishing: '5th December 2023',
    News: '\u201866 Indian Airports are operating on 100% Green Energy',
    LinkName: 'Rising Kashmir',
    URL: 'https://risingkashmir.com/66-indian-airports-are-operating-on-100-green-energy-1411fbc9-3cc1-4f9d-8891-d4813b103366',
  },
  {
    SrNo: 578,
    DateOfPublishing: '5th December 2023',
    News: 'India to receive $6.5 Billion climate finance boost from CFLI',
    LinkName: 'Elets Egov',
    URL: 'https://egov.eletsonline.com/2023/12/india-to-receive-6-5-billion-climate-finance-boost-from-cfli/',
  },
  {
    SrNo: 579,
    DateOfPublishing: '5th December 2023',
    News: 'Kerala\u2019s mega allocation of Rs 12,500 crore for green transport, hydrogen valleys',
    LinkName: 'Manufacturing Today',
    URL: 'https://www.manufacturingtodayindia.com/sectors/keralas-mega-allocation-of-rs-12500-crore-for-green-transport-hydrogen-valleys',
  },
  {
    SrNo: 580,
    DateOfPublishing: '5th December 2023',
    News: 'Global carbon emissions from fossil fuels to rise 1.1% to hit peak in 2023',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/global-carbon-emissions-from-fossil-fuels-to-rise-1-1-to-hit-peak-in-2023-123120500092_1.html',
  },
  {
    SrNo: 581,
    DateOfPublishing: '6th December 2023',
    News: 'How India and UK can do more at COP28',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/editorials/cop28-india-uk-relations-india-energy-transition-narendra-modi-9055974/',
  },
  {
    SrNo: 582,
    DateOfPublishing: '6th December 2023',
    News: 'Rise in green energy capacity, stopping fossil fuels, reducing methane discussed for first time at COP28',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/india/rise-in-green-energy-capacity-stopping-fossil-fuels-reducing-methane-discussed-for-first-time-at-cop28-9056089/',
  },
  {
    SrNo: 583,
    DateOfPublishing: '6th December 2023',
    News: 'COP28: Gautam Adani pitches for balanced approach to energy',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/cop28-gautam-adani-pitches-for-balanced-approach-to-energy/105757265',
  },
  {
    SrNo: 584,
    DateOfPublishing: '6th December 2023',
    News: 'Climate change made 2011-2020 decade wetter and warmer for India: WMO',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/climate-change-made-2011-2020-decade-wetter-warmer-for-india-wmo-9055338/',
  },
  {
    SrNo: 585,
    DateOfPublishing: '6th December 2023',
    News: "India's per capita greenhouse gas emissions less than half of global average: Report",
    LinkName: 'Wion',
    URL: 'https://www.wionews.com/world/indias-per-capita-emissions-less-than-half-of-global-average-report-says-666469',
  },
  {
    SrNo: 586,
    DateOfPublishing: '6th December 2023',
    News: 'TERI Drives Climate Action Discourse at COP28 with Unprecedented IPCC Insight and Industry Solutions from the AR6 Report',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/business/teri-drives-climate-action-discourse-at-cop28-with-unprecedented-ipcc-insight-and-industry-solutions-from-the-ar6-report-101701780911853.html',
  },
  {
    SrNo: 587,
    DateOfPublishing: '6th December 2023',
    News: 'Fossil fuels in crosshairs of COP28: Draft GST document shows',
    LinkName: 'Deccan Herald',
    URL: 'https://www.deccanherald.com/environment/fossil-fuels-in-crosshairs-of-cop28-draft-gst-document-shows-2798120',
  },
  {
    SrNo: 588,
    DateOfPublishing: '6th December 2023',
    News: 'World carbon dioxide emissions increase again, driven by China, India and aviation',
    LinkName: 'NBC News',
    URL: 'https://www.nbcnews.com/news/world/world-carbon-dioxide-emissions-increase-driven-china-india-aviation-rcna128048',
  },
  {
    SrNo: 589,
    DateOfPublishing: '6th December 2023',
    News: 'Controversial carbon credits flood COP28, yet no rules',
    LinkName: 'Forbes',
    URL: 'https://www.forbesindia.com/article/lifes/controversial-carbon-credits-flood-cop28-yet-no-rules/90173/1',
  },
  {
    SrNo: 590,
    DateOfPublishing: '6th December 2023',
    News: 'Why India Refused to Sign the Climate & Health Declaration at COP28',
    LinkName: 'The Quint',
    URL: 'https://www.thequint.com/climate-change/cop28-india-climate-health-declaration',
  },
  {
    SrNo: 591,
    DateOfPublishing: '6th December 2023',
    News: 'Global carbon emissions from fossil fuels to rise 1.1% to hit peak in 2023',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/global-carbon-emissions-from-fossil-fuels-to-rise-1-1-to-hit-peak-in-2023-123120500092_1.html',
  },
  {
    SrNo: 592,
    DateOfPublishing: '6th December 2023',
    News: 'ADB announces $250 million loan to Support India\u2019s Drive for Power Sector Reform',
    LinkName: 'egov.elets',
    URL: 'https://egov.eletsonline.com/2023/12/adb-announces-250-million-loan-to-support-indias-drive-for-power-sector-reform/',
  },
  {
    SrNo: 593,
    DateOfPublishing: '7th December 2023',
    News: 'India building world\u2019s largest renewable energy project in salt deserts bordering Pakistan',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/india-building-world-s-largest-renewable-energy-project-in-the-salt-deserts-bordering-pakistan-11701863454397.html',
  },
  {
    SrNo: 594,
    DateOfPublishing: '7th December 2023',
    News: 'India needs high economic growth to invest in energy transition: CEA',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-needs-high-economic-growth-to-invest-in-energy-transition-cea/105796769',
  },
  {
    SrNo: 595,
    DateOfPublishing: '7th December 2023',
    News: 'Our successful listing gives justice to RE growth in India: IREDA CMD',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/interviews/our-successful-listing-gives-justice-to-re-growth-in-india-ireda-cmd-123120500690_1.html',
  },
  {
    SrNo: 596,
    DateOfPublishing: '7th December 2023',
    News: "India's green hydrogen aid receives cautious response from investors",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/india-s-green-hydrogen-aid-receives-cautious-response-from-investors-123120600929_1.html',
  },
  {
    SrNo: 597,
    DateOfPublishing: '7th December 2023',
    News: 'Carbon emissions in India to increase by 8.2% in 2023: Study',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/world/carbon-emissions-in-india-to-increase-by-8-2-in-2023-study-11866421.html',
  },
  {
    SrNo: 598,
    DateOfPublishing: '7th December 2023',
    News: 'COP28 secures USD 83 billion in climate pledges, India skips energy and health pledges',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/cop28-secures-usd-83-billion-in-climate-pledges-india-skips-energy-and-health-pledges/105796952',
  },
  {
    SrNo: 599,
    DateOfPublishing: '8th December 2023',
    News: 'OPINION: India is strategically positioned to accelerate the take-off of the hydrogen economy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/opinion-india-is-strategically-positioned-to-accelerate-the-take-off-of-the-hydrogen-economy/105818560',
  },
  {
    SrNo: 600,
    DateOfPublishing: '8th December 2023',
    News: 'Understanding India\u2019s renewable energy push through Energy Storage Systems Framework',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-renewable-energy-push-through-energy-storage-systems-framework/105819033',
  },
  {
    SrNo: 601,
    DateOfPublishing: '8th December 2023',
    News: 'Waste Management institute will be set up in Goa: CM',
    LinkName: 'Digital Goa',
    URL: 'https://digitalgoa.com/waste-management-institute-will-be-set-up-in-goa-cm/',
  },
  {
    SrNo: 602,
    DateOfPublishing: '8th December 2023',
    News: 'Climate financing: Progress made at COP28, but is it enough?',
    LinkName: 'Wion',
    URL: 'https://www.wionews.com/world/climate-financing-progress-made-at-cop28-but-is-it-enough-667272',
  },
  {
    SrNo: 603,
    DateOfPublishing: '8th December 2023',
    News: 'Climate Change is the Focus at COP28: Technology Must Be Included in the Dialogue',
    LinkName: 'SME Street',
    URL: 'https://smestreet.in/technology/climate-change-is-the-focus-at-cop28-technology-must-be-included-in-the-dialogue-1813432',
  },
  {
    SrNo: 604,
    DateOfPublishing: '8th December 2023',
    News: 'Big oil shouldn\u2019t lead the green energy transition',
    LinkName: 'Money Control',
    URL: 'https://www.moneycontrol.com/news/opinion/big-oil-shouldnt-lead-the-green-energy-transition-11869451.html',
  },
  {
    SrNo: 605,
    DateOfPublishing: '11th December 2023',
    News: "India will address EU's carbon tax issue; will retaliate if required: Piyush Goyal",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-will-address-eus-carbon-tax-issue-will-retaliate-if-required-piyush-goyal/105887949',
  },
  {
    SrNo: 606,
    DateOfPublishing: '11th December 2023',
    News: 'Govt rolls out policy for upgrading old wind turbines',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/govt-rolls-out-policy-for-upgrading-old-wind-turbines/105886885',
  },
  {
    SrNo: 607,
    DateOfPublishing: '11th December 2023',
    News: 'AP to show way in building code implementation',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/visakhapatnam/ap-to-show-way-in-building-code-implementation/articleshow/105888161.cms',
  },
  {
    SrNo: 608,
    DateOfPublishing: '11th December 2023',
    News: 'Green hydrogen outperforms blue in water efficiency: Report',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/green-hydrogen-outperforms-blue-in-water-efficiency-report/105886940',
  },
  {
    SrNo: 609,
    DateOfPublishing: '11th December 2023',
    News: 'Govt plans long-term exemption for green hydrogen projects from its manufacturers shortlist for solar panels',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/business/economy/mnre-long-term-exemption-green-hydrogen-projects-solar-pv-modules-decarbonisation-goals-9062696/',
  },
  {
    SrNo: 610,
    DateOfPublishing: '11th December 2023',
    News: 'Tech transfer, finance for energy transition still a challenge: Min Yadav',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/tech-transfer-finance-for-energy-transition-still-a-challenge-min-yadav-123121000826_1.html',
  },
  {
    SrNo: 611,
    DateOfPublishing: '11th December 2023',
    News: 'ONGC plans to invest up to Rs 35,000 cr for 5 GW solar, wind energy capacity by 2030',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ongc-plans-to-invest-up-to-rs-35000-cr-for-5-gw-solar-wind-energy-capacity-by-2030/105866483',
  },
  {
    SrNo: 612,
    DateOfPublishing: '11th December 2023',
    News: 'COP28: CDRI unveils groundbreaking GIRI platform; will enhance national resilience through strategic infrastructure planning',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/sustainability-cop28-cdri-unveils-groundbreaking-giri-platform-will-enhance-national-resilience-through-strategic-infrastructure-planning-3334808/',
  },
  {
    SrNo: 613,
    DateOfPublishing: '12th December 2023',
    News: 'Keppel, AM Green sign MoU to develop sustainable fuels, aiming at decarbonization in Southeast Asia and Middle East',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/keppel-am-green-sign-mou-to-develop-sustainable-fuels-aiming-at-decarbonization-in-southeast-asia-and-middle-east/105918508',
  },
  {
    SrNo: 614,
    DateOfPublishing: '12th December 2023',
    News: 'Green protectionism comes with big risks',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/specials/green-protectionism-comes-with-big-risks-11702283729325.html',
  },
  {
    SrNo: 615,
    DateOfPublishing: '12th December 2023',
    News: 'Keppel, AM Green sign MoU to develop sustainable fuels, aiming at decarbonization in Southeast Asia and Middle East',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/keppel-am-green-sign-mou-to-develop-sustainable-fuels-aiming-at-decarbonization-in-southeast-asia-and-middle-east/105918508',
  },
  {
    SrNo: 616,
    DateOfPublishing: '12th December 2023',
    News: 'India is committed to the Goal of Net Zero by 2070 through Sustainable Development and Decarbonisation \u2013 Rajnath Ram, Advisor \u2013 Energy, NITI Aayog',
    LinkName: 'CSR Journal',
    URL: 'https://thecsrjournal.in/corporate-social-responsibility-csr-news-india-is-committed-to-the-goal-of-net-zero-by-2070-through-sustainable-development-and-decarbonisation-rajnath-ram-advisor-energy-niti-aayog/',
  },
  {
    SrNo: 617,
    DateOfPublishing: '12th December 2023',
    News: 'Carbon footprint of richest in developing countries same as low earners in developed countries, shows report',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/news/environment/carbon-footprint-of-richest-in-developing-countries-same-as-low-earners-in-developed-countries-shows-report-93316',
  },
  {
    SrNo: 618,
    DateOfPublishing: '12th December 2023',
    News: 'Climate change has significant, direct impact on global labour market: ILO',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/climate-change-has-significant-direct-impact-on-global-labour-market-ilo-123121100499_1.html',
  },
  {
    SrNo: 619,
    DateOfPublishing: '12th December 2023',
    News: 'Why climate efforts fail to cut emissions',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/opinion/columns/why-climate-efforts-fail-to-cut-emissions-123121101274_1.html',
  },
  {
    SrNo: 620,
    DateOfPublishing: '13th December 2023',
    News: 'ONGC intends to have separate business entity for green energy projects',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ongc-plans-to-have-separate-business-entity-for-green-energy-projects/105938280',
  },
  {
    SrNo: 621,
    DateOfPublishing: '13th December 2023',
    News: 'India records 8.9% increase in electricity production, peak demand reaches 243 MW',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/india-records-8-9-increase-in-electricity-production-peak-demand-reaches-243-mw/105945994',
  },
  {
    SrNo: 622,
    DateOfPublishing: '13th December 2023',
    News: "India's solar power capacity hits 72.02 GW, govt advances solar park projects",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-solar-power-capacity-hits-72-02-gw-govt-advances-solar-park-projects/105945970',
  },
  {
    SrNo: 623,
    DateOfPublishing: '13th December 2023',
    News: 'India\u2019s refined copper demand to grow by 11% in FY24 on govt\u2019s infra development plans, transition to renewable energy',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-indias-refined-copper-demand-to-grow-by-11-in-fy24-on-govts-infra-development-plans-transition-to-renewable-energy-3337087/',
  },
  {
    SrNo: 624,
    DateOfPublishing: '13th December 2023',
    News: 'President to felicitate winners of National Energy Conservation, Energy Efficiency Innovation awards',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/president-to-felicitate-winners-of-national-energy-conservation-energy-efficiency-innovation-awards/105946091',
  },
  {
    SrNo: 625,
    DateOfPublishing: '14th December 2023',
    News: 'India, France in talks for increased cooperation on small hydro projects',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india-france-in-talks-for-increased-cooperation-on-small-hydro-projects-11702469399004.html',
  },
  {
    SrNo: 626,
    DateOfPublishing: '14th December 2023',
    News: 'Climate resilient agriculture project shows progress in Maha',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/pune-news/climate-resilient-agriculture-project-shows-progress-in-maha-101702492447258.html',
  },
  {
    SrNo: 627,
    DateOfPublishing: '14th December 2023',
    News: 'Export-oriented green hydrogen projects may get fiscal support, multi-locational SEZs on the table',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/business/export-oriented-green-hydrogen-projects-may-get-fiscal-support-multi-locational-sezs-on-the-table-9067384/',
  },
  {
    SrNo: 628,
    DateOfPublishing: '14th December 2023',
    News: 'India surpasses 72.02 GW in solar power capacity, driving renewable energy transition',
    LinkName: 'DD News',
    URL: 'https://ddnews.gov.in/national/india-surpasses-7202-gw-solar-power-capacity-driving-renewable-energy-transition',
  },
  {
    SrNo: 629,
    DateOfPublishing: '14th December 2023',
    News: 'More than a third of power consumed in Delhi is green',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/delhi/more-than-a-third-of-power-consumed-in-delhi-is-green/articleshow/105972288.cms',
  },
  {
    SrNo: 630,
    DateOfPublishing: '15th December 2023',
    News: 'Fossil fuel-based energy also essential for India: President',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/fossil-fuel-based-energy-also-essential-for-india-president/106006054',
  },
  {
    SrNo: 631,
    DateOfPublishing: '15th December 2023',
    News: 'India to slash coal power capacity to 33% by 2030: Minister RK Singh',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/coal/india-to-slash-coal-power-capacity-to-33-by-2030-minister-rk-singh/106005706',
  },
  {
    SrNo: 632,
    DateOfPublishing: '15th December 2023',
    News: 'India to focus on battery storage for net zero goal: RK Singh',
    LinkName: 'CNBC TV18',
    URL: 'https://www.cnbctv18.com/environment/india-carbon-reduction-focus-on-battery-storage-for-net-zero-goal-power-minister-rk-singh-18573381.htm',
  },
  {
    SrNo: 633,
    DateOfPublishing: '15th December 2023',
    News: 'Consensus calls for urgent action based on national circumstances: Environment Minister Bhupender Yadav',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/htinterview-consensus-calls-for-urgent-action-based-on-national-circumstances-says-yadav-101702582761687.html',
  },
  {
    SrNo: 634,
    DateOfPublishing: '15th December 2023',
    News: 'NTPC eyeing merchant market as key revenue source for renewable energy',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/ntpc-eyeing-merchant-market-as-key-revenue-source-for-renewable-energy-11906541.html',
  },
  {
    SrNo: 635,
    DateOfPublishing: '15th December 2023',
    News: 'Punjab lags behind HP, Hry in treating sewage',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/chandigarh/punjab-lags-behind-hp-hry-in-treating-sewage/articleshow/106007441.cms',
  },
  {
    SrNo: 636,
    DateOfPublishing: '15th December 2023',
    News: 'ADB\u2019s USD 200 million boost for clean India: Loan to enhance waste management and sanitation across 100 cities',
    LinkName: 'The Print',
    URL: 'https://theprint.in/economy/adbs-usd-200-million-boost-for-clean-india-loan-to-enhance-waste-management-and-sanitation-across-100-cities/1885599/',
  },
  {
    SrNo: 637,
    DateOfPublishing: '15th December 2023',
    News: 'Chandigarh falling short in solar power generation goal',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/chandigarh-news/chandigarh-falling-short-in-solar-power-generation-goal-101702591165544.html',
  },
  {
    SrNo: 638,
    DateOfPublishing: '15th December 2023',
    News: 'Chandigarh emerges on the top in State Energy Efficiency Index 2023',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/cities/chandigarh-news/chandigarh-emerges-on-the-top-in-state-energy-efficiency-index-2023-101702592670761.html',
  },
  {
    SrNo: 639,
    DateOfPublishing: '18th December 2023',
    News: 'India boosts power generation capacity significantly over decade, aims for sizable renewable energy expansion',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-boosts-power-generation-capacity-significantly-over-decade-aims-for-sizable-renewable-energy-expansion/106073708',
  },
  {
    SrNo: 640,
    DateOfPublishing: '18th December 2023',
    News: 'Bihar invites bid for 185 MW Solar Plant, 254 MW battery storage in renewable push',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/bihar-invites-bid-for-185-mw-solar-plant-254-mw-battery-storage-in-renewable-push/106074057',
  },
  {
    SrNo: 641,
    DateOfPublishing: '18th December 2023',
    News: 'ONGC planning to build 2 MTPA Green Ammonia capacity by 2035: Head - New Energy',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/we-plan-to-build-2-mmtpa-green-ammonia-capacity-by-2035-harsh-nupur-joshi-head-new-energy-ongc/106051553',
  },
  {
    SrNo: 642,
    DateOfPublishing: '18th December 2023',
    News: '2,380 high-yielding crop varieties introduced in 9 years due to climate change',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/dec/18/2380-high-yielding-crop-varieties-introduced-in-9-years-due-to-climate-change-2642542.html',
  },
  {
    SrNo: 643,
    DateOfPublishing: '18th December 2023',
    News: 'Pre-treatment unit for biomethanation plant at Panaji soon',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/goa/pre-treatment-unit-for-biomethanation-plant-at-panaji-soon/articleshow/106068942.cms',
  },
  {
    SrNo: 644,
    DateOfPublishing: '19th December 2023',
    News: 'Hydropower mega-merger of NHPC, two others put on hold',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/hydropower-mega-merger-of-nhpc-two-others-put-on-hold-11702925845381.html',
  },
  {
    SrNo: 645,
    DateOfPublishing: '19th December 2023',
    News: 'State-run banks lead the way on green drive',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/banking/banks-take-consultants-on-board-in-green-journey-11702926174065.html',
  },
  {
    SrNo: 646,
    DateOfPublishing: '19th December 2023',
    News: 'NABARD And ADB Collaborate For Climate Resilience In Indian Agriculture, Natural Resources, And Rural Development Sector',
    LinkName: 'India Education Diary',
    URL: 'https://indiaeducationdiary.in/nabard-and-adb-collaborate-for-climate-resilience-in-indian-agriculture-natural-resources-and-rural-development-sector/',
  },
  {
    SrNo: 647,
    DateOfPublishing: '19th December 2023',
    News: 'No study done to quantify contribution of climate change to natural disasters in India says govt',
    LinkName: 'The Week',
    URL: 'https://www.theweek.in/wire-updates/national/2023/12/18/del80-lsq-climate-change-attribution.html',
  },
  {
    SrNo: 648,
    DateOfPublishing: '20th December 2023',
    News: 'NTPC surpasses 300 billion units power generation mark in FY24',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/ntpc-surpasses-300-billion-units-power-generation-mark-in-fy24/106138665',
  },
  {
    SrNo: 649,
    DateOfPublishing: '20th December 2023',
    News: 'India attracts $6.1 billion FDI in renewable sector, boosting green power drive',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-attracts-6-1-billion-fdi-in-renewable-sector-boosting-green-power-drive/106138553',
  },
  {
    SrNo: 650,
    DateOfPublishing: '20th December 2023',
    News: 'India will continue to rely on coal power until it becomes developed country: Environment Minister',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/coal/india-will-continue-to-rely-on-coal-power-until-it-becomes-developed-country-environment-minister/106117435',
  },
  {
    SrNo: 651,
    DateOfPublishing: '20th December 2023',
    News: 'India resisted pressure from developed nations for phase out of fossil fuels: Environment minister',
    LinkName: 'ET Auto',
    URL: 'India%20resisted%20pressure%20from%20developed%20nations%20for%20phase%20out%20of%20fossil%20fuels:%20Environment%20minister',
  },
  {
    SrNo: 652,
    DateOfPublishing: '20th December 2023',
    News: 'Goa Sets Target To Achieve Zero Carbon Emissions Tag Before 2050',
    LinkName: 'National News Portal',
    URL: 'https://tennews.in/goa-sets-target-to-achieve-zero-carbon-emissions-tag-before-2050/',
  },
  {
    SrNo: 653,
    DateOfPublishing: '20th December 2023',
    News: 'TN govt gives in-principle nod to start 12 hydroelectric projects',
    LinkName: 'DT Next',
    URL: 'https://www.dtnext.in/news/tamilnadu/tn-govt-gives-in-principle-nod-to-start-12-hydroelectric-projects-755563',
  },
  {
    SrNo: 654,
    DateOfPublishing: '20th December 2023',
    News: 'HP to amend energy policy: Sukhu',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/shimla/hp-to-amend-energy-policy-sukhu/articleshow/106139895.cms',
  },
  {
    SrNo: 655,
    DateOfPublishing: '21st December 2023',
    News: 'Fact of the matter: Climate change redistributes terrestrial ecosystems globally',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/xplore/2023/dec/20/fact-of-the-matter-climate-change-redistributes-terrestrial-ecosystems-globally-2643111.html',
  },
  {
    SrNo: 656,
    DateOfPublishing: '21st December 2023',
    News: 'Future with AI: Artificial intelligence will be a weapon in the fight against climate change',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/environment/future-with-ai-fight-against-climate-change-11931871.html',
  },
  {
    SrNo: 657,
    DateOfPublishing: '21st December 2023',
    News: 'A multi-trillion dollar showdown over energy\u2019s future',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/a-multi-trillion-dollar-showdown-over-energys-future/106149641',
  },
  {
    SrNo: 658,
    DateOfPublishing: '21st December 2023',
    News: 'Indian railways set to become net-zero carbon emitter by 2030: Vaishnaw',
    LinkName: 'Republicworld',
    URL: 'https://www.republicworld.com/economy/infrastructure/indian-railways-set-to-become-net-zero-carbon-emitter-by-2030-vaishnaw/',
  },
  {
    SrNo: 659,
    DateOfPublishing: '22nd December 2023',
    News: "CEA reiterates India's stand for more climate funds from developed nations",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/cea-reiterates-india-s-stand-for-more-climate-funds-from-developed-nations-123122101169_1.html',
  },
  {
    SrNo: 660,
    DateOfPublishing: '22nd December 2023',
    News: 'India may compensate exporters against UK, EU carbon tax',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/business/india-compensate-exporters-uk-eu-carbon-tax-9077978/',
  },
  {
    SrNo: 661,
    DateOfPublishing: '22nd December 2023',
    News: 'What NTPC, GAIL and other govt entities doing under Green Hydrogen Mission',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/what-ntpc-gail-and-other-govt-entities-doing-under-green-hydrogen-mission/106196741',
  },
  {
    SrNo: 662,
    DateOfPublishing: '22nd December 2023',
    News: 'IGX to start CBG certificate trading, eyes cross-border gas expansion',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/igx-to-start-cbg-certificate-trading-eyes-cross-border-gas-expansion/106198763',
  },
  {
    SrNo: 663,
    DateOfPublishing: '22nd December 2023',
    News: 'SBI to sign 200 mn euro LoC with EIB for climate action projects',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/business/sbi-to-sign-200-mln-euro-loc-with-eib-for-climate-action-projects-11937971.html',
  },
  {
    SrNo: 664,
    DateOfPublishing: '22nd December 2023',
    News: 'JICA extends \u20b9932 crore loan to India to implement Sustainable Development Goals',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/jica-extends-rs-932-crore-loan-to-india-to-implement-sustainable-development-goals-11703166185921.html',
  },
  {
    SrNo: 665,
    DateOfPublishing: '22nd December 2023',
    News: "Union Minister Bhupender Yadav Unveils NITI Aayog's Report on Green and Sustainable Growth",
    LinkName: 'SME Street',
    URL: 'https://smestreet.in/limelight/union-minister-bhupender-yadav-unveils-niti-aayogs-report-on-green-and-sustainable-growth-2034738',
  },
  {
    SrNo: 666,
    DateOfPublishing: '22nd December 2023',
    News: 'States fined Rs 79,098 crore over solid waste management rules non-compliance: Government',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2023/dec/21/states-fined-rs-79098-croreover-solid-waste-management-rules-non-compliance-government-2643675.html',
  },
  {
    SrNo: 667,
    DateOfPublishing: '22nd December 2023',
    News: 'Nod to \u20b91,079cr investments for green energy',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/bhubaneswar/investment-approval-for-green-energy-projects-in-bhubaneswar/articleshow/106197722.cms',
  },
  {
    SrNo: 668,
    DateOfPublishing: '2nd January 2024',
    News: 'Govt plans greener use of coal, mines as production surges',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/govt-plans-renewable-energy-projects-in-closed-coal-mines-11704089204588.html',
  },
  {
    SrNo: 669,
    DateOfPublishing: '2nd January 2024',
    News: 'Jakson Green to ship first green hydrogen electrolyser in few months',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/jakson-green-to-ship-first-green-hydrogen-electrolyser-in-few-months/106388870',
  },
  {
    SrNo: 670,
    DateOfPublishing: '2nd January 2024',
    News: 'Climate change may lead to more systems in Arabian Sea, says IMD',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/karnataka/2024/jan/02/climate-change-may-lead-to-more-systems-in-arabian-sea-says-imd-2646973.html',
  },
  {
    SrNo: 671,
    DateOfPublishing: '2nd January 2024',
    News: "Humanity's progress may be in peril as climate change sweeps in",
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/columns/humanitys-progress-may-be-in-peril-as-climate-change-sweeps-in-11704040520040.html',
  },
  {
    SrNo: 672,
    DateOfPublishing: '2nd January 2024',
    News: 'Boost domestic radar capability to fight climate change',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/opinions/editorials/2024/jan/02/boost-domestic-radar-capability-to-fight-climate-change-2646875.html',
  },
  {
    SrNo: 673,
    DateOfPublishing: '2nd January 2024',
    News: 'COP28: A crossroads for India and the climate crisis',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/cop28-a-crossroads-for-india-and-the-climate-crisis-101703656361357.html',
  },
  {
    SrNo: 674,
    DateOfPublishing: '2nd January 2024',
    News: 'Power Grid Corp bags inter-state power transmission project',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/power-grid-corp-bags-inter-state-power-transmission-project/106463604',
  },
  {
    SrNo: 675,
    DateOfPublishing: '3rd January 2024',
    News: 'Agriculture: \u2018Changing Waters\u2019 And The Sustainable Transition',
    LinkName: 'State Times',
    URL: 'https://statetimes.in/agriculture-changing-waters-and-the-sustainable-transition/',
  },
  {
    SrNo: 676,
    DateOfPublishing: '3rd January 2024',
    News: 'Reinventing climate action: Crucial to co-create with communities from India\u2019s tropics',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/blog/climate-change/reinventing-climate-action-crucial-to-co-create-with-communities-from-india-s-tropics-93646',
  },
  {
    SrNo: 677,
    DateOfPublishing: '3rd January 2024',
    News: 'IndiGrid secures major transmission projects, set to invest over Rs 1,000 crore',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/indigrid-secures-major-transmission-projects-set-to-invest-over-rs-1000-crore/106498609',
  },
  {
    SrNo: 678,
    DateOfPublishing: '3rd January 2024',
    News: 'Tripura eyes power generation from non-conventional sources',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/agartala/tripura-eyes-power-generation-from-non-conventional-sources/articleshow/106500319.cms',
  },
  {
    SrNo: 679,
    DateOfPublishing: '3rd January 2024',
    News: 'SC stays memo allowing post facto green nod for projects',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/delhi/sc-stays-memo-allowing-post-facto-green-nod-for-projects/articleshow/106498621.cms',
  },
  {
    SrNo: 680,
    DateOfPublishing: '4th January 2024',
    News: 'Agriculture: \u2018Changing Waters\u2019 and The Sustainable Transition',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/pngrb-world-bank-team-up-for-study-on-hydrogen-transmission-in-natural-gas-networks/106515042',
  },
  {
    SrNo: 681,
    DateOfPublishing: '4th January 2024',
    News: 'Torrent Power signs 4 MoUs with the Government of Gujarat to invest Rs 47,350 crores',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/energy/power/torrent-power-signs-4-mous-with-the-government-of-gujarat-to-invest-rs-47350-crores/articleshow/106523462.cms?from=mdr',
  },
  {
    SrNo: 682,
    DateOfPublishing: '4th January 2024',
    News: 'Sustainable Energy Goals Require Public Investment in Emerging Technologies, BCG Chair Says',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/technology/sustainable-energy-goals-require-public-investment-in-emerging-technologies-bcg-chair-says-11704288880205.html',
  },
  {
    SrNo: 683,
    DateOfPublishing: '4th January 2024',
    News: 'Gujarat signs deal worth \u20b97.17 trillion with 58 companies ahead of Vibrant Gujarat Global Summit',
    LinkName: 'India Shipping News',
    URL: 'https://indiashippingnews.com/gujarat-signs-deal-worth-%E2%82%B97-17-trillion-with-58-companies-ahead-of-vibrant-gujarat-global-summit/',
  },
  {
    SrNo: 684,
    DateOfPublishing: '4th January 2024',
    News: 'Diversified Crop Rotations: A Viable Strategy for Sustainable Agriculture and Climate Change Mitigation',
    LinkName: 'BNN Breaking',
    URL: 'https://bnnbreaking.com/breaking-news/climate-environment/diversified-crop-rotations-a-viable-strategy-for-sustainable-agriculture-and-climate-change-mitigation/',
  },
  {
    SrNo: 685,
    DateOfPublishing: '5th January 2024',
    News: 'Erisha E mobility inks Rs 6,900 crore mou with Gujarat government for green hydrogen and mega EV park',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/erisha-e-mobility-inks-rs-6900-crore-mou-with-gujarat-government-for-green-hydrogen-and-mega-ev-park/106546987',
  },
  {
    SrNo: 686,
    DateOfPublishing: '5th January 2024',
    News: 'Establish biogas units, farm ponds in every field: Expert',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/hubballi/establish-biogas-units-and-farm-ponds-for-climate-resilient-farming-icar-national-academy-of-agricultural-research-management/articleshow/106557818.cms',
  },
  {
    SrNo: 687,
    DateOfPublishing: '5th January 2024',
    News: 'Erisha E mobility inks Rs 6,900 crore mou with Gujarat government for green hydrogen and mega EV park',
    LinkName: 'ET Energyworld',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/erisha-e-mobility-inks-inr-6900-cr-mou-with-gujarat-government-for-green-hydrogen-and-mega-ev-park/106561337',
  },
  {
    SrNo: 688,
    DateOfPublishing: '5th January 2024',
    News: 'Climate agenda on table for three-daytown planners\u2019 meet',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/lucknow/climate-agenda-on-table-for-three-day-town-planners-meet/articleshow/106561000.cms',
  },
  {
    SrNo: 689,
    DateOfPublishing: '5th January 2024',
    News: 'NHPC and Gujarat govt sign MoU for \u20b94,000 crore Investment in Kuppa Hydro project',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/nhpc-and-gujarat-govt-sign-mou-for-4000-crore-investment-in-kuppa-hydro-project/106557559',
  },
  {
    SrNo: 690,
    DateOfPublishing: '5th January 2024',
    News: 'SBI raises $250 mn via Green Bonds to fund sustainable projects',
    LinkName: 'Moneycontrol',
    URL: 'https://www.moneycontrol.com/news/business/sbi-raises-250-mn-via-green-bonds-to-fund-sustainable-projects-12001091.html',
  },
  {
    SrNo: 691,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 692,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 693,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 694,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 695,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 696,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 697,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 698,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 699,
    DateOfPublishing: null,
    News: null,
    LinkName: null,
    URL: null,
  },
  {
    SrNo: 700,
    DateOfPublishing: '7th June 2024',
    News: 'Steel ministry ropes in consultant to explore use of green hydrogen in manufacturing',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/business/steel-ministry-ropes-in-consultant-to-explore-use-of-green-hydrogen-in-manufacturing-12742282.html',
  },
  {
    SrNo: 701,
    DateOfPublishing: '7th June 2024',
    News: 'Singapore group to export green ammonia from a plant in India to Japan',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-clean-energy-vanguard-the-onset-of-sustainable-economic-growth-through-renewable-energy/110735426',
  },
  {
    SrNo: 702,
    DateOfPublishing: '7th June 2024',
    News: 'Climate conference in November to emphasise \u2018peace\u2019 and \u2018truce\u2019',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/sci-tech/energy-and-environment/climate-conference-in-november-to-emphasise-peace-and-truce/article68259858.ece',
  },
  {
    SrNo: 703,
    DateOfPublishing: '7th June 2024',
    News: 'Mumbai Civic Body Introduces Its First Climate Budget Report',
    LinkName: 'NDTV',
    URL: 'https://www.ndtv.com/mumbai-news/mumbai-civic-body-bmc-introduces-its-first-climate-budget-report-5834336',
  },
  {
    SrNo: 704,
    DateOfPublishing: '7th June 2024',
    News: 'Climate change could raise ozone levels, cause serious respiratory issues, study finds',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/climate-change-could-raise-ozone-levels-cause-serious-respiratory-issues-study-finds-9376455/',
  },
  {
    SrNo: 705,
    DateOfPublishing: '10th June 2024',
    News: 'Highway to \u2018climate hell\u2019',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/explained/explained-climate/highway-to-climate-hell-9382226/',
  },
  {
    SrNo: 706,
    DateOfPublishing: '10th June 2024',
    News: 'Scorching Inequity: How Climate Change Disproportionately Affects Women in India',
    LinkName: 'The Quint',
    URL: 'https://www.thequint.com/climate-change/heatwave-climate-change-impact-indian-women-health',
  },
  {
    SrNo: 707,
    DateOfPublishing: '10th June 2024',
    News: 'India is committed to reduce emission intensity of GDP by 45% by 2030: Minister',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/india-is-committed-to-reduce-emission-intensity-of-gdp-by-45-by-2030-minister-11675782711875.html',
  },
  {
    SrNo: 708,
    DateOfPublishing: '10th June 2024',
    News: 'India may call bids for setting up green hydrogen plants',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/industry/energy/india-may-call-bids-for-setting-up-green-hydrogen-plants-11604222111290.html',
  },
  {
    SrNo: 709,
    DateOfPublishing: '10th June 2024',
    News: "Here's an idea: a worldwide universal basic income paid for by a carbon tax. A study found that it could boost the global GDP by 130%.",
    LinkName: 'Business Insider',
    URL: 'https://www.businessinsider.in/policy/economy/news/heres-an-idea-a-worldwide-universal-basic-income-paid-for-by-a-carbon-tax-a-study-found-that-it-could-boost-the-global-gdp-by-130-/amp_articleshow/110850487.cms',
  },
  {
    SrNo: 710,
    DateOfPublishing: '11th June 2024',
    News: 'Climate change-fuelled extreme weather events cost at least $41 billion globally since COP28',
    LinkName: 'Deccan Herald',
    URL: 'https://www.deccanherald.com/environment/climate-change-fuelled-extreme-weather-events-cost-at-least-41-billion-globally-since-cop28-3061048',
  },
  {
    SrNo: 711,
    DateOfPublishing: '11th June 2024',
    News: 'A push for more climate action',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/opinion/op-ed/a-push-for-more-climate-action/article68274342.ece',
  },
  {
    SrNo: 712,
    DateOfPublishing: '11th June 2024',
    News: 'At Bonn, talks hit climate finance roadblock ahead of Baku summit',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/environment/at-bonn-talks-hit-climate-finance-roadblock-ahead-of-baku-summit-101718077524400.html',
  },
  {
    SrNo: 713,
    DateOfPublishing: '11th June 2024',
    News: 'Green chulhas to be on frontline of state\u2019s fight against pollution, climate change',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/kolkata/green-chulhas-to-be-on-frontline-of-states-fight-against-pollution-climate-change/articleshow/110885699.cms',
  },
  {
    SrNo: 714,
    DateOfPublishing: '11th June 2024',
    News: 'Bengal govt to finalise state action plan for tackling climate change',
    LinkName: 'Millenniumpost',
    URL: 'https://www.millenniumpost.in/bengal/bengal-govt-to-finalise-state-action-plan-for-tackling-climate-change-567390',
  },
  {
    SrNo: 715,
    DateOfPublishing: '11th June 2024',
    News: 'Gartner Survey Reveals 69% of CEOs View Sustainability as a Growth Opportunity',
    LinkName: 'India Technology News',
    URL: 'https://indiatechnologynews.in/gartner-survey-reveals-69-of-ceos-view-sustainability-as-a-growth-opportunity/',
  },
  {
    SrNo: 716,
    DateOfPublishing: '11th June 2024',
    News: 'Candi Solar raises $38 million in Series C funding to expand clean energy solutions',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/candi-solar-raises-38-million-in-series-c-funding-to-expand-clean-energy-solutions/110870351?utm_source=latest_news&utm_medium=homepage',
  },
  {
    SrNo: 717,
    DateOfPublishing: '11th June 2024',
    News: 'Global temperatures surge: Urgent warning signals from climate agencies',
    LinkName: 'Millenniumpost',
    URL: 'https://www.millenniumpost.in/big-stories/global-temperatures-surge-urgent-warning-signals-from-climate-agencies-567306',
  },
  {
    SrNo: 718,
    DateOfPublishing: '11th June 2024',
    News: 'JSW Energy forays into energy storage; setting up India\u2019s largest commercial-scale green hydrogen project',
    LinkName: 'ET Enerygyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/jsw-energy-forays-into-energy-storage-setting-up-indias-largest-commercial-scale-green-hydrogen-project/110871173',
  },
  {
    SrNo: 719,
    DateOfPublishing: '12th June 2024',
    News: 'No breakthrough on finance so far at mid-year UN climate talks',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/no-breakthrough-on-finance-so-far-at-mid-year-un-climate-talks-9386176/',
  },
  {
    SrNo: 720,
    DateOfPublishing: '12th June 2024',
    News: 'State draws up plan to save Sundarbans from climate crisis',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/kolkata/state-draws-up-plan-to-save-sundarbans-from-climate-crisis/articleshow/110922409.cms',
  },
  {
    SrNo: 721,
    DateOfPublishing: '12th June 2024',
    News: 'Americans suffer disasters worth 11 billion-dollar disasters due to weather-related incidents',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/international/us/americans-suffer-disasters-worth-11-billion-dollar-disasters-due-to-weather-related-incidents/articleshow/110921086.cms?from=mdr',
  },
  {
    SrNo: 722,
    DateOfPublishing: '12th June 2024',
    News: 'Shri Bhupender Yadav takes charge as the Union Minister of Environment, Forest and Climate Change',
    LinkName: 'PIB',
    URL: 'https://pib.gov.in/PressReleaseIframePage.aspx?PRID=2024163',
  },
  {
    SrNo: 723,
    DateOfPublishing: '12th June 2024',
    News: 'Boosting oil & gas production, green H2 promotion on top of agenda',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/politics-and-nation/boosting-oil-gas-production-green-h2-promotion-on-top-of-agenda/articleshow/110919725.cms?from=mdr',
  },
  {
    SrNo: 724,
    DateOfPublishing: '13th June 2024',
    News: 'Ozone-harming gas declining faster than expected: study',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/amp/news/oil-and-gas/ozone-harming-gas-declining-faster-than-expected-study/110930237',
  },
  {
    SrNo: 725,
    DateOfPublishing: '13th June 2024',
    News: "Urbanisation's influence on climate change increasing in recent decades, study finds",
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/india/urbanisations-influence-on-climate-change-increasing-in-recent-decades-study-finds/articleshow/110937252.cms?from=mdr',
  },
  {
    SrNo: 726,
    DateOfPublishing: '13th June 2024',
    News: 'India at 176 among 180 countries in Environment Performance Index 2024, high emissions flagged again',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/india-ranks-5th-from-bottom-in-environment-performance-index-2024-high-emissions-flagged-again/2128391/',
  },
  {
    SrNo: 727,
    DateOfPublishing: '13th June 2024',
    News: 'German industry, policymakers urge patience on hydrogen conversion ',
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/german-industry-policymakers-urge-patience-on-hydrogen-conversion/110955768',
  },
  {
    SrNo: 728,
    DateOfPublishing: '13th June 2024',
    News: 'Aviation industry calls for more funding for synthetic green fuels',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/oil-and-gas/aviation-industry-calls-for-more-funding-for-synthetic-green-fuels/110938406',
  },
  {
    SrNo: 729,
    DateOfPublishing: '14th June 2024',
    News: 'How to phase out fossil fuels without leaving anyone behind',
    LinkName: 'Frontline',
    URL: 'https://frontline.thehindu.com/news/bonn-climate-talks-un-climate-change-lse-cop29-marginalised-groups-workforce/article68284817.ece',
  },
  {
    SrNo: 730,
    DateOfPublishing: '14th June 2024',
    News: 'Climate change taking its toll on wildlife too',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/states/karnataka/2024/Jun/14/climate-change-taking-its-toll-on-wildlife-too',
  },
  {
    SrNo: 731,
    DateOfPublishing: '14th June 2024',
    News: 'A fork in the road: Climate change caused main route of Silk Road to shift, finds study',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/news/world/a-fork-in-the-road-climate-change-caused-main-route-of-silk-road-to-shift-finds-study-96684',
  },
  {
    SrNo: 732,
    DateOfPublishing: '14th June 2024',
    News: 'Climate change funding talks stuck ahead of COP29 summit',
    LinkName: 'The Print',
    URL: 'https://theprint.in/world/climate-change-funding-talks-stuck-ahead-of-cop29-summit/2130709/',
  },
  {
    SrNo: 733,
    DateOfPublishing: '17th June 2024',
    News: 'World falling behind on environment, health and hunger goals, UN report says',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/world/rest-of-world/world-falling-behind-on-environment-health-and-hunger-goals-un-report-says/articleshow/111049073.cms',
  },
  {
    SrNo: 734,
    DateOfPublishing: '17th June 2024',
    News: 'India among world\u2019s worst five on green performance index',
    LinkName: 'Deccan Herald',
    URL: 'https://www.deccanherald.com/india/india-among-the-worlds-worst-five-on-green-performance-index-3068870',
  },
  {
    SrNo: 735,
    DateOfPublishing: '17th June 2024',
    News: 'Amount of $736.4 million to be disbursed at 67th council meet of Global Environment Facility at Washington',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/news/wildlife-and-biodiversity/amount-of-736-4-million-to-be-disbursed-at-67th-council-meet-of-global-environment-facility-at-washington-96720',
  },
  {
    SrNo: 736,
    DateOfPublishing: '17th June 2024',
    News: 'In West Bengal, how climate change is threatening a beloved, delicate heritage mango',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/kolkata/west-bengal-climate-change-threat-beloved-delicate-heritage-mango-9393943/',
  },
  {
    SrNo: 737,
    DateOfPublishing: '17th June 2024',
    News: '45% households in Alappuzha vulnerable to impacts of climate change',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/news/national/kerala/45-households-in-alappuzha-vulnerable-to-impacts-of-climate-change/article68296962.ece',
  },
  {
    SrNo: 738,
    DateOfPublishing: '18th June 2024',
    News: 'If the rates stay higher for longer, then the US will need a Carbon Tax',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/opinion/if-the-rates-stay-higher-for-longer-then-the-us-will-need-a-carbon-tax-12750147.html',
  },
  {
    SrNo: 739,
    DateOfPublishing: '18th June 2024',
    News: 'US, India to launch NASA-ISRO Synthetic Aperture Radar to combat climate change: White House',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/us-india-to-launch-nasa-isro-synthetic-aperture-radar-to-combat-climate-change-white-house-11718673722966.html',
  },
  {
    SrNo: 740,
    DateOfPublishing: '18th June 2024',
    News: 'Landmark EU nature restoration plan gets final approval as bloc gives the green light',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/landmark-eu-nature-restoration-plan-final-approval-bloc-gives-green-light-9397434/',
  },
  {
    SrNo: 741,
    DateOfPublishing: '18th June 2024',
    News: 'Adani gets environmental nod for Rs 45,000 cr Mundra port expansion',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/news/adani-gets-environmental-nod-for-rs-45000-cr-mundra-port-expansion-124061700305_1.html',
  },
  {
    SrNo: 742,
    DateOfPublishing: '18th June 2024',
    News: 'SBI steps up climate risk tracking after RBI nudge',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/business/india-business/sbi-steps-up-climate-risk-tracking-after-rbi-nudge/articleshow/111067161.cms',
  },
  {
    SrNo: 743,
    DateOfPublishing: '19th June 2024',
    News: 'RBI exploring appropriate coverage for green deposits; climate-change big risk to global economy, says Patra',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/business/rbi-exploring-appropriate-coverage-for-green-deposits-climate-change-big-risk-to-global-economy-says-patra-12751192.html',
  },
  {
    SrNo: 744,
    DateOfPublishing: '19th June 2024',
    News: 'Treating heatwaves as health care emergency',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/opinion/treating-heatwaves-as-health-care-emergency-101718722148684.html',
  },
  {
    SrNo: 745,
    DateOfPublishing: '19th June 2024',
    News: 'Sweltering heat wave broils swathes of north, east India; high night temps add to discomfort',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/delhi/sweltering-heat-wave-north-east-india-high-night-temps-discomfort-9400319/',
  },
  {
    SrNo: 746,
    DateOfPublishing: '19th June 2024',
    News: 'Strategic imperative and environment concern in Great Nicobar project',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/explained/great-nicobar-project-concern-9400418/',
  },
  {
    SrNo: 747,
    DateOfPublishing: '19th June 2024',
    News: 'Gaza conflict has caused major environmental damage, says UN',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/world/gaza-conflict-has-caused-major-environmental-damage-says-un-12751303.html',
  },
  {
    SrNo: 748,
    DateOfPublishing: '20th June 2024',
    News: 'Adani Group To Invest Over $100 Billion In Green Energy Transition',
    LinkName: 'Outlook India',
    URL: 'https://planet.outlookindia.com/news/adani-group-to-invest-over-100-billion-in-green-energy-transition-news-417973',
  },
  {
    SrNo: 749,
    DateOfPublishing: '20th June 2024',
    News: 'Is Earth really getting too hot for people to survive? A scientist explains extreme heat and the role of climate change',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/science/is-earth-really-getting-too-hot-for-people-to-survive-a-scientist-explains-extreme-heat-and-the-role-of-climate-change/articleshow/111128406.cms',
  },
  {
    SrNo: 750,
    DateOfPublishing: '20th June 2024',
    News: 'How climate change is worsening water scarcity in the Andaman Islands',
    LinkName: 'Scroll.In',
    URL: 'https://scroll.in/article/1069406/how-climate-changing-is-worsening-water-scarcity-in-the-andaman-islands',
  },
  {
    SrNo: 751,
    DateOfPublishing: '20th June 2024',
    News: 'As hundreds die from heat during the Hajj, recent study had warned of escalating climate-related health risks in Makkah',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/as-hundreds-die-from-heat-during-the-hajj-recent-study-had-warned-of-escalating-climate-related-health-risks-in-makkah-96774',
  },
  {
    SrNo: 752,
    DateOfPublishing: '20th June 2024',
    News: 'Heatwaves: A reminder to focus on health as part of climate action',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/healthcare/biotech/healthcare/heatwaves-a-reminder-to-focus-on-health-as-part-of-climate-action/articleshow/111105227.cms',
  },
  {
    SrNo: 753,
    DateOfPublishing: '21st June 2024',
    News: 'Climate change triggers unhealthy warmer nights in India: Report',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/climate-change-triggers-unhealthy-warmer-nights-in-india-report/111146129',
  },
  {
    SrNo: 754,
    DateOfPublishing: '21st June 2024',
    News: '77% of Indians demand stronger climate action: UNDP-Oxford survey',
    LinkName: 'CNBCTV18',
    URL: 'https://www.cnbctv18.com/india/environment/77-of-indians-demand-stronger-climate-action-undp-oxford-survey-19431271.htm',
  },
  {
    SrNo: 755,
    DateOfPublishing: '21st June 2024',
    News: 'Renewable energy companies look abroad for green hydrogen plants',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/sustainability-renewable-energy-companies-look-abroad-for-green-hydrogen-plants-3530469/',
  },
  {
    SrNo: 756,
    DateOfPublishing: '21st June 2024',
    News: 'Record $1.8 trillion investment in renewables made in 2023 not enough to meet COP28 goals, says EY report',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/record-1-8-trillion-investment-in-renewables-made-in-2023-not-enough-to-meet-cop28-goals-says-ey-report/articleshow/111146643.cms?from=mdr',
  },
  {
    SrNo: 757,
    DateOfPublishing: '21st June 2024',
    News: 'Environmental activists win landmark ruling over UK oil well plan',
    LinkName: 'ThePrint',
    URL: 'https://theprint.in/environment/environmental-activists-win-landmark-ruling-over-uk-oil-well-plan/2139459/',
  },
  {
    SrNo: 758,
    DateOfPublishing: '24th June 2024',
    News: '74% Indians support taxing super-rich to address global inequality, climate change: Survey',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/india/74-indians-support-taxing-super-rich-to-address-global-inequality-climate-change-survey-12754632.html',
  },
  {
    SrNo: 759,
    DateOfPublishing: '24th June 2024',
    News: 'Will climate change make insurance too expensive?',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/climate-change-insurance-expensive-9410301/',
  },
  {
    SrNo: 760,
    DateOfPublishing: '24th June 2024',
    News: 'Mission 2025 group urges governments to aim more ambitious climate goals',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/mission-2025-group-urges-governments-to-aim-more-ambitious-climate-goals-124062400102_1.html',
  },
  {
    SrNo: 761,
    DateOfPublishing: '24th June 2024',
    News: 'Create the space for governance with a green heart ',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/opinion/op-ed/create-the-space-for-governance-with-a-green-heart/article68324891.ece',
  },
  {
    SrNo: 762,
    DateOfPublishing: '24th June 2024',
    News: 'Only 60% of Australians accept climate disruption is human-caused, global poll finds',
    LinkName: 'The Guardian',
    URL: 'https://www.theguardian.com/environment/article/2024/jun/24/climate-change-survey-human-caused-poll-australia',
  },
  {
    SrNo: 763,
    DateOfPublishing: '25th June 2024',
    News: 'Paris Olympics promise climate action, experts remain sceptical',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/amp/news/renewable/paris-olympics-promise-climate-action-experts-remain-sceptical/111233144',
  },
  {
    SrNo: 764,
    DateOfPublishing: '25th June 2024',
    News: '\u2018Recent heatwaves in India had a major impact on human health, education, water resources, agriculture, energy and labour productivity\u2019: WMO chief',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/heatwaves-india-impact-human-health-education-water-resources-agriculture-energy-and-labour-productivity-wmo-chief-9411633/',
  },
  {
    SrNo: 765,
    DateOfPublishing: '25th June 2024',
    News: 'How droughts and worsening soil health can increase carbon emissions',
    LinkName: 'Mongabay',
    URL: 'https://india.mongabay.com/2024/06/how-droughts-and-worsening-soil-health-can-increase-carbon-emissions/',
  },
  {
    SrNo: 766,
    DateOfPublishing: '25th June 2024',
    News: 'New freshwater diatom genus discovered in Eastern and Western Ghats',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/mumbai/new-freshwater-diatom-genus-discovered-in-eastern-and-western-ghats/articleshow/111238485.cms',
  },
  {
    SrNo: 767,
    DateOfPublishing: '25th June 2024',
    News: 'India to host first Solar Festival, aim to enhance renewable energy sector',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/business/story/india-host-first-ever-solar-festival-aim-to-enhance-renewable-energy-sector-2557563-2024-06-25',
  },
  {
    SrNo: 768,
    DateOfPublishing: '26th June 2024',
    News: 'Climate Change Could Make Fungi More Dangerous To Humans',
    LinkName: 'Forbes',
    URL: 'https://www.forbes.com/sites/grrlscientist/2024/06/24/climate-change-could-make-fungi-more-dangerous-to-humans/',
  },
  {
    SrNo: 769,
    DateOfPublishing: '26th June 2024',
    News: '\u2018Climate change hit small farmers in 5 yrs\u2019',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/nation/2024/Jun/26/climate-change-hit-small-farmers-in-5-yrs',
  },
  {
    SrNo: 770,
    DateOfPublishing: '26th June 2024',
    News: 'A matter of life and death',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/blog/climate-change/a-matter-of-life-and-death-96856',
  },
  {
    SrNo: 771,
    DateOfPublishing: '26th June 2024',
    News: 'Worsening water shortage to hit sovereign rating: Moody\u2019s',
    LinkName: 'Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/economy/worsening-water-shortage-to-pressure-sovereign-water-intensive-sectors-says-moodys-ratings/article68332292.ece',
  },
  {
    SrNo: 772,
    DateOfPublishing: '26th June 2024',
    News: 'Rs 2 trillion funds tied up for expansion, says Adani Green Energy',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/news/rs-2-trillion-funds-tied-up-for-expansion-says-adani-green-energy-124062501371_1.html',
  },
  {
    SrNo: 773,
    DateOfPublishing: '27th June 2024',
    News: '233 new climate petitions globally in 2023, Indian Supreme Court\u2019s April order an important milestone',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/climate-litigation-supreme-court-order-9417667/',
  },
  {
    SrNo: 774,
    DateOfPublishing: '27th June 2024',
    News: 'Impact of Climate Change on Marginal Farmers: New report highlights urgent need for climate-resilient strategies',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/home/environment/impact-of-climate-change-on-marginal-farmers-new-report-highlights-urgent-need-for-climate-resilient-strategies/articleshow/111293629.cms',
  },
  {
    SrNo: 775,
    DateOfPublishing: '27th June 2024',
    News: 'World headed for \u2018food wars\u2019 amid geopolitics, climate change, warns Olam',
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/opinion/world-headed-for-food-wars-amid-geopolitics-climate-change-warns-olam-12756496.html',
  },
  {
    SrNo: 776,
    DateOfPublishing: '27th June 2024',
    News: "Climate Finance Inadequate to Curb India's Emissions: ICRA",
    LinkName: 'Deccan Chronicle',
    URL: 'https://www.deccanchronicle.com/news/current-affairs/climate-finance-inadequate-to-curb-indias-emissions-icra-901001',
  },
  {
    SrNo: 777,
    DateOfPublishing: '28th June 2024',
    News: 'More people in India faced climate change-induced extreme heat in June than any other country: Study',
    LinkName: 'Scroll.in',
    URL: 'Around%20619%20million%20persons%20in%20India%20bore%20the%20brunt%20of%20climate%20change-induced%20extreme%20heat%20between%20June%2016%20and%20June%2024,%20according%20to%20a%20study%20published%20on%20Thursday%20by%20the%20United%20States-based%20non-profit%20Climate%20Central.%20This%20is%20more%20than%20any%20other%20country.',
  },
  {
    SrNo: 778,
    DateOfPublishing: '28th June 2024',
    News: 'Amid extreme weather events, where are the political manifestos on climate change?',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/web-only/2024/Jun/27/amid-extreme-weather-events-where-are-the-political-manifestos-on-climate-change',
  },
  {
    SrNo: 779,
    DateOfPublishing: '28th June 2024',
    News: 'Displacement fueled by climate change & conflict demands renewed conservation approach: IUCN',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/news/climate-change/displacement-fueled-by-climate-change-conflict-demands-renewed-conservation-approach-iucn-96894',
  },
  {
    SrNo: 780,
    DateOfPublishing: '28th June 2024',
    News: "India must embrace 'Net Zero' development model, says Jayant Sinha",
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/amp/business/video/india-must-embrace-net-zero-development-model-says-jayant-sinha-2558999-2024-06-27',
  },
  {
    SrNo: 781,
    DateOfPublishing: '28th June 2024',
    News: 'India Leads Global Solutions In Climate, Agriculture, And Renewable Energy: President Murmu',
    LinkName: 'Outlook Planet',
    URL: 'https://planet.outlookindia.com/news/india-leads-global-solutions-in-climate-agriculture-and-renewable-energy-president-murmu-news-418043',
  },
  {
    SrNo: 782,
    DateOfPublishing: '1st July 2024',
    News: 'Renewables can cut 17% of India\u2019s heavy industry emissions by 2030',
    LinkName: 'Business Today',
    URL: 'https://www.businesstoday.in/industry/energy/story/renewables-can-cut-17-of-indias-heavy-industry-emissions-by-2030-435218-2024-06-30',
  },
  {
    SrNo: 783,
    DateOfPublishing: '1st July 2024',
    News: "World Bank approves USD 1.5 bn to support India's low-carbon energy sector",
    LinkName: 'ET Auto',
    URL: 'https://auto.economictimes.indiatimes.com/news/industry/world-bank-approves-usd-1-5-bn-to-support-indias-low-carbon-energy-sector/111377336',
  },
  {
    SrNo: 784,
    DateOfPublishing: '1st July 2024',
    News: 'Critical minerals needed for India\u2019s energy transition',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/critical-minerals-needed-for-india-s-energy-transition-101719665239802.html',
  },
  {
    SrNo: 785,
    DateOfPublishing: '1st July 2024',
    News: 'Must accelerate action for sustainable development goals: UN Secy General',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/international/world-news/must-accelerate-action-for-sustainable-development-goals-un-secy-general/articleshow/111386506.cms?from=mdr',
  },
  {
    SrNo: 786,
    DateOfPublishing: '1st July 2024',
    News: 'The \u2018burning era\u2019 has begun: Chairman of Atal Bamboo Samruddhi Yojna on climate change',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/mumbai/burning-era-chairman-atal-bamboo-samruddhi-yojna-climate-change-9424466/',
  },
  {
    SrNo: 787,
    DateOfPublishing: '2nd July 2024',
    News: 'India takes first steps to meet 2070 net-zero goal, Niti Aayog forms panels',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/economy/news/india-takes-first-steps-towards-net-zero-2047-target-niti-aayog-forms-comm-124063000455_1.html',
  },
  {
    SrNo: 788,
    DateOfPublishing: '2nd July 2024',
    News: 'COP29 will be litmus test for Paris agreement: Yalchin Rafiyev',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/international/world-news/cop29-will-be-litmus-test-for-paris-agreement-yalchin-rafiyev/articleshow/111412805.cms?from=mdr',
  },
  {
    SrNo: 789,
    DateOfPublishing: '2nd July 2024',
    News: 'India offers $500 billion investment opportunity in clean energy, time to tap skilled youth',
    LinkName: 'Zee Business',
    URL: 'https://www.zeebiz.com/economy-infra/news-india-offers-500-billion-investment-opportunity-in-clean-energy-time-to-tap-skilled-youth-iesa-iesw-green-energy-electronic-vehicle-toyota-kirloskar-motors-299102',
  },
  {
    SrNo: 790,
    DateOfPublishing: '2nd July 2024',
    News: 'Bhupender Yadav launches portal documenting Indian fauna; 641 new faunal species recorded in India during 2023',
    LinkName: 'Down to Earth',
    URL: 'https://www.downtoearth.org.in/wildlife-biodiversity/bhupender-yadav-launches-portal-documenting-indian-fauna-641-new-faunal-species-recorded-in-india-during-2023',
  },
  {
    SrNo: 791,
    DateOfPublishing: '2nd July 2024',
    News: 'US examines carbon pricing on imports, climate envoy says',
    LinkName: 'Financial Times',
    URL: 'https://www.ft.com/content/8c720674-e5df-4abe-90b4-f510697089da',
  },
  {
    SrNo: 792,
    DateOfPublishing: '3rd July 2024',
    News: 'Climate Change Can No Longer Be Ignored: CJI DY Chandrachud',
    LinkName: 'NDTV',
    URL: 'https://www.ndtv.com/india-news/cji-dy-chandrachud-says-climate-change-can-no-longer-be-ignored-6015919',
  },
  {
    SrNo: 793,
    DateOfPublishing: '3rd July 2024',
    News: 'Google blames AI as its emissions grow instead of heading to net zero',
    LinkName: 'Al Jazeera',
    URL: 'https://www.aljazeera.com/economy/2024/7/2/google-blames-ai-as-its-emissions-grow-instead-of-heading-to-net-zero',
  },
  {
    SrNo: 794,
    DateOfPublishing: '3rd July 2024',
    News: 'Kerala\u2019s Pachathuruthu project cannot singularly drive state to net zero carbon emissions',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/climate-change/keralas-pachathuruthu-project-cannot-singularly-drive-state-to-net-zero-carbon-emissions',
  },
  {
    SrNo: 795,
    DateOfPublishing: '3rd July 2024',
    News: 'EU "Green" Funds Invest In Expanding Coal Companies, Raising Greenwashing Concerns',
    LinkName: 'Outlook',
    URL: 'EU%20%22Green%22%20Funds%20Invest%20In%20Expanding%20Coal%20Companies,%20Raising%20Greenwashing%20Concerns',
  },
  {
    SrNo: 796,
    DateOfPublishing: '3rd July 2024',
    News: 'Decoding \u2018fair share\u2019 in NDCs',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/opinion/decoding-fair-share-in-ndcs/3541792/',
  },
  {
    SrNo: 797,
    DateOfPublishing: '4th July 2024',
    News: 'Global Action Needed to Mitigate Climate Change Impact on Health | Bhubaneswar News',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/bhubaneswar/global-action-needed-to-mitigate-climate-change-impact-on-health/articleshow/111471887.cms',
  },
  {
    SrNo: 798,
    DateOfPublishing: '4th July 2024',
    News: 'Bangladesh joins the race to climate-proof cities in South Asia',
    LinkName: 'Frontline',
    URL: 'https://frontline.thehindu.com/news/bangladesh-dhaka-climate-action-plan-buriganga-river-environment-south-asia/article68362892.ece',
  },
  {
    SrNo: 799,
    DateOfPublishing: '4th July 2024',
    News: 'Climate change led to 32% of heat-related neonatal deaths in 2001-2019 in poor countries, suggests research',
    LinkName: 'DownToEarth',
    URL: 'https://www.downtoearth.org.in/climate-change/climate-change-led-to-32-of-heat-related-neonatal-deaths-in-2001-2019-in-poor-countries-suggests-research-2',
  },
  {
    SrNo: 800,
    DateOfPublishing: '4th July 2024',
    News: 'Challenges in making Indian finance climate-resilient',
    LinkName: 'The New Indian Express',
    URL: 'https://www.newindianexpress.com/opinions/2024/Jul/03/challenges-in-making-indian-finance-climate-resilient',
  },
  {
    SrNo: 801,
    DateOfPublishing: '4th July 2024',
    News: 'BIRD & WRI India sign pact for research on climate change',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/lucknow/bird-wri-india-sign-pact-for-research-on-climate-change/articleshow/111472267.cms',
  },
  {
    SrNo: 802,
    DateOfPublishing: '4th July 2024',
    News: '\u2018Assessing various dimensions of green hydrogen opportunity\u2019',
    LinkName: 'The Financial Express',
    URL: 'https://www.financialexpress.com/business/industry-assessing-various-dimensions-of-green-hydrogen-opportunity-3542888/',
  },
  {
    SrNo: 803,
    DateOfPublishing: '4th July 2024',
    News: 'IREDA and Odisha government collaborate to boost renewable energy financing',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/ireda-and-odisha-government-collaborate-to-boost-renewable-energy-financing/111452822',
  },
  {
    SrNo: 804,
    DateOfPublishing: '5th July 2024',
    News: 'As Delhi, Dhaka prep to renew Ganga treaty, climate change and Mamata role are crucial',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/india-news/as-delhi-dhaka-prep-to-renew-ganga-treaty-climate-change-and-mamata-role-are-crucial-101720094705010.html',
  },
  {
    SrNo: 805,
    DateOfPublishing: '5th July 2024',
    News: 'Indian banks see climate change as their biggest source of systemic risk',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/industry/banking/indian-banks-see-climate-change-as-their-biggest-source-of-systemic-risk-124070500076_1.html',
  },
  {
    SrNo: 806,
    DateOfPublishing: '5th July 2024',
    News: 'Carbon removal industry reckons with a new problem \u2014 too many startups',
    LinkName: 'ET Infra',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/carbon-removal-industry-reckons-with-a-new-problem-too-many-startups/111478990',
  },
  {
    SrNo: 807,
    DateOfPublishing: '5th July 2024',
    News: 'India to boost green hydrogen sector with new testing and quality assurance facilities',
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/india-to-boost-green-hydrogen-sector-with-new-testing-and-quality-assurance-facilities/111500711',
  },
  {
    SrNo: 808,
    DateOfPublishing: '5th July 2024',
    News: 'Norms issued for funding of testing facilities, infra for National Green Hydrogen Mission',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/industry/renewables/norms-issued-for-funding-of-testing-facilities-infra-for-national-green-hydrogen-mission/articleshow/111495906.cms?from=mdr',
  },
  {
    SrNo: 809,
    DateOfPublishing: '8th July 2024',
    News: 'June warmest on record; every month since July 2023 breached 1.5 deg C threshold',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/june-warmest-july-climate-change-global-warming-9439260/',
  },
  {
    SrNo: 810,
    DateOfPublishing: '8th July 2024',
    News: 'A law around low-carbon climate resilient development ',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/opinion/lead/a-law-around-low-carbon-climate-resilient-development/article68378680.ece',
  },
  {
    SrNo: 811,
    DateOfPublishing: '8th July 2024',
    News: 'From green to transition finance: Re-gear Asia\u2019s carbon clean-up',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/transition-finance-asia-carbon-emission-climate-change-power-generation-fossil-fuels-solar-energy-climate-finance-11720343215898.html',
  },
  {
    SrNo: 812,
    DateOfPublishing: '8th July 2024',
    News: 'Clean Facts. On the carbon footprint of concrete buildings',
    LinkName: 'Hindu Business Line',
    URL: 'https://www.thehindubusinessline.com/specials/clean-tech/on-the-carbon-footprint-of-concrete-buildings/article68366446.ece',
  },
  {
    SrNo: 813,
    DateOfPublishing: '8th July 2024',
    News: 'Wetland conservation: 12 Ramsar sites in Tamil Nadu to be digitally mapped using hi-tech drones',
    LinkName: 'DT Next',
    URL: 'https://www.dtnext.in/news/tamilnadu/wetland-conservation-12-ramsar-sites-in-tamil-nadu-to-be-digitally-mapped-using-hi-tech-drones-793808',
  },
  {
    SrNo: 814,
    DateOfPublishing: '9th July 2024',
    News: 'The hypocrisy of the environment vs development debate',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/opinion/columns/environment-development-debate-hypocrisy-9440799/',
  },
  {
    SrNo: 815,
    DateOfPublishing: '9th July 2024',
    News: 'Prez Murmu urges people to take smaller, local steps to protect environment',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/india-news/prez-murmu-urges-people-to-take-smaller-local-steps-to-protect-environment-124070800133_1.html',
  },
  {
    SrNo: 816,
    DateOfPublishing: '9th July 2024',
    News: 'Why is climate action limping despite countries agreeing to cut down on emissions and consumption?',
    LinkName: 'Scroll.in',
    URL: 'https://scroll.in/article/1070327/why-is-climate-action-limping-despite-countries-agreeing-to-cut-down-on-emissions-and-consumption',
  },
  {
    SrNo: 817,
    DateOfPublishing: '9th July 2024',
    News: 'Hindustan Zinc releases report on nature-related risks, climate change',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/companies/news/hindustan-zinc-releases-report-on-nature-related-risks-climate-change-124070801066_1.html',
  },
  {
    SrNo: 818,
    DateOfPublishing: '9th July 2024',
    News: 'Did climate change occur 44 million years ago that changed Earth? What happened to river system that ran below Antarctica?',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/international/us/did-climate-change-occur-44-million-years-ago-that-changed-earth-what-happened-to-river-system-that-ran-below-antarctica/articleshow/111584547.cms?from=mdr',
  },
  {
    SrNo: 819,
    DateOfPublishing: '10th July 2024',
    News: 'Environment ministry releases project reports for rejuvenation of 13 rivers in India',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/environment-ministry-releases-project-reports-for-rejuvenation-of-13-rivers-in-india-11647273729660.html',
  },
  {
    SrNo: 820,
    DateOfPublishing: '10th July 2024',
    News: "Ocean circulation is slowing down and it's bad news for all of us",
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/environment/story/ocean-circulation-climate-change-carbon-global-warming-sea-levels-2564280-2024-07-09',
  },
  {
    SrNo: 821,
    DateOfPublishing: '10th July 2024',
    News: 'New US solar duties would raise costs and threaten climate goals, report says',
    LinkName: 'The Print',
    URL: 'https://theprint.in/environment/new-us-solar-duties-would-raise-costs-and-threaten-climate-goals-report-says/2167521/',
  },
  {
    SrNo: 822,
    DateOfPublishing: '10th July 2024',
    News: 'European carbon removal specialists to support new projects in India',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/world-news/european-carbon-removal-specialists-to-support-new-projects-in-india-101720512494940.html',
  },
  {
    SrNo: 823,
    DateOfPublishing: '10th July 2024',
    News: 'Climate Crisis: Oxfam claims rich countries are overstating financial support to poor countries',
    LinkName: 'CNBCTV18',
    URL: 'https://www.cnbctv18.com/india/environment/climate-crisis-oxfam-claims-rich-countries-are-overstating-financial-support-to-poor-countries-19440258.htm',
  },
  {
    SrNo: 824,
    DateOfPublishing: '11th July 2024',
    News: 'Why RBI should be concerned about climate change',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/et-explains/why-rbi-should-be-concerned-about-climate-change/articleshow/111638357.cms?from=mdr',
  },
  {
    SrNo: 825,
    DateOfPublishing: '11th July 2024',
    News: 'Azerbaijan Rethinks COP29 Strategy: New Fund Over Fossil-Fuel Levy',
    LinkName: 'Devdiscourse',
    URL: 'https://www.devdiscourse.com/article/headlines/3012850-azerbaijan-rethinks-cop29-strategy-new-fund-over-fossil-fuel-levy',
  },
  {
    SrNo: 826,
    DateOfPublishing: '11th July 2024',
    News: 'India-Austria to partner on sustainability, focus on green hydrogen',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/external-affairs-defence-security/news/india-austria-to-partner-on-sustainability-focus-on-green-hydrogen-124071001286_1.html',
  },
  {
    SrNo: 827,
    DateOfPublishing: '11th July 2024',
    News: 'New Delhi to be 4.5C hotter: New map predicts climate 60 years from now',
    LinkName: 'India Today',
    URL: 'https://www.indiatoday.in/environment/story/new-delhi-to-be-45c-hotter-new-map-predicts-climate-60-years-from-now-2564761-2024-07-10',
  },
  {
    SrNo: 828,
    DateOfPublishing: '11th July 2024',
    News: "Philippines chosen to host climate 'loss and damage' fund board",
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/sci-tech/science/philippines-chosen-to-host-climate-loss-and-damage-fund-board/article68388227.ece',
  },
  {
    SrNo: 829,
    DateOfPublishing: '12th July 2024',
    News: 'British Council to give lessons on climate change to youth',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/india/british-council-to-give-lessons-on-climate-change-to-youth-9447993/',
  },
  {
    SrNo: 830,
    DateOfPublishing: '12th July 2024',
    News: 'Rich nations failed to meet $100 bn climate finance promise in 2022: Oxfam',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/rich-nations-failed-to-meet-100-bn-climate-finance-promise-in-2022-oxfam-124071101089_1.html',
  },
  {
    SrNo: 831,
    DateOfPublishing: '12th July 2024',
    News: 'Implementing Punjab\u2019s climate change plan will need collaborative effort',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/city/chandigarh/implementing-punjabs-climate-change-plan-will-need-collaborative-effort/articleshow/111668816.cms',
  },
  {
    SrNo: 832,
    DateOfPublishing: '12th July 2024',
    News: 'MCA seeks easing of CSR rules, funds for MCA21, NCLT',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/industry/mca-seeks-easing-of-csr-rules-funds-for-mca21-nclt/3550917/',
  },
  {
    SrNo: 833,
    DateOfPublishing: '15th July 2024',
    News: 'India May Establish NITI Aayog-Like Commission for Climate Action',
    LinkName: 'One India',
    URL: 'https://www.oneindia.com/india/india-proposes-climate-change-commission-like-niti-aayog-011-3877423.html',
  },
  {
    SrNo: 834,
    DateOfPublishing: '15th July 2024',
    News: "Navigating climate risks: India's financial sector gears up for transparency with RBI's draft guidelines",
    LinkName: 'Economic Times',
    URL: 'https://economictimes.indiatimes.com/small-biz/sustainability/navigating-climate-risks-indias-financial-sector-gears-up-for-transparency-with-rbis-draftguidelines/articleshow/111706864.cms?from=mdr',
  },
  {
    SrNo: 835,
    DateOfPublishing: '15th July 2024',
    News: 'European Union to push at COP29 for followup on deal to curb fossil fuels',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/sci-tech/energy-and-environment/european-union-to-push-at-cop29-for-followup-on-deal-to-curb-fossil-fuels/article68399316.ece',
  },
  {
    SrNo: 836,
    DateOfPublishing: '15th July 2024',
    News: 'EV Industry seeks incentives, funds for infrastructure, tax rationalization from Budget',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/ev-industry-seeks-incentives-funds-for-infrastructure-tax-rationalisation-from-budget/111743010?utm_source=latest_news&utm_medium=homepage',
  },
  {
    SrNo: 837,
    DateOfPublishing: '15th July 2024',
    News: 'NITI Aayog launches hackathon to accelerate zero-emission truck adoption in India',
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/power/niti-aayog-launches-hackathon-to-accelerate-zero-emission-truck-adoption-in-india/111742878?utm_source=latest_news&utm_medium=homepage',
  },
  {
    SrNo: 838,
    DateOfPublishing: '16th July 2024',
    News: "Vance's criticism of clean energy, climate change grew as Ohio embraced it",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/vance-s-criticism-of-clean-energy-climate-change-grew-as-ohio-embraced-it-124071600157_1.html',
  },
  {
    SrNo: 839,
    DateOfPublishing: '16th July 2024',
    News: 'Unusual stratospheric warming occurring over South Pole',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/unusual-stratospheric-warming-south-pole-9453744/',
  },
  {
    SrNo: 840,
    DateOfPublishing: '16th July 2024',
    News: "Recent COPs have made significant strides in recognising WASH's importance in climate action; but there\u2019s still room for improvement",
    LinkName: 'Down to Earth',
    URL: 'https://www.downtoearth.org.in/climate-change/recent-cops-have-made-significant-strides-in-recognising-washs-importance-in-climate-action-but-theres-still-room-for-improvement',
  },
  {
    SrNo: 841,
    DateOfPublishing: '16th July 2024',
    News: 'Climate Change Threatens Education Gains For Vulnerable Populations: UN Report',
    LinkName: 'Outlook Planet',
    URL: 'https://planet.outlookindia.com/news/climate-change-threatens-education-gains-for-vulnerable-populations-un-report-news-418155',
  },
  {
    SrNo: 842,
    DateOfPublishing: '16th July 2024',
    News: "IOC's green hydrogen project sees sparse bids amidst industry concerns: Report",
    LinkName: 'MoneyControl',
    URL: 'https://www.moneycontrol.com/news/business/iocs-green-hydrogen-project-sees-sparse-bids-amidst-industry-concerns-report-12770043.html',
  },
  {
    SrNo: 843,
    DateOfPublishing: '17th July 2024',
    News: 'UNEP Foresight Report: Private micro-environmentalism may worsen socio-economic inequalities, burden resources',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/environment/unep-foresight-report-private-micro-environmentalism-may-worsen-socio-economic-inequalities-burden-resources',
  },
  {
    SrNo: 844,
    DateOfPublishing: '17th July 2024',
    News: 'New climate finance goal top priority for Baku deal: COP29 letter to member nations',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/world-news/new-climate-finance-goal-top-priority-for-baku-deal-cop29-letter-to-member-nations-101721189693277.html',
  },
  {
    SrNo: 845,
    DateOfPublishing: '17th July 2024',
    News: "World Bank Backs India's Clean Fuel Push: $1.5 Billion Loan To Increase Hydrogen Production",
    LinkName: 'Swarajya Mag',
    URL: 'https://swarajyamag.com/infrastructure/world-bank-backs-indias-clean-fuel-push-15-billion-loan-to-increase-hydrogen-production',
  },
  {
    SrNo: 846,
    DateOfPublishing: '17th July 2024',
    News: 'US envoy Garcetti visits Adani Group\u2019s mega renewable energy project site in Gujarat',
    LinkName: 'The Print',
    URL: 'https://theprint.in/economy/us-envoy-garcetti-visits-adani-groups-mega-renewable-energy-project-site-in-gujarat/2177385/',
  },
  {
    SrNo: 847,
    DateOfPublishing: '17th July 2024',
    News: "China's national carbon market incentivizes green transition",
    LinkName: 'CGTN',
    URL: 'https://news.cgtn.com/news/2024-07-16/China-s-national-carbon-market-incentivizes-green-transition-1vhLZXKkFvW/p.html',
  },
  {
    SrNo: 848,
    DateOfPublishing: '18th July 2024',
    News: 'Armed conflict and climate risks contribute to weaken people\u2019s ability to cope, says ICRC report',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/climate-change/armed-conflict-and-climate-risks-contribute-to-weak-peoples-ability-to-cope-says-icrc-report',
  },
  {
    SrNo: 849,
    DateOfPublishing: '18th July 2024',
    News: 'UN models may be underestimating climate change effect on rainfall in India',
    LinkName: 'Scroll.in',
    URL: 'https://scroll.in/article/1070192/un-models-may-be-underestimating-climate-change-effect-on-rainfall-in-india',
  },
  {
    SrNo: 850,
    DateOfPublishing: '18th July 2024',
    News: 'Political direction needed to settle dispute on climate finance: COP29 prez',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/political-direction-needed-to-settle-dispute-on-climate-finance-cop29-prez-124071800011_1.html',
  },
  {
    SrNo: 851,
    DateOfPublishing: '18th July 2024',
    News: 'Education systems under siege: Climate change threatens global learning',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/education-systems-under-siege-climate-change-threatens-global-learning-124071700474_1.html',
  },
  {
    SrNo: 852,
    DateOfPublishing: '18th July 2024',
    News: 'UNEP Foresight Report: 1.5% of world\u2019s population now forcibly displaced',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/environment/unep-foresight-report-15-of-worlds-population-now-forcibly-displaced',
  },
  {
    SrNo: 853,
    DateOfPublishing: '19th July 2024',
    News: "Trump's Fossil Fuel Agenda Gets Priority Over Climate Change At Convention",
    LinkName: 'NDTV',
    URL: 'https://www.ndtv.com/world-news/trumps-fossil-fuel-agenda-gets-priority-over-climate-change-at-convention-6124272',
  },
  {
    SrNo: 854,
    DateOfPublishing: '19th July 2024',
    News: 'Weather extremes grip globe: Rainfall, heatwaves, and wildfires',
    LinkName: 'Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-change/weather-extremes-globe-rainfall-heatwaves-wildfires-9462311/',
  },
  {
    SrNo: 855,
    DateOfPublishing: '19th July 2024',
    News: 'India needs a climate stack',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/opinion/india-needs-a-climate-stack/3557764/',
  },
  {
    SrNo: 856,
    DateOfPublishing: '19th July 2024',
    News: 'Growing Appeal Of Offshore Bonds Offers Hope For India\u2019s Green Energy Push',
    LinkName: 'Outlook',
    URL: 'https://planet.outlookindia.com/news/growing-appeal-of-offshore-bonds-offers-hope-for-indias-green-energy-push-news-418162',
  },
  {
    SrNo: 857,
    DateOfPublishing: '19th July 2024',
    News: "India To Lose 0.05% of GDP Due To EU's Carbon Tax, Should Impose 'Historical Polluter Tax' on EU: Report",
    LinkName: 'News18',
    URL: 'https://www.news18.com/business/economy/eu-european-union-carbon-border-adjustment-mechanism-climate-change-india-gdp-8970052.html',
  },
  {
    SrNo: 858,
    DateOfPublishing: '22nd July 2024',
    News: 'Kamala Harris Seen as Tougher Oil Industry Opponent Than Biden',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/economy/kamala-harris-seen-as-tougher-oil-industry-opponent-than-biden-11721608922325.html',
  },
  {
    SrNo: 859,
    DateOfPublishing: '22nd July 2024',
    News: 'Forest and Environment Ministry Gets New Leader in Madhya Pradesh',
    LinkName: 'Devdiscourse',
    URL: 'Forest%20and%20Environment%20Ministry%20Gets%20New%20Leader%20in%20Madhya%20Pradesh',
  },
  {
    SrNo: 860,
    DateOfPublishing: '22nd July 2024',
    News: 'Govt to focus on urban forests in its 100-day agenda',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/news/india/with-india-facing-extreme-weather-events-govt-plans-to-focus-on-urban-forests-in-its-100-day-agenda-11721544163225.html',
  },
  {
    SrNo: 861,
    DateOfPublishing: '22nd July 2024',
    News: 'Azerbaijan announces new fund to be raised by fossil fuel producers',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/education-systems-under-siege-climate-change-threatens-global-learning-124071700474_1.html',
  },
  {
    SrNo: 862,
    DateOfPublishing: '22nd July 2024',
    News: 'Global electricity demand forecast to grow by around 4% in 2024: IEA report',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/global-electricity-demand-forecast-to-grow-by-around-4-in-2024-iea-report-124072000359_1.html',
  },
  {
    SrNo: 863,
    DateOfPublishing: '23rd July 2024',
    News: 'Climate Change: Need to move away from \u2018excessive preoccupation\u2019 with meeting global temperature target, says Economic Survey',
    LinkName: 'Indian Express',
    URL: 'https://indianexpress.com/article/india/climate-change-need-to-move-away-from-excessive-preoccupation-with-meeting-global-temperature-target-says-economic-survey-9469547/',
  },
  {
    SrNo: 864,
    DateOfPublishing: '23rd July 2024',
    News: 'Why India needs a climate budget',
    LinkName: 'The Scroll',
    URL: 'https://scroll.in/article/1070623/why-india-needs-a-climate-budget',
  },
  {
    SrNo: 865,
    DateOfPublishing: '23rd July 2024',
    News: 'Indian Oil, Indian Navy sign MoU to promote hydrogen e-mobility',
    LinkName: 'The Print',
    URL: 'https://theprint.in/economy/indian-oil-indian-navy-sign-mou-to-promote-hydrogen-e-mobility/2186005/',
  },
  {
    SrNo: 866,
    DateOfPublishing: '23rd July 2024',
    News: "Boost for green energy': Govt enhances financial aid for biomass pellet manufacturing",
    LinkName: 'ET EnergyWorld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/boost-for-green-energy-govt-enhances-financial-aid-for-biomass-pellet-manufacturing/111942083',
  },
  {
    SrNo: 867,
    DateOfPublishing: '23rd July 2024',
    News: 'Clean energy needs Rs 31 trillion investments by 2030',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/budget/clean-energy-needs-rs-31-trillion-investments-by-2030-3561395/',
  },
  {
    SrNo: 868,
    DateOfPublishing: '24th July 2024',
    News: 'What is a climate finance taxonomy, announced by FM Sitharaman?',
    LinkName: 'Indian Express',
    URL: 'https://indianexpress.com/article/explained/everyday-explainers/climate-finance-taxonomy-9470655/',
  },
  {
    SrNo: 869,
    DateOfPublishing: '24th July 2024',
    News: "India's chief economic advisor calls out rich nations' climate hypocrisy",
    LinkName: 'ET Energyworld',
    URL: 'https://energy.economictimes.indiatimes.com/news/renewable/indias-chief-economic-advisor-calls-out-rich-nations-climate-hypocrisy/111953521',
  },
  {
    SrNo: 870,
    DateOfPublishing: '24th July 2024',
    News: 'New climate model puts equity at the forefront',
    LinkName: 'The Hindu',
    URL: 'https://frontline.thehindu.com/environment/india-climate-crisis-new-framework-mitigation-modelling-places-equity-front-centre-energy-climate-justice/article68392692.ece',
  },
  {
    SrNo: 871,
    DateOfPublishing: '24th July 2024',
    News: 'Adani Green Energy commissions 250 MW wind power project in Khavda, Gujarat',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/markets/capital-market-news/adani-green-energy-commissions-250-mw-wind-power-project-in-khavda-gujarat-124072400151_1.html',
  },
  {
    SrNo: 872,
    DateOfPublishing: '25th July 2024',
    News: 'Why Economic Survey argues for climate adaptation, not mitigation',
    LinkName: 'Indian Express',
    URL: 'https://indianexpress.com/article/explained/explained-climate/why-economic-survey-argues-for-climate-adaptation-not-mitigation-9474009/',
  },
  {
    SrNo: 873,
    DateOfPublishing: '25th July 2024',
    News: "BASIC bloc slams rich countries for 'leadership void' on climate change",
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/basic-bloc-slams-rich-countries-for-leadership-void-on-climate-change-124072500207_1.html',
  },
  {
    SrNo: 874,
    DateOfPublishing: '25th July 2024',
    News: 'Assessing the impact of the climate crisis on public health and nutrition security',
    LinkName: 'Hindustan Times',
    URL: 'https://www.hindustantimes.com/ht-insight/climate-change/assessing-the-impact-of-the-climate-crisis-on-public-health-and-nutrition-security-101721815510950.html',
  },
  {
    SrNo: 875,
    DateOfPublishing: '25th July 2024',
    News: 'UN COP29 Azerbaijan: Will the new Climate Finance Action Fund (CFAF) help developing countries?',
    LinkName: 'Down To Earth',
    URL: 'https://www.downtoearth.org.in/climate-change/un-cop29-azerbaijan-will-the-new-climate-finance-action-fund-cfaf-help-developing-countries',
  },
  {
    SrNo: 876,
    DateOfPublishing: '25th July 2024',
    News: 'Climate and finance crises could leave 600 million hungry by 2030',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/world/climate-and-finance-crises-could-leave-600-million-hungry-by-2030-9473372/',
  },
  {
    SrNo: 877,
    DateOfPublishing: '26th July 2024',
    News: 'India mustn\u2019t lose time on a climate finance taxonomy',
    LinkName: 'Mint',
    URL: 'https://www.livemint.com/opinion/online-views/climate-finance-climate-action-climate-change-private-investment-carbon-emission-asean-developing-countries-11721919079971.html',
  },
  {
    SrNo: 878,
    DateOfPublishing: '26th July 2024',
    News: 'Climate change may disrupt financial stability, warns RBI DG',
    LinkName: 'Financial Express',
    URL: 'https://www.financialexpress.com/business/banking-finance-climate-change-may-disrupt-financial-stability-warns-rbi-dg-3565272/',
  },
  {
    SrNo: 879,
    DateOfPublishing: '26th July 2024',
    News: 'CSR must ensure investments are resilient in a hotter world. Time to integrate a climate lens',
    LinkName: 'The Print',
    URL: 'https://theprint.in/opinion/csr-must-ensure-investments-are-resilient-in-a-hotter-world-time-to-integrate-a-climate-lens/2190429/',
  },
  {
    SrNo: 880,
    DateOfPublishing: '26th July 2024',
    News: "Britain's climate change plan challenged in landmark court case",
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/sci-tech/science/britains-climate-change-plan-challenged-in-landmark-court-case/article68444487.ece',
  },
  {
    SrNo: 881,
    DateOfPublishing: '26th July 2024',
    News: 'Budget 2024 - The Verdict | Incentives worth \u20b94,500 crore for Green Hydrogen to roll out by next month, scaling up solar energy ecosystem, says MNRE Secretary',
    LinkName: 'CNBCTV18',
    URL: 'https://www.cnbctv18.com/energy/budget-2024-the-verdict-incentives-worth-rs-4500-crore-for-green-hydrogen-to-roll-out-by-next-month-scaling-up-solar-energy-ecosystem-says-mnre-secretary-19449140.htm',
  },
  {
    SrNo: 882,
    DateOfPublishing: '29th July 2024',
    News: 'Women, disabled and indigenous people face heightened risks from Climate Change: CJI',
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/india/women-disabled-and-indigenous-people-face-heightened-risks-from-climate-change-cji/articleshow/112088258.cms?from=mdr\\',
  },
  {
    SrNo: 883,
    DateOfPublishing: '29th July 2024',
    News: 'What is South Africa\u2019s new law on climate change?',
    LinkName: 'The Hindu',
    URL: 'https://www.thehindu.com/news/international/what-is-south-africas-new-law-on-climate-change/article68458069.ece',
  },
  {
    SrNo: 884,
    DateOfPublishing: '29th July 2024',
    News: 'Prioritising climate finance',
    LinkName: 'Deccan Herald',
    URL: 'https://www.deccanherald.com/opinion/prioritising-climate-finance-3126028',
  },
  {
    SrNo: 885,
    DateOfPublishing: '29th July 2024',
    News: "US Treasury Secretary Janet Yellen calls climate fight the world's greatest economic opportunity",
    LinkName: 'The Economic Times',
    URL: 'https://economictimes.indiatimes.com/news/international/world-news/us-treasury-secretary-janet-yellen-calls-climate-fight-the-worlds-greatest-economic-opportunity/articleshow/112089152.cms?from=mdr',
  },
  {
    SrNo: 886,
    DateOfPublishing: '29th July 2024',
    News: 'Mangroves ecosystem in India vulnerable to collapse due to cyclones, shrimp aquaculture, says report',
    LinkName: 'The Indian Express',
    URL: 'https://indianexpress.com/article/cities/ahmedabad/mangroves-ecosystem-india-vulnerable-to-collapse-due-to-cyclones-shrimp-aquaculture-says-report-9478628/',
  },
  {
    SrNo: 887,
    DateOfPublishing: '30th July 2024',
    News: 'One Health Panel To Address Climate-Related Health Issues In TN',
    LinkName: 'Outlook India',
    URL: 'https://planet.outlookindia.com/news/one-health-panel-toaddress-climaterelated-health-issues-in-tn-news-418230',
  },
  {
    SrNo: 888,
    DateOfPublishing: '30th July 2024',
    News: 'Trump effect in clean tech sector keeps renewable energy investors in dark',
    LinkName: 'Business Standard',
    URL: 'https://www.business-standard.com/world-news/trump-effect-in-clean-tech-sector-keeps-renewable-energy-investors-in-dark-124072900378_1.html',
  },
  {
    SrNo: 889,
    DateOfPublishing: '30th July 2024',
    News: 'Germans combat climate change from their balconies',
    LinkName: 'ET Rise',
    URL: 'https://economictimes.indiatimes.com/small-biz/sustainability/germans-combat-climate-change-from-their-balconies/articleshow/112124412.cms?from=mdr',
  },
  {
    SrNo: 890,
    DateOfPublishing: '30th July 2024',
    News: 'Climate change is impacting the shape of the Earth and its timings warn scientists',
    LinkName: 'The Times of India',
    URL: 'https://timesofindia.indiatimes.com/etimes/trending/climate-change-is-impacting-the-shape-of-the-earth-and-its-timings-warn-scientists/articleshow/112108748.cms',
  },
  {
    SrNo: 891,
    DateOfPublishing: '30th July 2024',
    News: 'Shri Bhupender Yadav launches Ideas4LiFE portal for inviting ideas related to products and services for inducing behavioral changes related to environment-friendly lifestyles',
    LinkName: 'PIB',
    URL: 'https://pib.gov.in/PressReleaseIframePage.aspx?PRID=2038729',
  },
];
