import { Navigate } from "react-router-dom";
import React, { FC } from "react";
import { cookies } from "../api";

interface IPublicRouteProps {
  children: React.ReactNode;
  restricted?: boolean;
  redirectTo?: string;
}

const PublicRoute: FC<IPublicRouteProps> = ({ children, restricted = false, redirectTo = "/" }) => {
  const isLogged = cookies.get("access_token") !== undefined && cookies.get("access_token").length;
  const shouldRedirect = isLogged && restricted;
  return <>{shouldRedirect ? <Navigate replace to={redirectTo} /> : children}</>;
};

export default PublicRoute;
