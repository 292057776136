import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

type PaginationProps = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
};

const Pagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Change this to the desired number of visible pages

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const middlePage = Math.ceil(maxVisiblePages / 2);
      const startPage = Math.max(1, currentPage - middlePage + 1);
      const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
      if (startPage === 2) {
        pageNumbers.push(1);
      }
      if (startPage > 2) {
        pageNumbers.push(1, "...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        pageNumbers.push("...");
      }
    }

    return pageNumbers.map((page, index) => (
      <button
        key={index}
        onClick={() => (typeof page === "number" ? handlePageChange(page) : null)}
        className={`${
          page === currentPage
            ? "rounded-md border-2 border-black"
            : typeof page === "number"
              ? "rounded-md border-2 border-[#231F20]/40"
              : "cursor-default"
        } h-10 w-10 text-black`}
      >
        {page}
      </button>
    ));
  };

  return totalItems ? (
    <div className="flex items-center gap-8">
      <span className="text-md text-[#231F20]">
        {currentPage} of {totalPages} pages ({totalItems} items)
      </span>
      <div className="flex space-x-3">
        <button
          className={`${
            currentPage === 1
              ? "border-2 border-[#DFE3E8] text-[#DFE3E8]"
              : "cursor-pointer border-2 border-black text-[#714ECE]"
          } rounded-md p-2 font-bold`}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <IoIosArrowBack className="text-xl" />
        </button>

        <ul className=" flex gap-2">{renderPageNumbers()}</ul>

        <button
          className={`${
            currentPage === totalPages
              ? "border-2 border-[#DFE3E8] text-[#DFE3E8]"
              : "cursor-pointer border-2 border-black text-[#714ECE]"
          } rounded-md p-2 font-bold`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <IoIosArrowForward className="text-xl" />
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
