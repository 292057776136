import { useViewModeTabsSelector } from "../../../store/projects/selectors";
import { changeViewModeAction } from "../../../store/projects/actions";
import { dispatch } from "../../../store";
import { FaListUl } from "react-icons/fa6";
import { IoMdGrid } from "react-icons/io";
import { EViewMode } from "../../../types";

export const ViewModeTabs = () => {
  const viewMode = useViewModeTabsSelector();

  const viewModeTabs = [
    {
      code: EViewMode.LIST,
      icon: <FaListUl size={18} />,
      name: "List",
    },
    {
      code: EViewMode.GRID,
      icon: <IoMdGrid size={20} />,
      name: "Grid",
    },
  ];

  const renderViewModeTabs = viewModeTabs.map((viewModeTab) => (
    <li className="h-full w-full" key={viewModeTab.code}>
      <a
        href="#"
        className={`flex w-full items-center gap-2 border px-4 py-2 ${
          viewModeTab.code === EViewMode.LIST ? "rounded-s-full" : "rounded-e-full"
        } 
          ${
            viewMode === viewModeTab.code
              ? "active border-r-0 border-[#231F20]/10 bg-primaryPurple-100/10 text-[#714ECE]"
              : "border-r-0 border-gray-300 text-black hover:bg-gray-50 hover:text-gray-700"
          }`}
        onClick={() => dispatch(changeViewModeAction(viewModeTab.code))}
      >
        {viewModeTab.icon} {viewModeTab.name}
      </a>
    </li>
  ));

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select your country
        </label>
        <select
          id="tabs"
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        >
          {viewModeTabs.map((viewModeTab) => (
            <option key={viewModeTab.code} value={viewModeTab.code}>
              {viewModeTab.name}
            </option>
          ))}
        </select>
      </div>
      <ul className="hidden text-center text-sm font-normal text-black sm:flex">
        {renderViewModeTabs}
      </ul>
    </>
  );
};
