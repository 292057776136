import { TReProjectData } from "../../../types";
import React from "react";
import { Badge } from "../../../components/Badge";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";

export interface CurrentProjectGridProps {
  currentProjects: TReProjectData[];
}

export const CurrentProjectGrid = ({ currentProjects }: CurrentProjectGridProps) => {
  return (
    <>
      {currentProjects.map((item) => (
        // <div className="">
        <div
          data-testid={`${item.id}`}
          key={`${item.id}`}
          className="flex w-full flex-col justify-center gap-2 rounded-lg bg-white p-4 shadow-md"
        >
          <div className="flex items-center justify-between">
            <h4 className="basis-[90%] font-semibold text-primaryPurple-100">
              {item.name}
              <span className="font-light text-gray-500">&nbsp;| ID: #{item.id}</span>
            </h4>
            <BsThreeDotsVertical className="text-primaryPurple-100" />
          </div>

          <p
            className={"truncate border-gray-300 pb-4 font-normal text-[#231F20CC]"}
            style={{ borderBottomWidth: "1px" }}
          >
            {item.address}
          </p>

          <div className="mt-2 flex items-center justify-between">
            <Badge content={item.state}></Badge>
            <Link to={`/project/${item.id}`}>
              <button className="cursor-pointer rounded-md border border-primaryPurple-100 bg-white px-4 py-2 text-sm font-normal text-primaryPurple-100 outline-none transition-all duration-200 hover:bg-purple-50">
                More details
              </button>
            </Link>
          </div>
        </div>
        // </div>
      ))}
    </>
  );
};
