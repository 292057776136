import {
  BaseSyntheticEvent,
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from "react";
import { useStateRef } from "../../utils/useStateRef";
import { SearchableSelectOption } from "./SearchableSelectOption";
import { SearchableSelectProps } from "../../types/components/searchableselect";

const SearchableSelect = ({
  label,
  placeholder,
  value,
  onChange,
  disabled,
  children,
}: SearchableSelectProps) => {
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen, isOpenRef] = useStateRef(false);
  children = Array.isArray(children) ? children : [children];

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (
        isOpenRef.current &&
        e.target instanceof HTMLElement &&
        !wrapperRef.current?.contains(e.target)
      ) {
        setIsOpen(() => false);
      }
    });
  }, []);

  function toggleDropdown() {
    setIsOpen((currState) => !currState);
  }

  useEffect(() => {
    dropdownMenuRef.current?.classList.toggle("hidden", isOpen);
  }, [isOpen]);

  const getSelectedValueText = () => {
    if (!value) {
      return value;
    }
    return children.find((child) => child.props.value === value)?.props.children;
  };

  return (
    <div className="relative h-10" ref={wrapperRef}>
      <button
        id="dropdown-button"
        disabled={disabled}
        className={`red peer h-full w-full rounded-[7px] ${
          value && !isOpen ? "border" : "border-2"
        } ${!isOpen ? "border-blue-gray-200" : "border-gray-900"} ${
          (value || isOpen) && "border-t-transparent"
        } bg-transparent px-3 py-2.5 text-left font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all focus:outline-0 disabled:cursor-not-allowed disabled:border-0 disabled:bg-blue-gray-50`}
        onClick={() => {
          toggleDropdown();
        }}
      >
        <div className="flex w-full justify-between">{getSelectedValueText()}</div>
        <div
          className={`absolute right-2 top-2/4 grid h-5 w-5 -translate-y-2/4 rotate-0 place-items-center pt-px text-blue-gray-400 transition-all ${
            !isOpen ? "" : "mt-px rotate-180"
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </button>
      <label
        className={`before:content[' '] after:content[' '] pointer-events-none absolute -top-1.5 left-0 flex h-full w-full select-none ${
          value && !isOpen && "before:border-l before:border-t after:border-r after:border-t"
        } ${
          isOpen
            ? "text-gray-900 before:border-l-2 before:border-t-2 before:border-gray-900 after:border-r-2 after:border-t-2 after:border-gray-900"
            : "text-blue-gray-400 before:border-blue-gray-200 after:border-blue-gray-200"
        } ${
          isOpen || value
            ? "text-[11px] leading-tight peer-disabled:text-transparent"
            : "text-sm leading-[3.75] text-blue-gray-400 before:border-transparent after:border-transparent peer-disabled:text-blue-gray-400"
        } font-normal transition-all before:pointer-events-none before:mr-1 before:mt-[6.5px] before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:transition-all after:pointer-events-none after:ml-1 after:mt-[6.5px] after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:transition-all peer-disabled:before:border-transparent peer-disabled:after:border-transparent`}
      >
        {label}
      </label>
      <div
        ref={dropdownMenuRef}
        className="z-10 max-h-96 w-full overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
        style={{
          position: "absolute",
          top: "45px",
          left: "0px",
          overflow: "auto",
          zIndex: "99",
          display: !isOpen ? "none" : "flex",
          flexDirection: "column",
          rowGap: "4px",
          opacity: 1,
          transformOrigin: "center top",
        }}
      >
        <input
          id="search-input"
          className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-800 focus:outline-none"
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          onInput={(e: BaseSyntheticEvent<InputEvent>) => {
            const searchTerm = e.target.value.toLowerCase();
            const items = dropdownMenuRef.current?.querySelectorAll("li");

            items?.forEach((item) => {
              const text = item.textContent?.toLowerCase();
              if (text?.includes(searchTerm)) {
                item.style.display = "block";
              } else {
                item.style.display = "none";
              }
            });
          }}
        />
        {Children.map(
          children,
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {
              ...child.props,
              selected: child.props.value === value,
              onClick(value: string) {
                onChange(value);
                toggleDropdown();
              },
            }),
        )}
      </div>
    </div>
  );
};

export { SearchableSelect, SearchableSelectOption as Option };
export default Object.assign(SearchableSelect, { Option: SearchableSelectOption });
