import { createReducer } from '@reduxjs/toolkit';

import { sidebarInitialState } from './state';
import { changeActiveRouteAction, changeSidebarExpandAction } from './actions';

export const sidebarReducer = createReducer(sidebarInitialState, (builder) => {
  builder.addCase(changeActiveRouteAction, (state, { payload }: any) => ({
    ...state,
    activeRoute: payload,
  }));

  builder.addCase(changeSidebarExpandAction, (state, { payload }: any) => ({
    ...state,
    isSideBarOpen: payload,
  }));
});
