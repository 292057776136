// import { useSelectedProjectSelector } from "../../../store/projects/selectors";
// import { dispatch } from "../../../store";
// import { changeSelectedTypeAction } from "../../../store/projects/actions";

import { useEffect, useState } from "react";

interface ChildProps {
  setSelectedUploadTab: React.Dispatch<React.SetStateAction<string>>;
}

export const UploadDocumentTabs = ({ setSelectedUploadTab }: ChildProps) => {
  //   const selectedType = useSelectedProjectSelector();

  const [selectedTab, setSelectedTab] = useState("plantDetails");

  useEffect(() => {
    setSelectedUploadTab(selectedTab);
  }, [selectedTab]);

  const tabs = [
    {
      code: "plantDetails",
      name: "Plant Details",
    },
    {
      code: "uploadDocuments",
      name: "Upload Documents",
    },
    {
      code: "trackProject",
      name: "Track Project",
    },
  ];

  const renderTabs = tabs.map((tab) => (
    <li className="me-8" key={tab.code}>
      <a
        href="#"
        className={
          "inline-block rounded-t-lg py-1 " +
          (selectedTab === tab.code
            ? "active border-b-2 border-primaryPurple-100 font-medium text-black"
            : "border-transparent font-normal hover:text-gray-600")
        }
        aria-current="page"
        onClick={() => setSelectedTab(tab?.code)}
      >
        {tab.name}
      </a>
    </li>
  ));

  return (
    <div className="text-center text-gray-500">
      <ul className="-mb-px flex flex-wrap border-b-2 border-gray-200">{renderTabs}</ul>
    </div>
  );
};
