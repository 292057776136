import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import DocumentUploadDropZone from "./DocumentUploadDropZone";
// import { useEffect, useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";

// testing redux
import { useUploadedFilesSelector } from "../../../store/projects/selectors";
import { deleteUploadFiles, clearUploadFiles } from "../../../store/projects/actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../api";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { Progress } from "@chakra-ui/react";

interface uploadDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalHeader: string;
  documentTypeID: number;
  setNewDocumentUploaded: (value: boolean) => void;
}

interface UploadProgress {
  percent: number;
  status: string;
}

const UploadDocumentsModal = ({
  isOpen,
  onClose,
  modalHeader,
  documentTypeID,
  setNewDocumentUploaded,
}: uploadDocumentModalProps) => {
  const selectedProject = useSelectedReProjectSelector();
  const dispatch = useDispatch();

  const [uploadProcessStart, setUploadProcessStart] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: UploadProgress }>({});

  useEffect(() => {
    dispatch(clearUploadFiles());
  }, [isOpen]);

  const uploadedFilesSet = useUploadedFilesSelector();

  const bytesToMB = (bytes: number) => {
    // 1 MB = 1024 * 1024 bytes
    const MB = bytes / (1024 * 1024);
    return MB.toFixed(2);
  };

  const deleteFile = (fileName: string) => {
    dispatch(deleteUploadFiles(fileName));
  };

  const handleUploadFiles = async () => {
    setUploadProcessStart(true);
    const formData = new FormData();

    if (uploadedFilesSet?.length > 0) {
      for (const uploadedFile of uploadedFilesSet) {
        formData.append("project_id", selectedProject?.id?.toString() ?? ""); // selected project ID
        formData.append("document_type_id", documentTypeID.toString()); // document type ID -> should be passed as a prop
        formData.append("file", uploadedFile?.uploadedFile);

        try {
          console.log("formData =>", formData);
          const responseUpload = await api.post("/document/upload", formData, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [uploadedFile?.uploadedFile.name]: {
                    percent: percentCompleted,
                    status: "uploading",
                  },
                }));
              } else {
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [uploadedFile?.uploadedFile.name]: {
                    percent: 100,
                    status: "uploading",
                  },
                }));
              }
            },
          });

          const jobId = responseUpload?.data?.job_id;
          if (!jobId) {
            console.error("Job ID not found in the upload response");
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [uploadedFile?.uploadedFile.name]: {
                ...prevProgress[uploadedFile?.uploadedFile.name],
                status: "failed",
              },
            }));
            continue;
          }

          const checkStatus = async () => {
            try {
              const responseStatus = await api.get(`/document/uploadstatus/${jobId}`);
              if (responseStatus?.data?.status === "completed") {
                clearInterval(statusInterval);
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [uploadedFile?.uploadedFile.name]: {
                    ...prevProgress[uploadedFile?.uploadedFile.name],
                    status: "completed",
                  },
                }));
                setNewDocumentUploaded(true);
              }
            } catch (error) {
              console.error("Error fetching upload status =>", error);
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [uploadedFile?.uploadedFile.name]: {
                  ...prevProgress[uploadedFile?.uploadedFile.name],
                  status: "failed",
                },
              }));
            }
          };

          const statusInterval = setInterval(checkStatus, 300);

          setTimeout(() => {
            clearInterval(statusInterval);
          }, 300000);
        } catch (error) {
          console.error("Error during file upload =>", error);
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [uploadedFile?.uploadedFile.name]: {
              ...prevProgress[uploadedFile?.uploadedFile.name],
              status: "failed",
            },
          }));
        }
      }
    }
    setUploadProcessStart(false);
  };

  const onCloseModal = () => {
    setUploadProgress({});
    onClose();
  };

  return (
    <div>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onCloseModal} size={"xl"}>
        <ModalOverlay />
        <div className="font-primary">
          <ModalContent>
            <div className="border-b border-b-gray-300">
              <ModalHeader>{modalHeader}</ModalHeader>
              <ModalCloseButton />
            </div>

            <ModalBody>
              <h1 className="font-medium">Instructions</h1>
              <div className="mt-2 flex items-center gap-4">
                <p className="font-medium">
                  1. Maximum File size: <span className="font-normal">5MB</span>
                </p>
                <p className="font-medium">
                  2. File Type: <span className="font-normal">CSV, Jpeg, Excel or PDF</span>
                </p>
              </div>
              {/* document drag and drop zone */}
              <div>
                <h1 className="mb-2 mt-4 font-medium">Upload</h1>
                <DocumentUploadDropZone modalHeader={modalHeader} />
              </div>

              {/* uploaded files */}
              <div className="my-3 space-y-2">
                {uploadedFilesSet &&
                  uploadedFilesSet.map((uploadedFile, index) => {
                    return (
                      <div className="rounded-md border border-gray-400 p-3" key={index}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <div>
                              <FaRegFileAlt />
                            </div>
                            <div>
                              <p className="text-sm font-medium text-black">
                                {uploadedFile?.uploadedFile.name}
                              </p>
                              <p className="text-sm font-normal text-gray-600">
                                {bytesToMB(uploadedFile?.uploadedFile.size)} MB
                              </p>
                            </div>
                          </div>
                          <div>
                            <MdOutlineDeleteOutline
                              className="cursor-pointer text-xl"
                              onClick={() => deleteFile(uploadedFile?.uploadedFile.name)}
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          {uploadProcessStart && (
                            <>
                              <Progress
                                colorScheme="green"
                                size="sm"
                                value={
                                  uploadProgress[uploadedFile?.uploadedFile.name]?.percent
                                    ? uploadProgress[uploadedFile?.uploadedFile.name]?.percent
                                    : 0
                                }
                              />
                            </>
                          )}
                          <p
                            className={`text-sm font-normal ${
                              uploadProgress[uploadedFile?.uploadedFile.name]?.status ===
                                "completed" && "text-green-500"
                            } ${
                              uploadProgress[uploadedFile?.uploadedFile.name]?.status ===
                                "failed" && "text-red-600"
                            }`}
                          >
                            {uploadProgress[uploadedFile?.uploadedFile.name]?.status
                              ? uploadProgress[uploadedFile?.uploadedFile.name]?.status
                              : ""}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </ModalBody>

            <ModalFooter>
              <button
                onClick={onCloseModal}
                className="mx-3 rounded-md border border-black px-4 py-2 font-normal text-black outline-none"
              >
                Cancel
              </button>
              <button
                className="rounded-md border border-black bg-black px-4 py-2 font-normal text-white outline-none"
                onClick={handleUploadFiles}
              >
                Upload
              </button>
            </ModalFooter>
          </ModalContent>
        </div>
      </Modal>
    </div>
  );
};

export default UploadDocumentsModal;
