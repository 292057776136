import React from "react";
import PropTypes from "prop-types";

// utils
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import findMatch from "../../utils/findMatch";
import objectsToString from "../../utils/objectsToString";

// context
import { useTheme } from "../../context/theme";

// components
import Spinner from "../Spinner";

// types
import type { variant, size, color, className } from "../../types/components/button";
import { propTypesColor, propTypesSize, propTypesVariant } from "../../types/components/button";

export interface ActionIconProps extends React.ComponentProps<"button"> {
  variant?: variant;
  size?: size;
  color?: color;
  className?: className;
  icon: React.ReactNode;
  loading?: boolean;
}

const ActionIcon: React.FC<ActionIconProps> = ({
  variant,
  size,
  color,
  className,
  icon,
  loading,
  ...rest
}) => {
  // 1. init
  const { button } = useTheme();
  const { valid, defaultProps, styles } = button;
  const { base, variants, sizes } = styles;

  // 2. set default props
  variant = variant ?? defaultProps.variant;
  size = size ?? defaultProps.size;
  color = color ?? defaultProps.color;
  className = twMerge(defaultProps.className || "", className);

  // 3. set styles
  const buttonBase = objectsToString(base.initial);
  const buttonVariant = objectsToString(
    variants[findMatch(valid.variants, variant, "filled")][findMatch(valid.colors, color, "gray")],
  );
  const buttonSize = objectsToString(sizes[findMatch(valid.sizes, size, "md")]);
  const classes = twMerge(
    classnames(buttonBase, buttonSize, buttonVariant, {
      "flex items-center": loading,
    }),
    className,
  );

  const spinnerClass = twMerge(
    classnames({
      "w-4 h-4": true,
    }),
  );

  // 4. return
  return (
    <button {...rest} disabled={rest.disabled || loading} className={classes} type="button">
      {loading && <Spinner className={spinnerClass} />}
      {icon}
    </button>
  );
};

ActionIcon.propTypes = {
  variant: PropTypes.oneOf(propTypesVariant),
  size: PropTypes.oneOf(propTypesSize),
  color: PropTypes.oneOf(propTypesColor),
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default ActionIcon;
