import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { of, withLatestFrom } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'ts-action-operators';
import { loadProjectsSpecific } from './actions';
import { RootState } from '../root-state.type';
import { EProjectStatus, EProjectTypes, TSuggestions } from '../../types';
import { fetchIndicator, fetchReProjects } from '../../api/home.api';

const loadProjectsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(loadProjectsSpecific.submit),
    withLatestFrom(state$), // Combine with the latest state
    switchMap(([action, state]: [Action, RootState]) => {
      () => action;
      const {
        selected,
        filters,
        search: location,
        searchByNaming: search,
        pagination: { page, perPage },
      } = state.projects;
      const { role } = state.user;

      let commonProps: TSuggestions = { suggestions: [] };
      let filterProps = {};
      if (location) {
        commonProps = {
          suggestions: [
            {
              id: location.id,
              level: location?.level,
              name: location?.name,
            },
          ],
        };
      }
      const fCopy = { ...filters, page, take: perPage } as any;
      filterProps = Object.keys(fCopy).reduce((acc: any, curr) => {
        if (fCopy[curr] !== 'None' && !!fCopy[curr]) {
          acc[curr] = fCopy[curr];
          return acc;
        }
        return acc;
      }, {});

      if (search) {
        filterProps = { ...filterProps, search };
      }

      switch (selected) {
        case EProjectTypes.REGISTERED:
          filterProps = {
            ...filterProps,
            status: [EProjectStatus.CERTIFICATE_ISSUED, EProjectStatus.SALE_MADE],
          };
          break;
        case EProjectTypes.IN_PROGRESS:
          filterProps = {
            ...filterProps,
            status: [
              EProjectStatus.ADD_PROJECT,
              EProjectStatus.DOCS_RECEIVED,
              EProjectStatus.SENT_TO_REGISTRY,
              EProjectStatus.DUE_DILIGENCE_CHECK,
            ],
          };
          break;
        case EProjectTypes.COMPLETED:
          filterProps = { ...filterProps, status: [EProjectStatus.DOCS_APPROVED] };
          break;
      }

      fetchReProjects(role, commonProps, filterProps);
      fetchIndicator(role);

      return of(loadProjectsSpecific.success({}));
    }),
    catchError((error) => {
      console.error('Error in loadProjectsEpic:', error);
      return of(loadProjectsSpecific.fail(error.message));
    }),
  );

export const projectsEpics = combineEpics(loadProjectsEpic);
