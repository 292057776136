import { RxDashboard } from "react-icons/rx";
import { MdOutlineDocumentScanner, MdOutlineLiveHelp } from "react-icons/md";
import { TbReportSearch, TbUsers } from "react-icons/tb";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { RiUserSettingsLine } from "react-icons/ri";
// import ProClimeLogo from "../../images/ProClimeLogo.png";
// import ProClimeLogoSecondary from "../../images/ProClimeLogoSecondary.png";
import { useNavigate } from "react-router-dom";
import { useUserSelector } from "../../store/user/selectors";
import { EUserRoles } from "../../types";
import { changeActiveRouteAction } from "../../store/sidebar/actions";
import { dispatch } from "../../store";
import { useSelectActiveRouteSelector } from "../../store/sidebar/selectors";
import { useSelectSidebarExpandSelector } from "../../store/sidebar/selectors";

const SideBar = () => {
  const navigate = useNavigate();
  const activeRoute = useSelectActiveRouteSelector();
  const isSideBarOpen = useSelectSidebarExpandSelector();
  const { role } = useUserSelector();
  const sideBarMenuItems = [
    {
      icon: <RxDashboard />,
      title: "Dashboard",
      route: "dashboard",
      allowedRole: [EUserRoles.PROCLIME_USER, EUserRoles.CLIENT_SELLER],
    },
    {
      icon: <MdOutlineDocumentScanner />,
      title: "Projects",
      route: "/",
      allowedRole: [EUserRoles.PROCLIME_USER, EUserRoles.CLIENT_SELLER],
    },
    {
      icon: <TbUsers />,
      title: "Customers",
      allowedRole: [EUserRoles.PROCLIME_USER],
    },
    {
      icon: <HiOutlineClipboardDocumentList />,
      title: "Reports",
      allowedRole: [EUserRoles.PROCLIME_USER],
    },
    {
      icon: <RiUserSettingsLine />,
      title: "User Management",
      allowedRole: [EUserRoles.PROCLIME_USER],
    },
    {
      icon: <TbReportSearch />,
      title: "Audit Trial",
      route: "/audit-trail",
      allowedRole: [EUserRoles.PROCLIME_USER, EUserRoles.CLIENT_SELLER],
    },
    {
      icon: <MdOutlineLiveHelp />,
      title: "Help & Support",
      route: "/help-support",
      allowedRole: [EUserRoles.PROCLIME_USER, EUserRoles.CLIENT_SELLER],
    },
  ];

  const handleNavigation = (route: string | undefined) => {
    dispatch(changeActiveRouteAction(route));
    // setActiveRoute(route || "/");
    navigate(route || "/", { replace: true });
  };

  return (
    <div
      className={`sticky top-0 h-screen ${
        isSideBarOpen ? "w-72" : "w-20"
      } border-r border-r-gray-100 bg-white transition-all duration-100 ease-in-out`}
      id="sidebar"
    >
      {/* logo goes here */}
      <div className="h-11 py-4 text-center">
        {/* {isSideBarOpen ? (
          <img
            src={ProClimeLogo}
            alt="proclime logo"
            className={`mx-auto block h-11 ${isSideBarOpen ? "block" : "hidden scale-0"}`}
          />
        ) : (
          <img
            src={ProClimeLogoSecondary}
            alt="proclime secondary logo"
            className={`mx-auto h-11  ${!isSideBarOpen ? "block" : "hidden"}`}
          />
        )} */}
      </div>

      {/* links */}
      <nav className="mx-auto mt-8 w-full px-2">
        {sideBarMenuItems.map((menuItem, index) => {
          return (
            menuItem.allowedRole.includes(role) && (
              <li
                key={index}
                className={`flex h-[44px] w-full cursor-pointer items-center ${
                  isSideBarOpen ? "gap-4" : "gap-0"
                } hover:bg-[rgba(72,47,139,0.05)] hover:text-purple-900 ${
                  !isSideBarOpen && "rounded-l-lg"
                } ${
                  activeRoute === menuItem.route &&
                  "bg-[rgba(72,47,139,0.05)] font-medium text-purple-900"
                } rounded-md transition-all duration-200 ease-in-out`}
                onClick={() => handleNavigation(menuItem.route)}
              >
                <div className={`flex items-center ${isSideBarOpen ? "gap-2" : "gap-0"} px-4`}>
                  <span className="text-xl">{menuItem.icon}</span>
                  {isSideBarOpen && <span>{menuItem.title}</span>}
                </div>
              </li>
            )
          );
        })}
      </nav>
    </div>
  );
};

export default SideBar;
