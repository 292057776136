import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

export function useStateRef<Type>(
  initialValue: Type,
): [Type, Dispatch<SetStateAction<Type>>, RefObject<Type>] {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}
