import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const OAuthSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return <></>;
};

export default OAuthSuccess;
