import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

interface viewSampleDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentDetails: Document | null;
}

interface Document {
  id: number;
  document_name: string;
  system_gen_name: string;
  type: string;
  file_size: number;
  status: string;
  uploaded_at: string;
  type_id: number;
  project_id: number;
  user_id: number;
}

const ViewDocumentPreview = ({
  isOpen,
  onClose,
  documentDetails,
}: viewSampleDocumentModalProps) => {
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <div className="border-b border-b-gray-300">
            <ModalHeader>{documentDetails?.document_name}</ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            {/* <iframe
              src={`https://docs.google.com/viewer?url=https://irechubdocuments.blob.core.windows.net/uploads/${documentDetails?.system_gen_name}.doc&embedded=true`}
            ></iframe> */}

            <iframe
              src={`https://irechubdocuments.blob.core.windows.net/uploads/${documentDetails?.system_gen_name}`}
              width="100%"
              height="500px"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ViewDocumentPreview;
