import { api, cookies } from './index';
import {
  EProjectStatus,
  EUserRoles,
  TReProjectData,
  TReProjectDataResponse,
  TSuggestions,
  TUserData,
} from '../types';
import { dispatch } from '../store';
import {
  loadFiltersValues,
  loadIndicatorsAction,
  loadProjectsSpecific,
} from '../store/projects/actions';
import { changeUserAction } from '../store/user/actions';
import { FilterValues } from '../store/projects/state';

export const fetchLogout = () => {
  const access_token = cookies.get('access_token');
  return api.get(`/auth/logout`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const fetchReProjects = async (
  userRole: EUserRoles,
  suggestions: TSuggestions | null,
  params?: any,
) => {
  try {
    let body: any = { ...params };
    if (suggestions && suggestions.suggestions.length !== 0) {
      const { id, level } = suggestions.suggestions[0];
      body = { ...body, id, level };
    }

    if (params && params.locations) {
      const coordinates = Object.values(params.locations).map((item: any) =>
        item.map(({ lat, lng }: any) => [`${lng}`, `${lat}`]),
      );
      body.coordinates = coordinates;
    }

    const prefix = () => {
      switch (userRole) {
        case EUserRoles.CLIENT_SELLER:
          return `/seller`;
        case EUserRoles.PROCLIME_USER:
          return `/admin`;
        default:
          return '';
      }
    };

    const response = await api.post<TReProjectDataResponse>(`${prefix()}/re-projects/search`, body);

    const reProjectDataArray: TReProjectData[] = response?.data?.items || [];

    if (response) {
      dispatch(
        loadProjectsSpecific.success({
          projects: {
            items: reProjectDataArray,
          },
          pagination: {
            total: response?.data?.meta.count ?? 0,
            page: response?.data?.meta.page ?? 1,
            perPage: response?.data?.meta.take ?? 10,
          },
        }),
      );
    }

    return reProjectDataArray;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch(loadProjectsSpecific.fail('Results not found'));
      return;
    }
    console.error('Error in:', error);
  }
};

export const fetchIndicator = async (userRole: EUserRoles) => {
  const body = {
    take: 10,
    page: 1,
  };
  try {
    const prefix = () => {
      switch (userRole) {
        case EUserRoles.CLIENT_SELLER:
          return `/seller`;
        case EUserRoles.PROCLIME_USER:
          return `/admin`;
        default:
          return '';
      }
    };

    const responseTotal = await api.post<TReProjectDataResponse>(
      `${prefix()}/re-projects/search`,
      body,
    );

    const responseInProgress = await api.post<TReProjectDataResponse>(
      `${prefix()}/re-projects/search`,
      {
        ...body,
        status: [
          EProjectStatus.ADD_PROJECT,
          EProjectStatus.DOCS_RECEIVED,
          EProjectStatus.SENT_TO_REGISTRY,
          EProjectStatus.DUE_DILIGENCE_CHECK,
        ],
      },
    );

    if (responseTotal && responseInProgress) {
      dispatch(
        loadIndicatorsAction({
          total: responseTotal?.data?.meta.count ?? 0,
          inProgress: responseInProgress?.data?.meta.count ?? 0,
        }),
      );
    }
  } catch (error) {
    console.error('Error in:', error);
  }
};

export const fetchUserData = async () => {
  const response = await api.get<TUserData>('/user/me').catch((error) => {
    console.error('Error in request:', error);
    return null;
  });
  if (response) {
    dispatch(changeUserAction(response.data));
  }
  return response;
};

export const fetchFilters = async (userRole: EUserRoles) => {
  const access_token = cookies.get('access_token');
  dispatch(loadFiltersValues.submit());
  const { data } = await api.get(`/filters`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  const searchUserResponse = await api.post<{
    items: {
      id: number;
      username: string;
      client_name: string;
    }[];
  }>('/user/search', {
    page: 1,
    take: 100,
  });

  const countryResponse =
    await api.get<{ id: number; country: string }[]>('/admin-levels/countries');

  const filterValues: FilterValues =
    userRole !== EUserRoles.PROCLIME_USER
      ? data
      : {
          ...data,
          sellers: searchUserResponse.data.items
            .map((item) => ({ id: item.id, username: item.client_name }))
            .filter((item: any) => item.username),
          countries: countryResponse.data,
        };
  dispatch(loadFiltersValues.success(filterValues));
};

export const fetchSellerFilter = async (username: string) => {
  const searchUserResponse = await api.post('/user/search', { username, page: 1, take: 100 });
  const filterValues: Partial<FilterValues> = {
    sellers: searchUserResponse.data.items
      .map((item: any) => ({ id: item.id, username: item.client_name }))
      .filter((item: any) => item.username),
  };
  dispatch(loadFiltersValues.success(filterValues));
};

export const fetchState = async (country_id: number) => {
  const stateResponse = await api.get<{ id: number; name: string }[]>(
    `/admin-levels/states?country_id=${country_id}`,
  );
  dispatch(loadFiltersValues.success({ states: stateResponse.data }));
};
