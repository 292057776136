import UploadDocumentsBox from "../../Projects/components/UploadDocumentsBox";
import { api } from "../../../api";
import { useEffect, useState } from "react";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { useToast, Button } from "@chakra-ui/react";

const UploadDocumentsTab = () => {
  const toast = useToast();
  const selectedProject = useSelectedReProjectSelector();
  const [documentTypes, setDocumentTypes] = useState<any>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  const fetchDocumentTypes = async () => {
    try {
      const fetchedDocumentTypes = await api.get("/document/types");
      setDocumentTypes(fetchedDocumentTypes.data);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const downloadAllDocuments = async () => {
    setIsDownloading(true);
    try {
      const projectID: number | undefined = selectedProject?.id;
      const response = await api.get(`/document/download-docs/${projectID}`, {
        responseType: "blob",
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);

        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
        const filename = filenameMatch ? filenameMatch[1] : `IREC_PROJ_${projectID}_DOCS.zip`;

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
        toast({
          title: "Documents downloaded successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.error("Download failed with status:", response.status);
        toast({
          title: "Documents downloading Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsDownloading(false);
      }
    } catch (err) {
      console.error("Error downloading documents:", err);
      toast({
        title: "Documents downloading Failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsDownloading(false);
    }
  };

  return (
    <div className="rounded-md bg-white p-4 shadow-md">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-xl font-semibold text-black">Upload Documents</h1>
          <p className="text-sm font-normal text-gray-500">
            Supported Format PNG, JPG or PDF smaller than 5MB
          </p>
        </div>
        <div className="flex items-center justify-between gap-3">
          <Button
            isLoading={isDownloading}
            loadingText="Downloading Files"
            colorScheme="blue"
            variant="solid"
            spinnerPlacement="start"
            onClick={downloadAllDocuments}
          >
            Download Documents
          </Button>

          {/*<button*/}
          {/*  onClick={downloadAllDocuments}*/}
          {/*  type="submit"*/}
          {/*  className="rounded-md bg-primaryPurple-200 px-3 py-1 text-white hover:bg-primaryPurple-100"*/}
          {/*  disabled={isDownloading}*/}
          {/*>*/}
          {/*  Download Documents*/}
          {/*</button>*/}
          <div>View Switch</div>
        </div>
      </div>

      {/* upload documents div */}
      {documentTypes?.map((documentType: any) => {
        return (
          <UploadDocumentsBox
            documentType={documentType?.type}
            key={documentType?.id}
            documentID={documentType?.id}
          />
        );
      })}
    </div>
  );
};

export default UploadDocumentsTab;
