import { CSSProperties, useEffect, useRef, useState } from "react";
import { mapStyles } from "./GoogleMapStyle";
import ReactDOMServer from "react-dom/server";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { TReProjectData } from "../../types";

export type CiidMapProps = {
  readonly mapContainerStyle: CSSProperties;
  readonly currentProjects: TReProjectData[];
};

export function CiidMap({ mapContainerStyle, currentProjects }: CiidMapProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [cluster, setCluster] = useState<MarkerClusterer>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 3,
          center: {
            lat: 20.5937,
            lng: 78.9629,
          },
          restriction: {
            latLngBounds: { north: 90, south: -90, east: 180, west: -180 },
            strictBounds: false,
          },
          styles: mapStyles,
          streetViewControl: false,
          mapTypeControl: false,
        }),
      );
    }
  }, [ref, map]);

  useEffect(() => {
    if (map && window.google && window.google.maps) {
      const createMarkerWithListener = (
        position: google.maps.LatLngLiteral,
        projectName: string,
        projectStatus: string,
        projectId: string,
      ) => {
        const marker = new window.google.maps.Marker({
          position,
          map,
          title: projectName,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          },
        });

        const infoContent = ReactDOMServer.renderToString(
          <div className="text-left">
            <h3 className="mb-2">Name: {projectName}</h3>
            <p className="mb-2">Status: {projectStatus}</p>
            <a
              href={`/project/${projectId}`}
              className="text-blue-500 underline hover:text-blue-700"
            >
              More details
            </a>
          </div>,
        );

        const info = new window.google.maps.InfoWindow({
          content: infoContent,
          ariaLabel: projectName,
        });

        marker.addListener("click", () => {
          map.panTo({ lat: position.lat, lng: position.lng });
          info.open({
            anchor: marker,
            map,
          });
        });

        return marker;
      };

      // Clear existing markers from the map
      markers.forEach((marker) => {
        marker.setMap(null);
      });

      // Create new markers
      const newMarkers = currentProjects
        .filter(
          (project) =>
            project.location &&
            project.location.coordinates &&
            project.location.coordinates.length === 2,
        )
        .map((project) =>
          createMarkerWithListener(
            {
              lat: project.location.coordinates[1],
              lng: project.location.coordinates[0],
            },
            project.name,
            project.state,
            project.id.toString(),
          ),
        );

      setMarkers(newMarkers);
      const cluster = new MarkerClusterer({ markers: newMarkers, map });
      setCluster(cluster);
    }
  }, [map, currentProjects]);

  useEffect(() => {
    return () => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
    };
  }, [markers]);

  useEffect(() => {
    return () => {
      if (cluster) {
        cluster.clearMarkers();
      }
    };
  }, [cluster]);

  return <div style={mapContainerStyle} ref={ref} id="map" />;
}
