import Typography from "../Typography";
import Button from "../Button";
import SuccessSVG from "../../assets/Icons/Success";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import ErrorSVG from "../../assets/Icons/Error";

export interface NotificationModalProps {
  isOpen: boolean;
  handleOpen: () => void;
  title?: string;
  content?: string;
  isSuccess?: boolean;
}

export const NotificationModal = ({
  isOpen,
  handleOpen,
  title = "Successful",
  content = "Your request has ben complete successfully",
  isSuccess = true,
}: NotificationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={handleOpen} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {isSuccess ? (
            <SuccessSVG className="mx-auto my-6 h-16 w-16" />
          ) : (
            <ErrorSVG className="mx-auto my-6 h-16 w-16" />
          )}

          <Typography variant="h4" className="mb-2 text-center text-primaryBlack-200">
            {title}
          </Typography>

          <Typography className="text-center font-normal text-primaryBlack-100">
            {content}
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={handleOpen}
            className="mx-auto rounded-full bg-primaryPurple-100 px-6 py-2.5 text-base normal-case text-white outline-none"
            style={{ minWidth: "9rem" }}
          >
            Okay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
