import { useEffect } from "react";
import { Drawer } from "@material-tailwind/react";
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import ActionIcon from "../../../components/ActionIcon";
import {
  changeFilterAction,
  changePaginationAction,
  loadProjectsSpecific,
  resetFilterAction,
} from "../../../store/projects/actions";
import { dispatch } from "../../../store";
import {
  useProjectsSelector,
  useFilterProjectSelector,
  useFilterValuesSelector,
} from "../../../store/projects/selectors";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import { useUserSelector } from "../../../store/user/selectors";
import { EUserRoles } from "../../../types";
import MultiSelect from "../../../components/MultiSelect";
import SearchableSelect from "../../../components/SearchableSelect";
import SearchableMultiSelect from "../../../components/SearchableMultiSelect";
import { fetchState } from "../../../api/home.api";
import { FilterLabel } from "./FilterLabel";
import { useSearchParams } from "react-router-dom";

interface DrawerDefaultProps {
  isOpen: boolean;
  onClose: () => void;
}

//TODO: find way to make drawer smooth with overlay
export function FilterDrawer({ isOpen, onClose }: DrawerDefaultProps) {
  const { role } = useUserSelector();
  const filter = useFilterProjectSelector();
  const {
    technology,
    technology_group,
    country,
    max_capacity,
    min_capacity,
    capacity_bins,
    commissioning_date_from,
    commissioning_date_to,
    // last_updated_start_date,
    // last_updated_end_date,
    registration_date_from,
    registration_date_to,
    expiry_date_from,
    expiry_date_to,
    registrant,
    // min_certificates_issued,
    // max_certificates_issued,
    state,
    user_id,
    // seller_deal,
  } = filter;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterChange = (key: string, value: unknown) => {
    dispatch(changeFilterAction({ [key]: value }));
  };

  const { data: filterValues } = useFilterValuesSelector();

  const { isLoading: isLoadingProject } = useProjectsSelector();

  useEffect(() => {
    if (!country) return;
    const country_id = filterValues.countries.find((item) => item.country === country)?.id;
    country_id && fetchState(country_id);
  }, [country]);

  const handleApplyFilters = () => {
    setSearchParams({ page: "1" });
    dispatch(changePaginationAction({ page: 1 }));
    dispatch(loadProjectsSpecific.submit());
  };

  const handleResetFilters = () => {
    dispatch(resetFilterAction());
    handleApplyFilters();
  };

  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );

  return (
    <Drawer
      placement="right"
      placeholder="filter"
      open={isOpen}
      onClose={onClose}
      className="mt-14 overflow-auto border-r border-gray-500 px-6 shadow-lg"
      size={480}
      transition={{ type: "tween", duration: 0.5 }}
      overlay={false}
    >
      <div className="sticky top-0 z-10 mb-6 flex items-center justify-between bg-white pt-6">
        <Typography variant="h5">Filters</Typography>
        <ActionIcon icon={closeIcon} variant="text" color="gray" onClick={onClose} />
      </div>
      <div className="mb-36 flex flex-col gap-6">
        <MultiSelect
          className="h-full w-full"
          label="Technology"
          color="gray"
          variant="outlined"
          value={technology}
          onChange={(value: any) => {
            const copy: any = new Set(technology);

            const isExist = copy.has(value);

            if (isExist) {
              copy.delete(value);
              handleFilterChange("technology", [...copy]);
            } else {
              copy.add(value);
              handleFilterChange("technology", [...copy]);
            }
          }}
        >
          {filterValues.technologies?.map((item, index) => (
            <MultiSelect.Option value={item} key={`${index}-${item}`}>
              {item}
            </MultiSelect.Option>
          )) ?? <></>}
        </MultiSelect>

        <MultiSelect
          className="h-full w-full"
          label="Technology Group"
          color="gray"
          variant="outlined"
          value={technology_group}
          onChange={(value: any) => {
            const copy: any = new Set(technology_group);

            const isExist = copy.has(value);

            if (isExist) {
              copy.delete(value);
              handleFilterChange("technology_group", [...copy]);
            } else {
              copy.add(value);
              handleFilterChange("technology_group", [...copy]);
            }
          }}
        >
          {filterValues.technology_groups?.map((item, index) => (
            <MultiSelect.Option value={item} key={`${index}-${item}`}>
              {item}
            </MultiSelect.Option>
          )) ?? <></>}
        </MultiSelect>

        <SearchableSelect
          label={"Country"}
          placeholder={"Country"}
          value={country?.toString()}
          onChange={(value: string) => {
            handleFilterChange("country", value);
          }}
        >
          {filterValues.countries?.map((item, index) => (
            <SearchableSelect.Option
              key={`select-country-${index}-${item.id}`}
              value={item.country}
            >
              {item.country}
            </SearchableSelect.Option>
          ))}
        </SearchableSelect>

        <SearchableSelect
          label={"State"}
          placeholder={"State"}
          value={state?.toString()}
          onChange={(value: string) => {
            handleFilterChange("state", value);
          }}
          disabled={filterValues?.states?.length === 0}
        >
          {filterValues.states?.map((item, index) => (
            <SearchableSelect.Option key={`select-state-${index}-${item.id}`} value={item.name}>
              {item.name}
            </SearchableSelect.Option>
          ))}
        </SearchableSelect>

        <div>
          <FilterLabel>Capacity</FilterLabel>
          <div className="grid grid-cols-2 gap-6">
            <Input
              type="number"
              label="From"
              value={min_capacity ?? ""}
              onChange={(e) => handleFilterChange("min_capacity", Number(e.target.value))}
            />
            <Input
              type="number"
              label="To"
              value={max_capacity ?? ""}
              onChange={(e) => handleFilterChange("max_capacity", Number(e.target.value))}
            />
          </div>
        </div>
        <MultiSelect
          className="h-full w-full"
          label="Capacity Bins"
          color="gray"
          variant="outlined"
          value={capacity_bins}
          onChange={(value: any) => {
            const copy: any = new Set(capacity_bins);

            const isExist = copy.has(value);

            if (isExist) {
              copy.delete(value);
              handleFilterChange("capacity_bins", [...copy]);
            } else {
              copy.add(value);
              handleFilterChange("capacity_bins", [...copy]);
            }
          }}
        >
          {filterValues.capacity_bins?.map((item, index) => (
            <MultiSelect.Option value={item} key={`${index}-${item}`}>
              {item}
            </MultiSelect.Option>
          )) ?? <></>}
        </MultiSelect>

        <div>
          <FilterLabel>Commissioning Date</FilterLabel>
          <div className="grid grid-cols-2 gap-6">
            <DatePicker
              label="From Date"
              value={commissioning_date_from}
              onSelect={(value?: Date) => {
                handleFilterChange("commissioning_date_from", value);
              }}
            />
            <DatePicker
              label="To Date"
              value={commissioning_date_to}
              onSelect={(value?: Date) => {
                handleFilterChange("commissioning_date_to", value);
              }}
            />
          </div>
        </div>

        {/*<div>
          <FilterLabel>Last Updated Date</FilterLabel>
          <div className="grid grid-cols-2 gap-6">
            <DatePicker
              label="Select start date"
              value={last_updated_start_date}
              onChange={(value: any) => {
                if (value.constructor.name === "SyntheticBaseEvent")
                  handleFilterChange("last_updated_start_date", null);
                else
                  handleFilterChange(
                    "last_updated_start_date",
                    new Date(value).toISOString().split("T")[0],
                  );
              }}
            />
            <DatePicker
              label="Select end date"
              value={last_updated_end_date}
              onChange={(value: any) => {
                if (value.constructor.name === "SyntheticBaseEvent")
                  handleFilterChange("last_updated_end_date", null);
                else
                  handleFilterChange(
                    "last_updated_end_date",
                    new Date(value).toISOString().split("T")[0],
                  );
              }}
            />
          </div>
        </div>*/}

        <div>
          <FilterLabel>Registration Date</FilterLabel>
          <div className="grid grid-cols-2 gap-6">
            <DatePicker
              label="From Date"
              value={registration_date_from}
              onSelect={(value?: Date) => {
                handleFilterChange("registration_date_from", value);
              }}
            />
            <DatePicker
              label="To Date"
              value={registration_date_to}
              onSelect={(value?: Date) => {
                handleFilterChange("registration_date_to", value);
              }}
            />
          </div>
        </div>

        <div>
          <FilterLabel>Expiry Date</FilterLabel>
          <div className="grid grid-cols-2 gap-6">
            <DatePicker
              label="From Date"
              value={expiry_date_from}
              onSelect={(value?: Date) => {
                handleFilterChange("expiry_date_from", value);
              }}
            />
            <DatePicker
              label="To Date"
              value={expiry_date_to}
              onSelect={(value?: Date) => {
                handleFilterChange("expiry_date_to", value);
              }}
            />
          </div>
        </div>

        <SearchableMultiSelect
          label={"Registrant"}
          placeholder={"Registrant"}
          value={registrant}
          onChange={(value: string) => {
            const copy: any = new Set(registrant);

            const isExist = copy.has(value);

            if (isExist) {
              copy.delete(value);
              handleFilterChange("registrant", [...copy]);
            } else {
              copy.add(value);
              handleFilterChange("registrant", [...copy]);
            }
          }}
        >
          {filterValues.registrant?.map((item, index) => (
            <SearchableMultiSelect.Option
              key={`multiselect-registrant-${index}-${item}`}
              value={item}
            >
              {item}
            </SearchableMultiSelect.Option>
          ))}
        </SearchableMultiSelect>

        {/*<div>
          <Typography variant="small" color="black" className="mt-0 pb-2">
            Certificates Issued
          </Typography>
          <div className="mb-4 grid grid-cols-2 gap-6">
            <Input
              label="From"
              value={min_certificates_issued}
              onChange={(e) => handleMinCertificateIssuedChange(e)}
            />
            <Input
              label="To"
              value={max_certificates_issued}
              onChange={(e) => handleMaxCertificateIssuedChange(e)}
            />
          </div>
        </div>*/}

        {role == EUserRoles.PROCLIME_USER ? (
          <SearchableSelect
            label="Seller"
            placeholder="Search seller"
            value={user_id?.toString()}
            onChange={(value: string) => {
              handleFilterChange("user_id", Number(value));
            }}
          >
            {filterValues.sellers?.map((item, index) => (
              <SearchableSelect.Option
                key={`select-seller-${index}-${item.id}`}
                value={item.id.toString()}
              >
                {item.username}
              </SearchableSelect.Option>
            ))}
          </SearchableSelect>
        ) : null}

        {/*<MultiSelect
          className="h-full w-full"
          label="Seller deal"
          color="gray"
          variant="outlined"
          value={seller_deal}
          onChange={(value: any) => {
            const copy: any = new Set(seller_deal);

            const isExist = copy.has(value);

            if (isExist) {
              copy.delete(value);
              handleFilterChange("seller_deal", [...copy]);
            } else {
              copy.add(value);
              handleFilterChange("seller_deal", [...copy]);
            }
          }}
        >
          {filterValues.seller_deals?.slice(0, 128).map((item, index) => (
            <MultiSelectOption value={item} key={`${index}-${item}`}>
              {item}
            </MultiSelectOption>
          )) ?? <></>}
        </MultiSelect>*/}
      </div>
      <div className="fixed bottom-0 z-10 flex w-full flex-row gap-[10px] bg-white py-4 pr-2">
        <Button size="md" variant="outlined" onClick={handleResetFilters}>
          Reset Filter
        </Button>
        <Button size="md" color="gray" loading={isLoadingProject} onClick={handleApplyFilters}>
          Apply Filter
        </Button>
      </div>
    </Drawer>
  );
}
