import React from "react";

import { TCustomIcon } from "../../types";

const ErrorSVG = ({ fill = "#FF6C37", className }: TCustomIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className ? className : "h-6 w-6"}
    >
      <path
        d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"
        fill={fill}
      />
    </svg>
  );
};

export default ErrorSVG;
