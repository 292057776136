import { useEffect, useState } from "react";
import { IoIosArrowDropup } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";
import { FaUpload } from "react-icons/fa6";
import UploadDocmentsModal from "./UploadDocmentsModal";
import ViewSampleDocument from "./ViewSampleDocument";
import { useDisclosure } from "@chakra-ui/react";
import { api } from "../../../api/index";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { MdDeleteOutline } from "react-icons/md";
import ViewDocumentPreview from "./ViewDocumentPreview";

// what should be coming as props
// * project ID => Project details => uploaded files
// 1. upload document type
// 2. uploaded documents

// interface for props
interface uploadDocumentsTypeProp {
  documentType: string;
  documentID: number;
}

interface Document {
  id: number;
  document_name: string;
  system_gen_name: string;
  type: string;
  file_size: number;
  status: string;
  uploaded_at: string;
  type_id: number;
  project_id: number;
  user_id: number;
}

interface UploadedDocuments {
  [key: string]: Document[];
}

const UploadDocumentsBox = ({ documentType, documentID }: uploadDocumentsTypeProp) => {
  // table heading for displaying uploaded documents in the table
  const TABLE_HEAD = ["", "Document Name", "Document Type", "Size", "Uploaded on"];

  const selectedProject = useSelectedReProjectSelector();

  const [boxExpand, setBoxExpand] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [newDocumentUploaded, setNewDocumentUploaded] = useState(false);
  const [previewDocDetails, setPreviewDocDetails] = useState<Document | null>(null);

  const {
    onOpen: onOpenUploadModal,
    isOpen: isOpenUploadModal,
    onClose: onCloseUploadModal,
  } = useDisclosure();

  const {
    onOpen: onOpenViewSampleModal,
    isOpen: isOpenViewSampleModal,
    onClose: onCloseViewSampleModal,
  } = useDisclosure();

  const {
    onOpen: onOpenViewPreviewDocModal,
    isOpen: isOpenViewPreviewDocModal,
    onClose: onCloseViewPreviewDocModal,
  } = useDisclosure();

  useEffect(() => {
    fetchUploadedDocuments(documentID);
  }, [newDocumentUploaded]);

  const fetchUploadedDocuments = async (id: number | undefined) => {
    try {
      const uploadedDocumentsResponse = await api.get(
        `/document/uploaded-docs/${selectedProject?.id}`,
      );

      const uploadedDocuments: UploadedDocuments = uploadedDocumentsResponse?.data;

      // Convert the id to a string before filtering
      const filteredDocuments = Object.keys(uploadedDocuments)
        .filter((key) => key === id?.toString()) // Convert id to string for comparison
        .reduce((obj: UploadedDocuments, key: string) => {
          obj[key] = uploadedDocuments[key];
          return obj;
        }, {});

      setUploadedDocuments(filteredDocuments[documentID]);
    } catch (error) {
      console.log("error =>", error);
    } finally {
      setNewDocumentUploaded(false);
    }
  };

  const handleDeleteDocument = async (deleteDocumentID: number) => {
    try {
      const deleteDocumentResponse = await api.delete(
        `/document/delete/${selectedProject?.id}/${deleteDocumentID}`,
      );

      if (deleteDocumentResponse.status === 200) {
        // recall the api to displaye updated documents
        fetchUploadedDocuments(documentID);
      }
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const handleOpenPreviewModal = async (previewDocument: Document) => {
    setPreviewDocDetails(previewDocument);
    onOpenViewPreviewDocModal();
  };

  const bytesToMB = (bytes: number) => {
    // 1 MB = 1024 * 1024 bytes
    const MB = bytes / (1024 * 1024);
    return MB.toFixed(2);
  };

  const convertToIST = (dateTimeStr: string) => {
    // Parse the input date-time string
    const date = new Date(dateTimeStr as string);

    // Get the offset between UTC and IST in minutes (IST is UTC + 5:30)
    const ISTOffset = 330;

    // Apply the offset to the date object
    const ISTDate = new Date(date.getTime() + ISTOffset * 60 * 1000);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day, month, year, hours, and minutes from the ISTDate
    const day = ISTDate.getDate();
    const month = monthNames[ISTDate.getMonth()];
    const year = ISTDate.getFullYear();
    const hours = ISTDate.getHours();
    let minutes = ISTDate.getMinutes();

    // Add a leading zero to minutes if necessary
    if (minutes < 10) {
      minutes = 0 + minutes;
    }

    // Format the date and time
    return `${day} ${month} ${year} at ${hours}:${minutes}`;
  };

  return (
    <div>
      <div className="my-4 rounded-sm border border-gray-400 p-4">
        {/* document upload div top section */}
        <div
          className={`flex items-center justify-between gap-4 ${
            boxExpand && "border-b border-b-gray-300 pb-3"
          }`}
        >
          <div className="flex items-center gap-2">
            <button onClick={() => setBoxExpand(!boxExpand)}>
              {boxExpand ? (
                <IoIosArrowDropup className="text-xl text-gray-500" />
              ) : (
                <IoIosArrowDropdown className="text-xl text-gray-500" />
              )}
            </button>
            <h1 className="font-medium">
              {documentType}({uploadedDocuments?.length ? uploadedDocuments?.length : 0})
              <span>
                |{" "}
                <span
                  className="cursor-pointer text-sm font-medium text-blue-700"
                  onClick={onOpenViewSampleModal}
                >
                  view sample documents
                </span>
              </span>
            </h1>
          </div>

          <button
            className="rounded-md bg-gray-700 px-3 py-1 text-sm text-white"
            onClick={onOpenUploadModal}
          >
            Upload Documents
          </button>
        </div>
        {/* uploaded documents display here */}
        {boxExpand && (
          <div className="mt-6">
            {/* display uploaded docs */}
            {uploadedDocuments?.length > 0 ? (
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 font-medium"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {uploadedDocuments.map((document: Document, index: number) => {
                    const isLast = index === uploadedDocuments.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                    return (
                      <tr key={document.id} className="text-gray-700">
                        <td className={classes}>
                          <span>
                            <MdDeleteOutline
                              className="cursor-pointer hover:text-red-600"
                              aria-disabled={true}
                              size={20}
                              onClick={() => handleDeleteDocument(document?.id)}
                            />
                          </span>
                        </td>
                        <td className={classes} onClick={() => handleOpenPreviewModal(document)}>
                          <span className="cursor-pointer hover:text-blue-700">
                            {document?.document_name}
                          </span>
                        </td>
                        <td className={classes}>
                          <span>{document?.type}</span>
                        </td>
                        <td className={classes}>
                          <span>{bytesToMB(document?.file_size)} MB</span>
                        </td>
                        <td className={classes}>
                          <span>{convertToIST(document?.uploaded_at)}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                <div className="mx-auto mb-3 w-max rounded-full bg-gray-300 p-3">
                  <FaUpload className="text-2xl text-black" />
                </div>
                <h3 className="font-semibold">Upload Documents</h3>
                <p className="text-gray-700">
                  There are no documents uploaded. Click on the Upload button to upload documents.
                </p>
              </div>
            )}
          </div>
        )}

        <UploadDocmentsModal
          isOpen={isOpenUploadModal}
          onClose={onCloseUploadModal}
          modalHeader={documentType}
          documentTypeID={documentID}
          setNewDocumentUploaded={setNewDocumentUploaded}
        />
        <ViewSampleDocument
          isOpen={isOpenViewSampleModal}
          onClose={onCloseViewSampleModal}
          modalHeader={documentType}
        />

        <ViewDocumentPreview
          isOpen={isOpenViewPreviewDocModal}
          onClose={onCloseViewPreviewDocModal}
          documentDetails={previewDocDetails}
        />
      </div>
    </div>
  );
};

export default UploadDocumentsBox;
