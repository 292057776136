const inputOutlinedLabelColors: object = {
  black: {
    color: '!text-black peer-focus:text-black',
    before: 'before:border-black peer-focus:before:!border-black',
    after: 'after:border-black peer-focus:after:!border-black',
  },
  white: {
    color: '!text-white peer-focus:text-white',
    before: 'before:border-white peer-focus:before:!border-white',
    after: 'after:border-white peer-focus:after:!border-white',
  },
  'blue-gray': {
    color: 'text-blue-gray-400 peer-focus:text-blue-gray-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-blue-gray-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-blue-gray-500',
  },
  gray: {
    color: 'text-gray-500 peer-focus:text-gray-900',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-gray-900',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-gray-900',
  },
  brown: {
    color: 'text-blue-gray-400 peer-focus:text-brown-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-brown-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-brown-500',
  },
  'deep-orange': {
    color: 'text-blue-gray-400 peer-focus:text-deep-orange-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-deep-orange-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-deep-orange-500',
  },
  orange: {
    color: 'text-blue-gray-400 peer-focus:text-orange-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-orange-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-orange-500',
  },
  amber: {
    color: 'text-blue-gray-400 peer-focus:text-amber-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-amber-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-amber-500',
  },
  yellow: {
    color: 'text-blue-gray-400 peer-focus:text-yellow-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-yellow-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-yellow-500',
  },
  lime: {
    color: 'text-blue-gray-400 peer-focus:text-lime-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-lime-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-lime-500',
  },
  'light-green': {
    color: 'text-blue-gray-400 peer-focus:text-light-green-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-light-green-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-light-green-500',
  },
  green: {
    color: 'text-blue-gray-400 peer-focus:text-green-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-green-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-green-500',
  },
  teal: {
    color: 'text-blue-gray-400 peer-focus:text-teal-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-teal-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-teal-500',
  },
  cyan: {
    color: 'text-blue-gray-400 peer-focus:text-cyan-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-cyan-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-cyan-500',
  },
  'light-blue': {
    color: 'text-blue-gray-400 peer-focus:text-light-blue-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-light-blue-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-light-blue-500',
  },
  blue: {
    color: 'text-blue-gray-400 peer-focus:text-blue-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-blue-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-blue-500',
  },
  indigo: {
    color: 'text-blue-gray-400 peer-focus:text-indigo-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-indigo-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-indigo-500',
  },
  'deep-purple': {
    color: 'text-blue-gray-400 peer-focus:text-deep-purple-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-deep-purple-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-deep-purple-500',
  },
  purple: {
    color: 'text-blue-gray-400 peer-focus:text-purple-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-purple-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-purple-500',
  },
  pink: {
    color: 'text-blue-gray-400 peer-focus:text-pink-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-pink-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-pink-500',
  },
  red: {
    color: 'text-blue-gray-400 peer-focus:text-red-500',
    before: 'before:border-blue-gray-200 peer-focus:before:!border-red-500',
    after: 'after:border-blue-gray-200 peer-focus:after:!border-red-500',
  },
};

export default inputOutlinedLabelColors;
