import { useNavigate } from "react-router-dom";
import { SkeletonLoader } from "./SkeletonLoader";
import { setSelectedReProject } from "../../../store/projects/actions";
import { dispatch } from "../../../store";
import { TReProjectData } from "../../../types";

interface TableRow {
  id: number;
  name: string;
  device_type: string;
  device_code: string;
  active: boolean;
  technology: string;
  technology_code: string;
  technology_group: string;
  country: string;
  country_code: string;
  capacity: number;
  commissioning_date: string | null;
  last_updated: string | null;
  address: string;
  supported: boolean;
  registration_date: string | null;
  expiry_date: string | null;
  registrant: string;
  issuer: string;
  certificates: string | null;
  total: number;
  capacity_bins: string;
  state: string;
}

export interface TableProjectsProps {
  reProjects: TReProjectData[];
  isLoading: boolean;
}

export const TableProjects = ({ reProjects, isLoading }: TableProjectsProps) => {
  const navigate = useNavigate();

  // const convertDateString = (dateString: string): string => {
  //   const date = new Date(dateString);
  //   return ${date.toLocaleDateString("en-uk", {
  //     year: "numeric",
  //     month: "short",
  //     day: "2-digit",
  //   })} at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()};
  // };

  const handleNavigate = (
    type: "project" | "recommendation",
    id: number,
    selectedProject: TableRow,
  ) => {
    // set selected project
    dispatch(setSelectedReProject(selectedProject));
    navigate(`/${type}/${id}`);
  };

  const renderRows = (tableProjects: TableRow[]) =>
    tableProjects.map((tableRow) => {
      let certificates = {};
      try {
        const correctedCertificates = tableRow.certificates?.replace(/'/g, '"');
        certificates = JSON.parse(correctedCertificates ?? "{}");
      } catch (error) {
        console.error("Error parsing certificates JSON:", error);
        console.error("Error row:", tableRow.id);
        certificates = {};
      }

      return (
        <tr
          key={tableRow.id}
          className="border-b-2 hover:cursor-pointer hover:bg-gray-200"
          onClick={() => handleNavigate("project", tableRow.id, tableRow)}
        >
          <td className="p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-dots-vertical"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            </svg>
          </td>
          <td className="p-4">#{tableRow.id}</td>
          <td className="max-w-56 truncate p-4 font-medium text-primaryPurple-100">
            {tableRow.name}
          </td>
          <td className="max-w-36 truncate p-4">{tableRow.device_type}</td>
          <td className="max-w-56 truncate p-4">{tableRow.device_code}</td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.active ? "Active" : "Not active"}
          </td>
          <td className="max-w-56 truncate p-4">{tableRow.technology}</td>
          <td className="max-w-56 truncate p-4">
            {tableRow.technology_code ? "Active" : "Not available"}
          </td>
          <td className="max-w-56 truncate p-4">
            {tableRow.technology_group ? "Active" : "Not available"}
          </td>
          <td className="max-w-56 truncate p-4">{tableRow.country}</td>
          <td className="max-w-56 truncate p-4 text-center">{tableRow.country_code}</td>
          <td className="max-w-56 truncate p-4">{tableRow.capacity}</td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.state ? "Active" : "Not available"}
          </td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.commissioning_date ?? "Not available"}
          </td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.last_updated ?? "Not available"}
          </td>
          <td className="max-w-56 truncate p-4">
            {tableRow.address ? (
              <>
                {tableRow.address.length > 80 ? (
                  <abbr title={tableRow.address} style={{ textDecoration: "none" }}>
                    {tableRow.address.slice(0, 80)}...
                  </abbr>
                ) : (
                  tableRow.address
                )}
              </>
            ) : (
              "Not available"
            )}
          </td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.registration_date ?? "Not available"}
          </td>
          <td className="max-w-56 truncate p-4 text-center">
            {tableRow.expiry_date ?? "Not available"}
          </td>
          <td className="max-w-56 truncate p-4">{tableRow.registrant}</td>
          <td className="max-w-56 truncate p-4">{tableRow.issuer}</td>
          <td className="max-w-56 truncate p-4">
            <div>
              {Object.entries(certificates).map(
                ([key, value], index) =>
                  `${key === "total" ? "Total" : key}: ${value}${
                    index < Object.entries(certificates).length - 1 ? "; " : ""
                  }`,
              )}
            </div>
          </td>

          <td className="max-w-56 truncate p-4 text-center">{tableRow.capacity_bins}</td>
          <td className="max-w-56 truncate p-4">{tableRow.state}</td>
        </tr>
      );
    });

  const renderTableRow = reProjects.length !== 0 ? renderRows(reProjects) : <></>;

  const renderTableProjects = () =>
    reProjects.length !== 0 ? (
      <div className="w-full max-w-full overflow-x-auto rounded-2xl">
        <table className="table-auto border-spacing-8 bg-[#482F8B]/5">
          <thead className="border-y border-[#231F20]/10">
            <tr>
              <th></th>
              <th className="text-nowrap p-4 text-base font-normal text-[#231F20CC]">ID</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Name</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Device Type</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Device Code</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Active Status</th>
              <th className="text-nowrap p-4 text-base font-normal text-[#231F20CC]">Technology</th>
              <th className="text-nowrap p-4 text-base font-normal text-[#231F20CC]">
                Technology Code
              </th>
              <th className="text-nowrap p-4 text-base font-normal text-[#231F20CC]">
                Technology Group
              </th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Country</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Country Code</th>
              <th className="text-nowrap p-4 text-base font-normal text-[#231F20CC]">Capacity</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">State P</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Commissioning Date</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Last Updated</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Address</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Registration Date</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Expiry Date</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Registrant</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Issuer</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Certificates</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">Capacity Bins</th>
              <th className="p-4 text-base font-normal text-[#231F20CC]">State</th>
            </tr>
          </thead>
          <tbody className="bg-white">{renderTableRow}</tbody>
        </table>
      </div>
    ) : (
      <div className="flex h-64 items-center justify-center">
        <div className="rounded-md border-2	border-black	px-8 py-3">
          <span className="font-semibold	">Projects not found</span>
        </div>
      </div>
    );

  return isLoading ? <SkeletonLoader /> : renderTableProjects();
};
