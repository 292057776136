import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import sampleDoc from "../sampleDocuments/commSampleDoc.pdf";

interface viewSampleDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalHeader: string;
}

const ViewSampleDocument = ({ isOpen, onClose, modalHeader }: viewSampleDocumentModalProps) => {
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <div className="border-b border-b-gray-300">
            <ModalHeader>
              {modalHeader} <span className="text-sm text-gray-500">Sample Document</span>{" "}
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <iframe src={sampleDoc} width="100%" height="500px" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ViewSampleDocument;
