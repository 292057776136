import React from "react";

import { TCustomIcon } from "../../types";

const SuccessSVG = ({ fill = "#81BD5B", className }: TCustomIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      className={className ? className : "h-6 w-6"}
    >
      <path
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill={fill}
      />
      <path
        d="M30.261 13.7422C34.4171 13.7422 38.572 15.3296 41.7503 18.5078C48.1068 24.8643 48.1068 35.1347 41.7503 41.4912C35.3938 47.8477 25.1234 47.8477 18.7669 41.4912C12.4104 35.1347 12.4104 24.8643 18.7669 18.5078C21.9451 15.3296 26.1049 13.7422 30.261 13.7422ZM37.6634 24.9971C37.4266 25.0188 37.1966 25.1097 37.0091 25.2559L27.8782 32.1064L23.6448 27.873C22.7264 26.9165 20.9207 28.7222 21.8772 29.6406L26.8772 34.6406C27.2977 35.0603 28.032 35.1131 28.5081 34.7578L38.5081 27.2578C39.3497 26.6452 38.8312 25.012 37.7903 24.9971C37.748 24.9949 37.7056 24.9949 37.6634 24.9971L37.6634 24.9971Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessSVG;
