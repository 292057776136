const navbarFilled: object = {
  transparent: {
    background: 'bg-transparent',
    color: 'text-white',
    boxShadow: 'shadow-none',
  },
  white: {
    background: 'bg-white',
    color: 'text-white',
  },
  'blue-gray': {
    background: 'bg-blue-gray-500',
    color: 'text-white',
  },
  gray: {
    background: 'bg-gray-500',
    color: 'text-white',
  },
  brown: {
    background: 'bg-brown-500',
    color: 'text-white',
  },
  'deep-orange': {
    background: 'bg-deep-orange-500',
    color: 'text-white',
  },
  orange: {
    background: 'bg-orange-500',
    color: 'text-white',
  },
  amber: {
    background: 'bg-amber-500',
    color: 'text-black',
  },
  yellow: {
    background: 'bg-yellow-500',
    color: 'text-black',
  },
  lime: {
    background: 'bg-lime-500',
    color: 'text-black',
  },
  'light-green': {
    background: 'bg-light-green-500',
    color: 'text-white',
  },
  green: {
    background: 'bg-green-500',
    color: 'text-white',
  },
  teal: {
    background: 'bg-teal-500',
    color: 'text-white',
  },
  cyan: {
    background: 'bg-cyan-500',
    color: 'text-white',
  },
  'light-blue': {
    background: 'bg-light-blue-500',
    color: 'text-white',
  },
  blue: {
    background: 'bg-transparent',
    color: 'text-white',
  },
  indigo: {
    background: 'bg-indigo-500',
    color: 'text-white',
  },
  'deep-purple': {
    background: 'bg-deep-purple-500',
    color: 'text-white',
  },
  purple: {
    background: 'bg-purple-500',
    color: 'text-white',
  },
  pink: {
    background: 'bg-pink-500',
    color: 'text-white',
  },
  red: {
    background: 'bg-red-500',
    color: 'text-white',
  },
};

export default navbarFilled;
