import React from "react";
import Typography from "../Typography";

export type MicrosoftLoginButtonTheme = "dark_short" | "light_short" | "dark" | "light";

interface MicrosoftLoginButtonProps {
  buttonTheme?: MicrosoftLoginButtonTheme;
  buttonClassName?: string;
  onClick?: any;
}

const MicrosoftLoginButton: React.FunctionComponent<MicrosoftLoginButtonProps> = ({ onClick }) => {
  return (
    <button
      className="mx-auto flex w-2/3 items-center justify-center rounded-full shadow-md"
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="400 41">
        <path fill="#f25022" d="M13 11h9v9h-9z" />
        <path fill="#00a4ef" d="M13 21h9v9h-9z" />
        <path fill="#7fba00" d="M23 11h9v9h-9z" />
        <path fill="#ffb900" d="M23 21h9v9h-9z" />
      </svg>
      <Typography variant="h6" className="my-3 font-primary text-base font-normal">
        Login with Microsoft
      </Typography>
    </button>
  );
};

export default MicrosoftLoginButton;
