import { combineReducers } from '@reduxjs/toolkit';

import { projectsReducer } from './projects/reducers';
import { userReducer } from './user/reducers';
import { sidebarReducer } from './sidebar/reducers';

const rootStateReducersMap = {
  projects: projectsReducer,
  user: userReducer,
  sidebar: sidebarReducer,
};

export const rootReducer = combineReducers(rootStateReducersMap);
