const buttonText: object = {
  white: {
    color: 'text-white',
    hover: 'hover:bg-white/10',
    active: 'active:bg-white/30',
  },
  black: {
    color: 'text-gray-900',
    hover: 'hover:bg-gray-900/10',
    active: 'active:bg-gray-900/20',
  },
  'blue-gray': {
    color: 'text-blue-gray-500',
    hover: 'hover:bg-blue-gray-500/10',
    active: 'active:bg-blue-gray-500/30',
  },
  gray: {
    color: 'text-gray-900',
    hover: 'hover:bg-gray-900/10',
    active: 'active:bg-gray-900/20',
  },
  brown: {
    color: 'text-brown-500',
    hover: 'hover:bg-brown-500/10',
    active: 'active:bg-brown-500/30',
  },
  'deep-orange': {
    color: 'text-deep-orange-500',
    hover: 'hover:bg-deep-orange-500/10',
    active: 'active:bg-deep-orange-500/30',
  },
  orange: {
    color: 'text-orange-500',
    hover: 'hover:bg-orange-500/10',
    active: 'active:bg-orange-500/30',
  },
  amber: {
    color: 'text-amber-500',
    hover: 'hover:bg-amber-500/10',
    active: 'active:bg-amber-500/30',
  },
  yellow: {
    color: 'text-yellow-500',
    hover: 'hover:bg-yellow-500/10',
    active: 'active:bg-yellow-500/30',
  },
  lime: {
    color: 'text-lime-500',
    hover: 'hover:bg-lime-500/10',
    active: 'active:bg-lime-500/30',
  },
  'light-green': {
    color: 'text-light-green-500',
    hover: 'hover:bg-light-green-500/10',
    active: 'active:bg-light-green-500/30',
  },
  green: {
    color: 'text-green-500',
    hover: 'hover:bg-green-500/10',
    active: 'active:bg-green-500/30',
  },
  teal: {
    color: 'text-teal-500',
    hover: 'hover:bg-teal-500/10',
    active: 'active:bg-teal-500/30',
  },
  cyan: {
    color: 'text-cyan-500',
    hover: 'hover:bg-cyan-500/10',
    active: 'active:bg-cyan-500/30',
  },
  'light-blue': {
    color: 'text-light-blue-500',
    hover: 'hover:bg-light-blue-500/10',
    active: 'active:bg-light-blue-500/30',
  },
  blue: {
    color: 'text-blue-500',
    hover: 'hover:bg-blue-500/10',
    active: 'active:bg-blue-500/30',
  },
  indigo: {
    color: 'text-indigo-500',
    hover: 'hover:bg-indigo-500/10',
    active: 'active:bg-indigo-500/30',
  },
  'deep-purple': {
    color: 'text-deep-purple-500',
    hover: 'hover:bg-deep-purple-500/10',
    active: 'active:bg-deep-purple-500/30',
  },
  purple: {
    color: 'text-purple-500',
    hover: 'hover:bg-purple-500/10',
    active: 'active:bg-purple-500/30',
  },
  pink: {
    color: 'text-pink-500',
    hover: 'hover:bg-pink-500/10',
    active: 'active:bg-pink-500/30',
  },
  red: {
    color: 'text-red-500',
    hover: 'hover:bg-red-500/10',
    active: 'active:bg-red-500/30',
  },
};

export default buttonText;
