import Card, { CardBody } from "../../../components/Card";
import Typography from "../../../components/Typography";

export const SkeletonLoader = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((key) => (
        <Card
          key={`skeleton-loader-${key}`}
          variant="filled"
          className="max-h-sm    overflow-hidden rounded-xl bg-white p-4 shadow-lg"
        >
          <CardBody>
            <div className="max-w-full animate-pulse">
              <Typography as="div" variant="h1" className="mb-4 h-3 w-56 rounded-full bg-gray-300">
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 w-72 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
            </div>
          </CardBody>
        </Card>
      ))}
    </>
  );
};
