const typographyColors: object = {
  inherit: {
    color: 'text-inherit',
  },
  current: {
    color: 'text-current',
  },
  black: {
    color: 'text-black',
  },
  white: {
    color: 'text-white',
  },
  'blue-gray': {
    color: 'text-blue-gray-900',
    gradient: 'bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400',
  },
  gray: {
    color: 'text-gray-700',
    gradient: 'bg-gradient-to-tr from-gray-600 to-gray-400',
  },
  brown: {
    color: 'text-brown-500',
    gradient: 'bg-gradient-to-tr from-brown-600 to-brown-400',
  },
  'deep-orange': {
    color: 'text-deep-orange-500',
    gradient: 'bg-gradient-to-tr from-deep-orange-600 to-deep-orange-400',
  },
  orange: {
    color: 'text-orange-500',
    gradient: 'bg-gradient-to-tr from-orange-600 to-orange-400',
  },
  amber: {
    color: 'text-amber-500',
    gradient: 'bg-gradient-to-tr from-amber-600 to-amber-400',
  },
  yellow: {
    color: 'text-yellow-500',
    gradient: 'bg-gradient-to-tr from-yellow-600 to-yellow-400',
  },
  lime: {
    color: 'text-lime-500',
    gradient: 'bg-gradient-to-tr from-lime-600 to-lime-400',
  },
  'light-green': {
    color: 'text-light-green-500',
    gradient: 'bg-gradient-to-tr from-light-green-600 to-light-green-400',
  },
  green: {
    color: 'text-green-500',
    gradient: 'bg-gradient-to-tr from-green-600 to-green-400',
  },
  teal: {
    color: 'text-teal-500',
    gradient: 'bg-gradient-to-tr from-teal-600 to-teal-400',
  },
  cyan: {
    color: 'text-cyan-500',
    gradient: 'bg-gradient-to-tr from-cyan-600 to-cyan-400',
  },
  'light-blue': {
    color: 'text-light-blue-500',
    gradient: 'bg-gradient-to-tr from-light-blue-600 to-light-blue-400',
  },
  blue: {
    color: 'text-blue-500',
    gradient: 'bg-gradient-to-tr from-blue-600 to-blue-400',
  },
  indigo: {
    color: 'text-indigo-500',
    gradient: 'bg-gradient-to-tr from-indigo-600 to-indigo-400',
  },
  'deep-purple': {
    color: 'text-deep-purple-500',
    gradient: 'bg-gradient-to-tr from-deep-purple-600 to-deep-purple-400',
  },
  purple: {
    color: 'text-purple-500',
    gradient: 'bg-gradient-to-tr from-purple-600 to-purple-400',
  },
  pink: {
    color: 'text-pink-500',
    gradient: 'bg-gradient-to-tr from-pink-600 to-pink-400',
  },
  red: {
    color: 'text-red-500',
    gradient: 'bg-gradient-to-tr from-red-600 to-red-400',
  },
};

export default typographyColors;
