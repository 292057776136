import { createReducer } from '@reduxjs/toolkit';

import {
  changeFilterAction,
  changePaginationAction,
  changeSearchAction,
  changeSelectedTypeAction,
  changeViewModeAction,
  loadProjectsSpecific,
  changeUploadFiles,
  deleteUploadFiles,
  clearUploadFiles,
  setSelectedReProject,
  resetFilterAction,
  loadFiltersValues,
  loadIndicatorsAction,
} from './actions';
import { FilterValues, projectsInitialState, ProjectsState } from './state';
import { createEntity } from '../lib';
import { EProjectTypes, ProjectsInterface } from '../../types';

export const projectsReducer = createReducer<ProjectsState>(projectsInitialState, (builder) => {
  builder.addCase(loadProjectsSpecific.submit, (state) => ({
    ...state,
    projects: createEntity(state.projects.data, true),
    pagination: state.pagination,
  }));

  builder.addCase(
    loadProjectsSpecific.success,
    (state, { payload }: { payload: Partial<ProjectsInterface> }) => ({
      ...state,
      projects: createEntity(payload.projects || state.projects.data),
      pagination: payload.pagination || state.pagination,
    }),
  );

  builder.addCase(loadProjectsSpecific.fail, (state, { payload }) => ({
    ...state,
    projects: createEntity({ items: [] }, false, payload),
    pagination: projectsInitialState.pagination,
  }));

  builder.addCase(
    loadIndicatorsAction,
    (state, { payload }: { payload: { total: number; inProgress: number } }) => ({
      ...state,
      indicators: { total: payload.total, inProgress: payload.inProgress },
    }),
  );

  builder.addCase(changeSelectedTypeAction, (state, { payload }: { payload: EProjectTypes }) => ({
    ...state,
    selected: payload,
    pagination: projectsInitialState.pagination,
  }));

  builder.addCase(changeSearchAction, (state, { payload }: { payload: string }) => ({
    ...state,
    searchByNaming: payload,
  }));

  builder.addCase(changeFilterAction, (state, { payload: filter }) => ({
    ...state,
    filters: { ...state.filters, ...filter },
  }));

  builder.addCase(changeViewModeAction, (state, { payload }: any) => ({
    ...state,
    viewMode: payload,
  }));

  builder.addCase(changePaginationAction, (state, { payload: pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination },
  }));

  builder.addCase(loadFiltersValues.submit, (state) => ({
    ...state,
    filterValues: projectsInitialState.filterValues,
  }));

  builder.addCase(
    loadFiltersValues.success,
    (state, { payload }: { payload: Partial<FilterValues> }) => ({
      ...state,
      filterValues: createEntity({ ...state.filterValues.data, ...payload }),
    }),
  );

  builder.addCase(loadFiltersValues.fail, (state, { payload }) => ({
    ...state,
    filterValues: createEntity(projectsInitialState.filterValues.data, false, payload),
  }));

  builder.addCase(resetFilterAction, (state) => ({
    ...state,
    filters: {
      ...projectsInitialState.filters,
    },
    search: null,
    searchByNaming: projectsInitialState.searchByNaming,
    pagination: projectsInitialState.pagination,
  }));

  // testing action
  builder.addCase(changeUploadFiles, (state, { payload }: any) => ({
    ...state,
    uploadedFiles: [...state.uploadedFiles, payload],
  }));

  builder.addCase(deleteUploadFiles, (state, { payload }: any) => ({
    ...state,
    uploadedFiles: state.uploadedFiles.filter((file) => file.uploadedFile.name !== payload),
  }));

  builder.addCase(clearUploadFiles, (state) => ({
    ...state,
    uploadedFiles: [],
  }));

  builder.addCase(setSelectedReProject, (state, { payload }: any) => ({
    ...state,
    selectedProject: payload,
  }));
});
