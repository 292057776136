import React, { useEffect, useState } from "react";
import { useSelectedReProjectSelector } from "../../../store/projects/selectors";
import { RiPencilFill } from "react-icons/ri";
import { BsX } from "react-icons/bs";
import { BsCheck } from "react-icons/bs";
import { api } from "../../../api";
import { setSelectedReProject } from "../../../store/projects/actions";
import { dispatch } from "../../../store";
import { useToast } from "@chakra-ui/react";

interface Project {
  projectName: string;
  technology: string;
  capacity: number | string;
  client: number | null;
  latitude: number | null;
  longitude: number | null;
  sellerDeal: string | null;
}

function PlantDetailsTab() {
  const toast = useToast();
  const selectedReProject = useSelectedReProjectSelector();
  const [editEnabled, setEditEnabled] = useState<Record<keyof Project, boolean>>({
    projectName: true,
    technology: true,
    capacity: true,
    client: true,
    latitude: true,
    longitude: true,
    sellerDeal: true,
  });
  const [errors, setErrors] = useState({
    projectName: false,
    technology: false,
    capacity: false,
    client: false,
    latitude: false,
    longitude: false,
    sellerDeal: false,
  });

  const initialProjectDetails: Project = {
    projectName: selectedReProject?.name || "",
    technology: selectedReProject?.technology || "",
    capacity: selectedReProject?.capacity || "",
    client: selectedReProject?.user_id || null,
    latitude: selectedReProject?.location?.coordinates[0] || 0.0,
    longitude: selectedReProject?.location?.coordinates[1] || 0.0,
    sellerDeal: selectedReProject?.seller_deal?.id || "",
  };

  const [projectDetails, setProjectDetails] = useState<Project>(initialProjectDetails);
  const [technologyTypes, setTechnologyTypes] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sellerDeals, setSellerDeals] = useState<any>([]);

  // get initial data
  useEffect(() => {
    fetchTechnologyTypes();
    fetchSellers();
    fetchAllSellerDealItems().then((sellerDeals) => {
      setSellerDeals(sellerDeals);
    });
  }, []);

  const fetchAllSellerDealItems = async () => {
    let allItems: any[] = [];
    let page = 1;
    let has_next_page = true;

    while (has_next_page) {
      try {
        const response = await api.get("/seller/deals/", {
          params: { page: page },
        });

        // Safely access response data
        const items = response.data && response.data.items ? response.data.items : [];
        const meta = response.data && response.data.meta ? response.data.meta : {};

        allItems = [...allItems, ...items];
        has_next_page = meta.has_next_page ?? false; // Fallback to false if undefined
        page += 1;
      } catch (error) {
        console.error("Error fetching items:", error);
        has_next_page = false;
      }
    }

    return allItems;
  };

  const fetchTechnologyTypes = async () => {
    const technologyTypes = await api.get(`/filters`);
    setTechnologyTypes(technologyTypes?.data?.technologies);
  };

  const fetchSellers = async () => {
    const sellers = await api.post("/user/search", {
      role: "CLIENT_SELLER",
      page: 1,
      take: 15,
    });
    if (sellers?.data) {
      const sellerOptions = sellers.data.items
        .filter((seller: any) => seller?.client_name !== null)
        .map((seller: any) => ({
          name: "client",
          value: seller.id,
          label: seller.client_name,
        }));
      setClients(sellerOptions);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fieldName = e.target.name as keyof Project;
    const value = e.target.value;
    setProjectDetails({
      ...projectDetails,
      [fieldName]: value,
    });
    setErrors({
      ...errors,
      [fieldName]: false,
    });
  };

  const toggleEditState = (fieldName: keyof Project) => {
    setEditEnabled((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleClose = (fieldName: keyof Project) => {
    setProjectDetails({
      ...projectDetails,
      [fieldName]: initialProjectDetails[fieldName],
    });
    setErrors({
      ...errors,
      [fieldName]: false,
    });
    toggleEditState(fieldName);
  };

  const handleUpdate = async (fieldName: keyof Project) => {
    setIsLoading(true);
    if (!validateFields()) {
      setIsLoading(false);
      return;
    }
    try {
      const updatedObject = {
        id: selectedReProject?.id,
        name: projectDetails?.projectName,
        technology: projectDetails?.technology,
        capacity: projectDetails?.capacity,
        client: projectDetails?.client,
        location: `POINT(${
          projectDetails?.latitude !== null
            ? parseFloat(parseFloat(projectDetails.latitude.toString()).toFixed(6))
            : null
        } ${
          projectDetails?.longitude !== null
            ? parseFloat(parseFloat(projectDetails.longitude.toString()).toFixed(6))
            : null
        })`,
        seller_deal_id: projectDetails?.sellerDeal,
      };

      const updatedProjectResponse = await api.put("/admin/re-projects/", updatedObject);
      // after response came => update the state
      dispatch(setSelectedReProject(updatedProjectResponse?.data));
      setIsLoading(false);
      toggleEditState(fieldName);
      toast({
        title: `${fieldName} updated successfully`,
        description: "We've updated your project.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      toast({
        title: `An error occurred while updating the ${fieldName}`,
        description: "update failed.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validateFields = () => {
    const validateDecimalPlaces = (value: any) => {
      if (value === null) return false;
      const decimalPart = value.toString().split(".")[1];
      return decimalPart && decimalPart.length > 6;
    };

    const newErrors = {
      projectName: projectDetails?.projectName === "",
      technology: projectDetails?.technology === "",
      capacity: projectDetails?.capacity === "",
      client: projectDetails?.client === null,
      latitude: projectDetails?.latitude === null || validateDecimalPlaces(projectDetails.latitude),
      longitude:
        projectDetails?.longitude === null || validateDecimalPlaces(projectDetails.longitude),
      sellerDeal: projectDetails?.sellerDeal === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  return (
    <div className="rounded-md p-4 shadow-md">
      <h1 className="font-semibold">Plant Details</h1>

      <div className="mt-4 grid grid-cols-4 gap-4">
        {/* Project Name */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Project Name</span>

            {editEnabled.projectName ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("projectName")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("projectName")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("projectName")}
                />
              </div>
            )}
          </div>
          <input
            name="projectName"
            onChange={handleInputChange}
            disabled={editEnabled.projectName || isLoading}
            type="text"
            value={projectDetails.projectName}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.projectName && "border-blue-700"
            } ${errors?.projectName && "border-red-700"}`}
          />
          {errors?.projectName && (
            <span className="text-sm text-red-600">Project Name is required.</span>
          )}
        </div>

        {/* Technology */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Technology</span>

            {editEnabled.technology ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("technology")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("technology")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("technology")}
                />
              </div>
            )}
          </div>
          <select
            name="technology"
            id="technology"
            value={projectDetails.technology}
            onChange={handleInputChange}
            disabled={editEnabled.technology || isLoading}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.projectName && "border-blue-700"
            } ${errors?.technology && "border-red-700"}`}
          >
            {technologyTypes.length > 0 &&
              technologyTypes.map((technology: string, index: number) => (
                <option value={technology} key={index}>
                  {technology}
                </option>
              ))}
          </select>
          {errors?.technology && (
            <span className="text-sm text-red-600">Project Name is required.</span>
          )}
        </div>

        {/* Capacity */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Capacity</span>

            {editEnabled.capacity ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("capacity")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("capacity")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("capacity")}
                />
              </div>
            )}
          </div>
          <input
            name="capacity"
            onChange={handleInputChange}
            disabled={editEnabled.capacity || isLoading}
            type="number"
            value={projectDetails.capacity}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.capacity && "border-blue-700"
            } ${errors?.capacity && "border-red-700"}`}
          />
          {errors?.capacity && <span className="text-sm text-red-600">Capacity is required.</span>}
        </div>

        {/* Client */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Client</span>

            {editEnabled.client ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("client")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("client")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("client")}
                />
              </div>
            )}
          </div>
          <select
            name="client"
            id="client"
            value={projectDetails.client ?? ""}
            onChange={handleInputChange}
            disabled={editEnabled.client || isLoading}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.client && "border-blue-700"
            } ${errors?.client && "border-red-700"}`}
          >
            {clients.length > 0 &&
              clients.map((client: any) => (
                <option value={client.value} key={client.value}>
                  {client.label}
                </option>
              ))}
          </select>
          {errors?.client && <span className="text-sm text-red-600">Client is required.</span>}
        </div>

        {/* Latitude */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Latitude</span>

            {editEnabled.latitude ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("latitude")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("latitude")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("latitude")}
                />
              </div>
            )}
          </div>
          <input
            name="latitude"
            onChange={handleInputChange}
            disabled={editEnabled.latitude || isLoading}
            type="number"
            value={projectDetails.latitude ?? ""}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.latitude && "border-blue-700"
            } ${errors?.latitude && "border-red-700"}`}
          />
          {errors?.latitude && (
            <span className="text-sm text-red-600">
              Latitude is required or Value can't exceed 6 decimal points.
            </span>
          )}
        </div>

        {/* Longitude */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Longitude</span>

            {editEnabled.longitude ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("longitude")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("longitude")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("longitude")}
                />
              </div>
            )}
          </div>
          <input
            name="longitude"
            onChange={handleInputChange}
            disabled={editEnabled.longitude || isLoading}
            type="number"
            value={projectDetails.longitude ?? ""}
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              !editEnabled.longitude && "border-blue-700"
            } ${errors?.longitude && "border-red-700"}`}
          />
          {errors?.longitude && (
            <span className="text-sm text-red-600">
              Longitude is required or Value can't exceed 6 decimal points.
            </span>
          )}
        </div>

        {/* seller deal */}
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Seller Deal</span>

            {editEnabled.sellerDeal ? (
              <RiPencilFill
                size={14}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => toggleEditState("sellerDeal")}
              />
            ) : (
              <div className="flex items-center gap-4">
                <BsX
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => handleClose("sellerDeal")}
                />
                <BsCheck
                  className="cursor-pointer hover:text-blue-700"
                  onClick={() => handleUpdate("sellerDeal")}
                />
              </div>
            )}
          </div>
          {sellerDeals?.length > 0 && (
            <select
              name="sellerDeal"
              id="sellerDeal"
              disabled={editEnabled.sellerDeal || isLoading}
              onChange={handleInputChange}
              value={projectDetails.sellerDeal ?? ""}
              defaultValue={projectDetails?.sellerDeal ?? ""}
              className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
                !editEnabled.sellerDeal && "border-blue-700"
              } ${errors?.sellerDeal && "border-red-700"}`}
            >
              {/* default option */}
              <option value="" disabled>
                Select a seller deal
              </option>
              {sellerDeals?.map((sellerDeal: any) => (
                <option value={sellerDeal?.id} key={sellerDeal.id}>
                  {sellerDeal?.name}
                </option>
              ))}
            </select>
          )}
          {errors?.longitude && (
            <span className="text-sm text-red-600">
              Longitude is required or Value can't exceed 6 decimal points.
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlantDetailsTab;
