import React from 'react';

export type TCardInfo = {
  header: string;
  subhead: string;
  content: string;
  status: string;
  project_id: number;
};

export type TCustomIcon = {
  fill?: string;
  strokeWidth?: number;
  stroke?: string;
  className?: string;
};

export type TButton = {
  isDisabled: boolean;
  isOutlined: boolean;
};

export type TProtectedRoute = {
  children?: React.ReactNode;
};

export type AuthState = {
  token: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null | undefined;
};

export type TokenResponse = {
  access_token: string;
  token_type: string;
};

export type TLoginFormValues = {
  userName: string;
  password: string;
};

export type TSuggestion = {
  id: string;
  level: number;
  name: string;
};

export type TSuggestions = {
  suggestions: TSuggestion[];
};

/** @deprecated */
export type TCarbonProjectData = {
  type: string;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  properties: TReProjectDataProperties;
};

export type TReProjectDataResponse = {
  items: TReProjectData[];
  meta: {
    count: number;
    has_next_page: boolean;
    has_previous_page: boolean;
    page: number;
    page_count: number;
    take: number;
  };
};

export type TReProjectData = {
  id: number;
  name: string;
  device_type: string;
  device_code: string;
  active: boolean;
  technology: string;
  technology_code: string;
  technology_group: string;
  country: string;
  country_code: string;
  capacity: number;
  commissioning_date: string | null;
  last_updated: string | null;
  address: string;
  supported: boolean;
  registration_date: string | null;
  expiry_date: string | null;
  registrant: string;
  issuer: string;
  certificates: string | null;
  total: number;
  total_2023?: number;
  total_2022?: number;
  total_2024?: number;
  total_2021?: number;
  total_2020?: number;
  total_2018?: number;
  total_2019?: number;
  total_2017?: number;
  total_2016?: number;
  total_2015?: number;
  total_2014?: number;
  capacity_bins: string;
  state: string;
  status: string;
  location: {
    coordinates: [number, number];
    type: string;
  };
  user_id: number;
  seller_deal: {
    deal_provider: string;
    id: string;
    name: string;
  };
};

/** @deprecated */
export type TReProjectDataProperties = {
  id: number;
  name: string;
  device_type: string;
  device_code: string;
  active: boolean;
  technology: string;
  technology_group: string;
  country: string;
  country_code: string;
  capacity: number;
  commissioning_date: string | null;
  last_updated: string | null;
  address: string;
  registration_date: string | null;
  expiry_date: string | null;
  registrant: string;
  issuer: string;
  certificates: string;
  capacity_bins: string;
  state: string;
  location: {
    coordinates: [number, number];
    type: string;
  };
};

export type TTitle = {
  text: string;
};

export type TReProject = {
  items: Array<TReProjectData>;
};

export interface ProjectsInterface {
  projects: TReProject;
  pagination: {
    total: number;
    page: number;
    perPage: number;
  };
}

export enum EUserRoles {
  PROCLIME_USER = 'PROCLIME_USER',
  CLIENT_SELLER = 'CLIENT_SELLER',
  OTHER = 'OTHER',
}

export enum EProjectTypes {
  REGISTERED = 'registered',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export enum EProjectStatus {
  ADD_PROJECT = 'add_project',
  DOCS_RECEIVED = 'docs_received',
  SENT_TO_REGISTRY = 'sent_to_registry',
  DUE_DILIGENCE_CHECK = 'due_diligence_check',
  DOCS_APPROVED = 'docs_approved',
  CERTIFICATE_ISSUED = 'certificate_issued',
  SALE_MADE = 'sale_made',
}

export enum EViewMode {
  LIST = 'list',
  GRID = 'grid',
}

export type TUserData = {
  id: number;
  username: string;
  favourites: string | null;
  disabled: string | null;
  role: EUserRoles;
};
