import { FaBell } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { handleLogout } from "../../api";
import { useEffect, useState } from "react";
import { useUserSelector } from "../../store/user/selectors";
import { fetchUserData } from "../../api/home.api";
import { IoMenu } from "react-icons/io5";
import { changeSidebarExpandAction } from "../../store/sidebar/actions";
import { dispatch } from "../../store";
import { useSelectSidebarExpandSelector } from "../../store/sidebar/selectors";

const TopBar = () => {
  const isSideBarOpen = useSelectSidebarExpandSelector();

  const handleSideBar = () => {
    dispatch(changeSidebarExpandAction(!isSideBarOpen));
  };

  const [dropDownClicked, setDropDownClicked] = useState<boolean>(false);
  const { username } = useUserSelector();

  // api to get logged in user data
  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="sticky top-0 z-40 mb-4 w-full bg-white px-4 shadow-md">
      <div className="flex h-[60px] items-center justify-between">
        {/* menu icon */}
        <div className="cursor-pointer" onClick={handleSideBar}>
          <IoMenu fill={"black"} className={"h-8 w-8"} />
        </div>
        <div className={"h-8 w-8"} />

        <div className="flex items-center gap-6">
          {/* bell icon */}
          <div>
            <FaBell className="text-xl " />
          </div>

          <div className="h-6 w-0.5 bg-gray-300"></div>

          <div className="flex items-center gap-3 text-base">
            {/* user photo */}
            <div>{/* <img src={User} alt="user image" className="w-8 rounded-full" /> */}</div>
            <div>
              <p className="text-sm">{username}</p>
              <p className="text-xs text-gray-500">company</p>
            </div>
            <div className="rounded-full bg-gray-100 p-1 text-black">
              <RiArrowDropDownLine
                className="cursor-pointer text-xl"
                onClick={() => setDropDownClicked(!dropDownClicked)}
              />
            </div>
          </div>
          {/* <div className="h-6 w-0.5 bg-gray-300"></div>
          <button onClick={handleLogout}>Log out</button> */}
          {dropDownClicked && (
            <div className="absolute right-4 top-16 rounded-md bg-purple-900 px-4 py-2 text-sm text-white shadow-md">
              <button onClick={handleLogout}>Log out</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
