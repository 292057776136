import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
// import ProjectAnalysis from "./ProjectAnalysis";
import { useEffect, useRef, useState } from "react";
import { api } from "../../../api";
import Select from "react-select";
import { NotificationModal } from "../../../components/NotificationModal";

interface Project {
  projectName: string;
  technology: string;
  capacity: number | string;
  commissioningDate: string;
  client: number | null;
  latitude: number | null;
  longitude: number | null;
  locationName: string;
  sellerDeal: string;
}

const AddNewProject = () => {
  const [userData, setUserData] = useState<any>({});
  const [newProject, setNewProject] = useState<Project>({
    projectName: "",
    technology: "",
    capacity: "",
    commissioningDate: new Date().toISOString().split("T")[0],
    client: null,
    latitude: null,
    longitude: null,
    locationName: "",
    sellerDeal: "",
  });
  const [errors, setErrors] = useState({
    projectName: false,
    technology: false,
    capacity: false,
    commissioningDate: false,
    client: false,
    latitude: false,
    longitude: false,
    locationName: false,
    sellerDeal: false,
  });
  const [technologyTypes, setTechnologyTypes] = useState<any>([]);
  const [sellers, setSellers] = useState<any>([]);
  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
  const projectRef = useRef({ projectId: "" });

  const [sellerDeals, setSellerDeals] = useState<any>([]);

  // get seller deals
  useEffect(() => {
    fetchAllSellerDealItems().then((allItems) => {
      setSellerDeals(allItems);
    });
  }, []);

  const fetchAllSellerDealItems = async () => {
    let allItems: any[] = [];
    let page = 1;
    let has_next_page = true;

    while (has_next_page) {
      try {
        const response = await api.get("/seller/deals/", {
          params: { page: page },
        });
        allItems = [...allItems, ...response.data.items];
        has_next_page = response.data.meta.has_next_page; // or based on response logic
        page += 1;
      } catch (error) {
        console.error("Error fetching items:", error);
        has_next_page = false;
      }
    }

    return allItems;
  };

  useEffect(() => {
    api.get("/user/me").then((response: any) => {
      setUserData(response?.data);

      if (response?.data?.role === "CLIENT_SELLER") {
        setNewProject({
          ...newProject,
          client: response?.data?.id,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (userData?.role === "PROCLIME_USER") {
      fetchSellers();
    }
  }, [userData]);

  const fetchSellers = async () => {
    const sellers = await api.post("/user/search", {
      role: "CLIENT_SELLER",
      page: 1,
      take: 300,
    });

    if (sellers?.data) {
      const sellerOptions = sellers.data.items
        .filter((seller: any) => seller?.client_name !== null) // Filter out sellers with null client_name
        .map((seller: any) => ({
          name: "client",
          value: seller.id,
          label: seller.client_name,
        }));
      setSellers(sellerOptions);
    }
  };

  useEffect(() => {
    fetchTechnologyTypes();
  }, []);

  // function to get unique technology types
  const fetchTechnologyTypes = async () => {
    const technologyTypes = await api.get(`/filters`);
    const technologyOptions = technologyTypes?.data?.technologies.map((tech: any) => ({
      name: "technology",
      value: tech,
      label: tech,
    }));

    setTechnologyTypes(technologyOptions);
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  const handleDateChange = (e: any) => {
    setNewProject({
      ...newProject,
      commissioningDate: e.target.value,
    });
    setErrors({
      ...errors,
      commissioningDate: false,
    });
  };

  const handleInputChange = (e: any) => {
    if (e.name === "technology") {
      setNewProject({
        ...newProject,
        technology: e.value,
      });
    } else if (e.name === "client") {
      setNewProject({
        ...newProject,
        client: e.value,
      });
    } else {
      const fieldName = e.target.name;
      const value = e.target.value;
      setNewProject({
        ...newProject,
        [fieldName]: value,
      });
      setErrors({
        ...errors,
        [fieldName]: false,
      });
    }
  };

  const handleSubmitProject = async () => {
    if (!validateFields()) {
      return;
    }

    const finalProjectObject = {
      name: newProject?.projectName,
      technology: newProject?.technology,
      capacity:
        newProject?.capacity !== null
          ? parseFloat(parseFloat(newProject.capacity.toString()).toFixed(1))
          : null,
      commissioning_date: new Date(newProject?.commissioningDate),
      location: `POINT(${
        newProject?.latitude !== null
          ? parseFloat(parseFloat(newProject.latitude.toString()).toFixed(6))
          : null
      } ${
        newProject?.longitude !== null
          ? parseFloat(parseFloat(newProject.longitude.toString()).toFixed(6))
          : null
      })`,
      location_name: newProject?.locationName,
      seller_deal_id: newProject?.sellerDeal,
      user_id: newProject?.client,
    };

    let response;
    if (userData?.role === "CLIENT_SELLER") {
      response = await api.post(`/seller/re-projects/`, finalProjectObject);
    } else {
      response = await api.post(`/admin/re-projects/`, finalProjectObject);
    }

    if (response) {
      if (response.status === 201) {
        projectRef.current.projectId = response.data.id;
        setIsOpenedModal(true);
      }
      // clear the state
      setNewProject({
        projectName: "",
        technology: "",
        capacity: "",
        commissioningDate: new Date().toISOString().split("T")[0],
        client: null,
        latitude: 0,
        longitude: 0,
        locationName: "",
        sellerDeal: "",
      });
    }
  };

  const validateFields = () => {
    const validateDecimalPlaces = (value: any) => {
      if (value === null) return false;
      const decimalPart = value.toString().split(".")[1];
      return decimalPart && decimalPart.length > 6;
    };

    const newErrors = {
      projectName: newProject?.projectName === "",
      technology: newProject?.technology === "",
      capacity: newProject?.capacity === "",
      commissioningDate: newProject?.commissioningDate === "",
      client: newProject?.client === null,
      latitude: newProject?.latitude === null || validateDecimalPlaces(newProject.latitude),
      longitude: newProject?.longitude === null || validateDecimalPlaces(newProject.longitude),
      locationName: newProject?.locationName === "",
      sellerDeal: newProject?.sellerDeal === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleOpenModal = () => setIsOpenedModal((prev) => !prev);

  return (
    <div className="bg-white px-4">
      <div className="mb-4 text-sm">
        <p>
          Projects /<span className="text-gray-600">Add New Project</span>
        </p>
      </div>

      <div className="mb-4 flex items-center gap-2">
        <IoMdArrowRoundBack
          className="cursor-pointer text-primaryPurple-100"
          onClick={handleNavigate}
        />
        <h1 className="font-semibold">Add New Project</h1>
      </div>

      {/* form */}
      <div className="grid grid-cols-3 gap-4">
        {/* text field div */}
        <div>
          <span className="text-sm text-black">Name</span> <span className="text-red-600">*</span>
          <input
            id="projectName"
            name="projectName"
            type="text"
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              errors.projectName ? "border-red-600" : "border-[#A6A6A6]"
            }`}
            value={newProject?.projectName}
            onChange={handleInputChange}
          />
          {errors?.projectName && (
            <span className="text-sm text-red-600">Project Name is required.</span>
          )}
        </div>

        <div>
          <span className="text-sm text-black">Technology</span>
          <span className="text-red-600">*</span>
          <Select
            options={technologyTypes}
            name="technology"
            id="technology"
            onChange={handleInputChange}
            className="my-1"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: errors.technology ? "1px solid #e53935" : "1px solid #A6A6A6",
                height: "42px",
              }),
            }}
          />
          {errors?.technology && (
            <span className="text-sm text-red-600">Technology is required.</span>
          )}
        </div>

        <div>
          <span className="text-sm text-black">Capacity</span>{" "}
          <span className="text-red-600">*</span>
          <input
            id="capacity"
            name="capacity"
            type="number"
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              errors.capacity ? "border-red-600" : "border-[#A6A6A6]"
            }`}
            value={newProject?.capacity ?? ""}
            onChange={handleInputChange}
          />
          {errors?.capacity && <span className="text-sm text-red-600">Capacity is required.</span>}
        </div>

        <div>
          <span className="text-sm text-black">Commissioning date</span>{" "}
          <span className="text-red-600">*</span>
          <input
            id="commissioningDate"
            type="date"
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              errors.commissioningDate ? "border-red-600" : "border-[#A6A6A6]"
            }`}
            value={newProject?.commissioningDate}
            onChange={handleDateChange}
          />
        </div>

        <div>
          <span className="text-sm text-black">Location</span>
          {/*Latitude and Longitude input fields*/}
          <div className="flex items-start justify-between gap-2">
            <div className="w-full">
              <input
                type="number"
                className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
                  errors.latitude ? "border-red-600" : "border-[#A6A6A6]"
                }`}
                onChange={handleInputChange}
                placeholder="Latitude"
                name="latitude"
              />
              {errors?.latitude && (
                <span className="text-sm text-red-600">
                  Latitude is required or Value can't exceed 6 decimal points.
                </span>
              )}
            </div>
            <div className="w-full">
              <input
                type="number"
                className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
                  errors.longitude ? "border-red-600" : "border-[#A6A6A6]"
                }`}
                onChange={handleInputChange}
                placeholder="Longitude"
                name="longitude"
              />
              {errors?.longitude && (
                <span className="text-sm text-red-600">
                  Longitude is required or Value can't exceed 6 decimal points.
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          <span className="text-sm text-black">Location Name</span>
          <span className="text-red-600">*</span>
          <input
            id="locationName"
            name="locationName"
            type="text"
            className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
              errors.locationName ? "border-red-600" : "border-[#A6A6A6]"
            }`}
            value={newProject?.locationName}
            onChange={handleInputChange}
          />
          {errors?.locationName && (
            <span className="text-sm text-red-600">Location Name is required</span>
          )}
        </div>
        <div>
          <span className="text-sm text-black">Client</span> <span className="text-red-600">*</span>
          {/* 
            seller dropdown
          */}
          {userData?.role === "CLIENT_SELLER" && (
            <input
              id="client"
              name="client"
              type="string"
              className="my-1 w-full rounded-md border border-[#A6A6A6] px-3 py-2 outline-none"
              value={userData?.username}
              disabled={true}
            />
          )}
          {/* 
            admin dropdown
          */}
          {userData?.role === "PROCLIME_USER" && (
            <Select
              options={sellers}
              name="client"
              id="client"
              onChange={handleInputChange}
              className="my-1"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: errors.client ? "1px solid #e53935" : "1px solid #A6A6A6",
                  height: "42px",
                }),
              }}
            />
          )}
          {errors?.client && <span className="text-sm text-red-600">Please select a client.</span>}
        </div>
        {/* seller deal dropdown */}
        <div>
          <span className="text-sm text-black">Seller Deal</span>
          <span className="text-red-600">*</span>
          {sellerDeals?.length > 0 && (
            <select
              name="sellerDeal"
              id="sellerDeal"
              onChange={handleInputChange}
              defaultValue=""
              className={`my-1 w-full rounded-md border px-3 py-2 outline-none ${
                errors.sellerDeal ? "border-red-600" : "border-[#A6A6A6]"
              }`}
            >
              {/* default option */}
              <option value="" disabled>
                Select a seller deal
              </option>
              {sellerDeals?.map((sellerDeal: any) => (
                <option value={sellerDeal?.id} key={sellerDeal.id}>
                  {sellerDeal?.name}
                </option>
              ))}
            </select>
          )}
          {errors?.sellerDeal && (
            <span className="text-sm text-red-600">Seller deal is required.</span>
          )}
        </div>
      </div>

      {/* add location */}
      {/*<div className="mt-6">*/}
      {/*  <h1 className="font-medium text-black">Add Location</h1>*/}
      {/*  <div className="mt-2 grid grid-cols-12 gap-4">*/}
      {/*    <div className="col-span-4">*/}
      {/*      <ProjectAnalysis />*/}
      {/*    </div>*/}

      {/*    <div className="col-span-8">Map Goes here</div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* Add new Project Button */}
      <div className="fixed bottom-0 mt-4 w-full border-t border-t-gray-300 bg-white p-3 shadow-md">
        <button
          className="rounded-md bg-primaryPurple-100 px-4 py-2 text-white outline-none"
          onClick={handleSubmitProject}
        >
          Add Project
        </button>
      </div>

      <NotificationModal
        isOpen={isOpenedModal}
        handleOpen={handleOpenModal}
        title={"Project has been added successfully"}
        content={`Project created under the ID #${projectRef.current.projectId}`}
      />
    </div>
  );
};

export default AddNewProject;
