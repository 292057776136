import { createAsyncThunk } from '@reduxjs/toolkit';

import { api, cookies } from '../../api';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (formData: { userName: string; password: string }, thunkAPI) => {
    try {
      const data = new FormData();
      data.append('grant_type', 'password');
      data.append('username', formData.userName);
      data.append('password', formData.password);

      const response = await api.post('/auth/token', data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { access_token, refresh_token } = response.data;
      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        cookies.set('access_token', access_token);
        cookies.set('refresh_token', refresh_token);
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
