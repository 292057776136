// import accordion from "./components/accordion";
// import alert from "./components/alert";
// import avatar from "./components/avatar";
// import breadcrumbs from "./components/breadcrumbs";
import button from './components/button';
import card from './components/card';
import cardBody from './components/card/cardBody';
import cardFooter from './components/card/cardFooter';
import cardHeader from './components/card/cardHeader';
// import checkbox from "./components/checkbox";
// import chip from "./components/chip";
// import dialog from "./components/dialog";
// import dialogBody from "./components/dialog/dialogBody";
// import dialogFooter from "./components/dialog/dialogFooter";
// import dialogHeader from "./components/dialog/dialogHeader";
// import iconButton from "./components/iconButton";
import input from './components/input';
// import menu from "./components/menu";
import navbar from './components/navbar';
import popover from './components/popover';
// import progress from "./components/progress";
// import radio from "./components/radio";
import select from './components/select';
import multiSelect from './components/multiselect';

// import switchButton from "./components/switch";
// import tab from "./components/tabs/tab";
// import tabs from "./components/tabs";
// import tabsBody from "./components/tabs/tabsBody";
// import tabsHeader from "./components/tabs/tabsHeader";
// import tabPanel from "./components/tabs/tabPanel";
// import textarea from "./components/textarea";
// import tooltip from "./components/tooltip";
import typography from './components/typography';
// import collapse from "./components/collapse";
// import list from "./components/list";
// import buttonGroup from "./components/buttonGroup";
// import carousel from "./components/carousel";
// import drawer from "./components/drawer";
// import badge from "./components/badge";
// import rating from "./components/rating";
import slider from './components/slider';
import spinner from './components/spinner';
// import {
//   timeline,
//   timelineItem,
//   timelineIcon,
//   timelineConnector,
//   timelineHeader,
//   timelineBody,
// } from "./components/timeline";
// import { step, stepper } from "./components/stepper";
// import { speedDial, speedDialContent, speedDialAction } from "./components/speedDial";

const theme: any = {
  //   accordion,
  //   alert,
  //   avatar,
  //   breadcrumbs,
  button,
  card,
  cardBody,
  cardFooter,
  cardHeader,
  //   checkbox,
  //   chip,
  //   dialog,
  //   dialogBody,
  //   dialogFooter,
  //   dialogHeader,
  //   iconButton,
  input,
  //   menu,
  navbar,
  popover,
  //   progress,
  //   radio,
  select,
  multiSelect,
  //   switch: switchButton,
  //   tab,
  //   tabs,
  //   tabsBody,
  //   tabsHeader,
  //   tabPanel,
  //   textarea,
  //   tooltip,
  typography,
  //   collapse,
  //   list,
  //   buttonGroup,
  //   carousel,
  //   drawer,
  //   badge,
  //   rating,
  slider,
  spinner,
  //   timeline,
  //   timelineItem,
  //   timelineIcon,
  //   timelineHeader,
  //   timelineBody,
  //   timelineConnector,
  //   step,
  //   stepper,
  //   speedDial,
  //   speedDialContent,
  //   speedDialAction,
};

// export * from "./components/accordion";
// export * from "./components/alert";
// export * from "./components/avatar";
// export * from "./components/breadcrumbs";
export * from './components/button';
// export * from "./components/card";
// export * from "./components/card/cardBody";
// export * from "./components/card/cardFooter";
// export * from "./components/card/cardHeader";
// export * from "./components/checkbox";
// export * from "./components/chip";
// export * from "./components/dialog";
// export * from "./components/dialog/dialogBody";
// export * from "./components/dialog/dialogFooter";
// export * from "./components/dialog/dialogHeader";
// export * from "./components/iconButton";
export * from './components/input';
// export * from "./components/menu";
export * from './components/navbar';
export * from './components/popover';
// export * from "./components/progress";
// export * from "./components/radio";
export * from './components/select';
// export * from "./components/switch";
// export * from "./components/tabs/tab";
// export * from "./components/tabs";
// export * from "./components/tabs/tabsBody";
// export * from "./components/tabs/tabsHeader";
// export * from "./components/tabs/tabPanel";
// export * from "./components/textarea";
// export * from "./components/tooltip";
export * from './components/typography';
// export * from "./components/collapse";
// export * from "./components/list";
// export * from "./components/buttonGroup";
// export * from "./components/carousel";
// export * from "./components/drawer";
// export * from "./components/badge";
// export * from "./components/rating";
export * from './components/slider';
export * from './components/spinner';
// export * from "./components/timeline";
// export * from "./components/stepper";
// export * from "./components/speedDial";
export default theme;
