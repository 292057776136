type ProjectStatusCardProps = {
  readonly statusName: string;
  readonly value: number;
  readonly icon: string | JSX.Element;
  readonly iconBackground: string;
};
export const ProjectStatusCard = ({
  statusName,
  value,
  icon,
  iconBackground,
}: ProjectStatusCardProps) => {
  return (
    <div className="flex h-[88px] w-full items-center justify-between gap-3 px-4 py-14">
      <div>
        <p className="text-md mb-2 font-normal text-gray-700">{statusName}</p>
        <p className="text-2xl font-semibold text-black">{value}</p>
      </div>
      <div className={`rounded-full ${iconBackground} p-1.5 text-2xl`}>{icon}</div>
    </div>
  );
};
